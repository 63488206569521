import { map } from "rxjs/operators";
// Angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// RxJS
import { Observable } from "rxjs";

import { API } from "../../../core/_base/crud";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { TransactionModel } from "./transaction.model";
import { EntityType } from "../enums/entity/entityType";

@Injectable()
export class TransactionsService {
  constructor(private http: HttpClient) {}

  createProduct(product): Observable<any> {
    return this.http.post<any>(API.transactions, product);
  }

  getProductById(productId: number): Observable<any> {
    return this.http.get<any>(API.transactions + `/${productId}`);
  }

  indexProduct(params: IRequestPagination): Observable<any> {
    return this.http.get<IResponsePagination<TransactionModel>>(API.transactions, {
      params: { ...params },
    });
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.put<any>(`${API.transactions}/${productId}`, product);
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.delete<any>(`${API.transactions}/${productId}`);
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(`${API.roles}`);
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(`${API.permissions}/categories`);
  }

  getCVRData(q): Observable<any> {
    return this.http.post<any>(`${API.CVRData}`, { search: q });
  }

  downloadTransactionSetAttachment(transactionSetId) {
    return this.http.get(`${API.transactions}/download/set/${transactionSetId}`, {
      responseType: "blob",
    });
  }

  downloadTransactionsFiles(transaction_set_id) {
    return this.http.get(`${API.transactions}/download-files`, {
      params: { transaction_set_id },
    });
  }

  deleteTransactionSet(productIds, id, type) {
    if (type === EntityType.CONTRACT) {
      return this.http.delete<any>(`${API.transactionSets}/delete`, {
        body: { contract_id: id, transaction_set_ids: productIds },
      });
    } else {
      return this.http.delete<any>(`${API.transactionSets}/delete`, {
        body: { asset_id: id, transaction_set_ids: productIds },
      });
    }
  }

  reverseTransactionSet(productIds, id, type) {
    if (type === EntityType.CONTRACT) {
      return this.http.post<any>(`${API.transactionSets}/reverse`, {
        contract_id: id,
        transaction_set_ids: productIds,
      });
    } else {
      return this.http.post<any>(`${API.transactionSets}/reverse`, { vehicle_id: id, transaction_set_ids: productIds });
    }
  }

  getContractsList(q): Observable<any> {
    return this.http.get<any>(`${API.contracts}/getAll`, {
      params: {
        q,
      },
    });
  }

  getVehiclesList(q): Observable<any> {
    return this.http.get<any>(`${API.vehicles}/getAll/list`, {
      params: {
        q,
      },
    });
  }

  getContractDetails(contract_id) {
    return this.http.get<any>(`${API.contracts}/${contract_id}`);
  }

  getTransactionsList(contract_id) {
    return this.http.get<any>(`${API.transactionSets}/${contract_id}`).pipe(map((res) => res.data));
  }

  downloadImportTemplate() {
    return this.http.get(`${API.transactions}/download/import-template`, {
      responseType: "blob",
    });
  }

  importTransactions(payload: FormData) {
    return this.http.post(`${API.transactions}/import-sheet`, payload);
  }
}
