import { HttpErrorResponse } from "@angular/common/http";
import { ErrorResponse } from "../../../../views/pages/error-reponse.interface";

export function constructErrorDetailsMessage(err: HttpErrorResponse): string {
  const ExtraInformation = err?.error?.errors?.ExtraErrors.join("</br>");
  return `${err?.error?.errors?.message} </br> ${ExtraInformation}`;
}

export function returnErrorDetails(err: HttpErrorResponse): ErrorResponse {
  return {
    message: err?.error?.errors?.message,
    errors: err?.error?.errors?.ExtraErrors,
  };
}
