// Angular
import { PartialsModule } from "../../partials/partials.module";
import { NgxPermissionsModule } from "ngx-permissions";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

// Components
import { CreatePaymentDialogComponent } from "./create-payment/create-payment-dialog/create-payment-dialog.component";
import { CreateMonthlyPaymentComponent } from "./create-payment/monthly-payment/create-monthly-payment.component";
import { CreateSinglePaymentComponent } from "./create-payment/single-payment/create-single-payment.component";
import { CreateBulkInvoicesDialogComponent } from "./create-bulk-invoices-dialog.component";
import { PaymentsListComponent } from "./payments-list.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PaymentsService } from "./payments.service";
import { VehiclesService } from "../../pages/vehicles-management/vehicles";
import { CoreModule } from "../../../core/core.module";

@NgModule({
  declarations: [
    CreateBulkInvoicesDialogComponent,
    CreateMonthlyPaymentComponent,
    CreateSinglePaymentComponent,
    CreatePaymentDialogComponent,
    PaymentsListComponent,
  ],
  providers: [PaymentsService, VehiclesService],
  imports: [NgxPermissionsModule.forChild(), PartialsModule, DragDropModule, CommonModule, CoreModule],
})
export class PaymentsModule {}
