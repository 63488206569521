import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject, of } from "rxjs";
import { catchError, debounceTime, map, startWith, switchMap, takeUntil, tap } from "rxjs/operators";
import { CustomerEditComponent } from "../../../../pages/customers/customer-edit.component";
import { CustomerTypes } from "../../../enums/customer/customerTypes";
import { EntityTypes } from "../../../enums/entity/entityTypes";
import { PaymentConstants } from "../../payment.constants";
import { LayoutUtilsService, MessageType } from "./../../../../../core/_base/crud/utils/layout-utils.service";
import { TypesUtilsService } from "./../../../../../core/_base/crud/utils/types-utils.service";
import { SharedService } from "./../../../shared.service";
import { PaymentsService } from "./../../payments.service";

@Component({
  selector: "kt-create-monthly-payment",
  templateUrl: "./create-monthly-payment.component.html",
})
export class CreateMonthlyPaymentComponent implements OnInit, OnDestroy {
  compLifeSub: Subject<any> = new Subject();
  createMonthlyPaymentForm: FormGroup;
  filteredProducts: Observable<any>;
  loading: boolean = false;
  entityTypes = EntityTypes;
  errorMsgs = {};
  privateCustomers: Observable<any[]>;
  corporateCustomers: Observable<any[]>;
  contract_type: string;

  constructor(
    public dialogRef: MatDialogRef<CreateMonthlyPaymentComponent>,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentsService: PaymentsService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private productsService: SharedService
  ) {
    this.initializeForm();
    this.listenToProductSelection();
  }

  ngOnInit() {
    this.contract_type = this.data.contract_type;
    if (this.data.contract_id) {
      this.createMonthlyPaymentForm.get("contract_id").patchValue(this.data.contract_id);
    }
    this.getCustomersList();
  }

  getCustomersList() {
    this.privateCustomers = this.createMonthlyPaymentForm.get("privateCustomer").valueChanges.pipe(
      startWith(""),
      debounceTime(250),
      switchMap((value) => {
        if (typeof value == "string") {
          let params = { q: value, order_column: "name", order_dir: "asc" };
          return this.sharedService.getCustomersList(params);
        } else return of([]);
      }),
      map((res) => res.data)
    );

    if (this.contract_type == EntityTypes.SPLIT_LEASING) {
      this.corporateCustomers = this.createMonthlyPaymentForm.get("corporateCustomer").valueChanges.pipe(
        startWith(""),
        debounceTime(250),
        switchMap((value) => {
          if (typeof value == "string") {
            let params = { q: value, order_column: "name", order_dir: "asc" };
            return this.sharedService.getCustomersList(params);
          } else return of([]);
        }),
        map((res) => res.data)
      );
    }

    this.data.contract_customers?.map((contractCustomer) => {
      if (contractCustomer.customer.customer_type.code == EntityTypes.PRIVATE) {
        this.createMonthlyPaymentForm.get("privateCustomer")?.setValue(contractCustomer.customer);
      }
      if (this.contract_type == EntityTypes.CORPORATE) {
        this.createMonthlyPaymentForm.get("privateCustomer")?.setValue(contractCustomer.customer);
      }
      if (contractCustomer.customer.customer_type == CustomerTypes.CORPORATE) {
        this.createMonthlyPaymentForm.get("corporateCustomer")?.setValue(contractCustomer.customer);
      }
    });
  }

  initializeForm() {
    let validations: Object = {
      contract_id: [this.data.contract_id],
      product: ["", [Validators.required]],
      privateCustomer: [
        this.data.contract_customers?.find(
          (contractCustomer) => contractCustomer.customer.customer_type == CustomerTypes.PRIVATE
        )?.customer,
        [Validators.required],
      ],
      start_date: ["", [Validators.required]],
      months: [1, [Validators.required, Validators.min(1)]],
      price_excl_vat_cents: [0, [Validators.required]],
    };

    if (
      this.data?.paymentType !== PaymentConstants.VEHICLE_PAYMENT_TYPE &&
      this.data?.contract_type === EntityTypes.SPLIT_LEASING
    ) {
      let corporateValidation: Object = {
        corporateCustomer: [
          this.data.contract_customers.find(
            (contractCustomer) => contractCustomer.customer.customer_type == CustomerTypes.CORPORATE
          )?.customer,
          Validators.required,
        ],
      };

      validations = { ...validations, ...corporateValidation };
    }
    this.createMonthlyPaymentForm = this.fb.group(validations);

    this.getProductList();
  }

  listenToProductSelection() {
    this.createMonthlyPaymentForm.get("product").valueChanges.subscribe((res) => {
      if (res) this.createMonthlyPaymentForm.get("price_excl_vat_cents").patchValue(res.price_excl_vat_cents);
    });
  }

  getProductList() {
    this.filteredProducts = this.createMonthlyPaymentForm.get("product").valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (typeof value == "string") {
          return this.sharedService.getProductsList({
            q: value,
            is_payment_type: 1,
            is_monthly_product: 1,
          });
        } else return of([]);
      }),
      map((res) => res.data),
      catchError((err) => {
        return of([]);
      })
    );
  }

  onSubmit() {
    if (this.createMonthlyPaymentForm.valid) {
      this.loading = true;

      // Prepare payload
      let payload = this.createMonthlyPaymentForm.value;

      payload.product_id = payload.product.id;
      payload.private_customer = payload.privateCustomer?.id;
      payload.corporate_customer = payload.corporateCustomer?.id;
      payload.start_date = this.typesUtilsService.formatDate(payload.start_date, "dd-MM-yyyy");
      delete payload.product;
      delete payload.corporateCustomer;
      delete payload.privateCustomer;
      if (this.data?.paymentType == PaymentConstants.VEHICLE_PAYMENT_TYPE) {
        this.productsService
          .storeVehicleMonthlyPayment({ ...payload, vehicle_id: this.data?.id })
          .pipe(
            tap((res) => {
              if (res.data) {
                this.layoutUtilsService.showActionNotification(
                  res.data.message,
                  MessageType.Create,
                  10000,
                  true,
                  false
                );
              }
              this.dialogRef.close(true);
            }),
            takeUntil(this.compLifeSub)
          )
          .subscribe(null, (err) => {
            this.loading = false;
            if (err.error) {
              this.errorMsgs = err.error.errors || {};
            }
          });
      } else
        this.paymentsService
          .createMonthlyPayment(payload)
          .pipe(
            tap((res) => {
              if (res.data) {
                this.layoutUtilsService.showActionNotification(
                  res.data.message,
                  MessageType.Create,
                  10000,
                  true,
                  false
                );
              }
              this.dialogRef.close(true);
            }),
            takeUntil(this.compLifeSub)
          )
          .subscribe(null, (err) => {
            this.loading = false;
            if (err.error) {
              this.errorMsgs = err.error.errors || {};
            }
          });
    }
  }

  displayFn(product: any): string {
    return product && product.name ? product.name : "";
  }

  displayCustomerFn(customer: any): string {
    return customer && customer.name ? customer.name : "";
  }

  // Clean up
  ngOnDestroy(): void {
    this.compLifeSub.complete();
    this.compLifeSub.unsubscribe();
  }

  onAddNewCustomer() {
    const dialogRef = this.dialog.open(CustomerEditComponent, {
      width: "900px",
      maxHeight: "90vh",
      data: { isCancelEnabled: true, entityType: "contract" },
    });
    dialogRef.afterClosed().subscribe((payload) => {
      if (payload?.customer?.customer_type) {
        if (payload.customer.customer_type == CustomerTypes.PRIVATE) {
          this.createMonthlyPaymentForm.get("privateCustomer").setValue(payload.customer);
        }
        if (payload.customer.customer_type == CustomerTypes.CORPORATE) {
          this.createMonthlyPaymentForm.get("corporateCustomer").setValue(payload.customer);
        }
        if (this.data.contract_type !== EntityTypes.SPLIT_LEASING) {
          this.createMonthlyPaymentForm.get("privateCustomer").setValue(payload.customer);
        }
      }
    });
  }
}
