<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "TRANSACTIONS.VIEW.IMPORT_TRANSACTION_MODAL.TITLE" | translate }}
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <div class="kt-form__group">
        <div class="row">
          <div class="col-md-8 kt-margin-bottom-10-mobile">
            <mat-hint class="mx-2" align="start">
              {{ "TRANSACTIONS.ADD.FIELDS.ATTACHMENTS.NAME" | translate }}</mat-hint
            >
            <div class="row dropzone-row">
              <div class="col-md-10 kt-margin-bottom-10-mobile">
                <div class="dropzone-wrapper dropzone-borderless dropzone-multi">
                  <dropzone
                    #documentsDropzone
                    [disabled]="false"
                    [config]="dropZoneConfig"
                    [message]="dropzoneMessage"
                    (removedFile)="removeFile()"
                    (addedFile)="addFile($event)"
                  >
                  </dropzone>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex align-self-center justify-content-center">
            <button (click)="downloadTemplate()" class="btn btn-primary mx-2" color="primary" mat-raised-button>
              <i class="fas fa-download"></i>
              <span class="kt-nav__link-text">
                {{ "CONTRACTS.DETAILS.TRANSACTIONS.DOWNLOAD_TEMPLATE" | translate }}
              </span>
            </button>
          </div>
        </div>
        <div class="mt-4">
          <ul>
            <mat-error *ngFor="let err of importErrors">
              <li class="py-1" *ngFor="let msg of err.message">
                <span *ngIf="err.row !== 0"> Row {{ err.row }} - </span>
                <span *ngIf="err.row === 0"> Sheet 1 - </span>
                <span> {{ err.column }} - </span>
                <span> {{ msg }} </span>
              </li>
            </mat-error>
          </ul>
        </div>
      </div>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="onDismiss()">
          {{ "GENERAL.CANCEL" | translate }}
        </button>
        <button
          (click)="importTransactions()"
          class="btn btn-primary"
          color="primary"
          mat-raised-button
          [disabled]="viewLoading || numOfFiles !== 1"
        >
          {{ "TRANSACTIONS.ADD.ACTIONS.IMPORT" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
