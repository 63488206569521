import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS

import { NoteModel } from "./note.model";
import { SharedService } from "../shared.service";
import { MatDialog } from "@angular/material/dialog";
import { NoteAndFieldsDialogComponent } from "./note-and-fields-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService, MessageType, API } from "../../../core/_base/crud";
import { DOCUMENT } from "@angular/common";
import { currentUser } from "../../../core/auth";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../core/reducers";

@Component({
  selector: "cc-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.scss"],
})
export class NotesComponent implements OnInit, OnChanges {
  public product: NoteModel;
  public productForm: FormGroup;
  public notes: Array<NoteModel>;
  public customers: Array<any>;
  permissions: any = [];
  @Input() entityCustomers;
  @Input() entity_id;
  @Input() entity_type;
  @Input() user;
  idNote: any;
  updateNote: boolean = false;
  example: string;

  // user: any;

  constructor(
    private productsService: SharedService,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    @Inject(DOCUMENT) private document
  ) {
    this.notes = new Array();
    this.customers = new Array();
  }

  ngOnInit(): void {
    this.getNotes();
    this.loadCustomers();

    if (!this.user) {
      this.store.pipe(select(currentUser)).subscribe((res: { permission_list: [] }) => {
        this.user = res;
        this.permissions = this.user?.permissions_list;
      });
    } else {
      this.permissions = this.user?.permissions_list;
    }

    this.updateNote = this.permissions?.some((permission) => permission.name === "update_notes") ?? false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadCustomers();
  }

  getNotes() {
    this.notes = [];
    this.productsService.getContractOrCustomerNotes(this.entity_id, this.entity_type).subscribe((res) => {
      res.data.forEach((note) => {
        this.notes.push(new NoteModel().setAttributes(note));
        this.ref.detectChanges();
      });
    });
  }

  public loadCustomers() {
    this.customers = [];
    switch (this.entity_type) {
      case "customer":
        this.customers = [this.entityCustomers];
        break;

      default:
        this.entityCustomers.forEach((customer) => {
          if (customer.is_active || this.entity_type === "offer") this.customers.push(customer.customer);
        });
        break;
    }
  }

  editNote(note: NoteModel) {
    const dialogRef = this.dialog.open(NoteAndFieldsDialogComponent, {
      data: {
        entity_type: this.entity_type,
        entity: note ? note : new NoteModel().clear(),
        entity_id: this.entity_id,
        customers: this.customers,
        permissions: this.permissions,
      },
      width: "600px",
      maxHeight: "70vh",
    });
    dialogRef.afterClosed().subscribe((res) => {
      res = res?.data;
      if (res && !note) {
        res.text = res.text.replaceAll("\n", "<br>");
        this.notes.unshift(res);
        this.layoutUtilsService.showActionNotification(
          this.translate.instant("NOTES.ADD.CREATE_SUCCESS"),
          MessageType.Create
        );
        this.ref.detectChanges();
      } else if (res) {
        res.text = res.text.replaceAll("\n", "<br>");
        let i = this.notes.indexOf(note);
        this.notes[i] = res;
        this.layoutUtilsService.showActionNotification(
          this.translate.instant("NOTES.ADD.UPDATE_SUCCESS"),
          MessageType.Update
        );
        this.ref.detectChanges();
      }
    });
  }

  deleteNote(deletedNote: { id: number }) {
    this.productsService.deleteNote(this.entity_type, deletedNote).subscribe(
      (res: any) => {
        this.notes.splice(
          this.notes.findIndex((note) => note.id == deletedNote.id),
          1
        );
        // we Need change the message response later
        this.layoutUtilsService.showActionNotification("Item has been deleted successfully", MessageType?.Delete);
        this.ref.detectChanges();
      },
      (err) => {
        this.layoutUtilsService.showActionNotification(
          "Item has not been deleted successfully Try again later!",
          MessageType?.Delete
        );
      }
    );
  }

  deleteProduct(note) {
    const _title: string = this.translate.instant("GENERAL.DELETE_NOTE");
    const _description: string = this.translate.instant("GENERAL.DELETE_NOTE_MSG");
    const _waitDesciption: string = this.translate.instant("GENERAL.DELETE_WAIT_MSG");
    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.deleteNote(note);
      }
    });
  }

  toggleNoteOptions(id) {
    this.idNote = id;
    if (this.document.getElementById(id).classList.contains("hide-note-options")) {
      this.document.getElementById(id).classList.add("view-note-options");
      this.document.getElementById(id).classList.remove("hide-note-options");
    } else {
      this.document.getElementById(id).classList.add("hide-note-options");
      this.document.getElementById(id).classList.remove("view-note-options");
    }
  }

  onClickedOutside(event) {
    if (event == this.idNote) {
      this.document.getElementById(event).classList.add("hide-note-options");
      this.document.getElementById(event).classList.remove("view-note-options");
      this.idNote = undefined;
    }
  }
}
