<div class="kt-quick-search__result" id="kt-quick-search__result">
  <ng-container *ngFor="let item of data; let i = index">
    <ng-container
      *ngIf="item.type === 0"
      [ngTemplateOutlet]="separatorTemplate"
      [ngTemplateOutletContext]="{ item: item, index: i + 1 }"
    ></ng-container>
    <ng-container
      *ngIf="item.type === 1"
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{ item: item, index: i + 1 }"
    ></ng-container>
  </ng-container>
  <ng-container *ngIf="data && data.length === 0" [ngTemplateOutlet]="emptyResultTemplate"></ng-container>
</div>

<ng-template #itemTemplate let-item="item" let-index="index">
  <div tabindex="{{ index }}" class="kt-quick-search__item kt-quick-search__result__child" [routerLink]="item.url">
    <!-- <div class="" [innerHTML]="item.icon ? item.icon : item.img" [ngClass]="{'kt-quick-search__item-img': item.img, 'kt-quick-search__item-icon': item.icon}">
		</div> -->
    <div class="kt-quick-search__item-wrapper">
      <a href="javascript:;" (click)="redirectToResult()" [routerLink]="item.url" class="kt-quick-search__item-title">
        {{ item.title }}
      </a>
      <!-- <div class="kt-quick-search__item-desc">
				{{item.text}}
			</div> -->
    </div>
  </div>
</ng-template>

<ng-template #separatorTemplate let-item="item" let-index="index">
  <div tabindex="{{ index }}" class="kt-quick-search__category kt-quick-search__result__child" [routerLink]="item.url">
    <a href="javascript:;" (click)="redirectToResult()" [routerLink]="item.url" class="kt-quick-search__item-title">
      {{ item.title }}
    </a>
  </div>
</ng-template>

<ng-template #emptyResultTemplate>
  <div class="kt-quick-search__message kt-hidden">
    {{ noRecordText || "No record found" }}
  </div>
</ng-template>
