export enum LicensePlatesTypes {
  YELLOW = "Yellow",
  PARROT = "Parrot",
  NO_PLATE = "NoPlate",
  WHITE = "White",
}

export const licensePlatesLabels = new Map<string, string>([
  [LicensePlatesTypes.YELLOW, "CONTRACTS.DETAILS.LICENSE_PLATE_Types.YELLOW"],
  [LicensePlatesTypes.PARROT, "CONTRACTS.DETAILS.LICENSE_PLATE_Types.PARROT"],
  [LicensePlatesTypes.NO_PLATE, "CONTRACTS.DETAILS.LICENSE_PLATE_Types.NO_PLATE"],
  [LicensePlatesTypes.WHITE, "CONTRACTS.DETAILS.LICENSE_PLATE_Types.WHITE"],
]);
