// Angular
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../../../../../core/auth";

@Component({
  selector: "kt-search-dropdown",
  templateUrl: "./search-dropdown.component.html",
})
export class SearchDropdownComponent implements OnInit {
  // Public properties

  // Set icon class name
  @Input() icon = "flaticon2-search-1";

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  @Input() type: "brand" | "success" | "warning" = "success";

  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;

  data: any[];
  result: any[];
  loading: boolean;
  searchResult: any[] = [];

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  constructor(private cdr: ChangeDetectorRef, private authService: AuthService) {}

  /**
   * On init
   */
  ngOnInit(): void {
    // simulate result from API
    // type 0|1 as separator or item
    this.result = [
      {
        text: "Documents",
        type: 0,
      },
      {
        icon: '<i class="flaticon-interface-3 kt-font-warning">',
        text: "Annual finance report",
        type: 1,
      },
      {
        icon: '<i class="flaticon-share kt-font-success"></i>',
        text: "Company meeting schedule",
        type: 1,
      },
      {
        icon: '<i class="flaticon-paper-plane kt-font-info"></i>',
        text: "Project quotations",
        type: 1,
      },
      {
        text: "Customers",
        type: 0,
      },
      {
        img: '<img src="assets/media/users/user1.jpg" alt="">',
        text: "Amanda Anderson",
        type: 1,
      },
      {
        img: '<img src="assets/media/users/user2.jpg" alt="">',
        text: "Kennedy Lloyd",
        type: 1,
      },
      {
        img: '<img src="assets/media/users/user3.jpg" alt="">',
        text: "Megan Weldon",
        type: 1,
      },
      {
        img: '<img src="assets/media/users/user4.jpg" alt="">',
        text: "Marc-André ter Stegen",
        type: 1,
      },
      {
        text: "Files",
        type: 0,
      },
      {
        icon: '<i class="flaticon-lifebuoy kt-font-warning"></i>',
        text: "Revenue report",
        type: 1,
      },
      {
        icon: '<i class="flaticon-coins kt-font-primary"></i>',
        text: "Anual finance report",
        type: 1,
      },
      {
        icon: '<i class="flaticon-calendar kt-font-danger"></i>',
        text: "Tax calculations",
        type: 1,
      },
    ];
  }

  /**
   * Search
   * @param e: Event
   */
  search(e) {
    this.data = null;
    this.searchResult = [];
    if (e.target.value.length > 2) {
      this.loading = true;
      this.authService.globalSearch(e.target.value).subscribe((res) => {
        this.searchResult = [];
        Object.keys(res.data).forEach((key) => {
          if (key && res.data[key] && res.data[key].length) {
            this.searchResult.push({
              title: key,
              type: 0,
              url: `/${key}`,
            });
            res.data[key].forEach((element) => {
              this.searchResult.push({
                img: `<img src="/assets/media/users/default.jpg" alt="${element.name}">`,
                title: element.name,
                text: element.email,
                type: 1,
                url: `/${key}/edit/${element.id}`,
              });
            });
          }
        });
        this.loading = false;
        this.cdr.markForCheck();
      });
      // simulate getting search result
      // setTimeout(() => {
      // 	this.data = this.result;
      // 	this.loading = false;
      // 	this.cdr.markForCheck();
      // }, 500);
    }
  }

  /**
   * Clear search
   *
   * @param e: Event
   */
  clear(e) {
    this.data = null;
    this.searchInput.nativeElement.value = "";
  }

  openChange() {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }
}
