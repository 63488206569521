import { iif } from "rxjs";
// Angular
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Moment } from "moment";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, Observable, Subscription, of } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from "rxjs/operators";
import { LayoutUtilsService, MessageType } from "../../../core/_base/crud";
import { CustomerTypes } from "../enums/customer/customerTypes";
import { SharedService } from "../shared.service";
import { CustomersModel } from "./customers.model";
import { EntityType } from "../enums/entity/entityType";
import { IRequiredFieldsSettings } from "../../pages/settings/components/required-fields-settings-dialog/required-fields-settings.interface";
import { IResponse } from "../../../../app/core/_base/crud/interfaces/response-interface";
import { BaseSettingsModel } from "../../pages/settings/base-settings.model";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../app/core/reducers";
import { useCustomerFieldsSettings } from "../../../../app/core/_base/crud/utils/form-utils";
import { selectSettings } from "../../../../app/core/auth/_selectors/setting.selectors";
import { CustomerFormDefaultSettings } from "../../pages/settings/components/required-fields-settings-dialog/required-fields-enum";

@Component({
  selector: "kt-customer-form",
  templateUrl: "./customer-form.component.html",
  styleUrls: ["./customer-form.component.scss"],
  styles: [],
})
export class CustomerFromComponent implements OnInit, OnDestroy {
  @Input() id: any;
  @Input() withSubmit: boolean = true;
  @Input() columns = 3; // Grid columns
  @Input() customerTypeCode; // In case we need a specific type i.e. [split-leasing]
  @Input() entityType;
  @Input() isCancelEnabled: boolean = false;
  @Input() formId: number;
  @Output() customerSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  // Public properties
  product: CustomersModel;
  paymentTerms: any[];
  customerTypesEnums = CustomerTypes;
  customerTypes: any[];
  users: any[];
  loadingSubject = new BehaviorSubject<boolean>(true);
  loading$: Observable<boolean>;
  createdInContract: boolean = false;
  productForm: FormGroup;
  hasFormErrors: boolean = false;
  isReadonly: boolean = false;
  filteredUsers: Observable<any[]>;
  filteredCompanies: Observable<any[]>;
  filteredRiskClassifications: Observable<any[]>;
  filteredCurrencies: Observable<any[]>;
  errorMsgs: any = {}; //Back-end errors
  formSettings: BaseSettingsModel<IRequiredFieldsSettings>;
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  permissions: any = {}; // loggedin user permissions
  today: Moment = moment();
  last_zip_code: string | null = null;
  lastUsersPage: number = 1;
  isLoadingResults: boolean = false;

  @ViewChild("auto") selectElem: MatSelect;
  curPage = new BehaviorSubject<number>(1);
  concatWithNewData: any[] = [];
  settings$: Observable<IResponse<BaseSettingsModel<IRequiredFieldsSettings>>>;

  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private productsService: SharedService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private sharedService: SharedService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);

    this.permissionsService.permissions$.subscribe((res) => {
      this.isReadonly = this.id && !res.update_customer ? true : false;
      this.permissions = res;
      if (!this.id) {
        const newProduct = new CustomersModel();
        newProduct.clear();
        this.loadProduct(newProduct);
      }
    });

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };

    const customerTypesSubscription = this.productsService.getLookups("customer_types").subscribe((res) => {
      this.customerTypes = res.data;
      this.setDefaultCustomerType();
    });
    this.componentSubscriptions.push(customerTypesSubscription);

    const formClearanceExclusions = ["customer_type"];
    if (this.formId === 1) {
      const clearCustomerFormDataSubscription = this.sharedService.clearCustomerFormDataSubject.subscribe(() => {
        for (const c in this.productForm?.controls) {
          if (!formClearanceExclusions.includes(c)) this.productForm.controls[c].reset();
        }
      });
      this.componentSubscriptions.push(clearCustomerFormDataSubscription);
    } else {
      const clearCustomerFormDataSubscription = this.sharedService.clearCustomerSecondFormDataSubject.subscribe(() => {
        for (const c in this.productForm?.controls) {
          if (!formClearanceExclusions.includes(c)) this.productForm.controls[c].reset();
        }
      });
      this.componentSubscriptions.push(clearCustomerFormDataSubscription);
    }

    const paymentTermsSubscription = this.productsService.getLookups("payment_terms").subscribe((res) => {
      this.paymentTerms = res.data;
    });
    this.componentSubscriptions.push(paymentTermsSubscription);

    const usersSubscription = this.productsService.getUsers().subscribe((res) => {
      this.users = res.data;
      this.lastUsersPage = res.last_page;
      this.users = this.users.map((user) => {
        user.id = +user.id;
        return user;
      });
    });
    this.componentSubscriptions.push(usersSubscription);
  }

  loadCityName() {
    if (
      this.productForm.controls["postal_code"]?.dirty &&
      (this.productForm.controls["postal_code"]?.value !== this.last_zip_code || this.last_zip_code === null)
    ) {
      let postalCityName = "";
      let zip_code = this.productForm.controls["postal_code"]?.value;
      this.sharedService
        .getPostalCodeCity(zip_code)
        .subscribe((res: { id: number; zip_code: string; name: string }) => {
          this.last_zip_code = res.zip_code;
          if (!this.productForm.controls["postal_code"]?.value) {
            this.productForm.get("postal_code").setErrors({ required: true });
            this.productForm.get("city").setValue("");
          } else {
            this.productForm.get("postal_code").setErrors({ required: false });
            this.productForm.get("postal_code").updateValueAndValidity();
            postalCityName = res.name;
            this.productForm.get("city").setValue(postalCityName);
          }
          this.cdr.detectChanges();
        });
    } else {
      return;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["id"] && changes["id"].currentValue) {
      this.id = changes["id"].currentValue;
      this.loadProductFromService(changes["id"].currentValue);
      this.productForm?.markAsDirty();
    }
    if (
      changes["customerTypeCode"] &&
      !changes["customerTypeCode"].firstChange &&
      changes["customerTypeCode"].currentValue
    ) {
      this.setDefaultCustomerType();
      this.productForm?.markAsDirty();
    }

    if (this.productForm && this.customerTypeCode === CustomerTypes.CORPORATE) {
      this.productForm?.markAsDirty();

      this.productForm.get("customer_name").clearValidators();
    } else if (this.productForm) {
      this.productForm.get("customer_name").setValidators([Validators.required]);
      this.productForm?.markAsDirty();
    }
    this.productForm?.markAsDirty();
  }

  managerDisplayFn(user: any): string {
    return user && user.name ? user.name : "";
  }

  companyDisplayFn(company: any): string {
    return company && company.name ? company.name : "";
  }

  riskClassDisplayFn(riskClass: any): string {
    return riskClass && riskClass.name ? riskClass.name : "";
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
    this.cdr.markForCheck();
  }

  loadProductFromService(productId) {
    const getProductByIdSubscription = this.productsService.getCustomerById(productId).subscribe((res) => {
      res.data.company_name = { name: res.data.name };
      let product = res.data;
      this.loadProduct(product);
    });
    this.componentSubscriptions.push(getProductByIdSubscription);
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? +c1.id === +c2.id : c1 === c2;
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  initProduct() {
    this.createForm();
    this.loadingSubject.next(false);
  }

  companySelected($event) {
    if ($event.option.value) {
      this.productForm.patchValue({
        ...$event.option.value,
        id_number: $event.option.value.company_cvr_number,
        phone: $event.option.value.phone_number,
        name: $event.option.value.name,
        company_contact_name: $event.option.value.company_contact_name,
      });
    }
    this.productForm.markAsDirty();
  }

  displayFn(supplier: any): string {
    return supplier && supplier.name ? supplier.name : "";
  }

  getCustomerByScrollEvent() {
    const panel = this.selectElem.panel.nativeElement;
    panel.addEventListener("scroll", (event) => {
      if (
        Math.round(event.target.scrollTop + event.target.offsetHeight) === event.target.scrollHeight &&
        this.curPage.getValue() < this.lastUsersPage
      ) {
        this.curPage.next(this.curPage.getValue() + 1);
      }
    });
  }

  restGetCustomer() {
    this.curPage.next(1);
  }

  createForm() {
    const currency = {
      name: this.product.currency ? this.product.currency : "DKK",
      code: this.product.currency ? this.product.currency : "DKK",
    };
    this.productForm = this.productFB.group({
      customer_name: [{ value: this.product.name, disabled: this.isReadonly }],
      company_name: [{ value: this.product.company_name }],
      risk_class_id: [{ value: this.product.risk_class }],
      company_contact_name: [
        { value: this.product.company_contact_name, disabled: this.isReadonly },
        this.product?.customer_type !== CustomerTypes.CORPORATE ? [Validators.nullValidator] : [Validators.required],
      ],
      email: [{ value: this.product.email, disabled: this.isReadonly }, Validators.required],
      payment_eligible: [this.product.payment_eligible],
      address: [{ value: this.product.address, disabled: this.isReadonly }],
      postal_code: [{ value: this.product.postal_code, disabled: this.isReadonly }],
      city: [{ value: this.product.city, disabled: this.isReadonly }],
      phone: [{ value: this.product.phone, disabled: this.isReadonly }],
      id_number: [
        { value: this.product.id_number, disabled: this.isReadonly },
        this.entityType === EntityType.CONTRACT ? [Validators.required] : [Validators.nullValidator],
      ],
      // this.product?.customer_type !== CustomerTypes.CORPORATE ? [Validators.maxLength(10)] : [Validators.required]
      currency: [{ value: currency, disabled: this.isReadonly }],
      country: [{ value: this.product.country, disabled: this.isReadonly }],

      account_manger: [{ value: this.product.manager, disabled: this.isReadonly }, Validators.required],
      customer_type: [
        {
          value: this.product.customer_type,
          disabled: this.isReadonly || this.customerTypeCode,
        },
        Validators.required,
      ],
      payment_term_id: [{ value: this.product.payment_term_id, disabled: this.isReadonly }],
      bank_code: [{ value: this.product.bank_code, disabled: this.isReadonly }],
      birthdate: [
        {
          value: this.product?.birthdate ? moment(this.product?.birthdate, "DD-MM-YYYY") : "",
          disabled: this.isReadonly,
        },
      ],
      bank_account_number: [{ value: this.product.bank_account_number, disabled: this.isReadonly }],
      ean_number: [this.product.ean_number, [Validators.minLength(13), Validators.maxLength(13)]],
    });

    if (this.product.name) {
      this.productForm.get("company_name").setValue({ name: this.product.name });
    }
    this.productForm.patchValue({
      risk_class_id: this.product.risk_class,
    });

    if (this.entityType === "offer") {
      this.productForm.controls["address"].clearValidators();
      this.productForm.controls["postal_code"].clearValidators();
      this.productForm.controls["city"].clearValidators();
      this.productForm.controls["id_number"].clearValidators();
    }

    if (!this.permissions["view_users"] || !this.permissions["assign_customer_account_manager"]) {
      this.productForm.controls["account_manger"].clearValidators();
      this.productForm.controls["account_manger"].updateValueAndValidity();
    }

    this.filteredUsers = this.productForm.get("account_manger").valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (value) {
          return this.productsService.getUsers(value);
        } else {
          return this.curPage.pipe(
            switchMap((curPage) => {
              return this.productsService.getUsers(value, {}, curPage);
            })
          );
        }
      }),
      map((res) => {
        if (this.productForm.get("account_manger").value) {
          return res.data;
        } else {
          return (this.concatWithNewData = this.concatWithNewData.concat(res.data));
        }
      })
    );
    this.filteredCompanies = this.productForm.get("company_name").valueChanges.pipe(
      //startWith(''),
      switchMap((value) => {
        if (typeof value == "string" && value !== "") {
          return this.productsService.getCVRData(value);
        } else return of([]);
      }),
      map((res) => res.data),
      catchError((err) => {
        return of([]);
      })
    );

    this.filteredRiskClassifications = this.productForm.get("risk_class_id").valueChanges.pipe(
      startWith(""),
      switchMap((value) => this.productsService.getRiskClassifications(value)),
      map((res) => res.data)
    );
    this.setDefaultCustomerType();

    this.filteredCurrencies = this.productForm.get("currency").valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (typeof value == "string" && value !== "") {
          return this.productsService.listCurrencies(value);
        } else return of([]);
      }),
      map((res) => res.data),
      catchError((err) => {
        return of([]);
      })
    );
    this.listenToIDNumberChanges();
    const settings$: Observable<IResponse<BaseSettingsModel<IRequiredFieldsSettings>>> =
      this.store.select(selectSettings);
    const settingSubscription = settings$.subscribe((settings) => {
      if (settings) {
        useCustomerFieldsSettings(settings, this.productForm, this.entityType, CustomerFormDefaultSettings);
      }
    });
    this.componentSubscriptions.push(settingSubscription);
  }

  listenToIDNumberChanges() {
    this.productForm
      .get("id_number")
      .valueChanges.pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        startWith(this.productForm.get("id_number")?.value),
        switchMap((value: string) =>
          iif(
            () => !!value && this.productForm.get("customer_type")?.value === CustomerTypes.PRIVATE,
            this.productsService.getBirthdayFromCPR(value).pipe(
              catchError((err) => {
                return of(err);
              })
            )
          )
        ),
        tap((birthdate: string) => {
          !!birthdate && this.productForm.get("birthdate").patchValue(moment(birthdate, "DD-MM-YYYY"));
        })
      )
      .subscribe();
  }

  getCustomerByIdNumber() {
    const idNumber = this.productForm.get("id_number").value;
    if (!idNumber) return;

    this.productsService.getCustomerByCPR(idNumber).subscribe(
      (res) => {
        if (res && res.customer_type === this.productForm.get("customer_type").value) {
          if (res.customer_type === CustomerTypes.CORPORATE) {
            this.productForm.get("company_name").setValue({ name: res.name });
            this.productForm.get("company_contact_name").setValue(res.company_contact_name);
          }
          this.productForm.get("customer_name").setValue(res.name);
          this.productForm.get("email").setValue(res.email);
          this.productForm.get("phone").setValue(res.phone);
          this.productForm.get("address").setValue(res.address);
          this.productForm.get("postal_code").setValue(res.postal_code);
          this.productForm.get("city").setValue(res.city);
          this.productForm.get("country").setValue(res.country);
          this.productForm.get("bank_code").setValue(res.bank_code);
          this.productForm.get("bank_account_number").setValue(res.bank_account_number);
          this.productForm.get("payment_term_id").setValue(res.payment_term_id);
          this.productForm.get("currency").setValue({ name: res.currency, code: res.currency });
          this.productForm.get("account_manger").setValue(res.manager);
          this.productForm.get("risk_class_id").setValue(res.risk_class);
          this.productForm.get("ean_number").setValue(res.ean_number);
          this.id = res.id;
          this.productForm.markAllAsTouched();
        }
      },
      (err) => {
        return of(null);
      }
    );
  }

  setDefaultCustomerType() {
    if (this.customerTypes && this.customerTypes.length > 0 && this.productForm) {
      if (this.id) {
        let customerTypeCode = CustomerTypes.PRIVATE;
        if (this.customerTypeCode) {
          customerTypeCode = this.customerTypeCode;
        } else if (this.product && this.product.customer_type) {
          customerTypeCode = this.product.customer_type;
        }
        this.product.customer_type = this.customerTypes.find((t) => t.code == customerTypeCode)?.code;
        this.productForm.get("customer_type").setValue(this.product.customer_type);
      } else {
        let customerTypeCode = this.customerTypeCode ? this.customerTypeCode : CustomerTypes.PRIVATE;
        this.product.customer_type = this.customerTypes.find((t) => t.code == customerTypeCode)?.code;
        this.productForm.get("customer_type").setValue(this.product.customer_type);
      }
    }
  }

  customerTypeChanged(event: { value: number }) {
    const controls = this.productForm.controls;
    if (event.value === CustomerTypes.CORPORATE) {
      controls["customer_name"].clearValidators();
      controls["customer_name"].updateValueAndValidity();
      // LEMA-1522
      // controls["id_number"].setValidators([Validators.required]);
      // controls["id_number"].removeValidators([Validators.maxLength(10)]);
      controls["company_contact_name"].setValidators([Validators.required]);
    } else {
      // controls["id_number"].clearValidators();
      // controls["id_number"].removeValidators([Validators.required]);
      // controls["id_number"].setValidators([Validators.maxLength(10)]);
      controls["company_contact_name"].removeValidators([Validators.required]);
      controls["company_contact_name"].disable({ emitEvent: false });
    }
    controls["id_number"].updateValueAndValidity();
  }

  reset() {
    this.createForm();
    this.hasFormErrors = false;
    this.productForm.markAsPristine();
    this.productForm.markAsUntouched();
    this.productForm.updateValueAndValidity();
  }

  onSubmit() {
    if (this.loadingSubject.value) {
      return;
    }
    this.hasFormErrors = false;
    const controls = this.productForm.controls;
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => {
        controls[controlName].markAsTouched();
      });

      this.hasFormErrors = true;
      return;
    }

    // tslint:disable-next-line:prefer-const
    let payload: any = this.prepareProduct();

    if (this.id > 0) {
      this.updateProduct(payload);
      return;
    }

    this.addProduct(payload);
  }

  /**
   * Returns object for saving
   */
  prepareProduct(): any {
    const controls = this.productForm.controls;
    let product: any = {};
    Object.keys(controls).forEach((key) => {
      let control: any = controls[key];
      if (control && control.value) {
        product[key] = control.value;
      }
    });
    product.entity_type = this.entityType;
    product.currency = controls["currency"].value ? controls["currency"].value.code : null;
    product.customer_type = controls["customer_type"].value ? controls["customer_type"].value : null;
    product.payment_eligible = controls["payment_eligible"].value === true ? 1 : 0;
    product.risk_class_id = controls["risk_class_id"].value ? controls["risk_class_id"].value.id : null;
    product.account_manager_id = controls["account_manger"].value ? controls["account_manger"].value.id : null;
    product.birthdate = controls["birthdate"].value ? moment(controls["birthdate"].value).format("DD-MM-YYYY") : null;
    if (!this.permissions["view_users"] || !this.permissions["assign_customer_account_manager"]) {
      delete product.account_manager_id;
    }
    if (controls["customer_type"].value == CustomerTypes.CORPORATE) {
      product.name = controls["company_name"].value?.name
        ? controls["company_name"].value.name
        : controls["company_name"].value;
      product.company_contact_name = controls["company_contact_name"].value
        ? controls["company_contact_name"].value
        : "";
    } else {
      product.name = controls["customer_name"].value ? controls["customer_name"].value : null;
      delete product.company_contact_name;
    }

    delete product.account_manger;
    delete product.company_name;
    delete product.customer_name;

    product.contract_creation = this.createdInContract ? 1 : 0;

    return product;
  }

  addProduct(payload) {
    this.loadingSubject.next(true);

    const createProductSubscription = this.productsService.createCustomer(payload).subscribe(
      (res) => {
        this.loadingSubject.next(false);
        if (res.data && !this.customerTypeCode && this.withSubmit) {
          this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
        }
        this.errorMsgs = {};
        this.customerSubmitted.emit({
          customer: res.data ?? null,
          code: this.customerTypeCode,
        });
      },
      (err) => {
        this.loadingSubject.next(false);
        if (err.error && err.error.errors) {
          this.errorMsgs = err.error.errors;
          this.cdr.markForCheck();
          this.hasFormErrors = true;
        }
      }
    );
    this.componentSubscriptions.push(createProductSubscription);
  }

  updateProduct(payload) {
    this.loadingSubject.next(true);
    const updateProductSubscription = this.productsService.updateCustomer(payload, this.id).subscribe(
      (res) => {
        this.loadingSubject.next(false);
        if (res.data && !this.customerTypeCode && this.withSubmit) {
          this.layoutUtilsService.showActionNotification(res.message, MessageType.Update, 10000, true, false);
        }
        this.errorMsgs = {};
        this.customerSubmitted.emit({
          customer: res.data ?? null,
          code: this.customerTypeCode,
        });
      },
      (err) => {
        this.loadingSubject.next(false);
        if (err.error && err.error.errors) {
          this.errorMsgs = err.error.errors;
          this.cdr.markForCheck();
          this.hasFormErrors = true;
        }
      }
    );
    this.componentSubscriptions.push(updateProductSubscription);
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  onCloseCustomerModal() {
    this.closeModal.emit(true);
  }
}
