import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { PatchNoteService } from "../../../../pages/patch-note/patch-note.service";

@Component({
  selector: "kt-marquees",
  templateUrl: "./marquees.component.html",
  styleUrls: ["./marquees.component.scss"],
})
export class MarqueesComponent implements OnInit, OnDestroy {
  message = "";
  messageSubscription$: Subscription;

  constructor(private _tenantMessagesService: PatchNoteService) {}

  ngOnInit(): void {
    this.messageSubscription$ = this._tenantMessagesService.getAnnouncements().subscribe({
      next: (response) => {
        this.message = response.data
          .map((item: { message: string }) => item.message)
          .join("<span class='text-primary'> |•••| </span>");
      },
    });
  }

  ngOnDestroy() {
    this.messageSubscription$.unsubscribe();
  }
}
