/** Angular */
import { Injectable } from "@angular/core";
import { IRequestPagination, RequestPaginationModel } from "../interfaces/request-pagination";

@Injectable()
export class GlobalUtilsService {
  static getLocalStorageFilter(key: string): IRequestPagination {
    try {
      return JSON.parse(localStorage.getItem(key)) as IRequestPagination;
    } catch {
      localStorage.removeItem(key);
      return new RequestPaginationModel();
    }
  }
}
