export class LeasingCalculatorModel {
  id: number;
  product: any;
  product_id: any;
  leasing_type: any;
  leasing_type_id: any;
  type: any;
  // contract_type:any;
  vehicle_id: number;
  kilometer_limit: number;
  private_tax_cents: number;
  period: number;
  start_date: any;
  first_registration_date: any;
  vehicle_price_excl_vat_cents: number;
  registration_tax_cents: number;
  down_payment: number;
  deposit: number;
  salvage_value_cents: number;
  deprecation: number;
  down_payment_modified: boolean;
  commission_cents: number;
  profit_on_vehicle_cents: number;
  start_value_cents: number;
  payment_method: string;
  leasing_payment: number;
  interest_rate: number;
  markup_interest_rate: number;
  is_variable_interest: boolean;
  license_plate_type: number;
  split_leasing_private_share: number;
  split_leasing_corporate_share: number;
  vehicle_market_price_set: any;
  products: any[];
  key_figures: any[];
  corporate_key_figures: any[];
  add_on_product: any;
  market_price_set_id: any;
  is_edit: any;
  created_at: string;
  updated_at: string;
  entityType: string;
  vehicle_mileage?: number;

  clear() {
    this.product = "";
    this.payment_method = "leasing_payment";
    this.created_at = "";
    this.updated_at = null;
  }
}
