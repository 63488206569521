<kt-portlet class="add-edit-entity">
  <kt-portlet-header
    [sticky]="true"
    [title]="getComponentTitle()"
    [class]="'kt-portlet__head--lg' + dialogData.isCancelEnabled ? 'kt-portlet__head__custom' : ''"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
  </kt-portlet-header>
  <kt-portlet-body>
    <div>
      <kt-customer-form
        (customerSubmitted)="goBackToListPage($event)"
        (closeModal)="closeSelfDialog()"
        [isCancelEnabled]="dialogData.isCancelEnabled"
        [entityType]="dialogData.entityType"
        [id]="id"
      ></kt-customer-form>
    </div>
  </kt-portlet-body>
</kt-portlet>
