// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS
import { Subscription, of } from "rxjs";
// Layout
import { LayoutConfigService } from "../../../core/_base/layout";
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from "../../../core/_base/crud";
import { PaymentsService } from "./payments.service";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "../shared.service";
import { PaymentTermsTypes } from "../enums/payments/paymentTermsTypes";
import { SettingsService } from "../../pages/settings/settings.service";
import { SettingCodes } from "../enums/settings/settingCodes";

@Component({
  selector: "kt-create-bulk-invoices-dialog",
  templateUrl: "./create-bulk-invoices-dialog.component.html",
  styleUrls: ["./create-bulk-invoices-dialog.component.scss"],
})
export class CreateBulkInvoicesDialogComponent implements OnInit, OnDestroy {
  // Public properties
  product: any;
  productForm: FormGroup;
  hasFormErrors: boolean = false;
  months: number[] = [];
  errorMsgs: any = {}; //Back-end errors
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;
  paymentTermsEnums = PaymentTermsTypes;
  paymentTerms: any[];
  bulk_by_month = false;

  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private productsService: PaymentsService,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    public dialogRef: MatDialogRef<CreateBulkInvoicesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.permissionsService.permissions$.subscribe((res) => {
      let newProduct = {
        month: "",
        checked: false,
        payment_term_id: null,
        date: new Date(),
        payment_due_date: new Date(),
        payments_date: new Date(),
        notes_headline: "",
      };

      this.settingsService.getSetting(SettingCodes.DEFAULT_BULK_INOVICE_HEADER).subscribe((res) => {
        newProduct.notes_headline = res.data.value;
        this.loadProduct(newProduct);
      });
    });

    for (let i = 1; i <= 12; i++) {
      this.months.push(i);
    }
    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };

    const paymentTermsSubscription = this.sharedService.getLookups("payment_terms").subscribe((res) => {
      this.paymentTerms = res.data;
    });
    this.componentSubscriptions.push(paymentTermsSubscription);
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  initProduct() {
    this.createForm();
  }

  createForm() {
    this.productForm = this.productFB.group({
      date: [this.product.date, Validators.required],
      notes_headline: [this.product.notes_headline],
      month: [this.product.month],
      payments_date: [this.product.payments_date],
      checked: [this.product.checked],
      payment_term_id: [this.product.payment_term_id],
      payment_due_date: [this.product.payment_due_date],
    });
    this.cdr.detectChanges();
  }
  checkBulkByMonth() {
    this.bulk_by_month = this.productForm.controls["checked"].value;
  }

  displayFn(product: any): string {
    return product && product.name ? product.name : "";
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.viewLoading = true;
    const controls = this.productForm.controls;
    /** check form */
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      this.hasFormErrors = true;
      this.viewLoading = false;
      return;
    }

    let payload: any = {};

    if (controls["date"] && controls["date"].value) {
      payload.date = this.typesUtilsService.formatDate(controls["date"].value, "dd-MM-yyyy");
    }

    if (
      controls["payment_term_id"].value?.entity_object?.paymentTermsType == PaymentTermsTypes.DUE_DATE &&
      controls["payment_due_date"]?.value
    ) {
      payload.payment_due_date = this.typesUtilsService.formatDate(controls["payment_due_date"].value, "dd-MM-yyyy");
    }

    if (this.productForm.controls["checked"].value) {
      payload.month = `0${controls["month"].value}`.slice(-2);
    } else {
      payload.payments_date = this.typesUtilsService.formatDate(controls["payments_date"].value, "dd-MM-yyyy");
    }

    payload.payment_term_id = controls["payment_term_id"].value?.id;
    payload.bulk_by_month = controls["checked"].value;
    payload.notes_headline = controls["notes_headline"].value;
    payload.type = this.data.entityFilterType;

    this.addProduct(payload);
  }

  onBookChange(event) {
    console.log(this.productForm.controls["payment_term_id"]);
  }

  addProduct(payload) {
    const _title: string = this.translate.instant("PAYMENTS.VIEW.CONFIRM_MODAL.TITLE");
    const _description: string = this.translate.instant("PAYMENTS.VIEW.CONFIRM_MODAL.DESCRIPTION");
    const _waitDesciption: string = this.translate.instant("PAYMENTS.VIEW.CONFIRM_MODAL.WAIT_DESCRIPTION");
    const _submitText: string = this.translate.instant("GENERAL.CONFIRM");

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption, null, _submitText);
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        this.viewLoading = false;
        return;
      }
      const createProductSubscription = this.productsService.addBulkInvoices(payload).subscribe(
        (res) => {
          this.viewLoading = false;
          if (res.data) {
            this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Create, 10000, true, false);
          }
          this.dialogRef.close(true);
        },
        (err) => {
          this.viewLoading = false;
          if (err.error) {
            this.errorMsgs = err.error.errors || {};
            this.cdr.markForCheck();
            this.hasFormErrors = true;
          }
        }
      );
      this.componentSubscriptions.push(createProductSubscription);
    });
  }

  getComponentTitle() {
    let result = this.translate.instant("PAYMENTS.VIEW.ADD_BULK_INVOICES");
    return result;
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
