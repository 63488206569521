<div class="kt-portlet datatable-view datatable-view-no-filters">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "AMORTIZATIONS.VIEW.TITLE" | translate }}
      </h3>
    </div>
    <div class="kt-portlet__head-toolbar">
      <button
        *ngIf="contract_id"
        (click)="calculateAmortization()"
        [disabled]="isCalculating"
        mat-raised-button
        color="primary"
      >
        Genberegn amortisering
      </button>
      <div class="datatable-search">
        <div class="search-wrapper">
          <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
          <input
            #searchInput
            placeholder="{{ 'GENERAL.SEARCH' | translate }}"
            class="form-control amortization"
            style="height: calc(1.5em + 1rem + 2px)"
          />
          <button
            #searchButton
            mat-raised-button
            style="
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            "
            color="primary"
          >
            Søg
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="kt-portlet__body">
    <div class="mat-table__wrapper datatable-container">
      <div class="datatable-table-container">
        <table
          #table
          mat-table
          [dataSource]="data"
          class="example-table lmat-elevation-z8"
          matSort
          matSortActive="date"
          matSortDisableClear
          matSortDirection="asc"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef class="expand-entity-header"></th>
            <td
              mat-cell
              *matCellDef="let row"
              class="expand-entity-info"
              [class.entity-expanded-row]="expandedElement === row"
            >
              <button
                class="expand-info"
                mat-icon-button
                color="primary"
                (click)="expandedElement = expandedElement === row ? null : row"
              >
                <i class="far fa-plus-square"></i>
              </button>
              <button
                mat-icon-button
                class="collapse-info"
                color="warn"
                (click)="expandedElement = expandedElement === row ? null : row"
              >
                <i class="far fa-minus-square"></i>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.DATE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.date | dmyDate }}</td>
          </ng-container>

          <ng-container matColumnDef="interest_days">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive">
              {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.INTEREST_DAYS" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive">{{ row.interest_days }}</td>
          </ng-container>

          <ng-container matColumnDef="payment_excl_vat_cents">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive">
              {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.PAYMENT" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive">
              {{ row.payment_excl_vat_cents | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="interest_cents">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive">
              {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.INTEREST" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive">{{ row.interest_cents | number : "1.2-2" }}</td>
          </ng-container>

          <ng-container matColumnDef="repayment_cents">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive">
              {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.REPAYMENT" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive">
              {{ row.repayment_cents | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="balance_cents">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive">
              {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.BALANCE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive">
              {{ row.balance_cents | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="leftover_tax_value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive">
              {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.PROPORTIONATE_TAX_VALUE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.leftover_tax_value | number : "1.2-2" }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef width="10%"></th>
            <td mat-cell *matCellDef="let row" width="10%">
              <kt-actions-menu *ngxPermissionsOnly="['view_funding_rate_details', 'delete_funding_rate']">
                <li *ngxPermissionsOnly="['view_funding_rate_details']" class="kt-nav__item">
                  <a href="javascript:;" class="kt-nav__link">
                    <span [inlineSVG]="'./assets/media/icons/edit.svg'"></span>
                    <span class="kt-nav__link-text">
                      {{ "GENERAL.EDIT" | translate }}
                    </span>
                  </a>
                </li>
                <li *ngxPermissionsOnly="['delete_funding_rate']" class="kt-nav__item" (click)="deleteProduct(row.id)">
                  <a href="javascript:;" class="kt-nav__link">
                    <span [inlineSVG]="'./assets/media/icons/trash.svg'"></span>
                    <span class="kt-nav__link-text">
                      {{ "GENERAL.DELETE" | translate }}
                    </span>
                  </a>
                </li>
              </kt-actions-menu>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div
                class="entity-details-info-column"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
              >
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.INTEREST_DAYS" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.interest_days }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.PAYMENT" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.payment_excl_vat_cents | number : "1.2-2" }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.INTEREST" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.interest_cents | number : "1.2-2" }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.REPAYMENT" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.repayment_cents | number : "1.2-2" }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.BALANCE" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.balance_cents | number : "1.2-2" }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "AMORTIZATIONS.VIEW.TABLE_COLUMNS.PROPORTIONATE_TAX_VALUE" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.leftover_tax_value | number : "1.2-2" }}
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="entity-default-row"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="entity-details-info-row"></tr>
        </table>
        <div class="mat-table__message" *ngIf="isRateLimitReached">
          {{ "GENERAL.SOMETHING_WENT_WRONG" | translate }}
        </div>
        <!-- <div class="mat-table__message" *ngIf="isLoadingResults">{{'GENERAL.PLEASE_WAIT' | translate}}</div> -->
      </div>

      <!-- start: BOTTOM -->
      <div class="mat-table__bottom">
        <mat-spinner [diameter]="20" *ngIf="isLoadingResults"></mat-spinner>
        <mat-paginator appStylePaginator [length]="resultsLength" showFirstLastButtons></mat-paginator>
      </div>
      <!-- end: BOTTOM -->
    </div>
  </div>
</div>
