export enum CustomerFieldEnum {
  ADDRESS = "address",
  POSTAL_CODE = "postal_code",
  CITY = "city",
  PHONE = "phone",
  BIRTHDATE = "birthdate",
  AML_RISK_CLASS = "aml_risk_class_id",
  PAYMENT_TERM = "payment_term_id",
  BANK_CODE = "bank_code",
  BANK_ACCOUNT_NUMBER = "bank_account_number",
  CURRENCY = "currency",
  COUNTRY = "country",
  EAN_NUMBER = "ean_number",
}

export enum VehicleFieldEnum {
  NEXT_INSPECTION = "next_inspection",
  VIN_NUMBER = "vin_number",
  TRIM = "trim",
  HORSE_POWER = "horse_power",
  YEAR = "year",
  CO2_PER_KM = "co2_per_km",
  CURB_WEIGHT = "curb_weight",
  BATTERY_CAPACITY = "battery_capacity",
  MARKET_CAR = "market_car",
  HAS_MILEAGE_PHOTO = "hasMileagePhoto",
  MILEAGE_PHOTO = "mileagePhoto",
  MILEAGE_CAR = "mileage_car",
  FUEL_TYPE = "fuel_type",
  VEHICLE_CONDITION = "vehicle_condition_id",
  NEW_PRICE_CENTS = "new_price_cents",
  FUEL_NORM = "fuel_norm",
  FIRST_REGISTERATION_DATE = "first_registration_date",
}

export const CustomerFieldsMapper = new Map<string, string>([
  [CustomerFieldEnum.ADDRESS, "CUSTOMERS.ADD.FIELDS.ADDRESS.PLACEHOLDER"],
  [CustomerFieldEnum.POSTAL_CODE, "CUSTOMERS.ADD.FIELDS.POSTAL_CODE.PLACEHOLDER"],
  [CustomerFieldEnum.CITY, "CUSTOMERS.ADD.FIELDS.CITY.PLACEHOLDER"],
  [CustomerFieldEnum.PHONE, "CUSTOMERS.ADD.FIELDS.PHONE.PLACEHOLDER"],
  [CustomerFieldEnum.BIRTHDATE, "CUSTOMERS.ADD.FIELDS.BIRTHDAY.PLACEHOLDER"],
  [CustomerFieldEnum.AML_RISK_CLASS, "CUSTOMERS.ADD.FIELDS.RISK.PLACEHOLDER"],
  [CustomerFieldEnum.PAYMENT_TERM, "CUSTOMERS.ADD.FIELDS.PAYMENT_TERM.PLACEHOLDER"],
  [CustomerFieldEnum.BANK_CODE, "CUSTOMERS.ADD.FIELDS.BANK_CODE.PLACEHOLDER"],
  [CustomerFieldEnum.BANK_ACCOUNT_NUMBER, "CUSTOMERS.ADD.FIELDS.BANK_ACCOUNT_NUMBER.PLACEHOLDER"],
  [CustomerFieldEnum.CURRENCY, "CUSTOMERS.ADD.FIELDS.CURRENCY.PLACEHOLDER"],
  [CustomerFieldEnum.COUNTRY, "CUSTOMERS.ADD.FIELDS.COUNTRY.PLACEHOLDER"],
  [CustomerFieldEnum.EAN_NUMBER, "CUSTOMERS.ADD.FIELDS.EAN_NUMBER.PLACEHOLDER"],
]);

export const CustomerFieldsMapperForSettings = new Map<CustomerFieldEnum, string>([
  [CustomerFieldEnum.AML_RISK_CLASS, "risk_class_id"],
]);

export const VehicleFieldsMapper = new Map<string, string>([
  [VehicleFieldEnum.NEXT_INSPECTION, "VEHICLE.ADD.FIELDS.NEXT_INSPECTION.PLACEHOLDER"],
  [VehicleFieldEnum.VIN_NUMBER, "VEHICLE.ADD.FIELDS.VIN_NUMBER.PLACEHOLDER"],
  [VehicleFieldEnum.TRIM, "VEHICLE.ADD.FIELDS.TRIM.PLACEHOLDER"],
  [VehicleFieldEnum.HORSE_POWER, "VEHICLE.ADD.FIELDS.HORSE_POWER.PLACEHOLDER"],
  [VehicleFieldEnum.YEAR, "VEHICLE.ADD.FIELDS.YEAR.PLACEHOLDER"],
  [VehicleFieldEnum.CO2_PER_KM, "VEHICLE.ADD.FIELDS.CO2_PER_KM.PLACEHOLDER"],
  [VehicleFieldEnum.CURB_WEIGHT, "VEHICLE.ADD.FIELDS.CURB_WEIGHT.PLACEHOLDER"],
  [VehicleFieldEnum.BATTERY_CAPACITY, "VEHICLE.ADD.FIELDS.BATTERY_CAPACITY.PLACEHOLDER"],
  [VehicleFieldEnum.MARKET_CAR, "VEHICLE.ADD.FIELDS.MARKET.PLACEHOLDER"],
  [VehicleFieldEnum.HAS_MILEAGE_PHOTO, "VEHICLE.ADD.FIELDS.MILEAGE.HAS_PHOTO_LABEL"],
  [VehicleFieldEnum.MILEAGE_PHOTO, "VEHICLE.ADD.FIELDS.MILEAGE.PHOTO_LABEL"],
  [VehicleFieldEnum.MILEAGE_CAR, "VEHICLE.ADD.FIELDS.MILEAGE.PLACEHOLDER"],
  [VehicleFieldEnum.FUEL_TYPE, "VEHICLE.ADD.FIELDS.FUEL_TYPE.PLACEHOLDER"],
  [VehicleFieldEnum.VEHICLE_CONDITION, "VEHICLE.ADD.FIELDS.CONDITION.PLACEHOLDER"],
  [VehicleFieldEnum.NEW_PRICE_CENTS, "VEHICLE.ADD.FIELDS.NEW_PRICE_CENTS.PLACEHOLDER"],
  [VehicleFieldEnum.FUEL_NORM, "VEHICLE.ADD.FIELDS.FULE_NORM.PLACEHOLDER"],
  [VehicleFieldEnum.FIRST_REGISTERATION_DATE, "VEHICLE.ADD.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER"],
]);

export const CustomerFormDefaultSettings = {
  address: true,
  postal_code: true,
  city: false,
  phone: false,
  birthdate: false,
  risk_class_id: false,
  payment_term_id: true,
  bank_code: false,
  bank_account_number: false,
  currency: true,
  country: true,
};

export const CustomerRequestContractFromOfferFormDefaultSettings = {
  address: true,
  postal_code: true,
  city: true,
  payment_term_id: true,
  currency: true,
  country: true,
};

export const customerOfferFormFields = {
  phone: false,
  address: false,
  city: false,
  postal_code: false,
};

export const VehicleFormDefaultSettings = {
  fuel_type: true,
};
