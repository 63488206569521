// Angular
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Inject,
  Input,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";
// RxJS
import { Subscription, pipe, of, Observable } from "rxjs";
import { SharedService } from "../shared.service";

// Layout
// CRUD
import { TranslateService } from "@ngx-translate/core";
import { DropzoneComponent } from "ngx-dropzone-wrapper";

@Component({
  selector: "kt-add-vehicle-document",
  templateUrl: "./add-vehicle-document.component.html",
  styleUrls: ["./add-vehicle-document.component.scss"],
})
export class AddVehicleDocumentComponent implements OnInit {
  @ViewChildren("documentsDropzone")
  documentsDropzone: QueryList<DropzoneComponent>;
  dropZoneConfig: any = {
    acceptedFiles: ".jpeg,.png,.jpg,.bmp,.gif",
    maxFilesize: 20,
    maxFiles: 1,
  };
  // Public properties
  product: any;
  productForm: FormGroup;
  vehicleToEditIndex: number = -1;
  marketPriceDocuments: any[];
  hasFormErrors: boolean = false;
  calculations: any;
  @Input() isReadonly: boolean = false;
  dropZoneMessage: any;
  errorMsgs: any = {}; //Back-end errors
  documentsTooltipMsg = `
		- Upload PDF's for each advertised car. All content needs to be viewable as well as a timestamp for the ad.
		- The vehicle's price as new including all extra equipment.
		- Copy of original registration certificate
		- Copy of vehicle purchase invoice
		- Servicebook to document mileage
		- Other relevant documentation
	`;
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;

  constructor(
    private productFB: FormBuilder,
    public dialogRef: MatDialogRef<AddVehicleDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productsService: SharedService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.dropZoneMessage = `
			<div class="dropzone-placeholder">
				<img class="file-icon" src="assets/media/icons/document-grey.png">
				<span class="dropzone-placeholder-text">
					${this.translate.instant("VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.DROPZONE.PLACEHOLDER")}
				</span>
			</div>
		`;
  }

  ngOnInit(): void {
    this.loadProduct({});

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };

    const marketPriceDocumentsSubscription = this.productsService
      .getLookups("vehicle_market_price_documents")
      .subscribe((res) => {
        this.marketPriceDocuments = res.data;
      });
    this.componentSubscriptions.push(marketPriceDocumentsSubscription);
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  initProduct() {
    this.createForm();
  }

  createForm() {
    let documentsArray: FormGroup[] = [];
    // To have at least one default dropzone
    documentsArray.push(
      this.productFB.group({
        file: [null],
        file_id: [null],
        file_name: [""],
        vehicle_market_price_document_id: ["", Validators.required],
        is_first: [true],
      })
    );

    this.productForm = this.productFB.group({
      documents: this.productFB.array(documentsArray),
    });
  }

  addNewDocumentRow() {
    (<FormArray>this.productForm.get("documents")).push(
      this.productFB.group({
        vehicle_market_price_document_id: [""],
        file_id: [null],
        file_name: [""],
        file: [null],
        is_first: [false],
      })
    );
  }

  setPhotoFile(file, rowIndex) {
    const documentsArray = <FormArray>this.productForm.get("documents");
    if (documentsArray) {
      if (documentsArray.controls) {
        const photoRow = <FormGroup>documentsArray.controls[rowIndex];
        if (photoRow) {
          if (photoRow.controls) {
            const photoControl = <FormControl>photoRow.controls["file"];
            if (photoControl) {
              photoControl.setValue(file);
            }
          }
        }
      }
    }
  }

  removeDocumentRow(index) {
    (<FormArray>this.productForm.get("documents")).removeAt(index);
    this.addNewDocumentRow();
  }

  addVehicle() {
    this.hasFormErrors = false;
    const controls = this.productForm.controls;

    /** check form */
    console.log("addVehicle", this.productForm.invalid);
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAllAsTouched());

      this.hasFormErrors = true;
      // return;
    }

    if (this.productForm.valid) {
      this.dialogRef.close({
        success: true,
        documents: controls["documents"].value.file ? controls["documents"].value : controls["documents"].value,
      });
    }
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
