import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// RxJS
import { Observable } from "rxjs";

import { API } from "../../../core/_base/crud";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { SuppliersModel } from "./suppliers.model";
@Injectable({
  providedIn: "root",
})
export class SuppliersService {
  constructor(private http: HttpClient) {}

  createProduct(product): Observable<any> {
    return this.http.post<any>(`${API.suppliers}`, product);
  }

  getProductById(productId: number): Observable<any> {
    return this.http.get<any>(API.suppliers + `/${productId}`);
  }

  indexProduct(params: IRequestPagination): Observable<any> {
    return this.http.get<IResponsePagination<SuppliersModel>>(API.suppliers, {
      params: { ...params },
    });
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.put<any>(`${API.suppliers}/${productId}`, product);
  }

  getPaymentInfo(): Observable<any> {
    return this.http.get<any>(`${API.suppliers}/payment-info`);
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.delete<any>(`${API.suppliers}/${productId}`);
  }

  getLookups(category_code): Observable<any> {
    return this.http.get<any>(`${API.lookups}?category_code=${category_code}`);
  }

  getCVRData(q): Observable<any> {
    return this.http.post<any>(`${API.CVRData}`, { search: q });
  }
}
