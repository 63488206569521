<kt-portlet class="add-edit-entity">
  <kt-portlet-body>
    <div class="leasing-calculator" *ngIf="product">
      <div class="row">
        <div class="col-md-7">
          <form [formGroup]="productForm" class="leasing-calculator-form kt-form">
            <kt-alert
              *ngIf="hasFormErrors"
              type="warn"
              [showCloseButton]="true"
              [duration]="10000"
              (close)="onAlertClose($event)"
            >
              {{ "GENERAL.FORM_ERROR_MESSAGE" | translate }}
            </kt-alert>
            <div class="kt-form__group">
              <div class="row">
                <div class="col-md-6 push-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.LEASING_TYPE.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['leasing_type']
                    }"
                  >
                    <mat-select
                      (selectionChange)="standstillIsSelected()"
                      placeholder="{{ 'LEASING_CALCULATOR.FIELDS.LEASING_TYPE.PLACEHOLDER' | translate }}"
                      formControlName="leasing_type"
                      [compareWith]="compareFn"
                    >
                      <mat-option [value]="leasingType.code" *ngFor="let leasingType of leasingTypes">
                        {{ leasingType.name }}</mat-option
                      >
                    </mat-select>
                    <mat-error *ngIf="productForm.controls.leasing_type.hasError('required')">
                      <span [innerHTML]="'LEASING_CALCULATOR.FIELDS.LEASING_TYPE.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['leasing_type']">{{ errorMsgs["leasing_type"] }} </mat-error>
                </div>
                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    appearance="standard"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['first_registration_date']
                    }"
                  >
                    <input
                      matInput
                      [matDatepicker]="firstRegistrationDatePicker"
                      placeholder="{{ 'LEASING_CALCULATOR.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER' | translate }}"
                      formControlName="first_registration_date"
                      type="text"
                    />
                    <mat-datepicker-toggle matSuffix [for]="firstRegistrationDatePicker"> </mat-datepicker-toggle>
                    <mat-datepicker #firstRegistrationDatePicker></mat-datepicker>
                    <mat-error *ngIf="productForm.controls.first_registration_date.hasError('required')">
                      <span
                        [innerHTML]="'LEASING_CALCULATOR.FIELDS.FIRST_REGISTERATION_DATE.ERROR_MSG' | translate"
                      ></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['first_registration_date']">
                    {{ errorMsgs["first_registration_date"] }}</mat-error
                  >
                </div>
              </div>
              <div class="row">
                <div
                  *ngIf="
                    (entity.contract_type && entity.contract_type == contractTypes.SPLIT_LEASING) ||
                    (entity.type && entity.type == contractTypes.SPLIT_LEASING)
                  "
                  class="col-md-6 kt-margin-bottom-10-mobile"
                >
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.SPLIT_LEASING_PRIVATE_SHARE.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['split_leasing_private_share']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="{{
                        'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.SPLIT_LEASING_PRIVATE_SHARE.PLACEHOLDER' | translate
                      }}"
                      formControlName="split_leasing_private_share"
                    />
                    <mat-error *ngIf="productForm.controls.split_leasing_private_share.hasError('required')">
                      <span
                        [innerHTML]="
                          'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.SPLIT_LEASING_PRIVATE_SHARE.ERROR_MSG' | translate
                        "
                      ></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['split_leasing_private_share']">
                    {{ errorMsgs["split_leasing_private_share"] }}</mat-error
                  >
                </div>

                <div
                  *ngIf="
                    entity.contract_type == contractTypes.SPLIT_LEASING || entity.type == contractTypes.SPLIT_LEASING
                  "
                  class="col-md-6 kt-margin-bottom-10-mobile"
                >
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.SPLIT_LEASING_CORPORATE_SHARE.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['split_leasing_corporate_share']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="{{
                        'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.SPLIT_LEASING_CORPORATE_SHARE.PLACEHOLDER'
                          | translate
                      }}"
                      formControlName="split_leasing_corporate_share"
                    />
                    <mat-error *ngIf="productForm.controls.split_leasing_corporate_share.hasError('required')">
                      <span
                        [innerHTML]="
                          'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.SPLIT_LEASING_CORPORATE_SHARE.ERROR_MSG'
                            | translate
                        "
                      ></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['split_leasing_corporate_share']">
                    {{ errorMsgs["split_leasing_corporate_share"] }}</mat-error
                  >
                </div>

                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.EXPECTED_START_DATE.TITLE" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    appearance="standard"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['start_date']
                    }"
                  >
                    <input
                      matInput
                      [matDatepicker]="expectedStartDatePicker"
                      placeholder="{{ 'LEASING_CALCULATOR.FIELDS.EXPECTED_START_DATE.PLACEHOLDER' | translate }}"
                      formControlName="start_date"
                      type="text"
                    />
                    <mat-datepicker-toggle matSuffix [for]="expectedStartDatePicker"> </mat-datepicker-toggle>
                    <mat-datepicker #expectedStartDatePicker></mat-datepicker>
                    <mat-error *ngIf="productForm.controls.start_date.hasError('required')">
                      <span [innerHTML]="'LEASING_CALCULATOR.FIELDS.EXPECTED_START_DATE.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['start_date']">{{ errorMsgs["start_date"] }}</mat-error>
                </div>

                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.CONTRACT_PERIOD.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    no-scroll
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['period']
                    }"
                  >
                    <input
                      matInput
                      type="number"
                      placeholder="{{ 'LEASING_CALCULATOR.FIELDS.CONTRACT_PERIOD.PLACEHOLDER' | translate }}"
                      formControlName="period"
                    />
                    <mat-error *ngIf="productForm.controls.period.hasError('required')">
                      <span
                        [innerHTML]="'LEASING_CALCULATOR.FIELDS.CONTRACT_PERIOD.ERROR_MSGS.REQUIRED' | translate"
                      ></span>
                    </mat-error>
                    <mat-error *ngIf="productForm.controls.period.hasError('max')">
                      <span [innerHTML]="'LEASING_CALCULATOR.FIELDS.CONTRACT_PERIOD.ERROR_MSGS.MAX' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['period']">{{ errorMsgs["period"] }}</mat-error>
                </div>
                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.START_VALUE.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['start_value_cents']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      [options]="{ allowNegative: false }"
                      type="text"
                      placeholder="{{ 'LEASING_CALCULATOR.FIELDS.START_VALUE.PLACEHOLDER' | translate }}"
                      formControlName="start_value_cents"
                    />
                    <mat-error *ngIf="productForm.controls.start_value_cents.hasError('required')">
                      <span [innerHTML]="'LEASING_CALCULATOR.FIELDS.START_VALUE.ERROR_MSG' | translate"></span>
                    </mat-error>
                    <mat-error *ngIf="productForm.controls.start_value_cents.hasError('pattern')">
                      <span [innerHTML]="'LEASING_CALCULATOR.FIELDS.START_VALUE.PROVIDE_INVALID' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['start_value_cents']"> {{ errorMsgs["start_value_cents"] }}</mat-error>
                </div>

                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.VEHICLE_PRICE.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['vehicle_price_excl_vat_cents']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="{{ 'LEASING_CALCULATOR.FIELDS.VEHICLE_PRICE.PLACEHOLDER' | translate }}"
                      formControlName="vehicle_price_excl_vat_cents"
                    />
                    <mat-error *ngIf="productForm.controls.vehicle_price_excl_vat_cents.hasError('required')">
                      <span
                        [innerHTML]="'LEASING_CALCULATOR.FIELDS.VEHICLE_PRICE.ERROR_MSGS.REQUIRED' | translate"
                      ></span>
                    </mat-error>
                    <mat-error *ngIf="productForm.controls.vehicle_price_excl_vat_cents.hasError('max')">
                      <span [innerHTML]="'LEASING_CALCULATOR.FIELDS.VEHICLE_PRICE.ERROR_MSGS.MAX' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['vehicle_price_excl_vat_cents']">{{
                    errorMsgs["vehicle_price_excl_vat_cents"]
                  }}</mat-error>
                </div>
                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{ "VEHICLE.ADD.FIELDS.MILEAGE.PLACEHOLDER" | translate }}</label>
                  <mat-form-field class="mat-form-field-fluid">
                    <input
                      matInput
                      [options]="{ allowNegative: false }"
                      type="text"
                      placeholder="{{ 'VEHICLE.ADD.FIELDS.MILEAGE.PLACEHOLDER' | translate }}"
                      formControlName="vehicle_mileage"
                    />
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['vehicle_mileage']"> {{ errorMsgs["vehicle_mileage"] }}</mat-error>
                </div>
                <!-- Adding the new input field “Afskrivning i %” -->
                <!-- ==================================================== -->
                <!--TODO: we need to handel if it required still waiting Nicklas to Answer me   -->

                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">Afskrivning i %</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['deprecation']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="indsæt venligst afskrivning"
                      formControlName="deprecation"
                      (keyup)="setLastTouchedFieldName('deprecation')"
                    />
                    <mat-error *ngIf="productForm.controls.deprecation.hasError('required')">
                      <span [innerHTML]="'Depreciation must be included'"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['deprecation']"> {{ errorMsgs["deprecation"] }}</mat-error>
                </div>

                <!-- ==================================================== -->
                <!-- Adding the new input field “Afskrivning i %” -->
                <!-- ==================================================== -->

                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.REGESTRATION_TAX.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['registration_tax_cents']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="{{ 'LEASING_CALCULATOR.FIELDS.REGESTRATION_TAX.PLACEHOLDER' | translate }}"
                      formControlName="registration_tax_cents"
                    />
                    <mat-error *ngIf="productForm.controls.registration_tax_cents.hasError('required')">
                      <span [innerHTML]="'LEASING_CALCULATOR.FIELDS.REGESTRATION_TAX.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['registration_tax_cents']">
                    {{ errorMsgs["registration_tax_cents"] }}</mat-error
                  >
                </div>

                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.LICENSE_PLATE_TYPE.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['license_plate_type']
                    }"
                  >
                    <mat-select
                      placeholder="{{ 'INVOICES.ADD.FIELDS.LICENSE_PLATE_TYPE.PLACEHOLDER' | translate }}"
                      formControlName="license_plate_type"
                    >
                      <mat-option [value]="plateType?.code" *ngFor="let plateType of licensePlateTypes">{{
                        plateType.name
                      }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="productForm.controls.license_plate_type.hasError('required')">
                      <span [innerHTML]="'LEASING_CALCULATOR.FIELDS.LICENSE_PLATE_TYPE.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['license_plate_type']"> {{ errorMsgs["license_plate_type"] }}</mat-error>
                </div>
                <div *ngIf="view_down_payment" class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.DOWN_PAYMENT_EX_VAT.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['down_payment']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      [options]="{ allowNegative: false }"
                      type="text"
                      placeholder="{{ 'LEASING_CALCULATOR.FIELDS.DOWN_PAYMENT_EX_VAT.PLACEHOLDER' | translate }}"
                      formControlName="down_payment"
                    />
                    <mat-error *ngIf="productForm.controls.down_payment.hasError('required')">
                      <span [innerHTML]="'LEASING_CALCULATOR.FIELDS.DOWN_PAYMENT_EX_VAT.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['down_payment']">{{ errorMsgs["down_payment"] }} </mat-error>
                </div>

                <div *ngIf="view_deposit" class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.DEPOSIT_EX_VAT.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['deposit']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="{{ 'LEASING_CALCULATOR.FIELDS.DEPOSIT_EX_VAT.PLACEHOLDER' | translate }}"
                      formControlName="deposit"
                    />
                    <mat-error *ngIf="productForm.controls.deposit.hasError('required')">
                      <span [innerHTML]="'LEASING_CALCULATOR.FIELDS.DEPOSIT_EX_VAT.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['deposit']">{{ errorMsgs["deposit"] }}</mat-error>
                </div>

                <div *ngIf="view_salvage_value" class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.SALVAGE_VALUE.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['salvage_value_cents']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="{{
                        'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.SALVAGE_VALUE.PLACEHOLDER' | translate
                      }}"
                      formControlName="salvage_value_cents"
                      (keyup)="onSalvageValueChange()"
                    />
                    <mat-error *ngIf="productForm.controls.salvage_value_cents.hasError('required')">
                      <span
                        [innerHTML]="'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.SALVAGE_VALUE.ERROR_MSG' | translate"
                      ></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['salvage_value_cents']">
                    {{ errorMsgs["salvage_value_cents"] }}</mat-error
                  >
                </div>

                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.PROFIT_ON_VEHICLE.PLACEHOLDER" | translate
                  }}</label>

                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['profit_on_vehicle_cents']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="{{
                        'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.PROFIT_ON_VEHICLE.PLACEHOLDER' | translate
                      }}"
                      formControlName="profit_on_vehicle_cents"
                    />
                    <mat-error *ngIf="productForm.controls.profit_on_vehicle_cents.hasError('required')">
                      <span
                        [innerHTML]="
                          'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.PROFIT_ON_VEHICLE.ERROR_MSG' | translate
                        "
                      ></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['profit_on_vehicle_cents']">
                    {{ errorMsgs["profit_on_vehicle_cents"] }}</mat-error
                  >
                </div>

                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.COMMISSION.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['commission_cents']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="{{ 'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.COMMISSION.PLACEHOLDER' | translate }}"
                      formControlName="commission_cents"
                    />
                    <mat-error *ngIf="productForm.controls.commission_cents.hasError('required')">
                      <span
                        [innerHTML]="'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.COMMISSION.ERROR_MSG' | translate"
                      ></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['commission_cents']">{{ errorMsgs["commission_cents"] }} </mat-error>
                </div>
                <ng-container
                  *ngxPermissionsOnly="['view_contract_variable_interest_rate', 'view_offer_variable_interest_rate']"
                >
                  <div
                    *ngIf="settingValues?.is_active"
                    class="col-md-6 d-flex align-items-center kt-margin-bottom-10-mobile"
                  >
                    <div>
                      <mat-checkbox
                        name="is_variable_interest"
                        formControlName="is_variable_interest"
                        (change)="checkVariableInterest()"
                      >
                        {{ "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.VARIABLE_INTEREST_RATE.DESCRIPTION" | translate }}
                      </mat-checkbox>
                    </div>
                    <mat-error *ngIf="errorMsgs['is_variable_interest']"
                      >{{ errorMsgs["is_variable_interest"] }}
                    </mat-error>
                  </div>
                </ng-container>
                <div
                  *ngIf="view_interest_rate && !productForm.get('is_variable_interest').value"
                  class="col-md-6 kt-margin-bottom-10-mobile"
                >
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.INTEREST_RATE.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['interest_rate']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      [options]="{ precision: 4 }"
                      type="text"
                      placeholder="{{
                        'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.INTEREST_RATE.PLACEHOLDER' | translate
                      }}"
                      formControlName="interest_rate"
                      (keyup)="setLastTouchedFieldName('interest_rate')"
                    />
                    <mat-error *ngIf="productForm.controls.interest_rate.hasError('required')">
                      <span
                        [innerHTML]="'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.INTEREST_RATE.ERROR_MSG' | translate"
                      ></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['interest_rate']">{{ errorMsgs["interest_rate"] }} </mat-error>
                </div>
                <ng-container
                  *ngxPermissionsOnly="['view_contract_variable_interest_rate', 'view_offer_variable_interest_rate']"
                >
                  <div
                    *ngIf="productForm.get('is_variable_interest').value && settingValues?.is_active"
                    class="col-md-6 kt-margin-bottom-10-mobile"
                  >
                    <label class="mat-input-label">{{
                      "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.MARKUP_INTEREST_RATE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['markup_interest_rate']
                      }"
                    >
                      <input
                        matInput
                        currencyMask
                        [options]="{ precision: 4 }"
                        type="text"
                        placeholder="{{
                          'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.MARKUP_INTEREST_RATE.PLACEHOLDER' | translate
                        }}"
                        formControlName="markup_interest_rate"
                        (keyup)="setLastTouchedFieldName('markup_interest_rate')"
                      />
                      <mat-error *ngIf="productForm.controls.markup_interest_rate.hasError('required')">
                        <span
                          [innerHTML]="
                            'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.MARKUP_INTEREST_RATE.ERROR_MSG' | translate
                          "
                        ></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['markup_interest_rate']"
                      >{{ errorMsgs["markup_interest_rate"] }}
                    </mat-error>
                  </div>
                </ng-container>
                <div *ngIf="view_leasing_payment" class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.LEASING_PAYMENT.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['leasing_payment']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="{{
                        'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.LEASING_PAYMENT.PLACEHOLDER' | translate
                      }}"
                      formControlName="leasing_payment"
                      (keyup)="setLastTouchedFieldName('leasing_payment')"
                    />
                    <mat-error *ngIf="productForm.controls.leasing_payment.hasError('required')">
                      <span
                        [innerHTML]="'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.LEASING_PAYMENT.ERROR_MSG' | translate"
                      ></span>
                    </mat-error>
                  </mat-form-field>

                  <!-- here we add the new field Beskatningsgrundlag -->
                  <!-- <div>
										<label for="" class="mat-input-label">Beskatningsgrundlag</label>
										<mat-form-field class="mat-form-field-fluid">
											<input matInput currencyMask type="text" placeholder='Beskatningsgrundlag'
												formControlName='Beskatningsgrundlag' />
										</mat-form-field>
									</div> -->
                  <mat-error *ngIf="errorMsgs['leasing_payment']">{{ errorMsgs["leasing_payment"] }} </mat-error>
                </div>

                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.KILOMETER_LIMIT.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    no-scroll
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['kilometer_limit']
                    }"
                  >
                    <input
                      matInput
                      type="number"
                      placeholder="{{
                        'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.KILOMETER_LIMIT.PLACEHOLDER' | translate
                      }}"
                      formControlName="kilometer_limit"
                    />
                    <mat-error *ngIf="productForm.controls.kilometer_limit.hasError('required')">
                      <span
                        [innerHTML]="'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.KILOMETER_LIMIT.ERROR_MSG' | translate"
                      ></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['kilometer_limit']">{{ errorMsgs["kilometer_limit"] }} </mat-error>
                </div>
                <div class="col-md-6 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">{{
                    "LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.PRIVATE_TAX.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{
                      'mat-form-field-invalid': errorMsgs['private_tax_cents']
                    }"
                  >
                    <input
                      matInput
                      currencyMask
                      type="text"
                      placeholder="{{
                        'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.PRIVATE_TAX.PLACEHOLDER' | translate
                      }}"
                      formControlName="private_tax_cents"
                    />
                    <mat-error *ngIf="productForm.controls.private_tax_cents.hasError('required')">
                      <span
                        [innerHTML]="'LEASING_CALCULATOR.FIELDS.OPTIONAL_FIELDS.PRIVATE_TAX.ERROR_MSG' | translate"
                      ></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['private_tax_cents']">{{ errorMsgs["private_tax_cents"] }} </mat-error>
                </div>
              </div>
            </div>

            <div class="kt-form__group">
              <div class="row add-on-products">
                <div class="col-md-12">
                  <mat-accordion>
                    <mat-expansion-panel expanded>
                      <mat-expansion-panel-header [collapsedHeight]="'39px'">
                        <mat-panel-title>
                          {{ "LEASING_CALCULATOR.FIELDS.ADD_ON_PRODUCTS.TITLE" | translate }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="products" formArrayName="products">
                        <div class="product" *ngFor="let p of productForm.controls.products.controls; index as i">
                          <div class="row" [formGroupName]="i">
                            <div class="col-md-9 kt-margin-bottom-10-mobile">
                              <mat-checkbox
                                [name]="product.name"
                                formControlName="checked"
                                (change)="addOnProductChanged($event, i, p.value)"
                              >
                                {{ productForm.controls.products.controls[i].controls.name.value }}
                              </mat-checkbox>
                            </div>
                            <div class="col-md-3 kt-margin-bottom-10-mobile">
                              <div class="product-amount">
                                <mat-form-field class="sm-form-field mat-form-field-fluid">
                                  <input
                                    matInput
                                    currencyMask
                                    [options]="{
                                      align: 'right'
                                    }"
                                    type="text"
                                    formControlName="amount"
                                    title="amount"
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div [hidden]="!addAnotherProduct" class="kt-margin-bottom-10-mobile mt-2">
                        <div class="search-and-submit-form">
                          <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                          <input
                            type="text"
                            placeholder="{{
                              'LEASING_CALCULATOR.FIELDS.ADD_ON_PRODUCTS.ADD_ANOTHER_PRODUCT.PLACEHOLDER' | translate
                            }}"
                            aria-label="Number"
                            matInput
                            formControlName="add_on_product"
                            [matAutocomplete]="auto"
                          />
                          <mat-autocomplete
                            #auto="matAutocomplete"
                            [displayWith]="displayFn"
                            (optionSelected)="addNewAddonProduct()"
                          >
                            <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
                              {{ product.name }}
                            </mat-option>
                          </mat-autocomplete>
                          <!-- <span *ngIf="productForm.controls.add_on_product.value && productForm.controls.add_on_product.value.id"
													(click)="addNewAddonProduct()" class="submit-icon" matTooltip="{{'LEASING_CALCULATOR.FIELDS.ADD_ON_PRODUCTS.ADD_ANOTHER_PRODUCT.TOOLTIP' | translate}}">
													<i class="fas fa-check"></i>
												</span> -->
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12 my-4">
                      <a
                        href="javascript:;"
                        (click)="addAnotherProduct = !addAnotherProduct"
                        class="kt-font-primary d-block"
                      >
                        <span>
                          <i class="fas fa-plus" style="font-size: 10px"></i>
                          {{ "LEASING_CALCULATOR.FIELDS.ADD_ON_PRODUCTS.ADD_ANOTHER_PRODUCT.TITLE" | translate }}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="validation-error-messages mt-3">
                <ul class="list-unstyled">
                  <li class="kt-font-danger" *ngFor="let errorItem of errorMsgs | keyvalue">- {{ errorItem.value }}</li>
                </ul>
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="calculations" class="col-md-5">
          <div class="calculation-results">
            <kt-calculation-results
              [calculations]="calculations"
              [entityTypeCode]="(entity && entity.contract_type) || (entity && entity.type)"
              [entityType]="entityType"
              [summary]="true"
              [investment]="investment"
              [investmentTitle]="investmentTitle"
            >
            </kt-calculation-results>
          </div>
        </div>
      </div>
    </div>
  </kt-portlet-body>
</kt-portlet>
