<kt-portlet class="add-edit-entity pt-0">
  <kt-portlet-body>
    <button class="btn btn-primary mb-3 w-auto" (click)="addComparableVehicle(-1)">
      + {{ "VEHICLE_VALUATION.ADD_COMPARABLE_VEHICLE" | translate }}
    </button>
    <div class="vehicle-valuation-form" *ngIf="product">
      <div class="row row-no-padding row-col-separator-xl">
        <div class="col-md-12 table-responsive">
          <table
            *ngIf="product.vehicle_market_price && product.vehicle_market_price?.length"
            class="table ellipses-overflow"
          >
            <thead>
              <tr>
                <th>
                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.VEHICLE_MAKE.PLACEHOLDER" | translate }}
                </th>
                <th>
                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.VEHICLE_MODEL.PLACEHOLDER" | translate }}
                </th>
                <th>
                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER" | translate }}
                </th>
                <th>
                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.MILEAGE.PLACEHOLDER" | translate }}
                </th>
                <th>
                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.ADJ_MILEAGE.PLACEHOLDER" | translate }}
                </th>
                <th>
                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.ADVERTISED_PRICE.PLACEHOLDER" | translate }}
                </th>
                <th>
                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.ADJ_PRICE.PLACEHOLDER" | translate }}
                </th>
                <th>
                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.ACTIONS" | translate }}
                </th>
              </tr>
            </thead>
            <tbody *ngFor="let vehicle of product.vehicle_market_price; let i = index">
              <tr>
                <td>
                  {{ vehicle.vehicle_make.name }}
                </td>
                <td>
                  {{ vehicle.model?.model }}
                </td>
                <td>
                  {{ vehicle.first_registration_date }}
                </td>
                <td>
                  {{ vehicle.mileage | number : "2." }}
                </td>
                <td>
                  {{ vehicle.adjusted_mileage_cents | number }}
                </td>
                <td>
                  {{ vehicle.advertised_price_cents | number }}
                </td>
                <td>
                  {{ vehicle.adjusted_price_cents | number }}
                </td>
                <td>
                  <kt-actions-menu>
                    <li class="kt-nav__item" (click)="editVehicle(i)">
                      <a href="javascript:;" class="kt-nav__link">
                        <span [inlineSVG]="'./assets/media/icons/edit.svg'"></span>
                        <span class="kt-nav__link-text">
                          {{ "GENERAL.EDIT" | translate }}
                        </span>
                      </a>
                    </li>
                    <li class="kt-nav__item" (click)="deleteVehicle(i)">
                      <a href="javascript:;" class="kt-nav__link">
                        <span [inlineSVG]="'./assets/media/icons/trash.svg'"></span>
                        <span class="kt-nav__link-text">
                          {{ "GENERAL.DELETE" | translate }}
                        </span>
                      </a>
                    </li>
                  </kt-actions-menu>
                </td>
              </tr>
            </tbody>
          </table>

          <form [formGroup]="productForm" class="vehicle-price kt-form kt-form--group-seperator-dashed">
            <kt-alert
              *ngIf="hasFormErrors"
              type="warn"
              [showCloseButton]="true"
              [duration]="10000"
              (close)="onAlertClose($event)"
            >
              {{ "GENERAL.FORM_ERROR_MESSAGE" | translate }}
            </kt-alert>

            <div class="kt-form__group overflow-hidden">
              <div
                class="market-price-documents"
                *ngIf="calculations && product.vehicle_market_price && product.vehicle_market_price?.length"
              >
                <div class="row">
                  <div class="col-6">
                    <h5 class="kt-portlet__head-title mx-2">
                      {{ "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.SUMMARY" | translate }}
                    </h5>
                    <div class="registeration-tax">
                      <div class="kt-form__group pb-0">
                        <div class="registeration-tax-info">
                          <table class="table">
                            <tbody>
                              <tr>
                                <td>
                                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.AVERAGE" | translate }}
                                </td>
                                <td>
                                  {{ calculations?.adjusted_price_average | number : "1.2-2" }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{
                                    "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.ADVERTISEMENT_DEDUCTION" | translate
                                  }}
                                </td>
                                <td>
                                  {{ calculations?.advertisement_deduction | number : "1.2-2" }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.CONDITION_DEDUCTION" | translate }}
                                </td>
                                <td>
                                  {{ calculations?.condition_deduction | number : "1.2-2" }}
                                </td>
                              </tr>
                              <tr class="border-bottom pb-3">
                                <td>
                                  {{ "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.MILEAGE_DEDUCTION" | translate }}
                                </td>
                                <td>
                                  {{ calculations?.mileage_deduction | number : "1.2-2" }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{
                                    "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.MARKETPRICE_AFTER_ADJUSTMMENTS"
                                      | translate
                                  }}
                                </td>
                                <td>
                                  {{ calculations?.market_price_after_adjustments | number : "1.2-2" }}
                                </td>
                              </tr>
                              <tr (click)="openRegistrationFees()">
                                <td style="cursor: pointer">
                                  {{
                                    "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.REG_AFTER_ADJUSTMMENTS" | translate
                                  }}
                                </td>
                                <td style="cursor: pointer; color: blue">
                                  {{ calculations?.final_tax | number : "1.2-2" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Documents -->
                  <div class="col-6">
                    <div class="row h-100">
                      <div class="col-md-12">
                        <h5 class="kt-portlet__head-title mx-2">
                          {{ "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.TITLE" | translate }}
                          <i
                            class="fas fa-question-circle tooltip-icon"
                            [matTooltip]="documentsTooltipMsg"
                            matTooltipPosition="after"
                          ></i>
                        </h5>
                      </div>
                      <div class="col-md-12">
                        <div class="documents" formArrayName="documents">
                          <div
                            class="document-row"
                            *ngFor="let document of productForm.controls.documents.controls; let i = index"
                          >
                            <div class="kt-form__group" [formGroupName]="i">
                              <div
                                class="dropzone-row"
                                [ngClass]="{
                                  uploaded: productForm.controls.documents.controls[i].controls.file_name.value
                                }"
                              >
                                <div
                                  *ngIf="productForm.controls.documents.controls[i].controls.file_name.value"
                                  class="col-md-12"
                                >
                                  <div class="uploaded-file-info">
                                    <img class="file-icon" src="assets/media/icons/document.png" />
                                    <div class="file-info">
                                      <h5 class="title">
                                        {{
                                          "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.FILE_UPLOADED"
                                            | translate
                                        }}
                                      </h5>
                                      <p class="file-name">
                                        {{ productForm.controls.documents.controls[i].controls.file_name.value }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  [hidden]="productForm.controls.documents.controls[i].controls.file_name.value"
                                  class="col-md-6 kt-margin-bottom-10-mobile"
                                >
                                  <div
                                    class="dropzone-wrapper dropzone-borderless"
                                    [class.mat-form-field-invalid]="errorMsgs['documents.' + i + '.file']"
                                  >
                                    <dropzone
                                      #documentsDropzone
                                      (addedFile)="setPhotoFile($event, i)"
                                      (removedFile)="setPhotoFile(null, i)"
                                      [config]="dropZoneConfig"
                                      [message]="dropZoneMessage"
                                    ></dropzone>
                                  </div>
                                  <mat-error
                                    *ngIf="
                                      productForm.controls.documents.controls[i].controls.file.touched &&
                                      productForm.controls.documents.controls[i].controls.file.hasError('required')
                                    "
                                  >
                                    <span
                                      [innerHTML]="
                                        'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.DROPZONE.ERROR_MSG'
                                          | translate
                                      "
                                    ></span>
                                  </mat-error>
                                </div>
                                <div class="col-lg-5 kt-margin-bottom-10-mobile align-self-center">
                                  <div class="market-price-document-id">
                                    <mat-form-field
                                      class="mat-form-field-fluid"
                                      [ngClass]="{
                                        'mat-form-field-invalid':
                                          errorMsgs['documents.' + i + '.vehicle_market_price_document_id']
                                      }"
                                    >
                                      <mat-select
                                        placeholder="Choose file"
                                        formControlName="vehicle_market_price_document_id"
                                      >
                                        <mat-option value="">
                                          {{
                                            "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.MARKET_PRICE_DOCUMENT.PLACEHOLDER"
                                              | translate
                                          }}
                                        </mat-option>
                                        <mat-option
                                          [value]="marketPriceDocument.id"
                                          *ngFor="let marketPriceDocument of marketPriceDocuments"
                                        >
                                          {{ marketPriceDocument.name }}
                                        </mat-option>
                                      </mat-select>
                                      <mat-error
                                        *ngIf="
                                          productForm.controls.documents.controls[
                                            i
                                          ].controls.vehicle_market_price_document_id.hasError('required')
                                        "
                                      >
                                        <span
                                          [innerHTML]="
                                            'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.MARKET_PRICE_DOCUMENT.ERROR_MSG'
                                              | translate
                                          "
                                        ></span>
                                      </mat-error>
                                    </mat-form-field>
                                    <!-- <mat-error *ngIf="errorMsgs['documents.'+ i +'.vehicle_market_price_document_id']">{{errorMsgs['documents.'+ i +'.vehicle_market_price_document_id'][0]}}</mat-error> -->
                                  </div>
                                </div>
                                <div
                                  *ngIf="productForm.controls.documents.controls[i].controls.file_id.value"
                                  class="col-md-1 remove-document align-self-center"
                                >
                                  <button
                                    (click)="deleteDocument(document.value.file_id, i)"
                                    class="text-center d-block m-auto"
                                    mat-icon-button
                                    color="secondary"
                                    type="button"
                                    matTooltip="{{
                                      'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.REMOVE_DOCUMENT'
                                        | translate
                                    }}"
                                  >
                                    <img src="assets/media/icons/close.png" />
                                  </button>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div
                                  *ngIf="!productForm.controls.documents.controls[i].controls.file_id.value"
                                  class="col-md-12"
                                >
                                  <a
                                    *ngIf="
                                      productForm.controls.documents.controls[i].controls.is_first &&
                                        productForm.controls.documents.controls[i].controls.is_first.value;
                                      else not_first
                                    "
                                    href="javascript:;"
                                    (click)="addNewDocumentRow()"
                                    class="kt-font-primary d-block"
                                  >
                                    <span>
                                      <i class="fas fa-plus" style="font-size: 10px"></i>
                                      {{
                                        "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.ADD_ANOTHER_DOCUMENT"
                                          | translate
                                      }}
                                    </span>
                                  </a>
                                  <ng-template #not_first>
                                    <a
                                      href="javascript:;"
                                      (click)="removeDocumentRow(i)"
                                      class="kt-font-danger d-block"
                                    >
                                      <span>
                                        <i class="fas fa-minus" style="font-size: 10px"></i>
                                        {{
                                          "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.REMOVE_DOCUMENT"
                                            | translate
                                        }}
                                      </span>
                                    </a>
                                  </ng-template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 d-flex">
                        <button (click)="onAddAdditionalDocument()" class="btn btn-primary align-self-end w-100">
                          +
                          {{
                            "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.ADD_ANOTHER_DOCUMENT" | translate
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-center py-4" *ngIf="isGettingCalculationsLoading">
                <mat-spinner [diameter]="40"></mat-spinner>
              </div>

              <div class="validation-error-messages mt-3">
                <ul class="list-unstyled">
                  <li class="kt-font-danger" *ngFor="let errorItem of errorMsgs | keyvalue">- {{ errorItem.value }}</li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </kt-portlet-body>
</kt-portlet>
