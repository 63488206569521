<div
  ngbDropdown
  placement="bottom-right"
  autoClose="true"
  *ngIf="user$ | async as _user"
  class="kt-header__topbar-item kt-header__topbar-item--user has-arrow"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <div class="kt-header__topbar-user" [ngClass]="{ 'kt-header__topbar-icon': icon }">
      <!-- <span class="kt-header__topbar-welcome kt-hidden-mobile" *ngIf="greeting">Hi,</span> -->
      <!-- <span class="kt-header__topbar-username kt-hidden-mobile" *ngIf="greeting">{{_user.name}}</span> -->
      <i *ngIf="icon" class="far fa-id-badge"></i>
      <img *ngIf="avatar" alt="Pic" [attr.src]="_user.profile_picture_path || 'assets/media/users/default.jpg'" />
      <div class="kt-header__topbar-user-chevron">
        <span class="down" [inlineSVG]="'./assets/media/icons/down-chevron.svg'"></span>
        <span class="up" [inlineSVG]="'./assets/media/icons/up-chevron.svg'"></span>
      </div>
      <span
        *ngIf="badge"
        class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
        >{{ _user.name | firstLetter }}</span
      >
    </div>
  </div>
  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-rounded dropdown-menu-md"
  >
    <!--begin: Navigation(alternative) -->
    <ul class="kt-nav kt-margin-b-10">
      <li class="kt-nav__item kt-nav__item--disabled">
        <a href="javascript:;" class="kt-nav__link">
          <!-- <span class="kt-nav__link-icon"><i class="far fa-id-badge"></i></span> -->
          <span class="kt-nav__link-text">
            <b>{{ _user.name }}</b
            ><br />
            <span class="user-role">
              {{ _user.role?.title }}
            </span>
          </span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a [routerLink]="['/user-profile']" class="kt-nav__link" ngbDropdownItem>
          <!-- <span class="kt-nav__link-icon"><i class="far fa-id-badge"></i></span> -->
          <span class="kt-nav__link-text kt-font-brand">{{ "NAVBAR.PROFILE_MMENU.PROFILE" | translate }}</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a [routerLink]="['/user-profile/change-password']" class="kt-nav__link" ngbDropdownItem>
          <!-- <span class="kt-nav__link-icon"><i class="fas fa-cog"></i></span> -->
          <span class="kt-nav__link-text kt-font-brand">{{ "NAVBAR.PROFILE_MMENU.CHANGE_PASSWORD" | translate }}</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a href="javascript:;" (click)="logout()" class="kt-nav__link" ngbDropdownItem>
          <!-- <span class="kt-nav__link-icon"><i class="fas fa-cog"></i></span> -->
          <span class="kt-nav__link-text sign-out">{{ "NAVBAR.PROFILE_MMENU.SIGN_OUT" | translate }}</span>
        </a>
      </li>
      <!-- <li class="kt-nav__item kt-nav__item--custom kt-margin-t-15">
				<a (click)="logout()" class="btn btn-outline-brand btn-upper btn-sm btn-bold">Sign Out</a>
			</li> -->
    </ul>
    <!--end: Navigation(alternative) -->
  </div>
</div>
