export class NoteModel {
  public id: number;
  public text: any;
  public customer_id: number;
  public contract_id: number;
  public offer_id: number;
  public category?: any;
  public category_id?: number;
  public customers;
  public created_at: string;
  public updated_at: string;
  public created_by_id: any;
  public customer: any;

  clear() {
    this.text = "";
    this.customer_id = null;
    this.contract_id = null;
    this.offer_id = null;
    this.category_id = null;
    return this;
  }

  public setAttributes(model) {
    this.id = model.id;
    this.text = model.text.replaceAll("<br />\\r\\n<br />\\r\\n", "<br>").replaceAll("\n", "<br>");
    this.customer_id = model.customer_id;
    this.contract_id = model.contract_id;
    this.offer_id = model.offer_id;
    this.category_id = model.category_id;
    this.category = model.category;
    this.created_at = model.created_at;
    this.updated_at = model.updated_at;
    this.created_by_id = model.created_by_id;
    this.customer = model.customer;
    return this;
  }
}
