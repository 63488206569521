export class VehicleValuationModel {
  id: number;
  product: any;
  product_id: any;
  advertised_vehicles: any[];
  extra_equipment_value: any[];
  vehicle_market_price: any[];
  documents: any[];
  market_price_after_adjustments: any;
  total_registration_tax: any;
  advertisement_deduction: any;
  mileage_deduction: any;
  condition_deduction: any;
  extra_equipment_adjustment: any;
  created_at: string;
  updated_at: string;

  clear() {
    this.product = "";
    this.market_price_after_adjustments = 0;
    this.total_registration_tax = 0;
    this.advertisement_deduction = 0;
    this.mileage_deduction = 0;
    this.condition_deduction = 0;
    this.extra_equipment_adjustment = 0;
    this.created_at = "";
    this.updated_at = null;
  }
}
