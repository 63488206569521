// Angular
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS
import { Observable, Subscription, of } from "rxjs";
import { map, startWith, switchMap, debounceTime } from "rxjs/operators";
// CRUD
import { LayoutUtilsService, TypesUtilsService, MessageType } from "../../../core/_base/crud";
import { SharedService } from "../shared.service";
import { TranslateService } from "@ngx-translate/core";
import { DropzoneComponent } from "ngx-dropzone-wrapper";

@Component({
  selector: "kt-mark-task-done-dialog",
  templateUrl: "./mark-task-done-dialog-component.html",
})
export class MarkTaskDoneDialogComponent implements OnInit {
  private componentSubscriptions: Subscription[] = [];
  public viewLoading: boolean = false;
  endTaskForm: FormGroup;
  errorMsgs = {};

  constructor(
    private fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private productsService: SharedService,
    private cdr: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    public dialogRef: MatDialogRef<MarkTaskDoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initEndTaskForm();
  }

  initEndTaskForm() {
    this.endTaskForm = this.fb.group({
      final_registration_tax: [0, Validators.required],
    });
  }

  endTask() {
    const task_id = this.data.data.tasks
      .map((task) => task.task)
      .filter((task) => task.code == this.data.task_code)[0].id;
    const contract_id = this.data.data.contract_id;
    let endTaskSubscription = this.productsService
      .markContractTaskAsDone(contract_id, task_id, this.endTaskForm.value)
      .subscribe((res) => {
        this.dialogRef.close({ done: true });
      });
    this.componentSubscriptions.push(endTaskSubscription);
  }
}
