// Angular
import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from "@angular/core";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "datatable-filter-dropdown",
  templateUrl: "./datatable-filter-dropdown.component.html",
})
export class DatatableFilterDropdownComponent implements OnInit {
  activeFilters: number = 0;
  @ViewChild("myNgbDropdown", { static: true }) dropdown: NgbDropdown;

  // Public properties
  @Output() onReset: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() filters;

  constructor() {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {}

  onClickedOutside(e: Event) {
    if (this.dropdown.isOpen) {
      if (e.target["className"] !== "mat-option-text") {
        this.dropdown.close();
      }
    }
  }

  ngDoCheck() {
    let activeFilters = 0;
    Object.keys(this.filters).forEach((key) => {
      if (this.filters[key]) {
        activeFilters += 1;
      }
    });
    if (activeFilters !== this.activeFilters) {
      this.activeFilters = activeFilters;
    }
  }
}
