// Angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// RxJS
import { Observable } from "rxjs";

import { API } from "../../../core/_base/crud";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { PermissionModel } from "./permissions.model";
import { Store } from "@ngrx/store";
import { AppState } from "../../../core/reducers";
import { currentUser } from "../../../core/auth";
import { Permissions } from "../../shared/enums/permission/generalPermissions";

@Injectable()
export class PermissionsService {
  permissions: any = {};

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(currentUser).subscribe((res: { permissions_list: [] }) => {
      if (res?.permissions_list) {
        this.permissions = res.permissions_list;
      }
    });
  }

  createProduct(product): Observable<any> {
    return this.http.post<any>(API.permissions, product);
  }

  getProductById(productId: number): Observable<any> {
    return this.http.get<any>(API.permissions + `/${productId}`);
  }

  indexProduct(params: IRequestPagination): Observable<any> {
    return this.http.get<IResponsePagination<PermissionModel>>(API.permissions, {
      params: { ...params },
    });
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.put<any>(`${API.permissions}/${productId}`, product);
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.delete<any>(`${API.permissions}/${productId}`);
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(`${API.roles}`);
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(`${API.permissions}/categories`);
  }

  canUseSettings(): boolean {
    return this.permissions?.some((permission) => permission?.name === Permissions.USE_SETTINGS);
  }
}
