<div class="kt-portlet datatable-view">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "INVOICES.VIEW.TITLE" | translate }}
      </h3>
      <div class="datatable-selected-filters-wrapper">
        <div *ngIf="selectedFilters['statusName']" class="datatable-selected-filter-wrapper">
          <button class="datatable-selected-filters" mat-raised-button color="primary">
            {{ selectedFilters["statusName"] }}
          </button>
          <button
            (click)="filters.status = ''; selectedFilters['statusName'] = ''; loadProductsList()"
            class="datatable-selected-filters-remove"
            mat-icon-button
            color="primary"
            matTooltip="{{ 'GENERAL.REMOVE' | translate }}"
          >
            <i class="fas fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="kt-portlet__head-toolbar">
      <div class="datatable-search">
        <div class="search-wrapper" style="position: relative">
          <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
          <input
            placeholder="{{ 'GENERAL.SEARCH' | translate }}"
            class="form-control"
            #searchInput
            style="height: calc(1.5em + 1rem + 2px)"
          />
          <button
            #searchButton
            mat-raised-button
            style="
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            "
            color="primary"
          >
            Søg
          </button>
        </div>
      </div>
      <datatable-filter-dropdown (onReset)="resetFilters()" [filters]="filters">
        <mat-accordion>
          <mat-expansion-panel class="for-filters">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "INVOICES.VIEW.TABLE_COLUMNS.PAYMENT_STATUS" | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="kt-form__control">
              <mat-radio-group [(ngModel)]="filters.status" (change)="loadProductsList()">
                <mat-radio-button [value]="invoiceStatus.code" *ngFor="let invoiceStatus of invoiceStatuses">
                  {{ invoiceStatusLabels.get(invoiceStatus.name) | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </datatable-filter-dropdown>
    </div>
  </div>

  <div class="kt-portlet__body">
    <div class="mat-table__wrapper datatable-container">
      <div class="datatable-table-container">
        <table
          #table
          mat-table
          [dataSource]="data"
          class="example-table lmat-elevation-z8 readonly"
          matSort
          matSortActive="id"
          matSortDisableClear
          matSortDirection="asc"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef class="expand-entity-header"></th>
            <td
              mat-cell
              *matCellDef="let row"
              class="expand-entity-info"
              [class.entity-expanded-row]="expandedElement === row"
            >
              <button
                class="expand-info"
                mat-icon-button
                color="primary"
                (click)="expandedElement = expandedElement === row ? null : row"
              >
                <i class="far fa-plus-square"></i>
              </button>
              <button
                mat-icon-button
                class="collapse-info"
                color="warn"
                (click)="expandedElement = expandedElement === row ? null : row"
              >
                <i class="far fa-minus-square"></i>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%">
              {{ "INVOICES.VIEW.TABLE_COLUMNS.DATE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" width="10%">{{ row.date | dmyDate }}</td>
          </ng-container>

          <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef class="responsive" width="10%">
              {{ "INVOICES.VIEW.TABLE_COLUMNS.CUSTOMER" | translate }}
            </th>
            <td
              mat-cell
              [matTooltip]="row.customer?.name"
              *matCellDef="let row"
              class="responsive curser-initial"
              width="10%"
            >
              {{ this.layoutUtilsService.sliceWord(row.customer?.name) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="external_booked_invoice_number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "INVOICES.VIEW.TABLE_COLUMNS.INVOICE_NUMBER" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.external_booked_invoice_number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="amount_excl_vat_cents">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "INVOICES.VIEW.TABLE_COLUMNS.AMOUNT_EXCLUDE_VAT" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.amount_excl_vat_cents | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="vat_cents">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "INVOICES.VIEW.TABLE_COLUMNS.VAT_CENTS" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.vat_cents | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="total_amount">
            <th mat-header-cell *matHeaderCellDef class="responsive" width="10%">
              {{ "INVOICES.VIEW.TABLE_COLUMNS.TOTAL" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.total_amount | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "CUSTOMERS.ADD.FIELDS.CURRENCY.PLACEHOLDER" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="payment_due_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "INVOICES.VIEW.TABLE_COLUMNS.DUEDATE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              {{ row.payment_due_date | dmyDate }}
              <!-- <span [innerHTML]="row.payment_due_date"></span> -->
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "INVOICES.VIEW.TABLE_COLUMNS.PAYMENT_STATUS" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              <invoice-status-button [status]="row.status"></invoice-status-button>
            </td>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
              {{ "INVOICES.VIEW.TABLE_COLUMNS.CREATED" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive" width="10%">
              <span [innerHTML]="row.created_at | dmyDate | safe"></span>
            </td>
          </ng-container>

          <!-- <ng-container matColumnDef="updated_at">
						<th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive" width="10%">
							{{'INVOICES.VIEW.TABLE_COLUMNS.UPDATED' | translate}}</th>
						<td mat-cell *matCellDef="let row" class="responsive" width="10%">
							<span [innerHTML]="row.updated_at | dmyDate | safe"></span>
						</td>
					</ng-container> -->

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef width="10%"></th>
            <td mat-cell *matCellDef="let row" width="10%">
              <kt-actions-menu *ngIf="row.is_booked">
                <li class="kt-nav__item" (click)="downloadInvoice(row)">
                  <a href="javascript:;" class="kt-nav__link">
                    <!-- <span [inlineSVG]="'./assets/media/icons/edit.svg'"></span> -->
                    <span>
                      <i class="fas fa-download"></i>
                    </span>
                    <span class="kt-nav__link-text">
                      {{ "GENERAL.DOWNLOAD" | translate }}
                    </span>
                  </a>
                </li>
              </kt-actions-menu>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div
                class="entity-details-info-column"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
              >
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "INVOICES.VIEW.TABLE_COLUMNS.INVOICE_NUMBER" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.id }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "INVOICES.VIEW.TABLE_COLUMNS.PAYMENT_STATUS" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    <span class="badge kt-bg-fill-default" *ngIf="!element.is_booked">
                      {{ "GENERAL.NO" | translate }}
                    </span>
                    <span class="badge kt-bg-fill-success" *ngIf="element.is_booked">
                      {{ "GENERAL.YES" | translate }}
                    </span>
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "INVOICES.VIEW.TABLE_COLUMNS.NOTES" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.notes_text_1 }}
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "INVOICES.VIEW.TABLE_COLUMNS.CREATED" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    <span [innerHTML]="element.created_at | dmyDate | safe"></span>
                  </div>
                </div>
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "INVOICES.VIEW.TABLE_COLUMNS.UPDATED" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    <span [innerHTML]="element.updated_at | dmyDate | safe"></span>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="entity-default-row"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="entity-details-info-row"></tr>
        </table>
        <div *ngIf="data.length == 0 && !isLoadingResults" class="d-flex justify-content-center">
          <p class="m-3">{{ "GENERAL.NO_RESULTS_FOUND" | translate }}</p>
        </div>
        <div class="mat-table__message" *ngIf="isRateLimitReached">
          {{ "GENERAL.SOMETHING_WENT_WRONG" | translate }}
        </div>
        <!-- <div class="mat-table__message" *ngIf="isLoadingResults">{{'GENERAL.PLEASE_WAIT' | translate}}</div> -->
      </div>

      <!-- start: BOTTOM -->
      <div class="mat-table__bottom">
        <mat-spinner [diameter]="20" *ngIf="isLoadingResults"></mat-spinner>
        <mat-paginator appStylePaginator [length]="resultsLength" showFirstLastButtons></mat-paginator>
      </div>
      <!-- end: BOTTOM -->
    </div>
  </div>
  <!-- end::Body -->
</div>
