export enum EconomicEntityTypes {
  ACCOUNTING_YEAR_ENTRY = "AccountingYearEntry",
  ACCOUNTING_YEAR = "AccountingYear",
  ACCOUNT = "Account",
  JOURNAL = "Journal",
  LAYOUT = "Layout",
  PAYMENT_TERM = "PaymentTerm",
  PRODUCT_GROUP = "ProductGroup",
  SUPPLIER_GROUP = "SupplierGroup",
  VAT_ACCOUNT = "VatAccount",
}
