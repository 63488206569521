import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService, MessageType, TypesUtilsService } from "app/core/_base/crud";
import { Subscription } from "rxjs";
import { ContractMileageService } from "..";
import { ContractMileageModel } from "../contract-mileage.model";
import * as _moment from "moment";
import { default as _rollupMoment, Moment } from "moment";
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
const moment = _rollupMoment || _moment;

@Component({
  selector: "kt-create-adjustment-payments",
  templateUrl: "./create-adjustment-payments.component.html",
  styleUrls: ["./create-adjustment-payments.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class CreateAdjustmentPaymentsComponent implements OnInit {
  product: ContractMileageModel;
  productForm: FormGroup;
  hasFormErrors: boolean = false;
  errorMsgs: any = {}; //Back-end errors
  dateRequired: boolean = false;
  viewLoading = false;
  mileageCalculations: any = {};
  private componentSubscriptions: Subscription[] = [];
  date = new FormControl("", Validators.required);

  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private productsService: ContractMileageService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<CreateAdjustmentPaymentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const newProduct = new ContractMileageModel();
    newProduct.clear();
    this.loadProduct(newProduct);
  }

  loadProduct(_product) {
    this.product = _product;
    this.cdr.markForCheck();
  }

  onDateChange(date: MatDatepickerInputEvent<Moment>, datepicker: MatDatepicker<Moment>) {
    datepicker.close();
    if (date) {
      this.calcMileageSplit({
        split_adj_date: this.typesUtilsService.formatDate(date.value, "MM-yyyy"),
      });
      this.dateRequired = false;
    }
  }

  calcMileageSplit(payload) {
    this.errorMsgs = {};
    const calcMileageSubscription = this.productsService
      .calculateMileageSplit(this.data.contract.id, payload)
      .subscribe(
        (res) => {
          this.viewLoading = false;
          if (res.data) {
            this.mileageCalculations = res.data;
          }
        },
        (err) => {
          this.viewLoading = false;
          this.mileageCalculations = [];
          if (err.status === 422) {
            this.errorMsgs = { message: this.translate.instant("CONTRACTS.DETAILS.MILEAGE.INVALID_DATE") };
            this.cdr.markForCheck();
            this.hasFormErrors = true;
          }
          if (err.error) {
            this.layoutUtilsService.showActionNotification(err.error?.message, MessageType.Create, 10000, true, false);
          }
        }
      );
    this.componentSubscriptions.push(calcMileageSubscription);
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.errorMsgs = {};
    this.viewLoading = true;
    /** check form */
    if (!this.date || !this.date.value || this.date.value == "") {
      this.hasFormErrors = true;
      this.viewLoading = false;
      this.dateRequired = true;
      return;
    }

    let payload: any = {};

    if (this.date && this.date.value) {
      payload.split_adj_date = this.typesUtilsService.formatDate(this.date.value, "dd-MM-yyyy");
    }
    if (this.mileageCalculations.percent_split) {
      payload.actual_split_private = this.mileageCalculations.percent_split.private;
      payload.actual_split_corporate = this.mileageCalculations.percent_split.corporate;
    }

    if (this.mileageCalculations.adjustment_incl_vat) {
      payload.adjustment_incl_vat_private = this.mileageCalculations.adjustment_incl_vat.private;
      payload.adjustment_incl_vat_corporate = this.mileageCalculations.adjustment_incl_vat.corporate;
    }

    if (this.mileageCalculations.adjustment_excl_vat) {
      payload.adjustment_excl_vat_private = this.mileageCalculations.adjustment_excl_vat.private;
      payload.adjustment_excl_vat_corporate = this.mileageCalculations.adjustment_excl_vat.corporate;
    }

    if (this.mileageCalculations.total_mileage) {
      payload.total_mileage_corporate = this.mileageCalculations.total_mileage.corporate;
      payload.total_mileage_private = this.mileageCalculations.total_mileage.private;
    }

    this.createAdjustmentPayments(payload);
  }

  createAdjustmentPayments(payload) {
    const createAdjSubscription = this.productsService
      .createAdjustmentPayments(this.data.contract.id, payload)
      .subscribe(
        (res) => {
          this.viewLoading = false;
          if (res.data) {
            this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Create, 10000, true, false);
          }
          this.dialogRef.close({});
        },
        (err) => {
          this.viewLoading = false;
          if (err.error) {
            this.errorMsgs = err.error;
            this.cdr.markForCheck();
            this.hasFormErrors = true;
          }
        }
      );
    this.componentSubscriptions.push(createAdjSubscription);
  }
}
