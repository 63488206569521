<span
  [ngClass]="{
    'kt-bg-fill-default': status === invoiceStatus.NOT_PAID,
    'kt-bg-fill-danger': status === invoiceStatus.OVER_DUE,
    'kt-bg-fill-success': status === invoiceStatus.PAID
  }"
  class="badge kt-bg-fill-default"
>
  {{ (status === invoiceStatus.PAID ? "GENERAL.YES" : "GENERAL.NO") | translate }}
</span>
