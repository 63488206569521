// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PartialsModule } from "../../partials/partials.module";
import { NgxPermissionsModule } from "ngx-permissions";
// Components
import { InvoicesListComponent } from "./invoices-list.component";
import { InvoiceCreateDialogComponent } from "./invoice-create-dialog.component";
import { InvoicePreviewDialogComponent } from "./invoice-preview-dialog.component";
import { InvoicesService } from "./invoices.service";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [InvoicesListComponent, InvoiceCreateDialogComponent, InvoicePreviewDialogComponent],
  imports: [CommonModule, PartialsModule, NgxPermissionsModule.forChild(), DragDropModule],
  providers: [InvoicesService],
})
export class InvoicesModule {}
