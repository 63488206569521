<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ data.title }}</h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <div class="form-group kt-form__group row">
        <div class="col-lg-12" [innerHTML]="viewLoading ? data.waitDesciption : data.description">
          <!-- {{viewLoading ? data.waitDesciption : data.description}} -->
        </div>
      </div>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="onNoClick()">{{ "GENERAL.CANCEL" | translate }}</button>&nbsp;
        <button mat-button (click)="onYesClick()" color="primary" cdkFocusInitial [disabled]="viewLoading">
          {{ data.submitText || ("GENERAL.DELETE" | translate) }}
        </button>
      </div>
    </div>
  </div>
</div>
