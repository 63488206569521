<div class="kt-portlet cote-container">
  <div class="kt-portlet__head custom-note-h background__change pb-3">
    <div class="kt-portlet__head-label main-note-title">
      <h3 class="kt-portlet__head-title Letter__spacing">
        {{ "NOTES.VIEW.TITLE" | translate }}
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>

    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button
          class="btn btn-primary btn-edit"
          color="primary"
          mat-raised-button
          (click)="editNote(null)"
          *ngxPermissionsOnly="['create_notes', 'create_external_notes']"
        >
          {{ "NOTES.ADD.ADD_TITLE" | translate }}
        </button>
      </div>
    </div>
  </div>
  <kt-portlet-body class="notes-list">
    <div class="timeline">
      <div class="container right" *ngFor="let note of notes">
        <div class="content">
          <div class="col-md-12" style="margin-top: -16px">
            <h3 class="title">
              <p style="font-size: 13px">{{ note?.category?.name }}</p>
              <span
                [hidden]="note?.created_by_id != user?.id && user?.role?.name != 'super_admin' && !updateNote"
                (click)="toggleNoteOptions(note?.id)"
                (clickOutside)="onClickedOutside(note?.id)"
                [inlineSVG]="'./assets/media/icons/dots.svg'"
              ></span>
            </h3>
            <div class="drop-note hide-note-options" [id]="note?.id">
              <p *ngxPermissionsOnly="['update_notes']" style="margin-bottom: 5px" (click)="editNote(note)">
                <!-- <i class='fas fa-pen'></i> -->
                <span class="ng-tns-c14-66" ng-reflect-inline-s-v-g="./assets/media/icons/edit.svg"
                  ><svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="35 30 45 45"
                    height="40px"
                    width="40px"
                    style="enable-background: new 35 30 45 45"
                    xml:space="preserve"
                  >
                    <path
                      d="M62.2,39.8c-1-1-2.3-1.5-3.7-1.5c-1.4,0-2.7,0.5-3.7,1.5l-13,13c-0.1,0.1-0.2,0.3-0.3,0.5l-2,8c-0.1,0.3,0,0.7,0.3,0.9
										c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.2,0l8-2c0.2,0,0.3-0.1,0.5-0.3l13-13C64.3,45.2,64.3,41.8,62.2,39.8z M43,55.5l3.5,3.5
										l-4.7,1.2L43,55.5z M48.5,58.1l-4.6-4.6l10.6-10.6l1.6,1.6l-8.3,8.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
										l8.3-8.3l1.6,1.6L48.5,58.1z M60.8,45.8l-0.3,0.3l-4.6-4.6l0.3-0.3c1.2-1.2,3.4-1.2,4.6,0C62.1,42.5,62.1,44.5,60.8,45.8z"
                    ></path></svg
                ></span>
                <strong class="action-text">Rediger</strong>
              </p>
              <p
                *ngIf="note?.created_by_id == user?.id || user?.role?.name == 'super_admin'"
                style="margin-bottom: 5px"
                (click)="deleteProduct(note)"
              >
                <!-- <i class="fa fa-trash"></i> -->
                <span class="ng-tns-c14-66" ng-reflect-inline-s-v-g="./assets/media/icons/trash.svg"
                  ><svg
                    version="1.1"
                    id="Rectangle_1_1_"
                    height="40px"
                    width="40px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="-4 0 24 20"
                    style="enable-background: new 0 0 11 15"
                    xml:space="preserve"
                  >
                    <style type="text/css">
                      .st0 {
                        fill-rule: evenodd;
                        clip-rule: evenodd;
                      }
                    </style>
                    <g id="Rectangle_1">
                      <g>
                        <path
                          class="st0"
                          d="M0.78,13.23c0,0.84,0.69,1.52,1.53,1.52h6.12c0.84,0,1.53-0.68,1.53-1.52V3.34H0.78V13.23z M2,4.5h6.5v9H2
												V4.5z M7.66,1.05L6.9,0.29H3.84L3.07,1.05H0.01v1.52h10.7V1.05H7.66z"
                        ></path>
                      </g>
                    </g></svg
                ></span>
                <strong class="action-text">Slet</strong>
              </p>
            </div>
          </div>
          <span class="date" [innerHTML]="note?.created_at | dmyDate | safe"></span>
          <p class="customer-name" style="font-size: 11px">
            {{ note?.customer?.name ? note?.customer?.name : "ingen kunder" }}
          </p>
          <p class="note-text" [innerHTML]="note?.text"></p>
        </div>
      </div>
    </div>
  </kt-portlet-body>
</div>
