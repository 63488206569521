import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { API } from "../../../core/_base/crud";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { RegistrationTaxInterestRateModel } from "./registration-tax-interest-rate.model";

@Injectable()
export class RegistrationTaxInterestRateService {
  endpoint: string = API.registrationInterestRates;

  constructor(private http: HttpClient) {}

  createProduct(product): Observable<any> {
    return this.http.post<any>(this.endpoint, product);
  }

  getProductById(productId: number): Observable<any> {
    return this.http.get<any>(this.endpoint + `/${productId}`);
  }

  indexProduct(params: IRequestPagination): Observable<any> {
    return this.http.get<IResponsePagination<RegistrationTaxInterestRateModel>>(this.endpoint, {
      params: { ...params },
    });
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.put<any>(`${this.endpoint}/${productId}`, product);
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.delete<any>(`${this.endpoint}/${productId}`);
  }
}
