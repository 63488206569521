import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  CustomerFieldEnum,
  CustomerFieldsMapperForSettings,
  customerOfferFormFields,
  VehicleFieldEnum,
  VehicleFormDefaultSettings,
} from "../../../../../app/views/pages/settings/components/required-fields-settings-dialog/required-fields-enum";
import { EntityType } from "../../../../../app/views/shared/enums/entity/entityType";
import { IResponse } from "../interfaces/response-interface";
import { BaseSettingsModel } from "../../../../../app/views/pages/settings/base-settings.model";
import { IRequiredFieldsSettings } from "../../../../../app/views/pages/settings/components/required-fields-settings-dialog/required-fields-settings.interface";

export function castControlFromAbstractControlToFormControl(control: AbstractControl): FormControl {
  return control as FormControl;
}

export function useVehicleFieldsSettings(
  settings: IResponse<BaseSettingsModel<IRequiredFieldsSettings>>,
  form: FormGroup,
  entityType: EntityType
) {
  const formSettings = settings.data;
  applyVehicleValidators(form, entityType, formSettings);
}

function setVehicleDefaultValidators(form: FormGroup) {
  Object.keys(VehicleFormDefaultSettings).forEach((field) => {
    const control = form.get(field);
    if (control) {
      control.addValidators(VehicleFormDefaultSettings[field] ? [Validators.required] : [Validators.nullValidator]);
      control.updateValueAndValidity();
    }
  });
}

function applyVehicleValidators(
  form: FormGroup,
  entityType: EntityType,
  formSettings: BaseSettingsModel<IRequiredFieldsSettings>
) {
  if (formSettings && formSettings.is_active) {
    const vehicleFields = formSettings.value.vehicleFields;
    Object.entries(vehicleFields).forEach(([key, value]) => {
      let isRequired: boolean;
      switch (entityType) {
        case EntityType.CONTRACT:
          isRequired = value.requiredInContractForm;
          break;
        case EntityType.OFFER:
          isRequired = value.requiredInOfferForm;
          break;
        default:
          isRequired = value.requiredInDefaultForm;
      }

      setMielagePhotoRequired(key, isRequired, form);
      const control = form.get(key as VehicleFieldEnum);
      if (!control) return;
      control.setValidators(isRequired ? [Validators.required] : Validators.nullValidator);
      control.updateValueAndValidity();
    });
  } else {
    setVehicleDefaultValidators(form);
  }
}

function setMielagePhotoRequired(key, required: boolean, form: FormGroup) {
  if (!required || key != VehicleFieldEnum.MILEAGE_PHOTO) return;
  form.get("hasMileagePhoto").setValue(true);
  form.get("hasMileagePhoto").disable();
}

export function useCustomerFieldsSettings(
  settings: IResponse<BaseSettingsModel<IRequiredFieldsSettings>>,
  form: FormGroup,
  entityType: EntityType,
  defaultSettings: any
): void {
  const formSettings = settings.data;
  applyCustomerFormValidators(form, entityType, formSettings, defaultSettings);
}

function setCustomerDefaultValidators(form: FormGroup, entityType: EntityType, defaultSettings: any) {
  Object.keys(defaultSettings).forEach((field) => {
    const control = form.get(field);
    if (control) {
      control.setValidators(defaultSettings[field] ? [Validators.required] : [Validators.nullValidator]);
      control.updateValueAndValidity();
    }
  });
  if (entityType === EntityType.OFFER) {
    setCustomerOfferFormDefaultValidators(form);
  }
}

function setCustomerOfferFormDefaultValidators(form: FormGroup) {
  Object.keys(customerOfferFormFields).forEach((field) => {
    const control = form.get(field);
    if (control) {
      control.setValidators(customerOfferFormFields[field] ? [Validators.required] : [Validators.nullValidator]);
      control.updateValueAndValidity();
    }
  });
}

function applyCustomerFormValidators(
  form: FormGroup,
  entityType: EntityType,
  formSettings: BaseSettingsModel<IRequiredFieldsSettings>,
  defaultSettings: any
) {
  if (formSettings && formSettings.is_active) {
    const customerFields = formSettings.value.customerFields;
    Object.entries(customerFields).forEach(([key, value]) => {
      const enumKey = key as CustomerFieldEnum;
      const control = form.get(
        CustomerFieldsMapperForSettings.has(enumKey) ? CustomerFieldsMapperForSettings.get(enumKey) : enumKey
      );
      if (control) {
        let isRequired: boolean;
        switch (entityType) {
          case EntityType.CONTRACT:
            isRequired = value.requiredInContractForm;
            break;
          case EntityType.OFFER:
            isRequired = value.requiredInOfferForm;
            break;
          default:
            isRequired = value.requiredInDefaultForm;
        }
        control.setValidators(isRequired ? [Validators.required] : Validators.nullValidator);
        control.updateValueAndValidity();
      }
    });
  } else {
    setCustomerDefaultValidators(form, entityType, defaultSettings);
  }
}

export const emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
