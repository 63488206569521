import { PaymentType } from "./../../../payments/payment-type.model";
import { SuppliersService } from "./../../../../pages/suppliers/suppliers.service";
// Angular
import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS
import { Observable, of, Subscription } from "rxjs";
import { debounceTime, map, startWith, switchMap } from "rxjs/operators";
// CRUD
import { LayoutUtilsService, MessageType, TypesUtilsService } from "../../../../../core/_base/crud";
import { SharedService } from "../../../shared.service";
import { MarkTaskDoneDialogComponent } from "../../../mark-task-as-done/mark-task-done-dialog-component";
import { TransactionModel } from "./../../transaction.model";
import { TranslateService } from "@ngx-translate/core";
import { DropzoneComponent } from "ngx-dropzone-wrapper";

// External Components Refs
import { SupplierEditComponent } from "./../../../../pages/suppliers/supplier-edit.component";
import { TransactionTypes } from "../../../enums/transaction/transactionTypes";

@Component({
  selector: "kt-transaction-edit-dialog",
  templateUrl: "./transaction-edit-dialog.component.html",
  styles: [
    `
      .transactions {
        border-bottom: 1px dashed #eee;
        padding: 15px 0;
      }
    `,
  ],
})
export class TransactionEditDialogComponent implements OnInit, OnDestroy {
  @Input() enableHeader: boolean = true;

  @ViewChild("documentsDropzone") documentsDropzone: DropzoneComponent;
  dropZoneConfig: any = {
    acceptedFiles: ".jpeg,.png,.jpg,.bmp,.gif,.pdf",
    maxFilesize: 60,
    maxFiles: 10,
  };
  uploadedDocuments: any[];
  // Public properties
  product: TransactionModel;
  productForm: FormGroup;
  hasFormErrors: boolean = false;
  //transactionTypes:any[];
  vatCodes: any[];
  //accounts:any[];
  filteredContraAccounts: Observable<any[]>;
  filteredTransactionsProducts: Observable<any[]>[] = [];
  suppliers: any[];
  errorMsgs: any = {}; //Back-end errors
  filteredProducts: Observable<any>;
  filteredSuppliers: Observable<any>;
  isReadonly: boolean = false;
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;
  transactionType: any; // whether it's `financeVouchers` or `supplierInvoices`;
  taskCode: any = null;
  assetDetails: any = {};
  hideNewButton: any;
  paymentTypes: PaymentType[] = [];
  transactionTypes = TransactionTypes;

  constructor(
    private supplierService: SuppliersService,
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private productsService: SharedService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private permissionsService: NgxPermissionsService,
    public dialogRef: MatDialogRef<TransactionEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {
    this.dropZoneConfig.message = `
			<div class="dropzone-placeholder">
				<img class="file-icon" src="assets/media/icons/document-grey.png">
				<span class="dropzone-placeholder-text">
					${this.translate.instant("TRANSACTIONS.ADD.FIELDS.ATTACHMENTS.PLACEHOLDER")}
				</span>
			</div>
		`;
  }

  ngOnInit() {
    console.log("Transaction Data", this.data);
    this.permissionsService.permissions$.subscribe((res) => {
      if (this.data.transaction_set_id) {
        this.loadProductFromService(this.data.transaction_set_id);
      } else {
        this.transactionType = this.data.is_finance_vouchers ? "financeVouchers" : "supplierInvoices";
        this.taskCode = this.data.task_code;
        this.assetDetails = this.data?.asset_details;
        // this.getSuppliers();
        const newProduct = new TransactionModel();
        newProduct.clear();
        this.loadProduct(newProduct);
        this.getPaymentTypes();
      }
    });

    const vatCodesSubscription = this.productsService.getLookups("vat_codes").subscribe((res) => {
      this.vatCodes = res.data;
    });
    console.log("Vat Codes");
    this.componentSubscriptions.push(vatCodesSubscription);

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  loadProduct(_product) {
    console.log("Product ", _product);
    this.product = _product;
    this.initProduct();
    this.cdr.markForCheck();
  }

  setPaymentType() {
    const { value } = this.productForm.get("supplier_id");
    this.productForm.controls.payment_type_id.setValue(value?.paymentInfo || null);
    return value?.paymentInfo;
  }

  loadProductFromService(productId) {
    const getTransactionSetDetailsSubscription = this.productsService
      .getTransactionSetDetails(productId)
      .subscribe((res) => {
        if (res.data && res.data.transaction_lines && res.data.transaction_lines.length > 0) {
          let mainTransaction = res.data.transaction_lines.find((t) => t.product_id == null);
          if (!mainTransaction && res.data.transaction_lines && res.data.transaction_lines.length) {
            mainTransaction = res.data.transaction_lines[0];
          }
          console.log("Main Transaction", mainTransaction);
          if (mainTransaction) {
            if (mainTransaction.transaction_type == TransactionTypes.FINANCE_VOUCHERS) {
              this.transactionType = TransactionTypes.FINANCE_VOUCHERS;
            } else {
              this.transactionType = TransactionTypes.SUPPLIER_INVOICES;
            }
            // this.getSuppliers();
            let product = {
              ...mainTransaction,
              is_multi_lines: res.data.transaction_lines.length > 1,
              date: mainTransaction.date,
              supplier_invoice_duedate: mainTransaction.supplier_invoice_duedate ?? "",
              contra_account: mainTransaction.account,
              account_vat_code: +mainTransaction.account_vat_code,
              transactions: res.data.transaction_lines
                .filter((t) => +t.id != +mainTransaction.id)
                .map((t) => {
                  return {
                    product: t.product,
                    account_vat_code: +t.account_vat_code,
                    amount: t.amount_cents,
                  };
                }),
            };
            this.uploadedDocuments = res.data.documents.map((d) => ({
              file_name: d.file ? d.file.file_name : "",
              file_id: d.file ? d.file.id : null,
              document_id: d.id,
            }));
            this.loadProduct(product);
          }
        }
      });
    this.componentSubscriptions.push(getTransactionSetDetailsSubscription);
  }

  getPaymentTypes() {
    const getPaymentTypesSub = this.supplierService.getPaymentInfo().subscribe((res) => {
      this.paymentTypes = res.data.filter((payment) => payment.code !== "posting_only");
    });
    console.log("Payment types");
    this.componentSubscriptions.push(getPaymentTypesSub);
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  productSelected($event) {
    const payload = (<FormGroup>this.productForm).controls;
    if ($event.option && $event.option.value) {
      payload.contra_account.setValue($event.option.value.contra_account_number);
      payload.account_vat_code.setValue($event.option.value.vatCode ? $event.option.value.vatCode.id : "");
    }
  }

  transactionsProductSelected($event, index) {
    const payload = (<FormGroup>(<FormArray>this.productForm.get("transactions")).controls[index]).controls;
    if ($event.option && $event.option.value) {
      if (
        this.taskCode &&
        this.taskCode === "book_vehicle" &&
        $event.option.value.code === "buy_vehicle_with_reclaimable_eu_vat_to_contract"
      ) {
        this.productForm.controls.is_multi_lines.setValue(1);
        this.product.is_multi_lines = true;
        payload.account_vat_code.setValue($event.option.value.vatCode ? $event.option.value.vatCode.id : "");
      } else {
        payload.account_vat_code.setValue($event.option.value.vatCode ? $event.option.value.vatCode.id : "");
        this.productForm.get("contra_account").setValue($event.option.value.contra_account_number);
      }
    }
  }

  initProduct() {
    this.createForm();
  }

  createForm() {
    let transactionsArray: FormGroup[] = [];
    if (this.product.transactions && this.product.transactions.length > 0) {
      this.product.transactions.forEach((transaction) => {
        transactionsArray.push(
          this.productFB.group({
            product: [{ value: transaction.product, disabled: this.isReadonly }],
            account_vat_code: [
              {
                value: transaction.account_vat_code,
                disabled: this.isReadonly,
              },
            ],
            amount: [{ value: transaction.amount, disabled: this.isReadonly }],
            vehicle_plate_number: [
              {
                value: this.data.vehicle_plate_number,
                disabled: this.isReadonly,
              },
            ],
          })
        );
      });
    } else {
      transactionsArray.push(
        this.productFB.group({
          product: [""],
          account_vat_code: [],
          amount: [0],
          vehicle_plate_number: null,
        })
      );
    }

    this.productForm = this.createProductForm(transactionsArray);
    this.filteredContraAccounts = this.productForm.get("contra_account").valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (typeof value == "string") {
          return this.productsService.getAccounts(value);
        } else return of([]);
      }),
      map((res) => res.data)
    );

    this.searchForSuppliers();
    this.searchForProducts();
    this.searchForTransactionsProducts(0);
    this.getPaymentTypes();

    this.productForm.get("is_multi_lines").valueChanges.subscribe((value) => {
      if (this.transactionType == "financeVouchers" && value) {
        this.productForm.get("amount_cents").disable();
      } else this.productForm.get("amount_cents").enable();

      if (this.taskCode === "book_vehicle") {
        (<FormArray>this.productForm.get("transactions")).push(
          this.productFB.group({
            product: [{ value: "", disabled: true }],
            account_vat_code: [],
            amount: [0],
          })
        );
        let length = (<FormArray>this.productForm.get("transactions")).controls.length;
        this.searchForReclaimableProducts(length - 1);
        this.listenToTransactionsAmountValueChanges();
      }
    });

    this.listenToTransactionsAmountValueChanges();
    if (this.taskCode == "book_registration_tax" || this.taskCode === "end_agreement") {
      this.listenToRegTaxAmountValueChanges();
    }
    this.listenToPaymentTypeChanges();
  }

  searchForReclaimableProducts(rowIndex) {
    this.filteredTransactionsProducts[rowIndex] = (<FormGroup>(
      (<FormArray>this.productForm.get("transactions")).controls[rowIndex]
    )).controls.product.valueChanges.pipe(
      startWith(""),
      debounceTime(200),
      switchMap((value) => {
        if (typeof value == "string") {
          return this.productsService.getProductsList({
            q: "reclaimable",
            is_transaction_type: 1,
            used_in_contract_add_trans: 1,
            order_dir: "asc",
            order_column: "id",
          });
        } else return of([]);
      }),
      map((res) => {
        if (res.data) {
          this.productForm.controls.transactions["controls"][0].controls.product.reset({
            value: res.data[0],
            disabled: true,
          });
          (<FormGroup>(<FormArray>this.productForm.get("transactions")).controls[0]).controls.account_vat_code.setValue(
            res.data[0].vatCode ? res.data[0].vatCode.id : ""
          );

          if (this.productForm.controls.transactions["controls"][1]) {
            this.productForm.controls.transactions["controls"][1].controls.product.setValue(res.data[1]);
            (<FormGroup>(
              (<FormArray>this.productForm.get("transactions")).controls[1]
            )).controls.account_vat_code.setValue(res.data[1].vatCode ? res.data[1].vatCode.id : "");
          }
        } else return [];
      })
    );
    this.cdr.markForCheck();
  }

  createProductForm(transactionsArray) {
    if (this.taskCode === "book_registration_tax" || this.taskCode === "end_agreement") {
      return this.productFB.group({
        is_multi_lines: false,
        transaction_type_code: [this.product.transaction_type_code],
        date: [this.data.end_agreement_date || this.product.date, Validators.required],
        final_registration_tax: [
          "",
          this.taskCode === "book_registration_tax" ? Validators.required : Validators.nullValidator,
        ],
        vehicle: [
          {
            value: this.data?.asset_details
              ? `${this.data?.asset_details?.id}-${
                  this.data?.asset_details?.make?.name ? this.data?.asset_details?.make?.name : ""
                }${this.data?.asset_details?.model?.model ? "-" + this.data?.asset_details?.model?.model : ""}`
              : "",
            disabled: true,
          },
        ],
        product: [
          {
            value: this.product.product,
            disabled: this.taskCode === "book_registration_tax",
          },
        ],
        account_vat_code: [this.product.account_vat_code],
        amount_cents: [
          {
            value: this.data.amount_cents || this.product.amount_cents,
            disabled: this.transactionType == this.transactionTypes.FINANCE_VOUCHERS,
          },
        ],
        amount_cents_reg_tax: [
          {
            value: this.data.amount_cents_reg_tax || this.product.amount_cents,
            disabled: false,
          },
        ],
        contra_account: [this.product.contra_account],
        supplier_id: [
          {
            value: this.product.supplier,
            disabled: false,
          },
        ],
        supplier_invoice_number: [this.product.supplier_invoice_number],
        supplier_invoice_duedate: [this.product.supplier_invoice_duedate],
        transactions: this.productFB.array(transactionsArray),
        vehicle_plate_number: [this.data.vehicle_plate_number],
      });
    } else {
      return this.productFB.group({
        is_multi_lines: [this.taskCode == "book_registration_tax" ? false : true],
        transaction_type_code: [this.product.transaction_type_code],
        date: [this.product.date, Validators.required],
        vehicle: [
          {
            value: this.data?.asset_details
              ? `${this.data?.asset_details?.id}-${
                  this.data?.asset_details?.make?.name ? this.data?.asset_details?.make?.name : ""
                }${this.data?.asset_details?.model?.model ? "-" + this.data?.asset_details?.model?.model : ""}`
              : "",
            disabled: this.taskCode == "book_registration_tax" || this.taskCode == "book_vehicle",
          },
        ],
        product: [
          {
            value: this.product.product,
            disabled: this.taskCode == "book_registration_tax",
          },
        ],
        account_vat_code: [this.product.account_vat_code],
        amount_cents: [
          {
            value: this.product.amount_cents,
            disabled: this.transactionType == TransactionTypes.FINANCE_VOUCHERS,
          },
        ],
        amount_cents_reg_tax: [
          {
            value: this.product.amount_cents,
            disabled: false,
          },
        ],
        contra_account: [this.product.contra_account],
        supplier_id: [
          {
            value: this.product.supplier,
            disabled: false,
          },
        ],
        supplier_invoice_number: [this.product.supplier_invoice_number],

        supplier_invoice_duedate: [this.product.supplier_invoice_duedate],
        // Payment Type
        payment_type_id: [
          {
            value: this.product?.payment_info, //column  this.product["payment_type_id"] doesn't exist
            disabled: false,
          },
        ],
        payment_label: [
          this.product?.payment_info ? this.product.payment_details[this.product.payment_info?.label?.code] : "",
        ],
        payment_second_label: [
          this.product?.payment_info ? this.product.payment_details[this.product.payment_info?.second_label?.code] : "",
        ],
        transactions: this.productFB.array(transactionsArray),
      });
    }
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  listenToPaymentTypeChanges() {
    this.productForm?.get("payment_type_id")?.valueChanges.subscribe((payment) => {
      this.productForm.get("payment_label").setValue(this.productForm?.get("supplier_id")?.value?.account_no);
      this.productForm.get("payment_second_label").setValue(this.productForm?.get("supplier_id")?.value?.credit_id);
    });
  }

  listenToTransactionsAmountValueChanges() {
    // Listen to valueChanges in amount transaction lines to calculate the total amount
    (<FormArray>this.productForm.get("transactions")).controls.forEach((transactionControl: FormGroup) => {
      if (transactionControl) {
        transactionControl.controls.amount.valueChanges.subscribe((res) => {
          this.calculateTotalAmountCents();
        });
      }
    });
  }

  listenToRegTaxAmountValueChanges() {
    this.productForm.get("amount_cents_reg_tax").valueChanges.subscribe((res) => {
      this.hideNewButton = res;
      this.productForm.get("amount_cents").setValue(this.productForm.get("amount_cents_reg_tax").value * -1);
    });
  }

  calculateTotalAmountCents() {
    setTimeout(() => {
      let totalAmount = 0;
      if (this.transactionType == "financeVouchers" && this.productForm.get("is_multi_lines").value) {
        if (
          this.productForm.value &&
          this.productForm.value.transactions &&
          this.productForm.value.transactions.length
        ) {
          this.productForm.value.transactions.forEach((transaction) => {
            if (transaction.amount) {
              totalAmount += transaction.amount;
            }
          });
          this.productForm.get("amount_cents").setValue(totalAmount * -1);
        }
      }
    });
  }

  searchForSuppliers() {
    this.filteredSuppliers = this.productForm.get("supplier_id").valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (typeof value == "string") {
          return this.productsService.getSuppliers(value);
        } else return of([]);
      }),
      map((res) => res.data)
    );
  }

  searchForProducts() {
    this.filteredProducts = (<FormGroup>this.productForm).controls.product.valueChanges.pipe(
      startWith(""),
      debounceTime(200),
      switchMap((value) => {
        if (typeof value == "string") {
          if (
            this.taskCode &&
            (this.taskCode === "book_registration_tax" || this.taskCode === "end_agreement") &&
            !value
          ) {
            // return this.productsService.getProductsList({ q: "registration_tax", is_transaction_type: 1 });
            if (this.data.contract_id) {
              return this.productsService.getProductsList({
                q: "registration_tax",
                is_transaction_type: 1,
                used_in_contract_add_trans: 1,
              });
            } else if (this.data.vehicle_id) {
              return this.productsService.getProductsList({
                q: "registration_tax",
                is_transaction_type: 1,
                used_in_vehicle_add_trans: 1,
              });
            }
          } else {
            if (this.data.contract_id) {
              return this.productsService.getProductsList({
                q: value,
                is_transaction_type: 1,
                used_in_contract_add_trans: 1,
              });
            } else if (this.data.vehicle_id) {
              return this.productsService.getProductsList({
                q: value,
                is_transaction_type: 1,
                used_in_vehicle_add_trans: 1,
              });
            }
          }
        } else return of([]);
      }),
      map((res) => this.returnedProducts(res))
    );
    this.cdr.markForCheck();
  }

  returnedProducts(res) {
    //set product value with registration tax object if task code "book_registration_tax"
    if (
      res.data &&
      res.data[0]?.code === "registration_tax" &&
      (this.taskCode === "book_registration_tax" || this.taskCode === "end_agreement")
    ) {
      this.productForm.controls.product.setValue(res.data[0]);
      this.productForm.controls.contra_account.setValue(res.data[0]?.contra_account_number);
      this.productForm.controls.account_vat_code.setValue(res.data[0]?.vatCode ? res.data[0]?.vatCode.id : "");
    }
    // else return all the array
    else {
      return res.data;
    }
  }

  searchForTransactionsProducts(rowIndex) {
    this.filteredTransactionsProducts[rowIndex] = (<FormGroup>(
      (<FormArray>this.productForm.get("transactions")).controls[rowIndex]
    )).controls.product.valueChanges.pipe(
      startWith(""),
      debounceTime(200),
      switchMap((value) => {
        if (typeof value == "string") {
          if (this.taskCode && this.taskCode === "book_vehicle") {
            return this.productsService.getProductsList({
              q: "buy_vehicle_with",
              is_transaction_type: 1,
              used_in_contract_add_trans: 1,
            });
          } else if (this.taskCode === "end_agreement" && !value) {
            return this.productsService.getProductsList({
              q: "registration_tax",
              is_transaction_type: 1,
              used_in_contract_add_trans: 1,
            });
          } else if (this.data.contract_id) {
            return this.productsService.getProductsList({
              q: value,
              is_transaction_type: 1,
              used_in_contract_add_trans: 1,
            });
          } else if (this.data.vehicle_id) {
            return this.productsService.getProductsList({
              q: value,
              is_transaction_type: 1,
              used_in_vehicle_add_trans: 1,
            });
          }
        } else return of([]);
      }),
      map((res) => {
        if (this.taskCode && this.taskCode === "book_vehicle") {
          if (res && res.data) {
            if (this.assetDetails.is_used_vat) {
              const result = res.data.filter((product) => product.code == "buy_vehicle_with_used_vat_to_contract");
              return result;
            } else if (this.assetDetails.is_used_vat == 0) {
              const result = res.data.filter((product) => {
                const codes = [
                  "buy_vehicle_with_danish_vat_to_contract",
                  "buy_vehicle_with_eu_vat_to_contract",
                  "buy_vehicle_with_reclaimable_eu_vat_to_contract",
                ];
                return codes.includes(product.code);
              });
              return result;
            }
          } else {
            return [];
          }
        } else if (this.taskCode === "end_agreement") {
          return this.returnedProducts(res);
        } else {
          return res.data;
        }
      })
    );
    this.cdr.markForCheck();
  }

  accountDisplayFn(account: any): string {
    return account && account.entity_object ? (account.entity_id || "") + " - " + account.entity_object.name : "";
  }

  addTransactionRow() {
    (<FormArray>this.productForm.get("transactions")).push(
      this.productFB.group({
        product: [""],
        account_vat_code: [],
        amount: [0],
      })
    );
    let length = (<FormArray>this.productForm.get("transactions")).controls.length;
    this.searchForTransactionsProducts(length - 1);
    this.listenToTransactionsAmountValueChanges();
  }

  removeTransactionRow(index) {
    (<FormArray>this.productForm.get("transactions")).removeAt(index);
    this.calculateTotalAmountCents();
  }

  displayFn(product: any): string {
    return product && product.name ? (product.account_number?.entity_id || "") + " - " + product.name : "";
  }

  displaySupplierFn(product: any): string {
    return product && product.name ? product.name : "";
  }

  deleteDocument(document_id) {
    const _title: string = this.translate.instant("TRANSACTIONS.ADD.DELETE_DOCUMENT_MODAL.TITLE");
    const _description: string = this.translate.instant("TRANSACTIONS.ADD.DELETE_DOCUMENT_MODAL.DESCRIPTION");
    const _waitDesciption: string = this.translate.instant("TRANSACTIONS.ADD.DELETE_DOCUMENT_MODAL.WAIT_DESCRIPTION");

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      if (this.data.contract_id) {
        this.productsService
          .deleteTransactionSetDocument(this.data.contract_id, this.data.transaction_set_id, document_id, "contract")
          .subscribe(
            (res) => {
              if (res.data) {
                this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Delete);
              }
              let deletedIndex = this.uploadedDocuments.findIndex((d) => d.document_id == document_id);

              if (deletedIndex !== -1) {
                this.uploadedDocuments.splice(deletedIndex, 1);
              }
              this.cdr.markForCheck();
            },
            (err) => {
              if (err.error.data) {
                this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
              }
            }
          );
      } else {
        this.productsService
          .deleteTransactionSetDocument(this.data.vehicle_id, this.data.transaction_set_id, document_id, "vehicle")
          .subscribe(
            (res) => {
              if (res.data) {
                this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Delete);
              }
              let deletedIndex = this.uploadedDocuments.findIndex((d) => d.document_id == document_id);

              if (deletedIndex !== -1) {
                this.uploadedDocuments.splice(deletedIndex, 1);
              }
              this.cdr.markForCheck();
            },
            (err) => {
              if (err.error.data) {
                this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
              }
            }
          );
      }
    });
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.viewLoading = true;
    const controls = this.productForm.controls;
    /** check form */
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAllAsTouched());
      this.hasFormErrors = true;
      this.viewLoading = false;
      return;
    }

    let fd: FormData = new FormData();
    const payload = this.productForm.getRawValue();
    // if (!payload.account_vat_code) {
    // 	payload.account_vat_code = '';
    // }
    Object.keys(payload).forEach((key) => {
      if (key == "is_multi_lines") {
        fd.append(`${this.transactionType}[${key}]`, payload[key] ? "1" : "0");
      } else if (key == "transaction_type_code") {
        fd.append(
          `${this.transactionType}[${key}]`,
          this.transactionType == "financeVouchers" ? "financeVouchers" : "supplierInvoices"
        );
      } else if (key == "date") {
        if (payload[key]) {
          fd.append(`${this.transactionType}[${key}]`, this.typesUtilsService.formatDate(payload[key], "dd-MM-yyyy"));
        }
      } else if (key == "supplier_invoice_duedate") {
        if (payload[key]) {
          fd.append(`${this.transactionType}[${key}]`, this.typesUtilsService.formatDate(payload[key], "dd-MM-yyyy"));
        }
      } else if (key == "payment_type_id") {
        if (payload[key]) {
          fd.append(`${this.transactionType}[${key}]`, payload[key].id);
        }
      } else if (key == "payment_label") {
        if (payload[key] && payload["payment_type_id"]) {
          if (payload["payment_type_id"].code !== "none") {
            fd.append(`${this.transactionType}[${payload["payment_type_id"].label.code}]`, payload.payment_label);
          }
        }
      } else if (key == "payment_second_label") {
        if (payload[key] && payload["payment_type_id"]) {
          if (payload["payment_type_id"].code !== "none") {
            fd.append(
              `${this.transactionType}[${payload["payment_type_id"].second_label.code}]`,
              payload.payment_second_label
            );
          }
        }
      } else if (key == "product") {
        if (payload.is_multi_lines) {
          delete payload["product"];
          return; // product is not required in multi-lines
        }
        if (payload[key] && payload[key].id) {
          fd.append(`${this.transactionType}[product_id]`, payload[key].id);
        }
        delete payload["product"];
      } else if (key == "final_registration_tax" && this.taskCode === "book_registration_tax") {
        if (payload[key]) {
          fd.append(`${key}`, payload[key]);
          fd.append(`task_code`, this.taskCode);
        }
      } else if (key == "contra_account") {
        if (payload[key] && payload[key].id && this.transactionType == this.transactionTypes.FINANCE_VOUCHERS) {
          fd.append(`${this.transactionType}[contra_account]`, payload[key].id);
        }
      } else if (key == "transactions") {
        if (payload.is_multi_lines) {
          payload.transactions.forEach((transaction, transactionIndex) => {
            Object.keys(transaction).forEach((transactionKey) => {
              if (transactionKey == "product" && transaction[transactionKey]) {
                fd.append(
                  `${this.transactionType}[${key}][${transactionIndex}][product_id]`,
                  transaction[transactionKey].id
                );
              }
              if (transactionKey == "account_vat_code" && !transaction[transactionKey]) {
                fd.append(`${this.transactionType}[${key}][${transactionIndex}][account_vat_code]`, "");
              } else {
                fd.append(
                  `${this.transactionType}[${key}][${transactionIndex}][${transactionKey}]`,
                  transaction[transactionKey]
                );
              }
            });
          });
        }
      } else if (key == "account_vat_code") {
        if (this.transactionType == "supplierInvoices" && payload.is_multi_lines) return;
        if (payload[key]) {
          fd.append(`${this.transactionType}[account_vat_code]`, payload[key]);
        } else fd.append(`${this.transactionType}[account_vat_code]`, "");
      } else if (key == "amount_cents") {
        if (this.transactionType == "supplierInvoices" && payload.is_multi_lines) {
          delete payload["amount_cents_reg_tax"];
          return;
        }
        if (payload[key]) {
          this.taskCode == "book_registration_tax" || this.taskCode === "end_agreement"
            ? fd.append(`${this.transactionType}[amount_cents]`, payload["amount_cents_reg_tax"])
            : fd.append(`${this.transactionType}[amount_cents]`, payload[key]);
        }
        delete payload["amount_cents_reg_tax"];
      } else if (key == "supplier_id") {
        if (payload[key] && payload[key].id) {
          fd.append(`${this.transactionType}[supplier_id]`, payload[key].id);
        }
      } else {
        if (payload[key] !== null && payload[key] !== undefined) {
          fd.append(`${this.transactionType}[${key}]`, payload[key]);
        }
      }
    });

    if (this.data.task_code && (this.data.task_code === "book_vehicle" || this.data.task_code === "end_agreement")) {
      fd.append(`task_code`, this.data.task_code);
    }

    // Add dropzones properties only if it has files inside
    if (this.documentsDropzone.directiveRef.dropzone().files) {
      this.documentsDropzone.directiveRef.dropzone().files.forEach((file, index) => {
        fd.append(`documents[${index}][file]`, file);
      });
    }
    if (this.data.transaction_set_id) {
      if (this.data.contract_id) fd.append("contract_id", this.data.contract_id);
      else if (this.data.vehicle_id) fd.append("vehicle_id", this.data.vehicle_id);
      fd.append("_method", "PUT");
      this.updateProduct(fd);
      return;
    }
    this.addProduct(fd);
  }

  addProduct(payload) {
    if (this.data.contract_id) {
      const createProductSubscription = this.productsService
        .storeContractTransaction(payload, this.data.contract_id, "contract")
        .subscribe(
          (res) => {
            this.viewLoading = false;
            if (res.message) {
              this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
            }
            // this.dialogRef.close(true);
            this.dialogRef.close(res);
          },
          (err) => {
            this.viewLoading = false;
            if (err.error) {
              this.errorMsgs = err.error.errors;
              this.cdr.markForCheck();
              this.hasFormErrors = true;
            }
          }
        );
      this.componentSubscriptions.push(createProductSubscription);
    } else {
      const createProductSubscription = this.productsService
        .storeContractTransaction(payload, this.data.vehicle_id, "vehicle")
        .subscribe(
          (res) => {
            this.viewLoading = false;
            if (res.message) {
              this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
            }
            // this.dialogRef.close(true);
            this.dialogRef.close(res);
          },
          (err) => {
            this.viewLoading = false;
            if (err.error) {
              this.errorMsgs = err.error.errors;
              this.cdr.markForCheck();
              this.hasFormErrors = true;
            }
          }
        );
      this.componentSubscriptions.push(createProductSubscription);
    }
  }

  updateProduct(payload) {
    const createProductSubscription = this.productsService
      .updateTransactionSet(payload, this.data.transaction_set_id)
      .subscribe(
        (res) => {
          this.viewLoading = false;
          if (res.message) {
            this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
          }
          this.dialogRef.close(res);
        },
        (err) => {
          this.viewLoading = false;
          if (err.message) {
            if (err.message) {
              this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
            }
            this.errorMsgs = err.error.errors;
            this.cdr.markForCheck();
            this.hasFormErrors = true;
          }
        }
      );
    this.componentSubscriptions.push(createProductSubscription);
  }

  getComponentTitle() {
    let result = this.translate.instant("TRANSACTIONS.ADD.ADD_TITLE");
    if (!this.data.transaction_set_id) {
      if (this.taskCode && (this.taskCode === "book_registration_tax" || this.taskCode === "end_agreement")) {
        result = this.translate.instant("TASKS.VIEW.BOOK_REGISTRATION_TAX.ADD.ADD_TITLE");
      }
      return result;
    }

    result = `${this.translate.instant("TRANSACTIONS.ADD.EDIT_TITLE")}`;
    return result;
  }

  markTaskAsDone() {
    const dialogRef = this.dialog.open(MarkTaskDoneDialogComponent, {
      data: { task_code: this.taskCode, data: this.data },
      width: "600px",
      maxHeight: "85vh",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.dialogRef.close(true);
    });
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  onAddNewSupplier() {
    const dialogRef = this.dialog.open(SupplierEditComponent, {
      width: "900px",
      maxHeight: "90vh",
      data: { isCancelEnabled: true },
    });
    dialogRef.afterClosed().subscribe((supplier) => {
      this.productForm.get("supplier_id").patchValue(supplier);
    });
  }
}
