export enum PaymentTermsTypes {
  NET = "net",
  INVOICE_MONTH = "invoiceMonth",
  PAID_IN_CASH = "paidInCash",
  PREPAID = "prepaid",
  DUE_DATE = "dueDate",
  INVOICE_WEEK_STARTING_SUNDAY = "invoiceWeekStartingSunday",
  INVOICE_WEEK_STARTING_MONDAY = "invoiceWeekStartingMonday",
  CREDIT_CARD = "creditcard",
  AGREEMENT_GIRO = "avtaleGiro",
}
