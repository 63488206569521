// Angular
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  Inject,
  ChangeDetectionStrategy,
  OnDestroy,
  ViewEncapsulation,
  HostListener,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ActivatedRoute } from "@angular/router";
import { saveAs } from "file-saver";
// CRUD
import { LayoutUtilsService, MessageType, API } from "../../../core/_base/crud";
import { HttpClient } from "@angular/common/http";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fromEvent, merge, of as observableOf, Subscription } from "rxjs";
import { catchError, map, debounceTime, distinctUntilChanged, tap, filter } from "rxjs/operators";
import { InvoiceModel } from "./invoice.model";
import { InvoicesService } from "./invoices.service";
// import { InvoiceEditComponent } from './invoice-edit.component';
// Tranlsation
import { TranslateService } from "@ngx-translate/core";
import { currentUser } from "../../../core/auth";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../core/reducers";
import { MatButton } from "@angular/material/button";
import { PaymentConstants } from "../payments/payment.constants";
import { InvoiceStatus, invoiceStatusLabels } from "../enums/invoice/invoiceStatus";
import { EntityType } from "../enums/entity/entityType";
import { IInvoiceRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { MAT_DATA_TABLES_INVOICES } from "../../../core/constants/local-storage-constants";
import { GlobalUtilsService } from "../../../core/_base/crud/utils/global-utils.service";
import { VehiclesService } from "../../pages/vehicles-management/vehicles";

@Component({
  selector: "kt-invoices-list",
  templateUrl: "./invoices-list.component.html",
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*", marginBottom: "10px" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesListComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  displayedColumns: string[] = [
    "expand",
    "date",
    "customer",
    "external_booked_invoice_number",
    "amount_excl_vat_cents",
    "vat_cents",
    "total_amount",
    "currency",
    "payment_due_date",
    "status",
    "created_at",
    "actions",
  ];
  data: InvoiceModel[] = [];
  expandedElement: InvoiceModel | null;

  invoiceStatus = InvoiceStatus;
  invoiceStatusLabels = invoiceStatusLabels;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("searchButton") searchButton: MatButton;

  invoiceStatuses: any[];
  filters = {
    status: "",
  };
  selectedFilters = {}; // Selected filters names for display
  filtersState; // The currently saved filters in localStorage
  userId: any; //Logged in user id
  private componentSubscriptions: Subscription[] = [];
  contract_id: any;
  customer_id: any;
  from = null;

  constructor(
    private _httpClient: HttpClient,
    private productsService: InvoicesService,
    private vehiclesService: VehiclesService,
    protected layoutUtilsService: LayoutUtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InvoicesListComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const routeSubscription = this.route.queryParams.subscribe((queryParams) => {
      // If component is navigated to using routing i.e. `/contracts/invoices?contract_id=48`
      if (queryParams["contract_id"]) {
        this.contract_id = queryParams["contract_id"];
      }
      if (queryParams["customer_id"]) {
        this.customer_id = queryParams["customer_id"];
      }
    });
    this.subscriptions.push(routeSubscription);
    if (this.dialogData.contract_id) {
      // If component is opend as modal
      this.contract_id = this.dialogData.contract_id;
    }
    if (this.dialogData.customer_id) {
      // If component is opend as modal
      this.customer_id = this.dialogData.customer_id;
    }

    if ("from" in this.dialogData) {
      this.from = this.dialogData.from;
    }

    const invoiceStatusesSubscription = this.productsService.getLookups("invoice_statuses").subscribe((res) => {
      this.invoiceStatuses = res.data;
      this.findSelectedFiltersName();
    });
    this.subscriptions.push(invoiceStatusesSubscription);
  }

  ngAfterViewInit() {
    // Wrap inside setTimeout to remove the following error if it's opend in a modal
    // Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    setTimeout(() => {
      this.paginator.pageSize = this.layoutUtilsService.paginatorDefaultOptions.pageSize;
      this.paginator.pageSizeOptions = this.layoutUtilsService.paginatorDefaultOptions.pageSizeOptions;

      // If the user changes the sort order, reset back to the first page.
      const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      this.subscriptions.push(sortSubscription);

      const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadProductsList()))
        .subscribe();
      this.subscriptions.push(paginatorSubscriptions);

      // Filtration, bind to searchInput
      const searchButtonClickEvent = fromEvent(this.searchButton._elementRef.nativeElement, "click").pipe(
        distinctUntilChanged()
      );
      const searchBarEnterKeyEvent = fromEvent(this.searchInput.nativeElement, "keyup").pipe(
        filter((event: KeyboardEvent) => event.code === "Enter" || event.code === "NumpadEnter"),

        distinctUntilChanged()
      );
      const searchSubscription = merge(searchBarEnterKeyEvent, searchButtonClickEvent)
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.loadProductsList();
          })
        )
        .subscribe();
      this.subscriptions.push(searchSubscription);

      this.store.pipe(select(currentUser)).subscribe(
        (user) => {
          if (user) {
            this.userId = user.id;

            if (localStorage.getItem(`${MAT_DATA_TABLES_INVOICES}_${this.userId}`)) {
              this.filtersState = GlobalUtilsService.getLocalStorageFilter(
                `${MAT_DATA_TABLES_INVOICES}_${this.userId}`
              );
              this.searchInput.nativeElement.value = this.filtersState.search;
              this.paginator.pageSize = this.filtersState.per_page;
              this.paginator.pageIndex = this.filtersState.page - 1;
              this.sort.active = this.filtersState.order_column;
              this.sort.direction = this.filtersState.order_dir;
              this.filters.status = this.filtersState.status || "";
            }

            observableOf(undefined).subscribe(() => {
              this.loadProductsList();
            });
          }
        },
        (err) => {
          observableOf(undefined).subscribe(() => {
            this.loadProductsList();
          });
        }
      );
    });
  }

  loadProductsList() {
    this.isLoadingResults = true;

    const requestUrl = this.from === "vehicle" ? API.vehicleInvoices : API.contractInvoice;

    this.productsService
      .indexProduct(requestUrl, this.filterConfiguration())
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total;
          this.changeDetectorRef.markForCheck();
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Return empty data if error occurs
          this.isRateLimitReached = true;
          this.changeDetectorRef.markForCheck();
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.data = data));
  }

  // To display the selected filters name
  findSelectedFiltersName() {
    if (this.filters.status && this.invoiceStatuses) {
      let status = this.invoiceStatuses.find((c) => +c.id == +this.filters.status);
      if (status) {
        this.selectedFilters["statusName"] = status.name;
      }
    }
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): IInvoiceRequestPagination {
    let filter: IInvoiceRequestPagination = {
      order_column: this.sort.active,
      order_dir: this.sort.direction,
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
      search: this.searchInput.nativeElement.value,
      type: this.from,
    };

    if (this.contract_id) {
      filter["contract_id"] = this.contract_id;
    }
    if (this.customer_id) {
      filter["customer_id"] = this.customer_id;
    }

    if (this.filters.status) {
      filter["status"] = this.filters.status;
      this.findSelectedFiltersName();
    }

    if (this.dialogData?.paymentType == PaymentConstants.VEHICLE_PAYMENT_TYPE) {
      filter["vehicle_id"] = this.dialogData.id;
      delete filter["contract_id"];
    }

    return filter;
  }

  resetFilters() {
    localStorage.removeItem(`${MAT_DATA_TABLES_INVOICES}_${this.userId}`);
    this.filtersState = null;
    this.searchInput.nativeElement.value = "";
    this.paginator.pageSize = this.layoutUtilsService.paginatorDefaultOptions.pageSize;
    this.paginator.pageIndex = 0;
    this.sort.active = "date";
    this.sort.direction = "asc";
    this.filters = {
      status: "",
    };
    this.selectedFilters = {};
    this.loadProductsList();
  }

  downloadContractInvoice(invoice) {
    let invoice_id = invoice.id;
    const downloadDocumentSubscription = this.productsService.downloadContractInvoice(invoice_id).subscribe(
      (res: any) => {
        const contentType = res.type;
        var blob = new Blob([res], { type: contentType });
        saveAs(blob, `invoice_${invoice.external_booked_invoice_number}`);
      },
      (err) => {
        if (err.error.data) {
          this.layoutUtilsService.showActionNotification(
            err.error.data.message,
            MessageType.Delete,
            10000,
            true,
            false
          );
        } else if (err.error instanceof Blob) {
          // Make sure that the message in the file
          var myReader = new FileReader();
          myReader.addEventListener("loadend", (e) => {
            let json = e.srcElement["result"];
            if (json) {
              let data = JSON.parse(json).data;
              if (data) {
                this.layoutUtilsService.showActionNotification(data.message, MessageType.Delete, 10000, true, false);
              }
            }
          });
          myReader.readAsText(err.error);
        }
      }
    );
    this.subscriptions.push(downloadDocumentSubscription);
  }

  downloadVehicleInvoice(invoice) {
    let invoice_id = invoice.id;
    const downloadDocumentSubscription = this.vehiclesService.downloadVehicleInvoiceDetails(invoice_id).subscribe(
      (res: any) => {
        const contentType = res.type;
        var blob = new Blob([res], { type: contentType });
        saveAs(blob, `invoice_${invoice.external_booked_invoice_number}`);
      },
      (err) => {
        if (err.error.errors) {
          this.layoutUtilsService.showActionNotification(
            "something went wrong please try again later!",
            MessageType.Delete,
            10000,
            true,
            false
          );
        } else if (err.error.errors instanceof Blob) {
          // Make sure that the message in the file
          var myReader = new FileReader();
          myReader.addEventListener("loadend", (e) => {
            let json = e.srcElement["result"];
            if (json) {
              let data = JSON.parse(json).data;
              if (data) {
                this.layoutUtilsService.showActionNotification(data.message, MessageType.Delete, 10000, true, false);
              }
            }
          });
          myReader.readAsText(err.error.errors);
        }
      }
    );
    this.componentSubscriptions.push(downloadDocumentSubscription);
  }

  downloadInvoice(invoice) {
    if (this.contract_id) this.downloadContractInvoice(invoice);
    else this.downloadVehicleInvoice(invoice);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
    this.saveDatatableState();
    localStorage.removeItem(`${MAT_DATA_TABLES_INVOICES}_${this.userId}`);
  }

  @HostListener("window:unload", ["$event"])
  saveDatatableState() {
    localStorage.setItem(`${MAT_DATA_TABLES_INVOICES}_${this.userId}`, JSON.stringify(this.filterConfiguration()));
  }

  /**
   * Add product
   */
  addProduct() {
    const newRate = new InvoiceModel();
    newRate.clear(); // Set all defaults fields
    this.editProduct(newRate);
  }

  /**
   * Edit role
   *
   * @param role: Role
   */
  editProduct(funding_rate) {
    return;
    // const dialogRef = this.dialog.open(InvoiceEditComponent,
    // 	{
    // 		data: { funding_rate} ,
    // 		width: '440px'
    // 	});
    // dialogRef.afterClosed().subscribe(res => {
    // 	if (!res) {
    // 		return;
    // 	}
    // 	this.loadProductsList()
    // });
  }

  deleteProduct(productId) {
    const _title: string = this.translate.instant("INVOICES.VIEW.DELETE_MODAL.TITLE");
    const _description: string = this.translate.instant("INVOICES.VIEW.DELETE_MODAL.DESCRIPTION");
    const _waitDesciption: string = this.translate.instant("INVOICES.VIEW.DELETE_MODAL.WAIT_DESCRIPTION");

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.productsService.deleteProduct(productId).subscribe(
        (res) => {
          if (res.data) {
            this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Delete);
          }
          this.changeDetectorRef.detectChanges();
          this.loadProductsList();
        },
        (err) => {
          if (err.error.data) {
            this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
          }
        }
      );
    });
  }
}
