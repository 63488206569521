// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS
import { Subscription, of } from "rxjs";
// Layout
import { LayoutConfigService } from "../../../core/_base/layout";
// CRUD
import { LayoutUtilsService, MessageType } from "../../../core/_base/crud";
import { SharedService } from "../shared.service";
import { TranslateService } from "@ngx-translate/core";
import { PaymentConstants } from "../payments/payment.constants";
import { TasksConstants } from "../../pages/contracts/contracts.model";
import { EconomicEntityTypes } from "../enums/economicEntityTypes";
import { PaymentTermsTypes } from "../enums/payments/paymentTermsTypes";
import { CustomerTypes, customerTypesLabels } from "../enums/customer/customerTypes";

@Component({
  selector: "kt-invoice-preview-dialog",
  templateUrl: "./invoice-preview-dialog.component.html",
  styleUrls: ["./invoice-preview-dialog.component.css"],
})
export class InvoicePreviewDialogComponent implements OnInit, OnDestroy {
  // Public properties
  hasFormErrors: boolean = false;
  paymentTerms: any[];
  privateInvoice: any[];
  corporateInvoice: any[];
  customerTypes = customerTypesLabels;
  economicEntityTypes = EconomicEntityTypes;
  paymentTermTypes = PaymentTermsTypes;
  errorMsgs: any = {}; //Back-end errors
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;
  from = null;

  constructor(
    private layoutUtilsService: LayoutUtilsService,
    private productsService: SharedService,
    private cdr: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    public dialogRef: MatDialogRef<InvoicePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if ("from" in this.data) {
      this.from = this.data.from;
    }

    if (this.data.invoice.corporate_invoice && this.data.invoice.private_invoice) {
      this.privateInvoice = this.data.invoice.private_invoice;
      this.corporateInvoice = this.data.invoice.corporate_invoice;
    } else {
      this.privateInvoice = this.data.invoice;
    }

    const paymentTermsSubscription = this.productsService.getLookups("payment_terms").subscribe((res) => {
      this.paymentTerms = res.data;
    });
    this.componentSubscriptions.push(paymentTermsSubscription);

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  displayFn(product: any): string {
    return product && product.name ? product.name : "";
  }

  onSubmit() {
    let payload = this.data.payload;
    let contract_id = this.data.contract_id;
    let task = this.data.task;
    this.hasFormErrors = false;
    this.viewLoading = true;
    if (task == "invoice") {
      this.productsService.createInvoice(payload).subscribe(
        (response) => {
          this.layoutUtilsService.showActionNotification(response.message, MessageType.Create, 10000, true, false);
          this.viewLoading = false;
          this.dialogRef.close(true);
        },
        (err) => {
          console.log(err);
          if (err.error) {
            if (err.error.errors) {
              this.layoutUtilsService.showActionNotification(err.error.errors.message, MessageType.Delete);
              this.viewLoading = false;
              this.dialogRef.close(false);
              // this.prepareInvoice(payload)
            }
          }
        }
      );
    } else if (task == PaymentConstants.VEHICLE_PAYMENT_TYPE) {
      this.productsService.createVehicleInvoice(payload).subscribe(
        (response) => {
          this.layoutUtilsService.showActionNotification(response.message, MessageType.Create, 10000, true, false);
          this.viewLoading = false;
          this.dialogRef.close(true);
        },
        (err) => {
          if (err.error) {
            if (err.error.errors) {
              this.layoutUtilsService.showActionNotification(err.error.errors.message, MessageType.Delete);
              this.viewLoading = false;
              this.dialogRef.close(false);
            }
          }
        }
      );
    } else if (task == "create_first_payments") {
      this.productsService.createFirstPaymentTask(contract_id, payload).subscribe(
        (response) => {
          if (response.message) {
            this.layoutUtilsService.showActionNotification(response.message, MessageType.Create, 10000, true, false);
            this.viewLoading = false;
            this.dialogRef.close(true);
          }
        },
        (err) => {
          if (err.error) {
            if (err.error.data) {
              this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
              this.viewLoading = false;
              this.dialogRef.close(false);
              // this.openTask(task)
            }
          }
        }
      );
    } else if (task == "end_contract") {
      if (payload.product_name) delete payload.product_name;
      this.productsService.endContract(payload, contract_id).subscribe(
        (response) => {
          if (response.message) {
            this.layoutUtilsService.showActionNotification(response.message, MessageType.Create, 10000, true, false);
            this.viewLoading = false;
            this.dialogRef.close(true);
          }
        },
        (err) => {
          if (err.error) {
            if (err.error.data) {
              this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
              this.viewLoading = false;
              this.dialogRef.close(false);
              // this.openTask(task);
            }
          }
        }
      );
    } else if (task == "sell_move_vehicle") {
      if (payload.customer_name) delete payload.customer_name;
      const subscriber =
        this.from && this.from == "vehicle"
          ? this.productsService.sellVehicleFromInventory(payload, contract_id)
          : this.productsService.sellVehicle(payload, contract_id);
      subscriber.subscribe(
        (response) => {
          if (response.message) {
            this.layoutUtilsService.showActionNotification(response.message, MessageType.Create, 10000, true, false);
            this.viewLoading = false;
            this.dialogRef.close(true);
          }
        },
        (err) => {
          if (err.error) {
            if (err.error.data) {
              this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
              this.viewLoading = false;
              this.dialogRef.close(false);
              // this.openTask(task);
            }
          }
        }
      );
    } else if (task === TasksConstants.SELL_MOVE_VEHICLE_OPTION) {
      this.productsService.sellVehicleOption(this.data.vehicle_id, payload).subscribe(
        (response) => {
          if (response.message) {
            this.layoutUtilsService.showActionNotification(response.message, MessageType.Create, 10000, true, false);
            this.viewLoading = false;
            this.dialogRef.close(true);
          }
        },
        (err) => {
          if (err.error) {
            if (err.error.data) {
              this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
              this.viewLoading = false;
              this.dialogRef.close(false);
              // this.openTask(task);
            }
          }
        }
      );
    }
  }

  getComponentTitle() {
    let result = this.translate.instant("INVOICES.ADD.PREVIEW_TITLE");

    return result;
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
