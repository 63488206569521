// Angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ActivatedRoute } from "@angular/router";
// CRUD
import { LayoutUtilsService, MessageType } from "../../../core/_base/crud";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fromEvent, merge, of as observableOf, Subscription } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, filter, map, tap } from "rxjs/operators";
import { AmortizationModel, AmortizationsService } from ".";
// Tranlsation
import { TranslateService } from "@ngx-translate/core";
import { currentUser } from "../../../core/auth";
import { select, Store } from "@ngrx/store";
import { AppState } from "../../../core/reducers";
import { MatButton } from "@angular/material/button";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { GlobalUtilsService } from "../../../core/_base/crud/utils/global-utils.service";
import { MAT_DATA_TABLES_AMORTIZATIONS } from "../../../core/constants/local-storage-constants";
import { IResponse } from "../../../core/_base/crud/interfaces/response-interface";
import { ErrorResponse } from "../../pages/error-reponse.interface";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "kt-amortizations-list",
  templateUrl: "./amortizations-list.component.html",
  styleUrls: ["./amortizations-list.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*", marginBottom: "10px" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmortizationListComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  displayedColumns: string[] = [
    "expand",
    "date",
    "interest_days",
    "payment_excl_vat_cents",
    "interest_cents",
    "repayment_cents",
    "balance_cents",
    "leftover_tax_value",
  ];
  data: AmortizationModel[] = [];
  expandedElement: AmortizationModel | null;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("searchButton") searchButton: MatButton;

  filtersState; // The currently saved filters in localStorage
  userId: any; //Logged in user id
  contract_id: any;
  isCalculating: boolean = false;

  constructor(
    private _amortizationsService: AmortizationsService,
    private layoutUtilsService: LayoutUtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AmortizationListComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const routeSubscription = this.route.queryParams.subscribe((queryParams) => {
      // If component is navigated to using routing i.e. `/contracts/amortizations?contract_id=48`
      if (queryParams["contract_id"]) {
        this.contract_id = queryParams["contract_id"];
      }
    });
    this.subscriptions.push(routeSubscription);

    if (this.dialogData.contract_id) {
      // If component is opend as modal
      this.contract_id = this.dialogData.contract_id;
    }
  }

  ngAfterViewInit() {
    // Wrap inside setTimeout to remove the following error if it's opend in a modal
    // Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    setTimeout(() => {
      this.paginator.pageSize = this.layoutUtilsService.paginatorDefaultOptions.pageSize;
      this.paginator.pageSizeOptions = this.layoutUtilsService.paginatorDefaultOptions.pageSizeOptions;

      // If the user changes the sort order, reset back to the first page.
      const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      this.subscriptions.push(sortSubscription);

      const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadProductsList()))
        .subscribe();
      this.subscriptions.push(paginatorSubscriptions);

      const searchSubscriptionWithClick = fromEvent(this.searchButton._elementRef.nativeElement, "click").pipe(
        distinctUntilChanged(),
        tap(() => console.log("clicked"))
      );
      const searchSubscriptionWithEnterKeyUp = fromEvent(this.searchInput.nativeElement, "keyup").pipe(
        filter((event: KeyboardEvent) => event.code === "Enter" || event.code === "NumpadEnter"),
        distinctUntilChanged()
      );
      const searchSubscription = merge(searchSubscriptionWithClick, searchSubscriptionWithEnterKeyUp)
        .pipe(
          debounceTime(450),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.loadProductsList();
          })
        )
        .subscribe();
      this.subscriptions.push(searchSubscription);

      this.store.pipe(select(currentUser)).subscribe(
        (user) => {
          if (user) {
            this.userId = user.id;

            if (localStorage.getItem(`${MAT_DATA_TABLES_AMORTIZATIONS}_${this.userId}`)) {
              this.filtersState = GlobalUtilsService.getLocalStorageFilter(
                `${MAT_DATA_TABLES_AMORTIZATIONS}_${this.userId}`
              );
              this.searchInput.nativeElement.value = this.filtersState.search;
              this.paginator.pageSize = this.filtersState.per_page;
              this.paginator.pageIndex = this.filtersState.page - 1;
              this.sort.active = this.filtersState.order_column;
              this.sort.direction = this.filtersState.order_dir;
            }

            observableOf(undefined).subscribe(() => {
              this.loadProductsList();
            });
          }
        },
        (err) => {
          observableOf(undefined).subscribe(() => {
            this.loadProductsList();
          });
        }
      );
    });
  }

  loadProductsList() {
    this.isLoadingResults = true;

    this._amortizationsService
      .indexProduct(this.filterConfiguration())
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total;
          this.changeDetectorRef.markForCheck();
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Return empty data if error occurs
          this.isRateLimitReached = true;
          this.changeDetectorRef.markForCheck();
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.data = data));
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): IRequestPagination {
    let filter: IRequestPagination = {
      order_column: this.sort.active,
      order_dir: this.sort.direction,
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
      search: this.searchInput.nativeElement.value,
    };

    if (this.contract_id) {
      filter["contract_id"] = this.contract_id;
    }

    return filter;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
    this.saveDatatableState();
  }

  @HostListener("window:unload", ["$event"])
  saveDatatableState() {
    localStorage.setItem(`${MAT_DATA_TABLES_AMORTIZATIONS}_${this.userId}`, JSON.stringify(this.filterConfiguration()));
  }

  deleteProduct(productId) {
    const _title: string = this.translate.instant("AMORTIZATIONS.VIEW.DELETE_MODAL.TITLE");
    const _description: string = this.translate.instant("AMORTIZATIONS.VIEW.DELETE_MODAL.DESCRIPTION");
    const _waitDesciption: string = this.translate.instant("AMORTIZATIONS.VIEW.DELETE_MODAL.WAIT_DESCRIPTION");

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this._amortizationsService.deleteProduct(productId).subscribe(
        (res) => {
          if (res.data) {
            this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Delete);
          }
          this.changeDetectorRef.detectChanges();
          this.loadProductsList();
        },
        (err) => {
          if (err.error.data) {
            this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
          }
        }
      );
    });
  }

  calculateAmortization() {
    this.isCalculating = true;
    this._amortizationsService.recalculateAmortization(this.contract_id).subscribe(
      (res: IResponse) => {
        this.isCalculating = false;
        this.layoutUtilsService.showActionNotification(res.message, MessageType.Delete);
        this.changeDetectorRef.detectChanges();
        this.loadProductsList();
      },
      (err: HttpErrorResponse) => {
        this.isCalculating = false;
        this.layoutUtilsService.showActionNotification(err.error.message, MessageType.Delete);
      }
    );
  }
}
