export class AmortizationModel {
  id: number;
  name: string;
  effective_from: any;
  funding_rate: string;
  created_at: string;
  updated_at: string;

  clear() {
    this.name = "";
    this.funding_rate = "";
    this.created_at = "";
    this.updated_at = null;
  }
}
