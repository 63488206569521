import { MatDialogRef } from "@angular/material/dialog";
import { Component, ViewChild } from "@angular/core";
import { DropzoneComponent, type DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { TranslateService } from "@ngx-translate/core";
import { TransactionsService } from "../../transactions.service";
import saveAs from "file-saver";
import { FormBuilder } from "@angular/forms";
import { LayoutUtilsService, MessageType } from "../../../../../core/_base/crud";
import { IResponse } from "../../../../../core/_base/crud/interfaces/response-interface";
import { returnErrorDetails } from "../../../../../core/_base/crud/utils/error-utils";
import { ITransactionImportError } from "../../interfaces/transaction-import-error.interface";

@Component({
  selector: "kt-transaction-import-dialog",
  templateUrl: "./transaction-import-dialog.component.html",
  styleUrls: ["./transaction-import-dialog.component.scss"],
})
export class TransactionImportDialogComponent {
  enableHeader: boolean = true;

  @ViewChild("documentsDropzone") dropzoneRef: DropzoneComponent;
  dropZoneConfig: DropzoneConfigInterface = {
    acceptedFiles: ".csv,.xlsx",
    maxFilesize: 60,
    maxFiles: 1,
  };
  dropzoneMessage: string = `
  <div class="dropzone-placeholder">
    <img class="file-icon" src="assets/media/icons/document-grey.png">
    <span class="dropzone-placeholder-text">
      ${this.translate.instant("TRANSACTIONS.ADD.FIELDS.ATTACHMENTS.PLACEHOLDER")}
    </span>
  </div>
`;
  viewLoading = false;
  numOfFiles: number = 0;
  transactionSheetFile = this.formBuilder.control(null);
  importErrors: ITransactionImportError[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private layoutService: LayoutUtilsService,
    private transactionService: TransactionsService,
    public dialogRef: MatDialogRef<TransactionImportDialogComponent>
  ) {}

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  importTransactions() {
    this.viewLoading = true;
    const formDataPayload = new FormData();
    formDataPayload.append("transactions_sheet", this.transactionSheetFile.value);
    this.transactionService.importTransactions(formDataPayload).subscribe({
      next: (res: IResponse) => {
        this.viewLoading = false;
        this.layoutService.showActionNotification(res.message);
        this.importErrors = [];
        this.onDismiss();
      },
      error: (err) => {
        const errorDetails = returnErrorDetails(err);
        this.importErrors = (errorDetails.errors as []) ?? [];
        this.viewLoading = false;
        errorDetails.message
          ? this.layoutService.showActionNotification(errorDetails.message, MessageType.Delete)
          : this.layoutService.showActionNotification(
              this.translate.instant("GENERAL.SOMETHING_WENT_WRONG"),
              MessageType.Delete
            );
      },
    });
  }

  addFile(file) {
    if (this.numOfFiles === 0) {
      this.transactionSheetFile.setValue(file);
    }
    this.numOfFiles++;
  }

  removeFile() {
    if (this.numOfFiles === 1) {
      this.transactionSheetFile.reset();
    }
    this.numOfFiles--;
  }

  downloadTemplate() {
    this.transactionService.downloadImportTemplate().subscribe({
      next: (res: Blob) => {
        saveAs(res, "transactionsTemplate");
      },
    });
  }
}
