<div class="kt-portlet__body">
  <form [formGroup]="createMonthlyPaymentForm" autocomplete="off" class="kt-form">
    <div class="kt-form__group">
      <div class="row">
        <!-- product id -->
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "PAYMENTS.ADD.FIELDS.PRODUCT.PLACEHOLDER" | translate }}</label>
          <mat-form-field
            class="example-full-width searchable"
            [ngClass]="{
              'mat-form-field-invalid': errorMsgs['product_id']
            }"
          >
            <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
            <input
              type="text"
              placeholder="{{ 'PAYMENTS.ADD.FIELDS.PRODUCT.PLACEHOLDER' | translate }}"
              matInput
              formControlName="product"
              [matAutocomplete]="productAutoComplete"
            />
            <mat-autocomplete #productAutoComplete="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
                {{ product.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="createMonthlyPaymentForm.controls.product.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.PRODUCT.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['product_id']">{{ errorMsgs["product_id"][0] }}</mat-error>
        </div>
        <!-- start date -->
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "PAYMENTS.ADD.FIELDS.START_DATE.PLACEHOLDER" | translate }}</label>
          <mat-form-field
            class="mat-form-field-fluid"
            [ngClass]="{
              'mat-form-field-invalid': errorMsgs['start_date']
            }"
          >
            <input
              matInput
              [matDatepicker]="datePicker"
              placeholder="{{ 'PAYMENTS.ADD.FIELDS.DATE.PLACEHOLDER' | translate }}"
              formControlName="start_date"
              type="text"
            />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="createMonthlyPaymentForm.controls.start_date.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.DATE.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['start_date']">{{ errorMsgs["start_date"][0] }}</mat-error>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "CUSTOMERS.ADD.FIELDS.MONTHS_COUNT.PLACEHOLDER" | translate }} </label>
          <mat-form-field no-scroll class="mat-form-field-fluid">
            <input
              matInput
              type="number"
              min="1"
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.MONTHS_COUNT' | translate }}"
              formControlName="months"
              [ngClass]="{
                'mat-form-field-invalid': errorMsgs['months']
              }"
            />
            <mat-error *ngIf="createMonthlyPaymentForm?.controls?.months?.errors">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.MONTHS_COUNT.ERROR_MSG' | translate"> </span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['months']">{{ errorMsgs["months"][0] }}</mat-error>
        </div>
        <!-- price excl vat -->
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{
            "PAYMENTS.ADD.FIELDS.PRICE_EXCEL_VAT_CENTS.PLACEHOLDER" | translate
          }}</label>
          <mat-form-field
            class="mat-form-field-fluid"
            [ngClass]="{
              'mat-form-field-invalid': errorMsgs['price_excl_vat_cents']
            }"
          >
            <input
              matInput
              currencyMask
              type="text"
              placeholder="{{ 'PAYMENTS.ADD.FIELDS.PRICE_EXCEL_VAT_CENTS.PLACEHOLDER' | translate }}"
              formControlName="price_excl_vat_cents"
              autocomplete="off"
            />
            <mat-error *ngIf="createMonthlyPaymentForm.controls.price_excl_vat_cents.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.PRICE_EXCEL_VAT_CENTS.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['price_excl_vat_cents']"> {{ errorMsgs["price_excl_vat_cents"][0] }}</mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "CONTRACTS.ADD.FIELDS.CUSTOMER.CUSTOMER" | translate }}</label>
          <mat-form-field class="example-full-width searchable">
            <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
            <input
              type="text"
              aria-label="Number"
              matInput
              formControlName="privateCustomer"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCustomerFn">
              <mat-option *ngFor="let prvCustomer of privateCustomers | async" [value]="prvCustomer">
                {{ prvCustomer.id }} - {{ prvCustomer.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="createMonthlyPaymentForm.controls.privateCustomer.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.CUSTOMER.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['customer_id']">{{ errorMsgs["customer_id"][0] }}</mat-error>
          <a href="javascript:;" class="kt-font-primary d-block" (click)="onAddNewCustomer()">
            <span>
              <i class="fas fa-plus" style="font-size: 10px"></i>
              {{ "CUSTOMERS.VIEW.NEW_BUTTON_TOOLTIP" | translate }}
            </span>
          </a>
        </div>
        <div *ngIf="contract_type == entityTypes.SPLIT_LEASING" class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "CONTRACTS.ADD.FIELDS.CUSTOMER.COMPANY_CUSTOMER" | translate }}</label>
          <mat-form-field class="example-full-width searchable">
            <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
            <input
              type="text"
              aria-label="Number"
              matInput
              formControlName="corporateCustomer"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCustomerFn">
              <mat-option *ngFor="let crpCustomer of corporateCustomers | async" [value]="crpCustomer">
                {{ crpCustomer.id }} - {{ crpCustomer.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="createMonthlyPaymentForm.controls.corporateCustomer.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.CUSTOMER.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['customer_id']">{{ errorMsgs["customer_id"][0] }}</mat-error>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
  <div class="kt-form__actions kt-form__actions--sm">
    <button mat-button (click)="dialogRef.close()">
      {{ "GENERAL.CANCEL" | translate }}</button
    >&nbsp;
    <button
      mat-button
      (click)="onSubmit()"
      color="primary"
      cdkFocusInitial
      [disabled]="loading || !createMonthlyPaymentForm.valid"
    >
      {{ "GENERAL.SAVE" | translate }}
    </button>
  </div>
</div>
