export enum InvoiceStatus {
  PAID = "Paid",
  NOT_PAID = "NotPaid",
  OVER_DUE = "OverDue",
}

export const invoiceStatusLabels = new Map<string, string>([
  [InvoiceStatus.PAID, "INVOICES.STATUS.PAID"],
  [InvoiceStatus.NOT_PAID, "INVOICES.STATUS.NOT_PAID"],
  [InvoiceStatus.OVER_DUE, "INVOICES.STATUS.OVER_DUE"],
]);
