// Angular
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS
import { Observable, of, Subscription } from "rxjs";
import { catchError, debounceTime, map, startWith, switchMap } from "rxjs/operators";
// Layout
// CRUD
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService, MessageType, TypesUtilsService } from "../../../../../core/_base/crud";
import { CustomerEditComponent } from "../../../../pages/customers/customer-edit.component";
import { CustomerTypes } from "../../../enums/customer/customerTypes";
import { EntityType } from "../../../enums/entity/entityType";
import { EntityTypes } from "../../../enums/entity/entityTypes";
import { SharedService } from "../../../shared.service";
import { PaymentConstants } from "../../payment.constants";
import { PaymentModel } from "../../payment.model";

@Component({
  selector: "kt-create-single-payment",
  templateUrl: "./create-single-payment.component.html",
})
export class CreateSinglePaymentComponent implements OnInit, OnDestroy {
  // Public properties
  product: PaymentModel;
  entityTypes = EntityTypes;
  productForm: FormGroup;
  contract_type: string;
  contract_period: number;
  hasFormErrors: boolean = false;
  errorMsgs: any = {}; //Back-end errors
  filteredProducts: Observable<any>;
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;
  privateCustomers: Observable<any[]>;
  corporateCustomers: Observable<any[]>;

  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private productsService: SharedService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    public dialogRef: MatDialogRef<CreateSinglePaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.contract_type = this.data.contract_type;
    this.contract_period = this.data.contract_period;
    this.permissionsService.permissions$.subscribe((res) => {
      const newProduct = new PaymentModel();
      newProduct.clear();
      this.loadProduct(newProduct);
    });
    console.log(this.data);
    this.getCustomersList();
    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
  }

  getCustomersList() {
    this.privateCustomers = this.productForm.get("privateCustomer").valueChanges.pipe(
      startWith(""),
      debounceTime(250),
      switchMap((value) => {
        if (typeof value == "string") {
          let params = { q: value, order_column: "name", order_dir: "asc" };
          return this.productsService.getCustomersList(params);
        } else return of([]);
      }),
      map((res) => res.data)
    );

    if (this.data.contract_type == EntityTypes.SPLIT_LEASING) {
      this.corporateCustomers = this.productForm.get("corporateCustomer").valueChanges.pipe(
        startWith(""),
        debounceTime(250),
        switchMap((value) => {
          if (typeof value == "string") {
            let params = { q: value, order_column: "name", order_dir: "asc" };
            return this.productsService.getCustomersList(params);
          } else return of([]);
        }),
        map((res) => res.data)
      );
    }

    this.data.contract_customers?.map((contractCustomer) => {
      if (contractCustomer.customer.customer_type == CustomerTypes.PRIVATE) {
        this.productForm.get("privateCustomer").setValue(contractCustomer.customer);
      }
      if (this.contract_type == EntityTypes.CORPORATE) {
        this.productForm.get("privateCustomer").setValue(contractCustomer.customer);
      }
      if (contractCustomer.customer.customer_type == CustomerTypes.CORPORATE) {
        this.productForm.get("corporateCustomer").setValue(contractCustomer.customer);
      }
    });
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  initProduct() {
    this.createForm();
    this.listenToProductSelection();
  }

  listenToProductSelection() {
    this.productForm.get("product").valueChanges.subscribe((res) => {
      if (res) this.productForm.get("price_excl_vat_cents").patchValue(res.price_excl_vat_cents);
    });
  }

  createForm() {
    let corporateValidation: Object = {
      corporateCustomer: [this.data.contract_customers, Validators.required],
    };
    let validations: Object = {
      contract_id: [this.data.contract_id],
      date: [this.product.date, Validators.required],
      privateCustomer: [this.data.contract_customers, Validators.required],
      from_date: [this.product.from_date],
      to_date: [this.product.to_date],
      product: [this.product.product, Validators.required],
      price_excl_vat_cents: [this.product.price_excl_vat_cents, Validators.required],
      vat_deduction_corporate_cents: [this.product.vat_deduction_corporate_cents || 0, Validators.required],
    };
    if (this.data?.paymentType !== PaymentConstants.VEHICLE_PAYMENT_TYPE) {
      validations = { ...validations, ...corporateValidation };
    }
    this.productForm = this.productFB.group(validations);
    this.filteredProducts = this.productForm.get("product").valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (typeof value == "string") {
          return this.productsService.getProductsList({
            q: value,
            is_payment_type: 1,
          });
        } else return of([]);
      }),
      map((res) =>
        // res.data
        this.returnedProduct(res)
      ),
      catchError((err) => {
        return of([]);
      })
    );
    this.cdr.markForCheck();
  }

  returnedProduct(res) {
    if (this.productForm.get("product").value !== null) {
      if (
        (this.productForm.get("product").value.code === "leasing_payment" ||
          this.productForm.get("product").value.code === "down_payment") &&
        (this.contract_type === EntityTypes.SPLIT_LEASING || this.contract_type === EntityTypes.CORPORATE)
      ) {
        this.productForm.get("vat_deduction_corporate_cents").setValidators(Validators.required);
        this.productForm.get("vat_deduction_corporate_cents").setValue(0);
        return res.data;
      } else {
        this.productForm.get("vat_deduction_corporate_cents").clearValidators();
        this.productForm.get("vat_deduction_corporate_cents").reset();
        return res.data;
      }
    } else return res.data;
  }

  displayFn(product: any): string {
    return product && product.name ? product.name : "";
  }

  displayCustomerFn(customer: any): string {
    return customer && customer.name ? customer.name : "";
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.viewLoading = true;
    const controls = this.productForm.controls;
    /** check form */
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      this.hasFormErrors = true;
      this.viewLoading = false;
      return;
    }

    let payload: any = {};

    payload.contract_id = +controls["contract_id"].value;
    payload.private_customer = controls["privateCustomer"].value?.id;
    payload.corporate_customer = controls["corporateCustomer"]?.value?.id;
    payload.product_id = controls["product"].value && controls["product"].value.id ? controls["product"].value.id : "";

    if (controls["date"] && controls["date"].value) {
      payload.date = this.typesUtilsService.formatDate(controls["date"].value, "dd-MM-yyyy");
    }
    if (controls["product"].value && controls["product"].value.has_date_interval) {
      if (controls["from_date"] && controls["from_date"].value) {
        payload.from_date = this.typesUtilsService.formatDate(controls["from_date"].value, "dd-MM-yyyy");
      }
      if (controls["to_date"] && controls["to_date"].value) {
        payload.to_date = this.typesUtilsService.formatDate(controls["to_date"].value, "dd-MM-yyyy");
      }
    }
    payload.price_excl_vat_cents = controls["price_excl_vat_cents"].value;
    payload.vat_deduction_corporate_cents = controls["vat_deduction_corporate_cents"].value ?? 0;

    this.addProduct(payload);
  }

  addProduct(payload) {
    let createProductSubscription: Subscription;

    if (this.data?.paymentType == PaymentConstants.VEHICLE_PAYMENT_TYPE) {
      createProductSubscription = this.productsService
        .storeVehicleSinglePayment({ ...payload, vehicle_id: this.data?.id })
        .subscribe(
          (res) => {
            console.log("res payload", res);
            this.viewLoading = false;
            if (res.message) {
              this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
            }
            this.dialogRef.close(true);
          },
          (err) => {
            this.viewLoading = false;
            if (err.error) {
              this.errorMsgs = err.error.errors || {};
              this.cdr.markForCheck();
              this.hasFormErrors = true;
            }
          }
        );
    } else
      createProductSubscription = this.productsService.storePayment(payload).subscribe(
        (res) => {
          this.viewLoading = false;
          if (res.message) {
            this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
          }
          this.dialogRef.close(true);
        },
        (err) => {
          this.viewLoading = false;
          if (err.error) {
            this.errorMsgs = err.error.errors || {};
            this.cdr.markForCheck();
            this.hasFormErrors = true;
          }
        }
      );

    this.componentSubscriptions.push(createProductSubscription);
  }

  getComponentTitle() {
    let result = this.translate.instant("PAYMENTS.ADD.ADD_TITLE");
    if (!this.product || !this.product.id) {
      return result;
    }

    result = `${this.translate.instant("PAYMENTS.ADD.EDIT_TITLE")} - ${this.product.price_excl_vat_cents}`;
    return result;
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  // Shows the customer form and clears previous data
  onAddNewCustomer() {
    const dialogRef = this.dialog.open(CustomerEditComponent, {
      width: "900px",
      maxHeight: "90vh",
      data: { isCancelEnabled: true, entityType: EntityType.CONTRACT },
    });
    dialogRef.afterClosed().subscribe((payload) => {
      if (payload?.customer?.customer_type) {
        if (payload.customer.customer_type == CustomerTypes.PRIVATE) {
          this.productForm.get("privateCustomer").setValue(payload.customer);
        }
        if (payload.customer.customer_type == CustomerTypes.CORPORATE) {
          this.productForm.get("corporateCustomer").setValue(payload.customer);
        }
        if (this.data.contract_type !== EntityTypes.SPLIT_LEASING) {
          this.productForm.get("privateCustomer").setValue(payload.customer);
        }
      }
    });
  }
}
