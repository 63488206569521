import { Component, OnInit, OnDestroy, ChangeDetectorRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxPermissionsService } from "ngx-permissions";
import { Subscription } from "rxjs";
import { LayoutUtilsService, TypesUtilsService, MessageType } from "../../../../../core/_base/crud";
import { SharedService } from "../../../shared.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "kt-transaction-book-dialog",
  templateUrl: "./transaction-book-dialog.component.html",
})
export class TransactionBookDialogComponent implements OnInit, OnDestroy {
  // Public properties
  product: any;
  productForm: FormGroup;
  hasFormErrors: boolean = false;
  errorMsgs: any = {}; //Back-end errors
  //filteredProducts: Observable<any[]>;
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  viewLoading = false;

  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private productsService: SharedService,
    private cdr: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    public dialogRef: MatDialogRef<TransactionBookDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.permissionsService.permissions$.subscribe((res) => {
      if (this.data.contract) {
        const newProduct = {
          contract: this.data.contract || "",
          date: "",
        };
        this.loadProduct(newProduct);
      } else {
        if (this.data.asset) {
          const newProduct = {
            asset: this.data.asset || "",
            date: "",
          };
          this.loadProduct(newProduct);
        }
      }
    });

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
    };
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  initProduct() {
    this.createForm();
  }

  createForm() {
    this.productForm = this.productFB.group({
      date: [this.product.date, Validators.required],
      contract: [this.product.contract],
      asset: [this.product.asset],
    });
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.viewLoading = true;
    const controls = this.productForm.controls;
    /** check form */
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      this.hasFormErrors = true;
      this.viewLoading = false;
      return;
    }

    let payload: any = {};
    if (controls["contract"].value && controls["contract"].value.id) {
      payload.contract_id = controls["contract"].value.id;
    } else {
      delete payload.contract_id;
    }
    if (controls["asset"].value && controls["asset"].value.id) {
      payload.vehicle_id = controls["asset"].value.id;
    } else {
      delete payload.asset_id;
    }

    if (this.data.entityType) payload.type = this.data.entityType;

    if (controls["date"] && controls["date"].value) {
      payload.date = this.typesUtilsService.formatDate(controls["date"].value, "dd-MM-yyyy");
    }

    // if (this.id > 0) {
    // 	this.updateProduct(payload);
    // 	return;
    // }

    this.addProduct(payload);
  }

  addProduct(payload) {
    const _title: string = this.translate.instant("TRANSACTIONS.VIEW.CONFIRM_MODAL.TITLE");
    const _description: string = this.translate.instant("TRANSACTIONS.VIEW.CONFIRM_MODAL.DESCRIPTION");
    const _waitDesciption: string = this.translate.instant("TRANSACTIONS.VIEW.CONFIRM_MODAL.WAIT_DESCRIPTION");
    const _submitText: string = this.translate.instant("GENERAL.CONFIRM");

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption, null, _submitText);
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        this.viewLoading = false;
        return;
      }
      const createProductSubscription = this.productsService.bookContractTransaction(payload).subscribe(
        (res) => {
          this.viewLoading = false;
          if (res.message) {
            this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
          }
          this.dialogRef.close(true);
        },
        (err) => {
          this.viewLoading = false;
          if (err.error) {
            this.errorMsgs = err.error.errors || {};
            this.cdr.markForCheck();
            this.hasFormErrors = true;
          }
        }
      );
      this.componentSubscriptions.push(createProductSubscription);
    });
  }

  getComponentTitle() {
    let result = this.translate.instant("TRANSACTIONS.VIEW.BOOK_TRANSACTION_MODAL.TITLE");
    if (!this.product || !this.product.id) {
      return result;
    }
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
