<!--begin: Search -->
<div class="kt-header__topbar-item kt-header__topbar-item--search kt-header__topbar-item-global--search">
  <div class="kt-header__topbar-wrapper">
    <div class="kt-header-toolbar">
      <div
        ngbDropdown
        placement="bottom"
        autoClose="outside"
        [ngClass]="{ 'kt-quick-search--has-result': searchResult?.length }"
        class="kt-quick-search kt-quick-search--inline kt-quick-search--result-compact"
        id="kt_quick_search_default"
      >
        <form method="get" class="kt-quick-search__form">
          <div
            ngbDropdownToggle
            class="input-group"
            [ngClass]="{ 'kt-spinner kt-spinner--input kt-spinner--sm kt-spinner--brand kt-spinner--right': loading }"
          >
            <div class="input-group-prepend">
              <span class="input-group-text">
                <!-- <i *ngIf="!useSVG" [ngClass]="icon" style="color: #BFBFBF;"></i> -->
                <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
              </span>
            </div>
            <input
              #searchInput
              (input)="search($event)"
              type="text"
              class="form-control kt-quick-search__input"
              placeholder="Search..."
            />
            <!-- <div class="input-group-append">
							<span class="input-group-text">
								<i (click)="clear($event)" [ngStyle]="{'display': 'flex'}" [hidden]="!searchResult || !searchResult?.length" class="la la-close kt-quick-search__close"></i>
							</span>
						</div> -->
          </div>
        </form>
        <div
          ngbDropdownMenu
          *ngIf="searchResult?.length && !redirectedToResult"
          class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-lg"
        >
          <div
            [perfectScrollbar]="{ wheelPropagation: false }"
            [ngStyle]="{ 'max-height': '40vh', position: 'relative' }"
            class="kt-quick-search__wrapper"
          >
            <kt-search-result
              [data]="searchResult"
              [noRecordText]="searchResult.length"
              (redirectToSearchResult)="hideSearchResult($event)"
            ></kt-search-result>
          </div>
        </div>
        <div
          class="kt-quick-search__message pl-3 pb-2"
          *ngIf="searchResult?.length == 0 && inputTextLen > 0 && !loading"
        >
          {{ "GENERAL.NO_RESULTS_FOUND" | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<!--end: Search -->
