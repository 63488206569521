import { ContractSigningStatus } from "../../shared/enums/contract/contractSigningStatus";
import { ContractOfferStatus } from "../../shared/enums/entity/contractOfferStatus";

export class ContractModel {
  id: number;
  interest_rate: number;
  contract_name?: string;
  // contract_id?: string;
  entity_id?: string;
  asset_id?: any;
  asset_details: any;
  dealership: any;
  vehicle: any;
  customer?: any;
  secondCustomer?: any;
  customers?: any;
  contract_type?: any;
  active_customers: Array<any>;
  key_figures: Array<any>;
  period: any;
  contract_status?: ContractOfferStatus;
  // contract_step?: any;
  contract_value?: any;
  type?: any;
  status?: ContractOfferStatus;
  contract_signers?: any;
  step?: any;
  asset?: any;
  asset_type?: any;
  send_offer: any;
  customer_email: any;
  number: any;
  created_at?: string;
  contract_tasks: [];
  updated_at?: string;
  signing_status: {
    documents: any[]; //TODO add missing types
    status: ContractSigningStatus;
  };
  vehicle_plate_number?: any;

  clear?() {
    this.contract_name = "";
    this.entity_id = "";
    this.created_at = "";
    this.updated_at = null;
  }
}

export interface SigningStatus {
  category_id: number;
  code: "not_signed" | "signed" | "signing_pending" | "signing_declined";
  created_at: string;
  extra_details: any;
  id: number;
  is_active: 1 | 0;
  is_system: 0 | 0;
  model_type: string;
  name: string;
  updated_at: string;
  value: any;
}

export enum TasksConstants {
  SELL_MOVE_VEHICLE_TASK = "sell_move_vehicle",
  SELL_MOVE_VEHICLE_OPTION = "sell_move_vehicle_option",
}
