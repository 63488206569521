<div class="calculation-result">
  <div *ngIf="calculations" class="calculations">
    <div
      *ngIf="calculations.first_payments"
      class="first_payments bg-white"
      [ngClass]="firstPaymentExpanded || !withAccordion ? 'expanded' : 'collapsed'"
    >
      <table
        class="table table-bordered"
        *ngIf="entityTypeCode == entityTypes.SPLIT_LEASING; else not_split_leasing_first_payments"
      >
        <thead>
          <tr [ngClass]="{ changeStyle: applyStyle }">
            <td>
              {{ "CALCULATION_RESULT.FIRST_PAYMENT" | translate }}
            </td>
            <td>
              {{ "CALCULATION_RESULT.PRIVATE" | translate }}
            </td>
            <td>
              {{ "CALCULATION_RESULT.CORPORATE" | translate }}
            </td>
          </tr>
        </thead>
        <tbody *ngIf="calculations.first_payments.private && calculations.first_payments.corporate">
          <tr class="product" *ngFor="let p of calculations.first_payments.private?.products; index as i">
            <td>{{ (p | keyvalue)[0].key }}</td>
            <td>{{ (p | keyvalue)[0].value | number : "1.2-2" }}</td>
            <td>
              {{ (calculations.first_payments.corporate?.products[i] | keyvalue)[0].value | number : "1.2-2" }}
            </td>
          </tr>
          <tr class="total main highlighted">
            <td>{{ "CALCULATION_RESULT.TOTAL_BEFORE_VAT" | translate }}</td>
            <td>
              {{ calculations.first_payments.private.total_before_vat | number : "1.2-2" }}
            </td>
            <td>
              {{ calculations.first_payments.corporate.total_before_vat | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>{{ "CALCULATION_RESULT.VAT" | translate }}</td>
            <td>
              {{ calculations.first_payments.private.vat | number : "1.2-2" }}
            </td>
            <td>
              {{ calculations.first_payments.corporate.vat | number : "1.2-2" }}
            </td>
          </tr>
          <tr class="total highlighted">
            <td>{{ "CALCULATION_RESULT.TOTAL_AFTER_VAT" | translate }}</td>
            <td>
              {{ calculations.first_payments.private.total_after_vat | number : "1.2-2" }}
            </td>
            <td>
              {{ calculations.first_payments.corporate.total_after_vat | number : "1.2-2" }}
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="withAccordion" class="tfoot-hover" (click)="firstPaymentExpanded = !firstPaymentExpanded">
          <tr>
            <td colspan="3">
              {{
                firstPaymentExpanded
                  ? ("CALCULATION_RESULT.HIDE_DETAILS" | translate)
                  : ("CALCULATION_RESULT.DETAILS" | translate)
              }}
            </td>
          </tr>
        </tfoot>
      </table>
      <ng-template #not_split_leasing_first_payments>
        <table class="table table-bordered">
          <thead>
            <tr [ngClass]="{ changeStyle: applyStyle }">
              <td>
                {{ "CALCULATION_RESULT.FIRST_PAYMENT" | translate }}
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr class="product" *ngFor="let p of calculations.first_payments.products">
              <td>{{ (p | keyvalue)[0].key }}</td>
              <td>{{ (p | keyvalue)[0].value | number : "1.2-2" }}</td>
            </tr>
            <tr class="total main highlighted">
              <td>{{ "CALCULATION_RESULT.TOTAL_BEFORE_VAT" | translate }}</td>
              <td>
                {{ calculations.first_payments.total_before_vat | number : "1.2-2" }}
              </td>
            </tr>
            <tr>
              <td>{{ "CALCULATION_RESULT.VAT" | translate }}</td>
              <td>
                {{ calculations.first_payments.vat | number : "1.2-2" }}
              </td>
            </tr>
            <tr class="total highlighted">
              <td>{{ "CALCULATION_RESULT.TOTAL_AFTER_VAT" | translate }}</td>
              <td>
                {{ calculations.first_payments.total_after_vat | number : "1.2-2" }}
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="withAccordion" class="tfoot-hover" (click)="firstPaymentExpanded = !firstPaymentExpanded">
            <tr>
              <td colspan="2">
                {{
                  firstPaymentExpanded
                    ? ("CALCULATION_RESULT.HIDE_DETAILS" | translate)
                    : ("CALCULATION_RESULT.DETAILS" | translate)
                }}
              </td>
            </tr>
          </tfoot>
        </table>
      </ng-template>
    </div>
    <div
      *ngIf="calculations.monthly_payments"
      class="monthly_payments bg-white"
      [ngClass]="monthlyPaymentExpanded || !withAccordion ? 'expanded' : 'collapsed'"
    >
      <table
        class="table table-bordered"
        *ngIf="entityTypeCode == entityTypes.SPLIT_LEASING; else not_split_leasing_monthly_payments"
      >
        <thead>
          <tr [ngClass]="{ changeStyle: applyStyle }">
            <td>{{ "CALCULATION_RESULT.MONTHLY_PAYMENT" | translate }}</td>
            <td>{{ "CALCULATION_RESULT.PRIVATE" | translate }}</td>
            <td>{{ "CALCULATION_RESULT.CORPORATE" | translate }}</td>
          </tr>
        </thead>
        <tbody *ngIf="calculations.monthly_payments.private && calculations.monthly_payments.corporate">
          <tr class="product" *ngFor="let p of calculations.monthly_payments.private?.products; index as i">
            <td>{{ (p | keyvalue)[0].key }}</td>
            <td>{{ (p | keyvalue)[0].value | number : "1.2-2" }}</td>
            <td>
              {{ (calculations.monthly_payments.corporate?.products[i] | keyvalue)[0].value | number : "1.2-2" }}
            </td>
          </tr>
          <tr class="total main highlighted">
            <td>{{ "CALCULATION_RESULT.TOTAL_BEFORE_VAT" | translate }}</td>
            <td>
              {{ calculations.monthly_payments.private.total_before_vat | number : "1.2-2" }}
            </td>
            <td>
              {{ calculations.monthly_payments.corporate.total_before_vat | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>{{ "CALCULATION_RESULT.VAT" | translate }}</td>
            <td>
              {{ calculations.monthly_payments.private.vat | number : "1.2-2" }}
            </td>
            <td>
              {{ calculations.monthly_payments.corporate.vat | number : "1.2-2" }}
            </td>
          </tr>
          <tr class="total highlighted">
            <td>{{ "CALCULATION_RESULT.TOTAL_AFTER_VAT" | translate }}</td>
            <td>
              {{ calculations.monthly_payments.private.total_after_vat | number : "1.2-2" }}
            </td>
            <td>
              {{ calculations.monthly_payments.corporate.total_after_vat | number : "1.2-2" }}
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="withAccordion" class="tfoot-hover" (click)="monthlyPaymentExpanded = !monthlyPaymentExpanded">
          <tr>
            <td colspan="3">
              {{
                monthlyPaymentExpanded
                  ? ("CALCULATION_RESULT.HIDE_DETAILS" | translate)
                  : ("CALCULATION_RESULT.DETAILS" | translate)
              }}
            </td>
          </tr>
        </tfoot>
      </table>
      <ng-template #not_split_leasing_monthly_payments>
        <table class="table table-bordered">
          <thead>
            <tr [ngClass]="{ changeStyle: applyStyle }">
              <td>{{ "CALCULATION_RESULT.MONTHLY_PAYMENT" | translate }}</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr class="product" *ngFor="let p of calculations.monthly_payments.products">
              <td>{{ (p | keyvalue)[0].key }}</td>
              <td>{{ (p | keyvalue)[0].value | number : "1.2-2" }}</td>
            </tr>
            <tr class="total main highlighted">
              <td>{{ "CALCULATION_RESULT.TOTAL_BEFORE_VAT" | translate }}</td>
              <td>
                {{ calculations.monthly_payments.total_before_vat | number : "1.2-2" }}
              </td>
            </tr>
            <tr>
              <td>{{ "CALCULATION_RESULT.VAT" | translate }}</td>
              <td>
                {{ calculations.monthly_payments.vat | number : "1.2-2" }}
              </td>
            </tr>
            <tr class="total highlighted">
              <td>{{ "CALCULATION_RESULT.TOTAL_AFTER_VAT" | translate }}</td>
              <td>
                {{ calculations.monthly_payments.total_after_vat | number : "1.2-2" }}
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="withAccordion" class="tfoot-hover" (click)="monthlyPaymentExpanded = !monthlyPaymentExpanded">
            <tr>
              <td colspan="2">
                {{
                  monthlyPaymentExpanded
                    ? ("CALCULATION_RESULT.HIDE_DETAILS" | translate)
                    : ("CALCULATION_RESULT.DETAILS" | translate)
                }}
              </td>
            </tr>
          </tfoot>
        </table>
      </ng-template>
    </div>
    <div
      *ngIf="view_profit && calculations.profit"
      class="profit bg-white"
      [ngClass]="profitExpanded || !withAccordion ? 'expanded' : 'collapsed'"
    >
      <table class="table table-bordered">
        <thead>
          <tr [ngClass]="{ changeStyle: applyStyle }">
            <td>{{ "CALCULATION_RESULT.PROFIT" | translate }}</td>
            <td>{{ "CALCULATION_RESULT.AMOUNT" | translate }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ "CALCULATION_RESULT.INTEREST_INCOME" | translate }}</td>
            <td>{{ calculations.profit.interest | number : "1.2-2" }}</td>
          </tr>
          <tr *ngIf="calculations.profit.interest_rate">
            <td>{{ "CALCULATION_RESULT.INTEREST_RATE" | translate }}</td>
            <td>{{ calculations.profit.interest_rate | number : "1.2-2" }}%</td>
          </tr>
          <tr>
            <td>{{ "CALCULATION_RESULT.PROFIT_ON_VEHICLE" | translate }}</td>
            <td>
              {{
                calculations.profit.profit_on_vehicle_cents ?? calculations.profit_on_vehicle_cents | number : "1.2-2"
              }}
            </td>
          </tr>
          <tr>
            <td>{{ "CALCULATION_RESULT.CONTRACT_FEE" | translate }}</td>
            <td>
              {{ calculations.profit.setup_fees ?? calculations.setup_fees | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>{{ "CALCULATION_RESULT.FUNDING_COST" | translate }}</td>
            <td>
              {{ calculations.profit.funding_cost | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>
              {{ "CALCULATION_RESULT.INCOME_FROM_ADDON_PRODUCTS" | translate }}
            </td>
            <td>
              {{ calculations.profit.income_from_addon_products | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>
              {{ "CALCULATION_RESULT.COSTS_ON_ADDON_PRODUCTS" | translate }}
            </td>
            <td>
              {{ calculations.profit.costs_on_addon_products | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>{{ investmentTitle }}</td>
            <td>{{ investment | number : "1.2-2" }}</td>
          </tr>
          <tr class="total main highlighted">
            <td>{{ "CALCULATION_RESULT.TOTAL_PROFIT" | translate }}</td>
            <td>
              {{ calculations.profit.total_profit | number : "1.2-2" }}
            </td>
          </tr>
        </tbody>
        <tfoot *ngIf="withAccordion" class="tfoot-hover" (click)="profitExpanded = !profitExpanded">
          <tr>
            <td colspan="2">
              {{
                profitExpanded
                  ? ("CALCULATION_RESULT.HIDE_DETAILS" | translate)
                  : ("CALCULATION_RESULT.DETAILS" | translate)
              }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div
      *ngIf="calculations.key_figures && showKeyFigures"
      class="key_figures"
      [ngClass]="keyFiguresExpanded || !withAccordion ? 'expanded' : 'collapsed'"
    >
      <table class="table table-bordered">
        <thead>
          <tr>
            <td>{{ "CALCULATION_RESULT.KEY_FIGURES" | translate }}</td>
            <td>{{ "CALCULATION_RESULT.AMOUNT" | translate }}</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let key_figure of calculations.key_figures">
            <td *ngIf="key_figure.code !== 'Return_of_investment'">
              {{ key_figure.name }}
            </td>
            <ng-container [ngSwitch]="key_figure?.code">
              <ng-container *ngFor="let percentage_key of percentageKeyFigures">
                <ng-container *ngSwitchCase="percentage_key">
                  <td *ngIf="key_figure.code !== 'Return_of_investment'">{{ key_figure.value?.toFixed(2) }} %</td>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <td *ngIf="key_figure.code !== 'Return_of_investment'">
                  {{ key_figure.value | number : "1.2-2" }}
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </tbody>
        <tfoot *ngIf="withAccordion" class="tfoot-hover" (click)="keyFiguresExpanded = !keyFiguresExpanded">
          <tr>
            <td colspan="2">
              {{
                keyFiguresExpanded
                  ? ("CALCULATION_RESULT.HIDE_DETAILS" | translate)
                  : ("CALCULATION_RESULT.DETAILS" | translate)
              }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div
      *ngIf="calculations.corporate_key_figures && entityTypeCode != 'private' && showKeyFigures"
      class="corporate_key_figures"
      [ngClass]="corpKeyFiguresExpanded || !withAccordion ? 'expanded' : 'collapsed'"
    >
      <table class="table table-bordered">
        <thead>
          <tr>
            <td>
              {{ "CALCULATION_RESULT.CORPORATE_KEY_FIGURES" | translate }}
            </td>
            <td>{{ "CALCULATION_RESULT.AMOUNT" | translate }}</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let key_figure of calculations.corporate_key_figures">
            <td>{{ key_figure.name }}</td>
            <td>{{ key_figure.value | number : "1.2-2" }}</td>
          </tr>
        </tbody>
        <tfoot *ngIf="withAccordion" class="tfoot-hover" (click)="corpKeyFiguresExpanded = !corpKeyFiguresExpanded">
          <tr>
            <td colspan="2">
              {{
                corpKeyFiguresExpanded
                  ? ("CALCULATION_RESULT.HIDE_DETAILS" | translate)
                  : ("CALCULATION_RESULT.DETAILS" | translate)
              }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
