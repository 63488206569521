<kt-portlet class="add-edit-entity">
  <kt-portlet-header
    [sticky]="true"
    [title]="getComponentTitle()"
    [class]="'kt-portlet__head--lg' + dialogData.isCancelEnabled ? 'kt-portlet__head__custom' : ''"
    [viewLoading$]="loading$"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
  </kt-portlet-header>

  <kt-portlet-body>
    <div *ngIf="product">
      <form class="kt-form" [formGroup]="productForm">
        <!-- <kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000"
                    (close)="onAlertClose($event)">
                    {{'GENERAL.FORM_ERROR_MESSAGE' | translate}}
                </kt-alert> -->
        <div class="kt-form__section kt-form__section--first">
          <div class="row">
            <div class="kt-form__group">
              <div class="row">
                <div class="col-md-12 kt-margin-bottom-10-mobile mb-4">
                  <label class="mat-input-label">{{ "SUPPLIERS.ADD.FIELDS.NAME.PLACEHOLDER" | translate }}</label>
                  <mat-form-field
                    class="example-full-width searchable"
                    [ngClass]="{ 'mat-form-field-invalid': errorMsgs['supplier'] }"
                  >
                    <span class="kt-svg-icon search-icon" [inlineSVG]="'/assets/media/icons/magnify.svg'"></span>
                    <input
                      type="text"
                      placeholder="{{ 'SUPPLIERS.ADD.FIELDS.NAME.PLACEHOLDER' | translate }}"
                      aria-label="Number"
                      matInput
                      formControlName="supplier"
                      [matAutocomplete]="supplierAutoComplete"
                    />
                    <mat-autocomplete
                      #supplierAutoComplete="matAutocomplete"
                      [displayWith]="displayFn"
                      (optionSelected)="supplierSelected($event)"
                    >
                      <mat-option *ngFor="let supplier of filteredSuppliers | async" [value]="supplier">
                        {{ supplier.name }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="productForm.controls.supplier.hasError('required')">
                      <span [innerHTML]="'SUPPLIERS.ADD.FIELDS.NAME.ERROR_MSG' | translate"></span>
                    </mat-error>
                    <mat-hint align="start">
                      <span [innerHTML]="'SUPPLIERS.ADD.FIELDS.NAME.HINT_PLACEHOLDER' | translate"></span>
                    </mat-hint>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['supplier']">{{ errorMsgs["supplier"][0] }}</mat-error>
                </div>
                <div class="col-md-12 kt-margin-bottom-10-mobile mb-4">
                  <label class="mat-input-label">{{
                    "SUPPLIERS.ADD.FIELDS.SUPPLIER_CVR.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{ 'mat-form-field-invalid': errorMsgs['supplier_cvr'] }"
                  >
                    <input
                      matInput
                      type="text"
                      placeholder="{{ 'SUPPLIERS.ADD.FIELDS.SUPPLIER_CVR.PLACEHOLDER' | translate }}"
                      formControlName="supplier_cvr"
                      autocomplete="off"
                      maxlength="8"
                    />
                    <mat-error *ngIf="productForm.controls.supplier_cvr.hasError('required')">
                      <span [innerHTML]="'SUPPLIERS.ADD.FIELDS.SUPPLIER_CVR.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['supplier_cvr']">{{ errorMsgs["supplier_cvr"][0] }} </mat-error>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4 kt-margin-bottom-20-mobile">
                  <label class="mat-input-label">{{
                    "SUPPLIERS.ADD.FIELDS.SUPPLIER_GROUP.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{ 'mat-form-field-invalid': errorMsgs['supplier_group_id'] }"
                  >
                    <mat-select
                      placeholder="Enter supplier group"
                      formControlName="supplier_group_id"
                      [compareWith]="compareFn"
                    >
                      <mat-option [value]="supplierGroup.id" *ngFor="let supplierGroup of supplierGroups">
                        {{ supplierGroup.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="productForm.controls.supplier_group_id.hasError('required')">
                      Supplier Group is
                      <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['supplier_group_id']">
                    {{ errorMsgs["supplier_group_id"][0] }}
                  </mat-error>
                </div>
                <div class="col-lg-4 kt-margin-bottom-20-mobile">
                  <label class="mat-input-label">{{
                    "SUPPLIERS.ADD.FIELDS.PAYMENT_TERMS.PLACEHOLDER" | translate
                  }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{ 'mat-form-field-invalid': errorMsgs['payment_term_id'] }"
                  >
                    <mat-select
                      placeholder="Enter payment term"
                      formControlName="payment_term_id"
                      [compareWith]="compareFn"
                    >
                      <mat-option [value]="paymentTerm" *ngFor="let paymentTerm of paymentTerms">
                        {{ paymentTerm.name }}</mat-option
                      >
                    </mat-select>
                    <mat-error *ngIf="productForm.controls.payment_term_id.hasError('required')">
                      Payment term Type is
                      <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['payment_term_id']">
                    {{ errorMsgs["payment_term_id"][0] }}
                  </mat-error>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-4 kt-margin-bottom-20-mobile">
                  <label class="mat-input-label">
                    {{ "SUPPLIERS.ADD.FIELDS.PAYMENT_TYPE.PLACEHOLDER" | translate }}</label
                  >
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{ 'mat-form-field-invalid': errorMsgs['payment_info_id'] }"
                  >
                    <mat-select
                      placeholder="Enter payment term"
                      [compareWith]="compareFn"
                      [(value)]="productForm.get('payment_info_id').value"
                      formControlName="payment_info_id"
                      (change)="refresh()"
                    >
                      <mat-option [value]="paymentInfo" *ngFor="let paymentInfo of paymenstInfo">
                        {{ paymentInfo.payment_type }}</mat-option
                      >
                    </mat-select>
                    <mat-error *ngIf="productForm.controls.payment_info_id.hasError('required')">
                      <span [innerHTML]="'SUPPLIERS.ADD.FIELDS.PAYMENT_TYPE.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['payment_info_id']">
                    {{ errorMsgs["payment_info_id"][0] }}
                  </mat-error>
                </div>
              </div>

              <div class="row">
                <div
                  class="col-md-6 kt-margin-bottom-10-mobile mb-4"
                  *ngIf="productForm.controls.payment_info_id.value?.show_credit_id"
                >
                  <label class="mat-input-label">{{ productForm.controls.payment_info_id.value.label.name }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{ 'mat-form-field-invalid': errorMsgs['credit_id'] }"
                  >
                    <input
                      matInput
                      type="text"
                      placeholder="{{ 'SUPPLIERS.ADD.FIELDS.CREDIT_ID.PLACEHOLDER' | translate }}"
                      formControlName="credit_id"
                      autocomplete="off"
                      max="50"
                    />
                    <mat-error *ngIf="productForm.controls.credit_id.hasError('required')">
                      <span [innerHTML]="'SUPPLIERS.ADD.FIELDS.CREDIT_ID.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['credit_id']">{{ errorMsgs["credit_id"][0] }} </mat-error>
                </div>

                <div class="col-md-6 kt-margin-bottom-10-mobile mb-4">
                  <label class="mat-input-label">{{ "SUPPLIERS.ADD.FIELDS.ACCOUNT_NO.PLACEHOLDER" | translate }}</label>
                  <mat-form-field
                    class="mat-form-field-fluid"
                    [ngClass]="{ 'mat-form-field-invalid': errorMsgs['account_no'] }"
                  >
                    <input
                      matInput
                      type="text"
                      placeholder="{{ 'SUPPLIERS.ADD.FIELDS.ACCOUNT_NO.PLACEHOLDER' | translate }}"
                      formControlName="account_no"
                      autocomplete="off"
                    />
                    <mat-error *ngIf="productForm.controls.account_no.hasError('required')">
                      <span [innerHTML]="'SUPPLIERS.ADD.FIELDS.ACCOUNT_NO.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs['account_no']">{{ errorMsgs["account_no"][0] }} </mat-error>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </kt-portlet-body>

  <kt-portlet-footer class="entity__footer">
    <div *ngIf="product" class="kt-form__actions kt-form__actions--sm">
      <button *ngIf="dialogData.isCancelEnabled" mat-button (click)="dialogRef.close()">
        {{ "GENERAL.CANCEL" | translate }}
      </button>
      <button
        (click)="onSubmit()"
        class="btn btn-primary"
        color="primary"
        mat-raised-button
        matTooltip="{{ 'GENERAL.SAVE' | translate }}"
      >
        {{ id ? ("SUPPLIERS.ADD.EDIT_TITLE" | translate) : ("SUPPLIERS.ADD.ADD_TITLE" | translate) }}
      </button>
      &nbsp;
    </div>
  </kt-portlet-footer>
</kt-portlet>
