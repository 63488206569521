// Angular
import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import moment from "moment";

/**
 * Returns only first letter of string
 */
@Pipe({
  name: "dmyDate",
})
export class DMYDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, protected _sanitizer: DomSanitizer) {}

  transform(value: null | string, format: string = "dd-MM-yyyy"): null | string {
    if (value) {
      const momentDate = moment.utc(value);
      if (momentDate.isValid()) {
        const hasTime = this.checkIfDateHasTime(value);
        const localDate = momentDate.local(!hasTime).toDate();
        let dateFormatted;
        if (hasTime) {
          dateFormatted =
            this.datePipe.transform(localDate, format) + `<b> ${this.datePipe.transform(localDate, "HH:mm")} </b>`;
        } else {
          dateFormatted = this.datePipe.transform(localDate, format);
        }
        return dateFormatted;
      }
      return null;
    }
    return null;
  }

  checkIfDateHasTime(dateString: string): boolean {
    return moment(dateString, "YYYY-MM-DD HH:mm:ss", true).isValid();
  }
}
