import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[no-scroll]",
})
export class NoScrollDirective {
  @HostListener("wheel", ["$event"])
  onWheel(event: Event) {
    event.preventDefault();
  }
}
