import { createReducer, on } from "@ngrx/store";
import { loadSettingsFailure, loadSettingsSuccess, updateFormSettings } from "../_actions/setting.actions";
import { BaseSettingsModel } from "../../../views/pages/settings/base-settings.model";
import { IRequiredFieldsSettings } from "../../../views/pages/settings/components/required-fields-settings-dialog/required-fields-settings.interface";
import { IResponse } from "../../_base/crud/interfaces/response-interface";

export interface SettingsState {
  settings: IResponse<BaseSettingsModel<IRequiredFieldsSettings>>;
  error: any;
}

export const initialState: SettingsState = {
  settings: null,
  error: null,
};

const _settingsReducer = createReducer(
  initialState,
  on(loadSettingsSuccess, (state, { settings }) => ({ ...state, settings })),
  on(loadSettingsFailure, (state, { error }) => ({ ...state, error })),
  on(updateFormSettings, (state, { updatedValue }) => ({
    ...state,
    settings: state.settings ? { ...state.settings, data: { ...state.settings.data, ...updatedValue } } : null,
  }))
);

export function settingsReducer(state, action) {
  return _settingsReducer(state, action);
}
