// Angular
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
// RxJS
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
// NGRX
import { Store } from "@ngrx/store";
// Auth reducers and selectors
import { AppState } from "../../../core/reducers/";
import { environment } from "../../../../environments/environment";
import { Login, Logout, UserLoaded, UserRequested } from "../_actions/auth.actions";
import { AuthService } from "../_services/index";
import { NgxPermissionsService } from "ngx-permissions";
import { User } from "../_models/user.model";
import { loadSettings } from "../_actions/setting.actions";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private auth: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const userToken = localStorage.getItem(environment.authTokenKey);
    if (userToken) {
      new Login({ authToken: userToken });
      localStorage.setItem(environment.authTokenKey, userToken);
      this.store.dispatch(new UserRequested());
      return this.auth.getUserByToken().pipe(
        map((_user: any) => {
          if (_user) {
            this.store.dispatch(new UserLoaded({ user: _user }));
            this.store.dispatch(loadSettings());

            if (_user.role) {
              this.permissionsService.flushPermissions();
              this.permissionsService.loadPermissions(_user.role.permissions.map((pm: any) => pm.name));
            }
            return true;
          } else {
            this.store.dispatch(new Logout());
            return false;
          }
        })
      );
    }
    this.router.navigateByUrl("/auth/login");
    return of(false);
  }
}
