// Angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// RxJS
import { Observable } from "rxjs";

import { API } from "../../../core/_base/crud";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { AmortizationModel } from "./amortization.model";
import { IResponse } from "../../../core/_base/crud/interfaces/response-interface";

@Injectable()
export class AmortizationsService {
  constructor(private http: HttpClient) {}

  createProduct(product): Observable<any> {
    return this.http.post<any>(API.contractAmortization, product);
  }

  getProductById(productId: number): Observable<any> {
    return this.http.get<any>(API.contractAmortization + `/${productId}`);
  }

  indexProduct(params: IRequestPagination): Observable<any> {
    return this.http.get<IResponsePagination<AmortizationModel>>(API.contractAmortization, {
      params: { ...params },
    });
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.put<any>(`${API.contractAmortization}/${productId}`, product);
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.delete<any>(`${API.contractAmortization}/${productId}`);
  }

  recalculateAmortization(contractId: number): Observable<IResponse> {
    return this.http.post<IResponse>(`${API.contractAmortization}/${contractId}/recalculate`, { contractId });
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(`${API.roles}`);
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(`${API.permissions}/categories`);
  }

  getCVRData(q): Observable<any> {
    return this.http.post<any>(`${API.CVRData}`, { search: q });
  }
}
