<!--begin::Body-->
<div class="kt-login__body">
  <!--begin::Signin-->
  <div class="kt-login__form">
    <div class="kt-login__title">
      <h3>{{ "AUTH.RESET_PASSWORD.TITLE" | translate }}</h3>
    </div>

    <kt-auth-notice></kt-auth-notice>

    <form class="kt-login__form kt-form" [formGroup]="registerForm" autocomplete="off">
      <div class="form-group">
        <mat-label>{{ "AUTH.INPUT.EMAIL" | translate }}</mat-label>
        <mat-form-field>
          <input
            matInput
            type="email"
            placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
            formControlName="email"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('email', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'email')">
            <strong>{{ "AUTH.VALIDATION.INVALID_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'minlength')">
            <strong>{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('email', 'maxlength')">
            <strong>{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 320</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-label>{{ "AUTH.INPUT.PASSWORD" | translate }}</mat-label>
        <mat-form-field>
          <input
            matInput
            type="password"
            placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
            formControlName="password"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'minlength')">
            <strong>{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'maxlength')">
            <strong>{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-label>{{ "AUTH.INPUT.CONFIRM_PASSWORD" | translate }}</mat-label>
        <mat-form-field>
          <input
            matInput
            type="password"
            placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}"
            formControlName="password_confirmation"
            autocomplete="off"
          />
          <mat-error *ngIf="isControlHasError('password_confirmation', 'required')">
            <strong>{{ "AUTH.VALIDATION.REQUIRED_FIELD" | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password_confirmation', 'minlength')">
            <strong>{{ "AUTH.VALIDATION.MIN_LENGTH_FIELD" | translate }} 3</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password_confirmation', 'maxlength')">
            <strong>{{ "AUTH.VALIDATION.MAX_LENGTH_FIELD" | translate }} 100</strong>
          </mat-error>
          <mat-error
            *ngIf="
              registerForm.get('password_confirmation').errors &&
              registerForm.get('password_confirmation').errors.ConfirmPassword
            "
          >
            <strong>Passsword and ConfirmPassword didn't match.</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <!--begin::Action-->
      <div class="kt-login__actions">
        <a href="javascript:;" routerLink="/auth/forgot-password" class="kt-link kt-login__link-forgot">
          {{ "AUTH.GENERAL.FORGOT_BUTTON" | translate }}
        </a>
        <button
          routerLink="/auth/login"
          [disabled]="loading"
          id="kt_login_signup_cancel"
          class="btn btn-secondary btn-elevate kt-login__btn-secondary"
        >
          {{ "AUTH.GENERAL.BACK_BUTTON" | translate }}
        </button>
        <button
          (click)="submit()"
          id="kt_login_signin_submit"
          class="btn btn-primary btn-elevate kt-login__btn-primary"
          [ngClass]="{ 'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading }"
        >
          {{ "AUTH.GENERAL.SUBMIT_BUTTON" | translate }}
        </button>
      </div>
      <!--end::Action-->
    </form>
  </div>
</div>
