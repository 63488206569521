<div
  ngbDropdown
  #myNgbDropdown="ngbDropdown"
  [placement]="'bottom-right'"
  [autoClose]="false"
  class="datatable-filters-dropdown dropdown dropdown-inline"
  (clickOutside)="onClickedOutside($event)"
  [exclude]="'.mat-option-text,.mat-calendar-body-cell-content'"
  [excludeBeforeClick]="true"
  title="{{ 'GENERAL.TABLE_FILTERS' | translate }}"
>
  <a
    ngbDropdownToggle
    href="javascript:;"
    class="btn btn-icon"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <svg
      class="datatable-filters-icon"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="30px"
      height="30px"
      x="0px"
      y="0px"
      viewBox="0 0 100 125"
      style="enable-background: new 0 0 100 125"
      xml:space="preserve"
    >
      <path d="M89,27c0,1.1-0.9,2-2,2H13c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h74c1.1,0,2,0.9,2,2V27z" />
      <path d="M79,43c0,1.1-0.9,2-2,2H23c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h54c1.1,0,2,0.9,2,2V43z" />
      <path d="M71,59c0,1.1-0.9,2-2,2H31c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h38c1.1,0,2,0.9,2,2V59z" />
      <path d="M63,75c0,1.1-0.9,2-2,2H39c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h22c1.1,0,2,0.9,2,2V75z" />
    </svg>
  </a>
  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-md dropdown-menu-right">
    <ul class="kt-nav">
      <li class="kt-nav__head">
        <div class="head-title">
          <svg
            class="datatable-filters-icon"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="30px"
            height="30px"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
          >
            <path d="M89,27c0,1.1-0.9,2-2,2H13c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h74c1.1,0,2,0.9,2,2V27z" />
            <path d="M79,43c0,1.1-0.9,2-2,2H23c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h54c1.1,0,2,0.9,2,2V43z" />
            <path d="M71,59c0,1.1-0.9,2-2,2H31c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h38c1.1,0,2,0.9,2,2V59z" />
            <path d="M63,75c0,1.1-0.9,2-2,2H39c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h22c1.1,0,2,0.9,2,2V75z" />
          </svg>
          {{ "GENERAL.FILTERS" | translate }}
          <div class="active-filters-count">
            {{ activeFilters }}
          </div>
        </div>
        <button (click)="onReset.emit(true)" class="btn btn-secondary reset-btn">
          {{ "GENERAL.RESET" | translate }}
        </button>
      </li>
      <div class="my-2">
        <ng-content></ng-content>
      </div>
    </ul>
  </div>
</div>
