<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "CONTRACTS.DETAILS.MILEAGE.CREATE_ADJ_PAYMENTS" | translate }}
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body" *ngIf="product">
      <form autocomplete="off" class="kt-form" #ngForm="ngForm" name="adjForm">
        <div class="kt-form__group">
          <div class="row">
            <div class="col-md-6 kt-margin-bottom-10-mobile d-flex">
              <label class="mat-input-label mr-2">{{
                "CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.ADJ_DATE.PLACEHOLDER" | translate
              }}</label>
              <div class="d-flex flex-column">
                <mat-form-field
                  class="mat-form-field-fluid"
                  [ngClass]="{ 'mat-form-field-invalid': errorMsgs['message'] }"
                >
                  <input
                    matInput
                    [matDatepicker]="datePicker"
                    readonly
                    placeholder="{{ 'CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.ADJ_DATE.PLACEHOLDER' | translate }}"
                    [formControl]="date"
                    type="text"
                    (dateChange)="onDateChange($event, datePicker)"
                  />
                  <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                  <mat-datepicker #datePicker panelClass="example-month-picker"> </mat-datepicker>
                </mat-form-field>
                <span
                  class="error-msg"
                  *ngIf="(date.hasError('required') && ngForm.submitted) || dateRequired"
                  [innerHTML]="'CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.ADJ_DATE.ERROR_MSG' | translate"
                ></span>
                <mat-error *ngIf="errorMsgs">{{ errorMsgs["message"] }}</mat-error>
              </div>
            </div>
          </div>
        </div>
      </form>
      <table>
        <thead>
          <th></th>
          <th>{{ "CONTRACTS.DETAILS.MILEAGE.PRIVATE" | translate }}</th>
          <th>{{ "CONTRACTS.DETAILS.MILEAGE.CORPORATE" | translate }}</th>
          <th>{{ "CONTRACTS.DETAILS.MILEAGE.TOTAL" | translate }}</th>
        </thead>
        <tbody>
          <tr>
            <td>{{ "CONTRACTS.DETAILS.MILEAGE.TOTAL_MILEAGE" | translate }}</td>
            <td>
              {{ mileageCalculations.total_mileage?.private | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.total_mileage?.corporate | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.total_mileage?.total | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>{{ "CONTRACTS.DETAILS.MILEAGE.PERCENT_SPLIT" | translate }}</td>
            <td>
              {{ mileageCalculations.percent_split?.private | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.percent_split?.corporate | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.percent_split?.total | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>{{ "CONTRACTS.DETAILS.MILEAGE.BOOKED_INCL" | translate }}</td>
            <td>
              {{ mileageCalculations.booked_payment_incl_vat?.private | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.booked_payment_incl_vat?.corporate | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.booked_payment_incl_vat?.total | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>{{ "CONTRACTS.DETAILS.MILEAGE.BOOKED_EXCL" | translate }}</td>
            <td>
              {{ mileageCalculations.booked_payment_excl_vat?.private | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.booked_payment_excl_vat?.corporate | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.booked_payment_excl_vat?.total | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>{{ "CONTRACTS.DETAILS.MILEAGE.ADJ_INCL" | translate }}</td>
            <td>
              {{ mileageCalculations.adjustment_incl_vat?.private | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.adjustment_incl_vat?.corporate | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.adjustment_incl_vat?.total | number : "1.2-2" }}
            </td>
          </tr>
          <tr>
            <td>{{ "CONTRACTS.DETAILS.MILEAGE.ADJ_EXCL" | translate }}</td>
            <td>
              {{ mileageCalculations.adjustment_excl_vat?.private | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.adjustment_excl_vat?.corporate | number : "1.2-2" }}
            </td>
            <td>
              {{ mileageCalculations.adjustment_excl_vat?.total | number : "1.2-2" }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-2">
        <span style="font-size: 11px; font-weight: 300">
          {{ "CONTRACTS.DETAILS.MILEAGE.NOTE" | translate }}
        </span>
      </div>
    </div>

    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="dialogRef.close()">
          {{ "GENERAL.CANCEL" | translate }}</button
        >&nbsp;
        <button mat-button (click)="onSubmit()" color="primary" cdkFocusInitial [disabled]="viewLoading">
          {{ "GENERAL.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
