<mat-form-field class="mat-form-field-fluid">
  <mat-select
    (selectionChange)="onFilterChange()"
    [(value)]="filters"
    class="mat-form-field mat-form-field-fluid"
    [multiple]="isMultiple"
  >
    <mat-option *ngFor="let item of itemList" [value]="item.code"> {{ item.name }} </mat-option>
  </mat-select>
</mat-form-field>
