// Angular
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../../../../../core/auth";

@Component({
  selector: "kt-general-search",
  templateUrl: "./general-search.component.html",
})
export class GeneralSearchComponent implements OnInit {
  // Public properties

  // Set icon class name
  @Input() icon = "flaticon2-search-1";

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
  inputTextLen: any;
  data: any[];
  loading: boolean;
  searchResult: any[] = [];
  redirectedToResult: boolean = false;

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  constructor(private cdr: ChangeDetectorRef, private authService: AuthService) {}

  /**
   * On init
   */
  ngOnInit(): void {}

  hideSearchResult(event) {
    this.redirectedToResult = true;
  }

  /**
   * Search
   * @param e: Event
   */
  search(e) {
    this.redirectedToResult = false;
    this.data = null;
    this.searchResult = [];
    this.inputTextLen = e.target.value.length;
    if (e.target.value.length >= 1) {
      this.loading = true;
      this.authService.globalSearch(e.target.value).subscribe((res) => {
        this.searchResult = [];
        Object.keys(res.data).forEach((key) => {
          if (key && res.data[key] && res.data[key].length) {
            this.searchResult.push({
              title: key,
              type: 0,
              url: `/${key}`,
            });
            if (key == "customers") {
              res.data[key].forEach((element) => {
                this.searchResult.push({
                  img: `<img src="/assets/media/users/default.jpg" alt="${element.name}">`,
                  title: element.name,
                  //text:element.email,
                  type: 1,
                  url: `/${key}/details/${element.id}`,
                });
              });
            } else if (key == "vehicles") {
              res.data[key].forEach((element) => {
                let make = null;
                let model = null;
                make = element.make?.name ?? "";
                model = element.model?.model ?? "";
                this.searchResult.push({
                  img: null,
                  title: element.id + " - " + make + " - " + model,
                  //text:element.email,
                  type: 1,
                  url: `/${key}/details/${element.id}`,
                });
              });
            } else if (key == "contracts") {
              res.data[key].forEach((element) => {
                let make = null;
                let model = null;
                if (element.vehicle) {
                  make = element.vehicle.make?.name ?? null;
                  model = element.vehicle.model?.model ?? null;
                }
                this.searchResult.push({
                  img: null,
                  title:
                    element.id +
                    " - " +
                    (element.customers && element.customers.length > 0 ? element.customers[0]?.customer?.name : "") +
                    " - " +
                    (make != null ? make + " - " : "") +
                    (model != null ? model : ""),
                  type: 1,
                  url: `/${key}/details/${element.number}`,
                });
              });
            } else if (key == "offers") {
              res.data[key].forEach((element) => {
                let make = null;
                let model = null;
                if (element.vehicle) {
                  make = element.vehicle.make?.name ?? null;
                  model = element.vehicle.model?.model ?? null;
                }

                this.searchResult.push({
                  img: null,
                  title:
                    element.id +
                    " - " +
                    (element.customers && element.customers.length > 0 ? element.customers[0]?.customer?.name : "") +
                    " - " +
                    (make != null ? make + " - " : "") +
                    (model != null ? model : ""),
                  type: 1,
                  url: `/${key}/details/${element.number}`,
                });
              });
            }
          }
        });
        this.loading = false;
        this.cdr.markForCheck();
        setTimeout(() => {
          this.highlight(e.target.value);
        }, 200);
      });
    }
  }

  highlight(text) {
    var itemTitles = document.getElementsByClassName("kt-quick-search__item-title");
    for (var i = 0; i < itemTitles.length; i++) {
      let itemTitle = itemTitles[i];
      var innerHTML = itemTitle.innerHTML;
      var index = innerHTML.indexOf(text);
      if (index >= 0) {
        innerHTML =
          innerHTML.substring(0, index) +
          "<span class='highlight'>" +
          innerHTML.substring(index, index + text.length) +
          "</span>" +
          innerHTML.substring(index + text.length);
        itemTitle.innerHTML = innerHTML;
      }
    }
  }

  /**
   * Clear search
   *
   * @param e: Event
   */
  clear(e) {
    this.data = null;
    this.searchInput.nativeElement.value = "";
  }
}
