// Angular
import { animate, state, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
// CRUD
import { HttpClient } from "@angular/common/http";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fromEvent, merge, Observable, of as observableOf, Subscription } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, tap } from "rxjs/operators";
import { LayoutUtilsService, MessageType, TypesUtilsService } from "../../../core/_base/crud";
import { PaymentModel } from "./payment.model";
import { PaymentsService } from "./payments.service";
// Tranlsation
import { SelectionModel } from "@angular/cdk/collections";
import { MatButton } from "@angular/material/button";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { IPaymentRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { GlobalUtilsService } from "../../../core/_base/crud/utils/global-utils.service";
import { currentUser } from "../../../core/auth";
import { MAT_DATA_TABLES_PAYMENTS } from "../../../core/constants/local-storage-constants";
import { AppState } from "../../../core/reducers";
import { VehiclesService } from "../../pages/vehicles-management/vehicles";
import { PaymentEntityTypes } from "../../shared/enums/transaction/transactionEntityTypes";
import { InvoiceCreateDialogComponent } from "../invoices/invoice-create-dialog.component";
import { InvoicePreviewDialogComponent } from "../invoices/invoice-preview-dialog.component";
import { CreateBulkInvoicesDialogComponent } from "./create-bulk-invoices-dialog.component";
import { CreatePaymentDialogComponent } from "./create-payment/create-payment-dialog/create-payment-dialog.component";
import { PaymentConstants } from "./payment.constants";
import { SharedService } from "../shared.service";
import { ContractOfferStatus } from "../enums/entity/contractOfferStatus";

@Component({
  selector: "kt-payments-list",
  templateUrl: "./payments-list.component.html",
  styleUrls: ["./payments-list.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*", marginBottom: "10px" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsListComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  displayedColumns: string[] = [
    "select",
    "expand",
    "date",
    "customer",
    "product_line_description",
    "price_excl_vat_cents",
    "vat_cents",
    "total_amount",
    "vat_deduction_corporate_cents",
    "is_booked",
    "actions",
  ];
  data: PaymentModel[] = [];
  expandedElement: PaymentModel | null;
  filteredCustomers: Observable<any[]>;
  filteredContracts: Observable<any[]>;
  noContractsFound: boolean = false;
  noCustomersFound: boolean = false;
  existingPagination: number;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  isUnbookedPaymentsSelected = false;
  isBookedPaymentsSelected = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild("searchButton") searchButton: MatButton;

  lastSavedContractId: any;
  unloadWindow: boolean = false;
  selectedFilters = {};
  filters = {
    is_booked: "all",
    from_date: null,
    to_date: null,
    customer: null,
    type: "contract",
    contract: null,
  };
  filtersState; // The currently saved filters in localStorage
  userId: any; //Logged in user id

  contract_id: any;
  vehicle_id: number;
  contract_customers: Array<any>;
  contract_type: string;
  contract_period: number;
  from = null;
  contract_status: ContractOfferStatus;
  isCancelled: boolean = false;

  selection = new SelectionModel<PaymentModel>(true, []);

  constructor(
    private _httpClient: HttpClient,
    private productsService: PaymentsService,
    private vehicleService: VehiclesService,
    protected layoutUtilsService: LayoutUtilsService,
    private sharedService: SharedService,
    private changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PaymentsListComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private translate: TranslateService,
    private typesUtilsService: TypesUtilsService
  ) {}

  ngOnInit() {
    const routeSubscription = this.route.queryParams.subscribe((queryParams) => {
      // If component is navigated to using routing i.e. `/contracts/payments?contract_id=48`
      if (queryParams["contract_id"]) {
        this.contract_id = queryParams["contract_id"];
        this.sort.direction = "desc";
      }
    });
    this.subscriptions.push(routeSubscription);

    if (this.dialogData.contract_id) {
      // If component is opened as modal
      this.contract_id = this.dialogData.contract_id;
    }
    if (this.dialogData.contract_type) {
      // If component is opened as modal
      this.contract_type = this.dialogData.contract_type;
    }
    if (this.dialogData.contract_period) {
      // If component is opened as modal
      this.contract_period = this.dialogData.contract_period;
    }
    if (this.dialogData.customers) {
      this.contract_customers = this.dialogData.customers;
    }
    if ("from" in this.dialogData) {
      this.from = this.dialogData.from;
      this.filters.type = this.dialogData.from;
    }
    if (this.dialogData?.contract_status) {
      this.contract_status = this.dialogData?.contract_status;
      this.isCancelled = this.contract_status === ContractOfferStatus.CANCELED;
    }
  }

  ngAfterViewInit() {
    this.sort.direction = "asc";
    this.sort.active = "date";
    // Wrap inside setTimeout to remove the following error if it's opened in a modal
    // Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    setTimeout(() => {
      this.paginator.pageSize = this.layoutUtilsService.paginatorDefaultOptions.pageSize;
      this.paginator.pageSizeOptions = this.layoutUtilsService.paginatorDefaultOptions.pageSizeOptions;

      // If the user changes the sort order, reset back to the first page.
      const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      this.subscriptions.push(sortSubscription);

      const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadProductsList()))
        .subscribe();
      this.subscriptions.push(paginatorSubscriptions);

      // Filtration, bind to searchInput
      const searchButtonClickEvent = fromEvent(this.searchButton._elementRef.nativeElement, "click").pipe(
        distinctUntilChanged()
      );
      const searchBarEnterKeyEvent = fromEvent(this.searchInput.nativeElement, "keyup").pipe(
        filter((event: KeyboardEvent) => event.code === "Enter" || event.code === "NumpadEnter"),
        distinctUntilChanged()
      );
      const searchSubscription = merge(searchBarEnterKeyEvent, searchButtonClickEvent)
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.loadProductsList();
          })
        )
        .subscribe();
      this.subscriptions.push(searchSubscription);

      this.store.pipe(select(currentUser)).subscribe(
        (user) => {
          if (user) {
            this.userId = user.id;

            if (localStorage.getItem(`${MAT_DATA_TABLES_PAYMENTS}_${this.userId}`)) {
              this.filtersState = GlobalUtilsService.getLocalStorageFilter(
                `${MAT_DATA_TABLES_PAYMENTS}_${this.userId}`
              );
              //==========================================================
              // show the saved input value based on the route

              if (this.route.snapshot.url.length > 0) {
                this.paginator.pageIndex = this.filtersState.page - 1;
              } else {
                this.existingPagination = this.filtersState.per_page;
                this.paginator.pageSize = this.filtersState.per_page;
                this.paginator.pageIndex = 0;
              }
              //==========================================================
              this.searchInput.nativeElement.value = "";
              this.sort.active = this.filtersState.order_column;
              this.sort.direction = this.filtersState.order_dir;
              this.filtersState.customer_id = null;
              if (this.filtersState.customer_id) {
                const customer_id = this.filtersState.customer_id;
                this.filterPaymentsByCustomerId(customer_id);
              }

              this.filtersState.contract_id = null;
              if (this.filtersState.contract_id && !this.contract_id) {
                const contract_id = this.filtersState.contract_id;
                this.filterPaymentsByContractId(contract_id);
              }

              this.filtersState.from_date = null;
              if (this.filtersState.from_date) {
                this.filters.from_date = this.typesUtilsService.getDateFromString(this.filtersState.from_date);
              }

              this.filtersState.to_date = null;
              if (this.filtersState.to_date) {
                this.filters.to_date = this.typesUtilsService.getDateFromString(this.filtersState.to_date);
              }

              this.filtersState.is_booked = null;
              if (this.filtersState.is_booked && this.filtersState.is_booked == 1) {
                this.filters.is_booked = "booked";
              } else if (this.filtersState.is_booked == 0) {
                this.filters.is_booked = "not_booked";
              } else this.filters.is_booked = "all";
            }
            observableOf(undefined).subscribe(() => {
              this.loadProductsList();
            });
          }
        },
        (err) => {
          observableOf(undefined).subscribe(() => {
            this.loadProductsList();
          });
        }
      );
    });
  }

  loadProductsList() {
    this.isLoadingResults = true;

    const routeURL = this.filters.type === PaymentEntityTypes.VEHICLE ? PaymentEntityTypes.VEHICLE : "";
    this.productsService
      .indexProduct(this.filterConfiguration(), routeURL)
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total;
          this.changeDetectorRef.markForCheck();
          // Reset selections
          this.selection = new SelectionModel<PaymentModel>(true, []);
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Return empty data if error occurs
          this.isRateLimitReached = true;
          this.changeDetectorRef.markForCheck();
          // Reset selections
          this.selection = new SelectionModel<PaymentModel>(true, []);
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.data = data));
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): IPaymentRequestPagination {
    let filter: IPaymentRequestPagination = {
      order_column: this.sort.active,
      order_dir: this.sort.direction,
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
      search: this.searchInput.nativeElement.value,
      type: this.filters.type ?? "contract",
    };

    const not_booked = this.translate.instant("PAYMENTS.VIEW.NOT_BOOKED");
    const booked = this.translate.instant("PAYMENTS.VIEW.BOOKED");
    if (this.filters.is_booked == "not_booked") {
      filter["is_booked"] = 0;
      this.selectedFilters["isBooked"] = not_booked;
    } else if (this.filters.is_booked == "booked") {
      filter["is_booked"] = 1;
      this.selectedFilters["isBooked"] = booked;
    } else {
      delete filter["is_booked"];
      this.selectedFilters["isBooked"] = "";
    }

    if (this.filters.from_date) {
      filter["from_date"] = this.typesUtilsService.formatDate(this.filters.from_date, "dd-MM-yyyy");
      this.selectedFilters["from_date"] = filter["from_date"];
    } else {
      this.selectedFilters["from_date"] = "";
    }

    if (this.filters.to_date) {
      filter["to_date"] = this.typesUtilsService.formatDate(this.filters.to_date, "dd-MM-yyyy");
      this.selectedFilters["to_date"] = filter["to_date"];
    } else {
      this.selectedFilters["to_date"] = "";
    }

    if (this.filters.customer && this.filters.customer.id) {
      filter["customer_id"] = this.filters.customer.id;
    }

    if (this.filters.contract && this.filters.contract.contract_id) {
      filter["contract_id"] = this.filters.contract.contract_id;
    }
    if (this.contract_id) {
      this.lastSavedContractId =
        this.filtersState && this.filtersState.contract_id ? this.filtersState.contract_id : null;
      if (this.unloadWindow && this.lastSavedContractId) filter["contract_id"] = this.lastSavedContractId;
      else filter["contract_id"] = this.contract_id;
    }

    if (this.dialogData?.paymentType == PaymentConstants.VEHICLE_PAYMENT_TYPE) {
      filter["vehicle_id"] = this.dialogData.id;
      delete filter["contract_id"];
    }

    return filter;
  }

  filterPaymentsByCustomerId(customer_id) {
    this.productsService.getCustomerDetails(customer_id).subscribe((res) => {
      this.filters.customer = {
        id: res.data.id,
        name: res.data.name,
      };
      this.selectedFilters["customerName"] = res.data.name;
      this.loadProductsList();
    });
  }

  filterPaymentsByContractId(contract_id) {
    this.productsService.getContractDetails(contract_id).subscribe((res) => {
      this.filters.contract = {
        id: res.data.id,
        number: res.data.number,
      };
      this.selectedFilters["contract"] = res.data.number;
      this.loadProductsList();
    });
  }

  filterCustomers(event) {
    let q = event.target.value;
    let params = { q: q, order_column: "name", order_dir: "asc" };
    this.filteredCustomers = this.sharedService.getCustomersList(params).pipe(
      startWith(""),
      map((res) => this.returnedCustomers(res))
    );
    if (q == "" && this.selectedFilters["customerName"]) {
      this.filters.customer = null;
      this.selectedFilters["customerName"] = "";
      this.loadProductsList();
    }
    this.changeDetectorRef.markForCheck();
  }

  returnedCustomers(res) {
    if (res.data && res.data.length == 0) {
      this.noCustomersFound = true;
    } else this.noCustomersFound = false;
    return res.data;
  }

  customerDisplayFn(customer: any): string {
    return customer ? customer.name : "";
  }

  contractDisplayFn(contract: any): string {
    return contract ? "Kontrakt #" + contract.number : "";
  }

  filterContracts(event) {
    let q = event.target.value;
    this.filteredContracts = this.productsService.getContractsList(q).pipe(
      startWith(""),
      map((res) => this.returnedContracts(res))
    );
    if (q == "" && this.selectedFilters["contract"]) {
      this.filters.contract = null;
      this.selectedFilters["contract"] = "";
      this.loadProductsList();
    }
    this.changeDetectorRef.markForCheck();
  }

  returnedContracts(res) {
    if (res.data && res.data.length == 0) {
      this.noContractsFound = true;
    } else this.noContractsFound = false;
    return res.data;
  }

  contractSelected($event) {
    const contract = $event.option.value;
    this.filters.contract = contract;
    this.selectedFilters["contract"] = contract.number;
    this.loadProductsList();
  }

  customerSelected($event) {
    const customer = $event.option.value;
    this.filters.customer = customer;
    this.selectedFilters["customerName"] = customer.name;
    this.loadProductsList();
  }

  resetFilters() {
    localStorage.removeItem(`${MAT_DATA_TABLES_PAYMENTS}_${this.userId}`);
    this.filtersState = null;
    this.searchInput.nativeElement.value = "";
    this.paginator.pageSize = this.layoutUtilsService.paginatorDefaultOptions.pageSize;
    this.paginator.pageIndex = 0;
    this.sort.active = "date";
    this.sort.direction = "asc";
    this.filters = {
      is_booked: "all",
      from_date: null,
      to_date: null,
      customer: null,
      contract: null,
      type: "contract",
    };
    this.selectedFilters = {};
    this.loadProductsList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.route.snapshot.url.length <= 0) {
      if (this.filtersState?.length) {
        this.filtersState.length = this.existingPagination;
      }
    }
    this.saveDatatableState();
  }

  @HostListener("window:unload", ["$event"])
  saveDatatableState() {
    if (this.contract_id) {
      this.unloadWindow = true;
    }
    localStorage.setItem(`${MAT_DATA_TABLES_PAYMENTS}_${this.userId}`, JSON.stringify(this.filterConfiguration()));
  }

  @HostListener("window:beforeunload", ["$event"])
  clearSearchValue() {
    if (this.filtersState.search?.value) {
      this.filtersState.search.value = "";
    }
  }

  createPayment() {
    let dialogRef: MatDialogRef<any>;
    if (this.dialogData.from === "asset") {
      dialogRef = this.dialog.open(CreatePaymentDialogComponent, {
        data: {
          ...this.dialogData,
          vehicle_id: this.dialogData.vehicle_id,
          paymentType: PaymentConstants.VEHICLE_PAYMENT_TYPE,
        },
        width: "600px",
        maxHeight: "85vh",
      });
    } else {
      dialogRef = this.dialog.open(CreatePaymentDialogComponent, {
        data: {
          contract_id: this.contract_id,
          contract_customers: this.contract_customers,
          contract_type: this.contract_type,
          contract_period: this.contract_period,
        },
        width: "600px",
        maxHeight: "85vh",
      });
    }
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.loadProductsList();
    });
  }

  prepareInvoice(payload) {
    const dialogRef = this.dialog.open(InvoiceCreateDialogComponent, {
      data: {
        contract_id: this.contract_id,
        payload,
        contract: this.dialogData?.contractDetails,
      },
      width: "600px",
      maxHeight: "85vh",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.previewInvoice(res.invoice, res.payload);
    });
  }

  previewInvoice(invoice, payload) {
    const dialogRef = this.dialog.open(InvoicePreviewDialogComponent, {
      data: { invoice, payload, task: "invoice" },
      width: "700px",
      maxHeight: "90vh",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        this.prepareInvoice(payload);
        return;
      }
      // this.productsService.createInvoice(payload).subscribe(response => {
      // 	if (response.data) {
      // 		this.layoutUtilsService.showActionNotification(response.data.message, MessageType.Create, 10000, true, false);
      this.loadProductsList();
      // 	}
      // }, err => {
      // 	if (err.error) {
      // 		if (err.error.data) {
      // 			this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
      // 			this.prepareInvoice(payload)
      // 		}
      // 	}
      // })
    });
  }

  addBulkInvoices() {
    const dialogRef = this.dialog.open(CreateBulkInvoicesDialogComponent, {
      data: { entityFilterType: this.filters.type },
      width: "600px",
      maxHeight: "85vh",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.loadProductsList();
    });
  }

  deleteProduct(productId) {
    const _title: string = this.translate.instant("PAYMENTS.VIEW.DELETE_MODAL.TITLE");
    const _description: string = this.translate.instant("PAYMENTS.VIEW.DELETE_MODAL.DESCRIPTION");
    const _waitDesciption: string = this.translate.instant("PAYMENTS.VIEW.DELETE_MODAL.WAIT_DESCRIPTION");

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const deleteRequestObserver =
        this.filters.type === "vehicle"
          ? this.vehicleService.deleteVehiclePayment(productId)
          : this.productsService.deleteProduct(productId);
      deleteRequestObserver.subscribe(
        (res) => {
          this.layoutUtilsService.showActionNotification("Item Deleted", MessageType.Delete);
          this.changeDetectorRef.detectChanges();
          this.loadProductsList();
        },
        (err) => {
          if (err.error) {
            this.layoutUtilsService.showActionNotification(err.error.message, MessageType.Delete);
          }
        }
      );
    });
  }

  onBulkDeletePayments() {
    if (this.isBookedPaymentsSelected) {
      return;
    }

    const _title: string = this.translate.instant("PAYMENTS.VIEW.DELETE_MODAL.TITLE");
    const _description: string = this.translate.instant("PAYMENTS.VIEW.DELETE_MODAL.BULK_DESCRIPTION");
    const _waitDesciption: string = this.translate.instant("PAYMENTS.VIEW.DELETE_MODAL.WAIT_DESCRIPTION");

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const paymentList = this.selection.selected.map((payment) => payment.id);
      const masDeleteRequestObserver =
        this.from === "vehicle"
          ? this.vehicleService.massDeleteVehiclePayments(paymentList)
          : this.productsService.bulkDeletePayments(paymentList);
      masDeleteRequestObserver.subscribe(
        (res) => {
          this.layoutUtilsService.showActionNotification("Items Deleted", MessageType.Delete);
          this.changeDetectorRef.detectChanges();
          this.loadProductsList();
        },
        (err) => {
          if (err.error) {
            this.layoutUtilsService.showActionNotification(err.error.message, MessageType.Delete);
          }
        }
      );
    });
  }

  onBulkReversePayments() {
    if (this.isUnbookedPaymentsSelected) {
      return;
    }

    const _title: string = this.translate.instant("PAYMENTS.VIEW.REVERSE_MODAL.TITLE");
    const _description: string = this.translate.instant("PAYMENTS.VIEW.REVERSE_MODAL.BULK_DESCRIPTION");
    const _waitDesciption: string = this.translate.instant("PAYMENTS.VIEW.REVERSE_MODAL.WAIT_DESCRIPTION");
    const _buttonText: string = this.translate.instant("PAYMENTS.VIEW.REVERSE_MODAL.BUTTON");
    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
      undefined,
      _buttonText
    );
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const paymentList = this.selection.selected.map((payment) => payment.id);
      const masDeleteRequestObserver =
        this.from === "vehicle"
          ? this.vehicleService.reverseVehiclePayments(paymentList)
          : this.productsService.reversePayments(paymentList);
      masDeleteRequestObserver.subscribe(
        (res) => {
          this.layoutUtilsService.showActionNotification(
            this.translate.instant("PAYMENTS.VIEWPAYMENT_REVERSED"),
            MessageType.Delete
          );
          this.changeDetectorRef.detectChanges();
          this.loadProductsList();
        },
        (err) => {
          if (err.error) {
            this.layoutUtilsService.showActionNotification(err.error.message, MessageType.Delete);
          }
        }
      );
    });
  }

  reverseProduct(paymentId) {
    const _title: string = this.translate.instant("PAYMENTS.VIEW.REVERSE_MODAL.TITLE");
    const _description: string = this.translate.instant("PAYMENTS.VIEW.REVERSE_MODAL.DESCRIPTION");
    const _waitDesciption: string = this.translate.instant("PAYMENTS.VIEW.REVERSE_MODAL.WAIT_DESCRIPTION");
    const _buttonText: string = this.translate.instant("PAYMENTS.VIEW.REVERSE_MODAL.BUTTON");

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
      undefined,
      _buttonText
    );
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const reverseRequestObserver =
        this.filters.type === "vehicle"
          ? this.vehicleService.reverseVehiclePayments([paymentId])
          : this.productsService.reversePayments([paymentId]);
      reverseRequestObserver.subscribe(
        (res) => {
          this.layoutUtilsService.showActionNotification(
            this.translate.instant("PAYMENTS.VIEW.PAYMENT_REVERSED"),
            MessageType.Delete
          );
          this.changeDetectorRef.detectChanges();
          this.loadProductsList();
        },
        (err) => {
          if (err.error) {
            this.layoutUtilsService.showActionNotification(err.error.message, MessageType.Delete);
          }
        }
      );
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected == this.data.length;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) => {
          this.selection.select(row);
        });

    this.checkPaymentsSelection();
  }

  selectPayment(event, row) {
    event ? this.selection.toggle(row) : null;
    this.checkPaymentsSelection();
  }

  checkPaymentsSelection() {
    if (this.selection.selected.length > 0) {
      this.isUnbookedPaymentsSelected = this.selection.selected.some((payment) => payment.is_booked == false);
      this.isBookedPaymentsSelected = this.selection.selected.some((payment) => payment.is_booked == true);
    } else {
      this.isUnbookedPaymentsSelected = false;
      this.isBookedPaymentsSelected = false;
    }
  }
}
