<div class="short-info-widget">
  <!-- <kt-portlet [class]="'kt-portlet--height-fluid'">
		<kt-portlet-header [title]="'Active Contract'">
			<ng-container ktPortletTools>
				<kt-context-menu2></kt-context-menu2>
			</ng-container>
		</kt-portlet-header>

		<kt-portlet-body>
			
		</kt-portlet-body>
	</kt-portlet> -->
</div>

<ng-template #itemTemplate let-item="item">
  <div class="kt-widget1__item">
    <div class="kt-widget1__info">
      <h3 class="kt-widget1__title">{{ item.title }}</h3>
      <span class="kt-widget1__desc">{{ item.desc }}</span>
    </div>
    <span class="kt-widget1__number" [ngClass]="item.valueClass">{{ item.value }}</span>
  </div>
</ng-template>
