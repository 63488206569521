<div
  ngbDropdown
  placement="bottom-right"
  autoClose="outside"
  class="kt-header__topbar-item notifications-item has-arrow"
>
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <span
      class="kt-header__topbar-icon"
      [ngClass]="{
        'kt-pulse kt-pulse--brand': pulse,
        'kt-pulse--light': pulseLight,
        'kt-header__topbar-icon--success': iconType === 'success'
      }"
    >
      <!-- <i *ngIf="!useSVG" [ngClass]="icon"></i>
			<span *ngIf="useSVG" class="kt-svg-icon" [ngClass]="{'kt-svg-icon--success' : iconType === 'success'}" [inlineSVG]="icon"></span> -->
      <!-- <i matBadge="99" matBadgeColor="warn" class="fas fa-bell"></i> -->
      <span matBadge="9" matBadgeColor="warn" matBadgeSize="medium">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="30 0 60 85"
          style="enable-background: new 0 0 100 125"
          xml:space="preserve"
        >
          <style type="text/css">
            .st0 {
              fill-rule: evenodd;
              clip-rule: evenodd;
            }
          </style>
          <title>Bell v2.6</title>
          <desc>Created with Sketch.</desc>
          <g>
            <g>
              <path
                class="st0"
                d="M45.5,29.07c-7.76,2-13.5,9.04-13.5,17.43v12c0,1.11-0.9,2-2,2c-2.21,0-4,1.8-4,4c0,2.21,1.79,4,4.01,4h39.98
							c2.21,0,4.01-1.8,4.01-4c0-2.21-1.8-4-4-4c-1.1,0-2-0.89-2-2v-12c0-8.39-5.74-15.43-13.5-17.43V27c0-2.49-2.01-4.5-4.5-4.5
							c-2.48,0-4.5,2.02-4.5,4.5V29.07z M43,70.5h14c0,3.87-3.13,7-7,7S43,74.37,43,70.5z"
              />
            </g>
          </g>
        </svg>
      </span>
      <!-- <span class="kt-pulse__ring" [hidden]="!pulse"></span> -->
    </span>
    <span class="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm kt-badge--brand" [hidden]="!dot"></span>
  </div>

  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-rounded dropdown-menu-xl"
  >
    <form class="dropdown-menu-content">
      <div
        [perfectScrollbar]="{ wheelPropagation: false }"
        [ngStyle]="{ 'max-height': '40vh', position: 'relative' }"
        class="kt-notification kt-margin-t-10 kt-margin-b-10"
      >
        <a href="javascript:;" class="kt-notification__item not-read">
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">New order has been received</div>
            <div class="kt-notification__item-time">2 hrs ago</div>
          </div>
        </a>
        <a href="javascript:;" class="kt-notification__item">
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">New order has been received</div>
            <div class="kt-notification__item-time">2 hrs ago</div>
          </div>
        </a>
        <a href="javascript:;" class="kt-notification__item">
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">New customer is registered</div>
            <div class="kt-notification__item-time">3 hrs ago</div>
          </div>
        </a>
        <a href="javascript:;" class="kt-notification__item">
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">Application has been approved</div>
            <div class="kt-notification__item-time">3 hrs ago</div>
          </div>
        </a>
        <a href="javascript:;" class="kt-notification__item">
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">New file has been uploaded</div>
            <div class="kt-notification__item-time">5 hrs ago</div>
          </div>
        </a>
        <a href="javascript:;" class="kt-notification__item">
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">New order has been received</div>
            <div class="kt-notification__item-time">2 hrs ago</div>
          </div>
        </a>
        <a href="javascript:;" class="kt-notification__item">
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">New customer is registered</div>
            <div class="kt-notification__item-time">3 hrs ago</div>
          </div>
        </a>
        <a href="javascript:;" class="kt-notification__item">
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">Application has been approved</div>
            <div class="kt-notification__item-time">3 hrs ago</div>
          </div>
        </a>
        <a href="javascript:;" class="kt-notification__item">
          <div class="kt-notification__item-details">
            <div class="kt-notification__item-title">New file has been uploaded</div>
            <div class="kt-notification__item-time">5 hrs ago</div>
          </div>
        </a>
        <div class="ps__rail-x" style="left: 0px; bottom: 0px">
          <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
        </div>
        <div class="ps__rail-y" style="top: 0px; right: 0px">
          <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px"></div>
        </div>
      </div>
      <!--begin: Head -->
      <!-- <div class="kt-head kt-head--skin-{{skin}} kt-head--fit-x kt-head--fit-b" [ngStyle]="{'background-image': backGroundStyle() }">
				<h3 class="kt-head__title">
					User Notifications&nbsp;
					<span class="btn btn-{{ type }} btn-sm btn-bold btn-font-md">23 new</span>
				</h3>
				<ul ktTabClickEvent class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-{{ type }} kt-notification-item-padding-x" role="tablist">
					<li class="nav-item">
						<a (click)="tab.select('tab-id-1')" class="nav-link active show" data-toggle="tab" href="javascript:;" role="tab" aria-selected="true">Alerts</a>
					</li>
					<li class="nav-item">
						<a (click)="tab.select('tab-id-2')" class="nav-link" data-toggle="tab" href="javascript:;" role="tab" aria-selected="false">Events</a>
					</li>
					<li class="nav-item">
						<a (click)="tab.select('tab-id-3')" class="nav-link" data-toggle="tab" href="javascript:;" role="tab" aria-selected="false">Logs</a>
					</li>
				</ul>
			</div> -->
      <!--end: Head -->

      <!-- <ul class="kt-nav kt-margin-b-10">
				<li class="kt-nav__item">
					<a [routerLink]="['/user-profile']" class="kt-nav__link">
						<span class="kt-nav__link-text kt-font-brand">{{'NAVBAR.PROFILE_MMENU.PROFILE' | translate}}</span>
					</a>
				</li>
				<li class="kt-nav__item">
					<div class="kt-notification__item-icon">
						<i class="flaticon2-line-chart kt-font-success"></i>
					</div>
					<div class="kt-notification__item-details">
						<div class="kt-notification__item-title">
							New order has been received
						</div>
						<div class="kt-notification__item-time">
							2 hrs ago
						</div>
					</div>
				</li>
				<li class="kt-nav__item">
					<a href="javascript:;" (click)="logout()" class="kt-nav__link">
						<span class="kt-nav__link-text sign-out">{{'NAVBAR.PROFILE_MMENU.SIGN_OUT' | translate}}</span>
					</a>
				</li>
			</ul> -->

      <!-- <ngb-tabset #tab="ngbTabset">
				<ngb-tab id="tab-id-1">
					<ng-template ngbTabContent>
						<div [perfectScrollbar]="{wheelPropagation: false}" [ngStyle]="{'max-height': '40vh', 'position': 'relative'}" class="kt-notification kt-margin-t-10 kt-margin-b-10">
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-line-chart kt-font-success"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New order has been received
									</div>
									<div class="kt-notification__item-time">
										2 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-box-1 kt-font-brand"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New customer is registered
									</div>
									<div class="kt-notification__item-time">
										3 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-chart2 kt-font-danger"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										Application has been approved
									</div>
									<div class="kt-notification__item-time">
										3 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-image-file kt-font-warning"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New file has been uploaded
									</div>
									<div class="kt-notification__item-time">
										5 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-drop kt-font-info"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New user feedback received
									</div>
									<div class="kt-notification__item-time">
										8 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-pie-chart-2 kt-font-success"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										System reboot has been successfully completed
									</div>
									<div class="kt-notification__item-time">
										12 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-favourite kt-font-focus"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New order has been placed
									</div>
									<div class="kt-notification__item-time">
										15 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item kt-notification__item--read">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-safe kt-font-primary"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										Company meeting canceled
									</div>
									<div class="kt-notification__item-time">
										19 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-psd kt-font-success"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New report has been received
									</div>
									<div class="kt-notification__item-time">
										23 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon-download-1 kt-font-danger"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										Finance report has been generated
									</div>
									<div class="kt-notification__item-time">
										25 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon-security kt-font-warning"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New customer comment recieved
									</div>
									<div class="kt-notification__item-time">
										2 days ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-pie-chart kt-font-focus"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New customer is registered
									</div>
									<div class="kt-notification__item-time">
										3 days ago
									</div>
								</div>
							</a>
							<div class="ps__rail-x" style="left: 0px; bottom: 0px;">
								<div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
							</div>
							<div class="ps__rail-y" style="top: 0px; right: 0px;">
								<div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
							</div>
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab id="tab-id-2">
					<ng-template ngbTabContent>
						<div [perfectScrollbar]="{wheelPropagation: false}" [ngStyle]="{'max-height': '40vh', 'position': 'relative'}" class="kt-notification kt-margin-t-10 kt-margin-b-10">
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-psd kt-font-success"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New report has been received
									</div>
									<div class="kt-notification__item-time">
										23 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon-download-1 kt-font-danger"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										Finance report has been generated
									</div>
									<div class="kt-notification__item-time">
										25 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-line-chart kt-font-success"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New order has been received
									</div>
									<div class="kt-notification__item-time">
										2 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-box-1 kt-font-brand"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New customer is registered
									</div>
									<div class="kt-notification__item-time">
										3 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-chart2 kt-font-danger"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										Application has been approved
									</div>
									<div class="kt-notification__item-time">
										3 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-image-file kt-font-warning"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New file has been uploaded
									</div>
									<div class="kt-notification__item-time">
										5 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-drop kt-font-info"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New user feedback received
									</div>
									<div class="kt-notification__item-time">
										8 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-pie-chart-2 kt-font-success"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										System reboot has been successfully completed
									</div>
									<div class="kt-notification__item-time">
										12 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-favourite kt-font-focus"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New order has been placed
									</div>
									<div class="kt-notification__item-time">
										15 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item kt-notification__item--read">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-safe kt-font-primary"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										Company meeting canceled
									</div>
									<div class="kt-notification__item-time">
										19 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-psd kt-font-success"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New report has been received
									</div>
									<div class="kt-notification__item-time">
										23 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon-download-1 kt-font-danger"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										Finance report has been generated
									</div>
									<div class="kt-notification__item-time">
										25 hrs ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon-security kt-font-warning"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New customer comment recieved
									</div>
									<div class="kt-notification__item-time">
										2 days ago
									</div>
								</div>
							</a>
							<a href="javascript:;" class="kt-notification__item">
								<div class="kt-notification__item-icon">
									<i class="flaticon2-pie-chart kt-font-focus"></i>
								</div>
								<div class="kt-notification__item-details">
									<div class="kt-notification__item-title">
										New customer is registered
									</div>
									<div class="kt-notification__item-time">
										3 days ago
									</div>
								</div>
							</a>
							<div class="ps__rail-x" style="left: 0px; bottom: 0px;">
								<div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
							</div>
							<div class="ps__rail-y" style="top: 0px; right: 0px;">
								<div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
							</div>
						</div>
					</ng-template>
				</ngb-tab>
				<ngb-tab id="tab-id-3">
					<ng-template ngbTabContent>
						<div class="kt-grid kt-grid--ver" style="min-height: 200px;">
							<div class="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
								<div class="kt-grid__item kt-grid__item--middle kt-align-center">
									All caught up!
									<br>No new notifications.
								</div>
							</div>
						</div>
					</ng-template>
				</ngb-tab>
			</ngb-tabset> -->
    </form>
  </div>
</div>
