import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class DocumentService {
  constructor(private http: HttpClient, private httpBackend: HttpBackend) {
    this.http = new HttpClient(httpBackend);
  }

  downloadDocumentByUrl(url: string): Observable<any> {
    return this.http.get(url, { responseType: "blob" });
  }
}
