// Angular
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
// RxJS
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { LayoutUtilsService, MessageType } from "./layout-utils.service";

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private router: Router
  ) {}

  // intercept request and add token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      // withCredentials: true,
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem(environment.authTokenKey)}`,
        accept: "application/json",
      },
    });
    return next.handle(request).pipe(
      catchError((error) => {
        const SERVER_MSG = this.translate.instant("GENERAL.SERVER_ERRORS_MSG");
        if (error instanceof HttpErrorResponse) {
          if (error.status == 0) {
            this.layoutUtilsService.showActionNotification(
              "Something went wrong",
              MessageType.Delete,
              10000,
              true,
              false
            );
            return throwError({
              name: "HttpErrorResponse",
              error: {
                errors: {
                  message: "Something went wrong",
                },
              },
            });
          }
          if (error.status == 419 || error.status == 401) {
            // this.store.dispatch(new Logout());
            localStorage.removeItem(environment.authTokenKey);
            window.location.reload();
            return throwError({
              name: "HttpErrorResponse",
              error: {
                data: {
                  message: error.error.message,
                },
                errors: {
                  message: error.error.message,
                },
              },
            });
          }
          if (error.status == 422) {
            return throwError(error);
          }
          if (error.status == 403) {
            return throwError({
              name: "HttpErrorResponse",
              error: {
                data: {
                  message: error.error.message,
                },
                errors: {
                  message: error.error.message,
                },
              },
            });
          }
          if (error.status == 400) {
            if (error.error instanceof Blob && error.error.type === "application/json") {
              // https://github.com/angular/angular/issues/19888
              // When request of type Blob, the error is also in Blob instead of object of the json data
              return new Promise<any>((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = (e: Event) => {
                  try {
                    const errmsg = JSON.parse((<any>e.target).result);
                    reject(
                      new HttpErrorResponse({
                        error: errmsg,
                        headers: error.headers,
                        status: error.status,
                        statusText: error.statusText,
                        url: error.url,
                      })
                    );
                  } catch (e) {
                    reject(error);
                  }
                };
                reader.onerror = (e) => {
                  reject(error);
                };
                reader.readAsText(error.error);
              });
            } else {
              this.layoutUtilsService.showActionNotification(
                error.error.message,
                MessageType.Delete,
                10000,
                true,
                false
              );
              if (error.message) {
                return throwError({
                  name: "HttpErrorResponse",
                  error: {
                    data: {
                      message: error.error.data ? error.error.data.message : error.error.message,
                    },
                    errors: {
                      message: error.error.data ? error.error.data.message : error.error.message,
                      ExtraErrors: error.error.errors ? error.error.errors : null,
                    },
                  },
                });
              } else {
                // Like blob returned response
                return throwError({
                  name: "HttpErrorResponse",
                  error: error.error,
                });
              }
            }
          }
          if (error.status == 500) {
            this.layoutUtilsService.showActionNotification(SERVER_MSG, MessageType.Delete, 10000, true, false);
            return throwError({
              name: "HttpErrorResponse",
              error: { message: SERVER_MSG, errors: error.error.message },
            });
          }
          return throwError(error);
        } else {
          this.layoutUtilsService.showActionNotification(
            "Something went wrong",
            MessageType.Delete,
            10000,
            true,
            false
          );
          return throwError("Something went wrong");
        }
      })
    );
  }
}
