import { AddVehicleDocumentComponent } from "./../add-vehicle-document/add-vehicle-document.component";
// Angular
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  SimpleChange,
  ChangeDetectorRef,
  Input,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
// RxJS
import { Observable, Subscription, of } from "rxjs";
import { startWith, switchMap, map, debounceTime } from "rxjs/operators";
// CRUD
import { LayoutUtilsService, MessageType, TypesUtilsService } from "../../../core/_base/crud";
import { VehicleValuationModel } from "./vehicle-valuation-model";
import { ExtraEquipmentDialogComponent } from "../extra-equipment/extra-equipment-dialog.component";
import { SharedService } from "../shared.service";
import { DropzoneComponent } from "ngx-dropzone-wrapper";
import { TranslateService } from "@ngx-translate/core";
import { AddComparableVehicleComponent } from "../add-comparable-vehicle/add-comparable-vehicle.component";
import { RegistrationFeesComponent } from "../registration-fees/registration-fees.component";

@Component({
  selector: "kt-vehicle-valuation",
  templateUrl: "./vehicle-valuation.component.html",
  styleUrls: ["./vehicle-valuation.component.scss"],
})
export class VehicleValuationComponent implements OnInit, OnDestroy {
  @ViewChildren("documentsDropzone")
  documentsDropzone: QueryList<DropzoneComponent>;
  @Input() entity;
  @Input() entityType;
  @Output() vehicleValuated: EventEmitter<any> = new EventEmitter<any>();
  dropZoneConfig: any = {
    acceptedFiles: ".jpeg,.png,.jpg,.bmp,.gif",
    maxFilesize: 20,
    maxFiles: 1,
  };
  // Public properties
  product: VehicleValuationModel;
  vehicleId: any;
  productForm: FormGroup;
  webPortals: any[];
  marketPriceDocuments: any[];
  extra_equipment_value: any[];
  filteredMakes: Observable<any[]>[] = [];
  filteredModels: Observable<any[]>[] = [];
  filteredVehicles: any[];
  hasFormErrors: boolean = false;
  calculations: any;
  @Input() isReadonly: boolean = false;
  dropZoneMessage: any;
  errorMsgs: any = {}; //Back-end errors
  documentsTooltipMsg = `
		- Upload PDF's for each advertised car. All content needs to be viewable as well as a timestamp for the ad.
		- The vehicle's price as new including all extra equipment.
		- Copy of original registration certificate
		- Copy of vehicle purchase invoice
		- Servicebook to document mileage
		- Other relevant documentation
	`;
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  isGettingCalculationsLoading: boolean = false;
  viewLoading = false;

  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private productsService: SharedService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.dropZoneMessage = `
			<div class="dropzone-placeholder">
				<img class="file-icon" src="assets/media/icons/document-grey.png">
				<span class="dropzone-placeholder-text">
					${this.translate.instant("VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.DROPZONE.PLACEHOLDER")}
				</span>
			</div>
		`;
  }

  ngOnInit() {
    this.product = this.entity.vehicle_market_price_set;
    // if (this.contract.vehicle_market_price_set) {
    // 	this.filteredVehicles = this.contract.vehicle_market_price_set.vehicle_market_price.filter(
    // 		vehicle => vehicle.adjusted_mileage_cents != 0);
    // }
    this.loadProduct(this.product || {});
    this.vehicleId = this.entity && this.entity.asset_details && this.entity.asset_details.id;

    const webPortalsSubscription = this.productsService.getLookups("ad_web_portal").subscribe((res) => {
      this.webPortals = res.data;
    });
    this.componentSubscriptions.push(webPortalsSubscription);

    const marketPriceDocumentsSubscription = this.productsService
      .getLookups("vehicle_market_price_documents")
      .subscribe((res) => {
        this.marketPriceDocuments = res.data;
      });
    this.componentSubscriptions.push(marketPriceDocumentsSubscription);

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    // Update vehicle details because contract is changed
    if (changes["entity"] && !changes["entity"].firstChange && changes["entity"].currentValue) {
      this.vehicleId = this.entity.asset_details.id;
      this.calculateRegistrationTax();
      this.cdr.markForCheck();
      // this.getContractDetails();
    }
  }

  getContractDetails() {
    const contractDetailsSubscription = this.productsService
      .getContractDetails(this.entity.id, this.entityType)
      .subscribe((res) => {
        this.entity = res.data;
        this.product = this.entity.vehicle_market_price_set;
        if (this.entity.vehicle_market_price_set && this.product.vehicle_market_price) {
          this.loadProduct(this.product || {});
        } else {
          const newProduct = new VehicleValuationModel();
          newProduct.clear();
          this.loadProduct(newProduct || {});
        }
        this.loadProduct(this.product || {});
        this.vehicleId = res.data && res.data.asset_details && res.data.asset_details.id;
        this.calculateRegistrationTax();
        this.cdr.markForCheck();
      });
    this.componentSubscriptions.push(contractDetailsSubscription);
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  initProduct() {
    this.createForm();
  }

  createForm() {
    let documentsArray: FormGroup[] = [];
    if (this.product.documents && this.product.documents.length > 0) {
      this.product.documents.forEach((document) => {
        documentsArray.push(
          this.productFB.group({
            file: [null],
            file_id: [document.file_id],
            file_name: [document.file ? document.file.file_name : ""],
            vehicle_market_price_document_id: [
              {
                value: document.vehicle_market_price_document_id,
                disabled: true,
              },
              Validators.required,
            ],
            is_first: [false],
          })
        );
      });
    }
    // To have at least one default dropzone
    // documentsArray.push(this.productFB.group({
    // 	file: [null],
    // 	file_id: [null],
    // 	file_name: [''],
    // 	vehicle_market_price_document_id: [''],
    // 	is_first: [true]
    // }))
    this.productForm = this.productFB.group({
      documents: this.productFB.array(documentsArray),
      market_price_after_adjustments: [
        {
          value: this.product.market_price_after_adjustments,
          disabled: this.isReadonly,
        },
      ],
      total_registration_tax: [
        {
          value: this.product.total_registration_tax,
          disabled: this.isReadonly,
        },
      ],
      advertisement_deduction: [
        {
          value: this.product.advertisement_deduction,
          disabled: this.isReadonly,
        },
      ],
      mileage_deduction: [
        {
          value: this.product.mileage_deduction,
          disabled: this.isReadonly,
        },
      ],
      condition_deduction: [
        {
          value: this.product.condition_deduction,
          disabled: this.isReadonly,
        },
      ],
      extra_equipment_adjustment: [
        {
          value: this.product.extra_equipment_adjustment,
          disabled: this.isReadonly,
        },
      ],
    });
    this.calculateRegistrationTax();
  }

  calculateRegistrationTax() {
    this.errorMsgs = {};
    let payload = {
      contract_id: null,
      offer_id: this.entity.id,
      entity_type: this.entityType,
      advertised_vehicles: [],
    };
    if (this.entityType == "contract") {
      payload.contract_id = this.entity.id;
      delete payload.offer_id;
    } else {
      payload.offer_id = this.entity.id;
      delete payload.contract_id;
    }

    if (this.product.vehicle_market_price && this.product.vehicle_market_price.length > 0) {
      this.isGettingCalculationsLoading = true;
      payload.advertised_vehicles = this.product.vehicle_market_price.map((vehicle) => {
        // if (vehicle.adjusted_price_cents !== 0 && vehicle.mileage !== 0 && vehicle.advertised_price_cents !== 0
        // 	&& vehicle.extra_equipment_value !== null) {
        return {
          mileage: vehicle.mileage,
          advertised_price: vehicle.advertised_price_cents,
          adjusted_price: vehicle.adjusted_price_cents,
          first_registration_date: vehicle.first_registration_date,
          extra_equipment_value: vehicle.extra_equipment_value,
        };
        // }
      });
      if (this.vehicleId) {
        const registrationTaxCalculatorSubscription = this.productsService
          .registrationTaxCalculator(payload, this.vehicleId)
          .subscribe((res) => {
            this.isGettingCalculationsLoading = false;
            this.calculations = res.data;
            this.calculations.total_registration_tax = res.data.final_tax;
            this.cdr.markForCheck();
          });
        this.componentSubscriptions.push(registrationTaxCalculatorSubscription);
      }
    } else {
      this.calculations = null;
      this.isGettingCalculationsLoading = false;
    }
  }

  editVehicle(index) {
    this.addComparableVehicle(index);
  }

  deleteVehicle(index) {
    let productToEdit = this.product.vehicle_market_price[index];
    (<FormArray>this.productForm.get("documents")).value.forEach((document, index) => {
      if (
        productToEdit.documents &&
        productToEdit.documents.length > 0 &&
        document.vehicleToEditIndex == productToEdit.documents[0].vehicleToEditIndex
      ) {
        (<FormArray>this.productForm.get("documents")).removeAt(index);
      }
    });
    this.product.vehicle_market_price.splice(index, 1);
    if (this.product.vehicle_market_price?.length === 0) {
      this.product.documents = [];
      (<FormArray>this.productForm.get("documents")).clear();
    }
    this.calculateRegistrationTax();
  }

  addNewDocumentRow() {
    (<FormArray>this.productForm.get("documents")).push(
      this.productFB.group({
        vehicle_market_price_document_id: [""],
        file_id: [null],
        file_name: [""],
        file: [null],
        is_first: [false],
      })
    );
  }

  removeDocumentRow(index) {
    let docToEditIndex = (<FormArray>this.productForm.get("documents")).value[index]?.vehicleToEditIndex;
    this.product.vehicle_market_price.forEach((product, index) => {
      if (
        product?.documents &&
        product?.documents?.length &&
        docToEditIndex == product?.documents[0]?.vehicleToEditIndex
      ) {
        delete this.product.vehicle_market_price[index].documents;
        return;
      }
    });
    (<FormArray>this.productForm.get("documents")).removeAt(index);
  }

  setPhotoFile(file, rowIndex) {
    const documentsArray = <FormArray>this.productForm.get("documents");
    if (documentsArray) {
      if (documentsArray.controls) {
        const photoRow = <FormGroup>documentsArray.controls[rowIndex];
        if (photoRow) {
          if (photoRow.controls) {
            const photoControl = <FormControl>photoRow.controls["file"];
            if (photoControl) {
              photoControl.setValue(file);
            }
          }
        }
      }
    }
  }

  //  utf8Decode(utf8String) {
  // 	if (typeof utf8String != 'string') throw new TypeError('parameter ‘utf8String’ is not a string');
  // 	// note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
  // 	const unicodeString = utf8String.replace(
  // 		/[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
  // 		function(c) {  // (note parentheses for precedence)
  // 			var cc = ((c.charCodeAt(0)&0x0f)<<12) | ((c.charCodeAt(1)&0x3f)<<6) | ( c.charCodeAt(2)&0x3f);
  // 			return String.fromCharCode(cc); }
  // 	).replace(
  // 		/[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
  // 		function(c) {  // (note parentheses for precedence)
  // 			var cc = (c.charCodeAt(0)&0x1f)<<6 | c.charCodeAt(1)&0x3f;
  // 			return String.fromCharCode(cc); }
  // 	);
  // 	return unicodeString;
  // }

  //  utf8Decode(bytes) {
  // 	var i = 0, s = '';
  // 	while (i < bytes.length) {
  // 		var c = bytes[i++];
  // 		if (c > 127) {
  // 			if (c > 191 && c < 224) {
  // 				if (i >= bytes.length)
  // 					throw new Error('UTF-8 decode: incomplete 2-byte sequence');
  // 				c = (c & 31) << 6 | bytes[i++] & 63;
  // 			} else if (c > 223 && c < 240) {
  // 				if (i + 1 >= bytes.length)
  // 					throw new Error('UTF-8 decode: incomplete 3-byte sequence');
  // 				c = (c & 15) << 12 | (bytes[i++] & 63) << 6 | bytes[i++] & 63;
  // 			} else if (c > 239 && c < 248) {
  // 				if (i + 2 >= bytes.length)
  // 					throw new Error('UTF-8 decode: incomplete 4-byte sequence');
  // 				c = (c & 7) << 18 | (bytes[i++] & 63) << 12 | (bytes[i++] & 63) << 6 | bytes[i++] & 63;
  // 			} else throw new Error('UTF-8 decode: unknown multibyte start 0x' + c.toString(16) + ' at index ' + (i - 1));
  // 		}
  // 		if (c <= 0xffff) s += String.fromCharCode(c);
  // 		else if (c <= 0x10ffff) {
  // 			c -= 0x10000;
  // 			s += String.fromCharCode(c >> 10 | 0xd800)
  // 			s += String.fromCharCode(c & 0x3FF | 0xdc00)
  // 		} else throw new Error('UTF-8 decode: code point 0x' + c.toString(16) + ' exceeds UTF-16 reach');
  // 	}
  // 	return s;
  // }

  addComparableVehicle(index: number) {
    const dialogRef = this.dialog.open(AddComparableVehicleComponent, {
      data: {
        vehicles: this.product.vehicle_market_price,
        contract: this.entity,
        vehicleToEditIndex: index,
      },
      width: "700px",
      maxHeight: "85vh",
    });
    dialogRef.afterClosed().subscribe((res) => {
      console.log("AddComparableVehicleComponent", res);
      // const decodedFile = this.utf8Decode(res?.documents[0]?.file);
      // console.log('decodedFile', decodedFile);
      if (!res) {
        return;
      } else {
        if (res.documents) {
          (<FormArray>this.productForm.get("documents")).value.forEach((document, index) => {
            if (document.vehicleToEditIndex == res.documents[0]?.vehicleToEditIndex) {
              (<FormArray>this.productForm.get("documents")).removeAt(index);
            }
          });
          res.documents.forEach((document) => {
            if (document.file) {
              (<FormArray>this.productForm.get("documents")).push(
                this.productFB.group({
                  file: [document.file],
                  file_id: [document.file_id],
                  file_name: [document.file ? document.file.name : ""],
                  vehicle_market_price_document_id: [
                    {
                      value: document.vehicle_market_price_document_id,
                      disabled: false,
                    },
                    Validators.required,
                  ],
                  is_first: [false],
                  vehicleToEditIndex: document.vehicleToEditIndex,
                })
              );
            } else if (document.file_name) {
              console.log("else document", document);
              (<FormArray>this.productForm.get("documents")).push(
                this.productFB.group({
                  file: [document.file],
                  file_id: [document.file_id],
                  file_name: [document.file_name],
                  vehicle_market_price_document_id: [
                    {
                      value: document.vehicle_market_price_document_id,
                      disabled: false,
                    },
                    Validators.required,
                  ],
                  is_first: [false],
                  crawled: document.crawled,
                  vehicleToEditIndex: document.vehicleToEditIndex,
                })
              );

              console.log("documents", this.productForm.get("documents"));
            }
          });
        }
        if (res.vehicle_data) {
          //edit vehicle
          if (res.vehicleToEditIndex >= 0) {
            this.product.vehicle_market_price[res.vehicleToEditIndex] = res.vehicle_data;
          }
          //add new vehicle
          else {
            if (this.product.vehicle_market_price) {
              // this.product.vehicle_market_price.push(res.vehicle_data);
              this.product.vehicle_market_price = [...this.product.vehicle_market_price, res.vehicle_data];
            } else {
              this.product.vehicle_market_price = [];
              this.product.vehicle_market_price.push(res.vehicle_data);
            }
          }
          this.cdr.detectChanges();
        }
      }
      this.calculateRegistrationTax();
    });
  }

  openRegistrationFees() {
    const dialogRef = this.dialog.open(RegistrationFeesComponent, {
      data: { calculations: this.calculations, contract: this.entity },
      width: "700px",
      maxHeight: "85vh",
    });
  }

  onSubmit() {
    this.hasFormErrors = false;
    const controls = this.productForm.controls;

    /** check form */
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAllAsTouched());

      this.hasFormErrors = true;
      return;
    }

    // tslint:disable-next-line:prefer-const
    let fd: FormData = this.prepareProduct();

    this.storeRegistrationTax(fd);
  }

  storeRegistrationTax(payload) {
    const createProductSubscription = this.productsService.storeRegistrationTax(payload, this.vehicleId).subscribe(
      (res) => {
        this.viewLoading = false;
        // if(res.data){
        // 	this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Create, 10000, true, false);
        // }
        this.vehicleValuated.emit(this.calculations.total_registration_tax);
        this.errorMsgs = {};
        this.cdr.markForCheck();
      },
      (err) => {
        this.viewLoading = false;
        if (err.error) {
          this.errorMsgs = err.error.errors;
          this.cdr.markForCheck();
          this.hasFormErrors = true;
        }
      }
    );
    this.componentSubscriptions.push(createProductSubscription);
  }

  /**
   * Returns object for saving
   */
  prepareProduct(): FormData {
    // this.productForm.patchValue(this.calculations);

    const controls = { ...this.productForm.controls };

    let fd = new FormData();

    Object.keys(controls).forEach((key) => {
      if (controls[key] && controls[key].value != null) {
        fd.append(key, controls[key].value);
      }
    });
    let docs = [];
    if (this.product.vehicle_market_price) {
      console.log("vehicle_market_price", this.product.vehicle_market_price);
      this.product.vehicle_market_price.forEach((vehicle, index) => {
        if (vehicle.first_registration_date !== null) {
          fd.append(`advertised_vehicles[${index}][first_registration_date]`, vehicle.first_registration_date);
        }
        if (vehicle.vehicle_make) {
          fd.append(`advertised_vehicles[${index}][vehicle_make_id]`, vehicle.vehicle_make.id);
        }
        if (vehicle.model) {
          fd.append(`advertised_vehicles[${index}][vehicle_model_id]`, vehicle.model.id);
        }
        if (vehicle.ad_web_portal_id) {
          fd.append(`advertised_vehicles[${index}][ad_web_portal_id]`, vehicle.ad_web_portal_id);
        }
        if (vehicle.mileage) {
          fd.append(`advertised_vehicles[${index}][mileage]`, vehicle.mileage);
        }
        // if (vehicle.adjusted_mileage_cents >= 0) {
        fd.append(`advertised_vehicles[${index}][adjusted_mileage_cents]`, vehicle.adjusted_mileage_cents);
        // }
        if (vehicle.extra_equipment_adjustment_cents) {
          fd.append(
            `advertised_vehicles[${index}][extra_equipment_adjustment_cents]`,
            vehicle.extra_equipment_adjustment_cents
          );
        }
        // if (vehicle.advertised_price_cents >= 0) {
        fd.append(`advertised_vehicles[${index}][advertised_price]`, vehicle.advertised_price_cents);
        // }
        // if (vehicle.adjusted_price_cents >= 0) {
        fd.append(`advertised_vehicles[${index}][adjusted_price_cents]`, vehicle.adjusted_price_cents);
        // }
        // if (vehicle.year_adjustment_cents >= 0) {
        fd.append(`advertised_vehicles[${index}][year_adjustment_cents]`, vehicle.year_adjustment_cents);
        // }
        // if (vehicle.model_adjustment_cents >= 0) {
        fd.append(`advertised_vehicles[${index}][model_adjustment_cents]`, vehicle.model_adjustment_cents);
        // }
        // if (vehicle.adjusted_advertised_price_cents >= 0) {
        fd.append(
          `advertised_vehicles[${index}][adjusted_advertised_price_cents]`,
          vehicle.adjusted_advertised_price_cents
        );
        // }
        if (vehicle.extra_equipment_value) {
          vehicle.extra_equipment_value.forEach((equipment, equipment_index) => {
            if (equipment.name) {
              fd.append(
                `advertised_vehicles[${index}][extra_equipment_value][${equipment_index}][name]`,
                equipment.name
              );
            }
            if (equipment.value) {
              fd.append(
                `advertised_vehicles[${index}][extra_equipment_value][${equipment_index}][value]`,
                equipment.value
              );
            }
          });
        }
        if (vehicle.documents) {
          console.log("vehicle.documents", vehicle.documents);
          vehicle.documents.forEach((document) => {
            if ((document.file || document.file_name) && document.vehicle_market_price_document_id) {
              docs.push(document);
            }
          });
        }
      });
    }

    fd.delete("documents");
    // Send only the new uploaded ones
    (<FormArray>this.productForm.get("documents")).value
      .filter((doc) => !doc.file_id)
      .forEach((document) => {
        if (document.file && document.vehicle_market_price_document_id) {
          docs.push(document);
        }
      });
    docs.forEach((document, index) => {
      if (document.file && document.vehicle_market_price_document_id) {
        fd.append(`documents[${index}][file]`, document.file);
        fd.append(`documents[${index}][vehicle_market_price_document_id]`, document.vehicle_market_price_document_id);
      } else {
        fd.append(`documents[${index}][crawled]`, document.crawled);
        fd.append(`documents[${index}][vehicle_market_price_document_id]`, document.vehicle_market_price_document_id);
        fd.append(`documents[${index}][file_name]`, document.file_name);
      }
    });

    if (this.calculations) {
      fd.append("market_price_after_adjustments", this.calculations.market_price_after_adjustments);
      fd.append("total_registration_tax", this.calculations.total_registration_tax);
      fd.append("advertisement_deduction", this.calculations.advertisement_deduction);
      fd.append("mileage_deduction", this.calculations.mileage_deduction);
      fd.append("condition_deduction", this.calculations.condition_deduction);
    }

    if (this.entityType === "contract") {
      if (this.entity && this.entity.id) {
        fd.append("contract_id", this.entity.id);
      }
    } else {
      if (this.entity && this.entity.id) {
        fd.append("offer_id", this.entity.id);
      }
    }

    if (this.product && this.product.id) {
      fd.append("market_price_set_id", this.product.id.toString());
    }

    return fd;
  }

  displayFn(product: any): string {
    return product && product.name ? product.name : "";
  }

  modelDisplayFn(vehicle_model: any): string {
    return vehicle_model && vehicle_model.model ? vehicle_model.model : "";
  }

  deleteDocument(fileId, documentIndex) {
    const _title: string = this.translate.instant(
      "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.DELETE_MODAL.TITLE"
    );
    const _description: string = this.translate.instant(
      "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.DELETE_MODAL.DESCRIPTION"
    );
    const _waitDesciption: string = this.translate.instant(
      "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.DELETE_MODAL.WAIT_DESCRIPTION"
    );

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.productsService.deleteMarketPriceDocument(this.vehicleId, fileId).subscribe(
        (res) => {
          if (res.data) {
            this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Delete);
          }
          (<FormArray>this.productForm.get("documents")).removeAt(documentIndex);
          this.cdr.detectChanges();
        },
        (err) => {
          if (err.error.data) {
            this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
          }
        }
      );
    });
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  onAddAdditionalDocument() {
    const dialogRef = this.dialog.open(AddVehicleDocumentComponent, {
      width: "700px",
      maxHeight: "85vh",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        res.documents.forEach((document) => {
          if (document.file && document.vehicle_market_price_document_id) {
            (<FormArray>this.productForm.get("documents")).push(
              this.productFB.group({
                vehicle_market_price_document_id: [document.vehicle_market_price_document_id],
                file_id: [null],
                file_name: [document.file.name],
                file: [document.file],
                is_first: [false],
              })
            );
            this.cdr.detectChanges();
          }
        });
      }
    });
  }
}
