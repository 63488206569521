<div class="kt-portlet">
  <form [formGroup]="productForm" autocomplete="off" class="kt-form">
    <div class="mt-3" formArrayName="customerInfo" *ngFor="let singleInfo of customerInfo.controls; let i = index">
      <div class="d-flex align-items-baseline" [formGroupName]="i">
        <ng-container *ngIf="contractInfo !== entityTypesEnum.SPLIT_LEASING; else splitLeasingSignForm">
          <mat-form-field appearance="fill">
            <mat-label class="p-2">{{ customerInfoLabel | translate }}</mat-label>
            <input matInput placeholder="Name" type="text" formControlName="name" />
            <mat-error *ngIf="customerInfo.controls[i]">{{
              "TASKS.VIEW.SEND_CONTRACT.FIELDS.ERROR_REQUIRED" | translate
            }}</mat-error>
          </mat-form-field>
          <div class="ml-4 email-form">
            <mat-form-field appearance="fill">
              <mat-label>{{ "TASKS.VIEW.SEND_CONTRACT.FIELDS.CUSTOMER_EMAIL_LABEL" | translate }}</mat-label>
              <input matInput placeholder="example@example.com" type="email" formControlName="email" />
            </mat-form-field>
            <ng-container
              *ngIf="
                customerInfo.controls[i].controls['email']?.errors
                  | onlyOneError : ['required', 'email', 'pattern'] as error
              "
            >
              <mat-error *ngIf="error.required">
                <span class="mat-invalid-input" [innerHTML]="'CUSTOMERS.ADD.FIELDS.EMAIL.ERROR_MSG' | translate"></span>
              </mat-error>
              <mat-error *ngIf="error.email">
                <span
                  class="mat-invalid-input"
                  [innerHTML]="'CUSTOMERS.ADD.FIELDS.EMAIL.INVALID_EMAIL' | translate"
                ></span>
              </mat-error>
              <mat-error *ngIf="error.pattern">
                <span class="mat-invalid-input" [innerHTML]="'CUSTOMERS.ADD.FIELDS.EMAIL.INVALID' | translate"></span>
              </mat-error>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #splitLeasingSignForm>
          <mat-form-field appearance="fill">
            <mat-label class="p-2">{{
              customerInfoLabel[i] ?? "TASKS.VIEW.SEND_CONTRACT.FIELDS.CUSTOMER_NAME_LABEL" | translate
            }}</mat-label>
            <input matInput placeholder="Name" type="text" formControlName="name" />
            <mat-error *ngIf="customerInfo.controls[i]">{{
              "TASKS.VIEW.SEND_CONTRACT.FIELDS.ERROR_REQUIRED" | translate
            }}</mat-error>
          </mat-form-field>
          <div class="ml-4 email-form">
            <mat-form-field appearance="fill">
              <mat-label>{{ "TASKS.VIEW.SEND_CONTRACT.FIELDS.CUSTOMER_EMAIL_LABEL" | translate }}</mat-label>
              <input matInput placeholder="example@example.com" type="email" formControlName="email" />
            </mat-form-field>
            <ng-container
              *ngIf="
                customerInfo.controls[i].controls['email']?.errors
                  | onlyOneError : ['required', 'email', 'pattern'] as error
              "
            >
              <mat-error *ngIf="error.required">
                <span class="mat-invalid-input" [innerHTML]="'CUSTOMERS.ADD.FIELDS.EMAIL.ERROR_MSG' | translate"></span>
              </mat-error>
              <mat-error *ngIf="error.email">
                <span
                  class="mat-invalid-input"
                  [innerHTML]="'CUSTOMERS.ADD.FIELDS.EMAIL.INVALID_EMAIL' | translate"
                ></span>
              </mat-error>
              <mat-error *ngIf="error.pattern">
                <span class="mat-invalid-input" [innerHTML]="'CUSTOMERS.ADD.FIELDS.EMAIL.INVALID' | translate"></span>
              </mat-error>
            </ng-container>
          </div>
        </ng-template>
        <mat-form-field appearance="fill" class="ml-4" *ngIf="includeIdNumber">
          <mat-label>{{ "CUSTOMERS.ADD.FIELDS.ID_NUMBER.PLACEHOLDER" | translate }}</mat-label>
          <input
            matInput
            [placeholder]="'CUSTOMERS.ADD.FIELDS.ID_NUMBER.PLACEHOLDER' | translate"
            type="text"
            formControlName="id_number"
          />
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          class="ml-4"
          *ngIf="productForm.controls.customerInfo.controls[i]?.controls['customer_id']?.value && includeBankInfo"
        >
          <mat-label>{{ "TASKS.VIEW.SEND_CONTRACT.FIELDS.BANK_CODE_LABEL" | translate }}</mat-label>
          <input
            matInput
            [placeholder]="'TASKS.VIEW.SEND_CONTRACT.FIELDS.BANK_CODE_LABEL' | translate"
            type="text"
            formControlName="bank_code"
            title="Bank Code"
          />
          <mat-error>
            <div *ngIf="customerInfo.controls[i]">
              <strong>The field require 4 digits max</strong>
            </div>
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="fill"
          class="ml-4"
          *ngIf="productForm.controls.customerInfo.controls[i]?.controls['customer_id']?.value && includeBankInfo"
        >
          <mat-label>{{ "TASKS.VIEW.SEND_CONTRACT.FIELDS.BANK_ACCOUNT_NUMBER_LABEL" | translate }}</mat-label>
          <input
            matInput
            [placeholder]="'TASKS.VIEW.SEND_CONTRACT.FIELDS.BANK_ACCOUNT_NUMBER_LABEL' | translate"
            type="text"
            formControlName="bank_account_number"
            title="Bank Account"
          />
          <mat-error *ngIf="customerInfo.controls[i]">The field require 20 digits max</mat-error>
        </mat-form-field>
        <ng-container *ngTemplateOutlet="customContent; context: { $implicit: i }"></ng-container>
        <button
          *ngIf="i >= baseCustomerCount && addMultipleCustomer && !isDisabled"
          type="button"
          mat-mini-fab
          color="warn"
          aria-label="button with a delete icon"
          class="mx-2 mt-2"
          (click)="removeCustomerInfo(i)"
        >
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  </form>
</div>
