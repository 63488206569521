<kt-portlet class="registration-tax">
  <kt-portlet-body>
    <div class="row">
      <div class="col-12">
        <div class="registeration-tax">
          <div class="kt-form__group">
            <div class="vehicle-valuation-titles">
              <h3 class="title">
                {{ "VEHICLE_VALUATION.REGESTRATION_TAX.TITLE" | translate }}
              </h3>
            </div>
            <div *ngIf="calculations" class="registeration-tax-info">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.VEHICLE_MARKET_PRICE" | translate }}</td>
                    <td>{{ calculations?.vehicle_market_price | number : "1.2-2" }}</td>
                  </tr>
                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.VEHICLE_NEW_PRICE" | translate }}</td>
                    <td>{{ calculations?.vehicle_new_price | number : "1.2-2" }}</td>
                  </tr>
                </tbody>
              </table>

              <table class="table table-bordered">
                <thead>
                  <tr>
                    <td colspan="2">
                      {{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.ADJUSTMENT_TO_THE_TEXABLE_VALUE" | translate }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.ELECTRIC_BATTERY_CAPACITY" | translate }}</td>
                    <td>{{ calculations?.electric_battery_deduction | number : "1.2-2" }}</td>
                  </tr>
                  <tr class="highlighted">
                    <td>
                      {{
                        "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.TAXABLE_VALUE_AFTER_BATTERY_ADJUSTMENT" | translate
                      }}
                    </td>
                    <td>{{ calculations?.taxable_value_after_battery_deduction | number : "1.2-2" }}</td>
                  </tr>
                </tbody>
              </table>

              <table class="table table-bordered">
                <thead>
                  <tr>
                    <td colspan="2">
                      {{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.CALCULATION_OF_REGISTRATION_FEE" | translate }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.FIRST_SCALE" | translate }}</td>
                    <td>{{ calculations?.first_scale | number : "1.2-2" }}</td>
                  </tr>
                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.SECOND_SCALE" | translate }}</td>
                    <td>{{ calculations?.second_scale | number : "1.2-2" }}</td>
                  </tr>
                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.THIRD_SCALE" | translate }}</td>
                    <td>{{ calculations?.third_scale | number : "1.2-2" }}</td>
                  </tr>

                  <tr class="highlighted">
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.REG_TAX_BEFORE_ADJUSTMENT" | translate }}</td>
                    <td>{{ calculations?.tax_before_adjustments | number : "1.2-2" }}</td>
                  </tr>

                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.CO2_ADJUSTMENT" | translate }}</td>
                    <td>{{ calculations?.co2_adjustment | number : "1.2-2" }}</td>
                  </tr>

                  <tr class="highlighted">
                    <td>
                      {{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.REG_TAX_BEFORE_BOTTOM_DEDUCTION" | translate }}
                    </td>
                    <td>{{ calculations?.registration_tax_before_bottom_deduction | number : "1.2-2" }}</td>
                  </tr>

                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.BOTTOM_DEDUCTION_ALL_CARS" | translate }}</td>
                    <td>{{ calculations?.bottom_deduction_all_cars | number : "1.2-2" }}</td>
                  </tr>

                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.VAN_ABOVE_3TONE_ADJUSTMENT" | translate }}</td>
                    <td>{{ calculations?.van_above_3ton_adjustment | number : "1.2-2" }}</td>
                  </tr>

                  <tr class="highlighted">
                    <td>
                      {{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.TAX_BEFORE_PHASING_IN_DEDUCTION" | translate }}
                    </td>
                    <td>{{ calculations?.tax_before_phasing_in_deduction | number : "1.2-2" }}</td>
                  </tr>
                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.PHASING_IN_DEDUCTION" | translate }}</td>
                    <td>{{ calculations?.phasing_in_deduction | number : "1.2-2" }}</td>
                  </tr>
                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.EMMISSION_IN_DEDUCTION" | translate }}</td>
                    <td>{{ calculations?.emmission_deduction | number : "1.2-2" }}</td>
                  </tr>
                </tbody>
                <tfoot class="highlighted">
                  <tr>
                    <td>{{ "VEHICLE_VALUATION.REGESTRATION_TAX.TABLES.FINAL_TAX" | translate }}</td>
                    <td>{{ calculations?.final_tax | number : "1.2-2" }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kt-portlet-body>
</kt-portlet>
