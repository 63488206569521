import { IOfferTask } from "./offerTask.model";

export class OfferModel {
  id: number;
  entity_id?: string;
  asset_id?: any;
  asset_details: any;
  customer?: any;
  secondCustomer?: any;
  customers?: any;
  type?: any;
  status?: any;
  step?: any;
  asset?: any;
  asset_type?: any;
  send_offer: any;
  customer_email: any;
  number: any;
  created_at?: string;
  updated_at?: string;
  offer_tasks: IOfferTask[];
  insurance_company_id?: number;
  insurance_created_by_customer?: boolean;

  clear?() {
    this.entity_id = "";
    this.created_at = "";
    this.updated_at = null;
  }
}
