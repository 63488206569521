import { Component, OnInit } from "@angular/core";

@Component({
  selector: "kt-create-payment-dialog",
  templateUrl: "./create-payment-dialog.component.html",
})
export class CreatePaymentDialogComponent implements OnInit {
  activePaymentMode: "SINGLE" | "MONTHLY" = "SINGLE";
  type: string;
  viewLoading: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
