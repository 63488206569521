import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { HttpClient } from "@angular/common/http";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { loadSettings, loadSettingsFailure, loadSettingsSuccess } from "../_actions/setting.actions";
import { API } from "../../_base/crud";
import { BaseSettingsModel } from "../../../views/pages/settings/base-settings.model";
import { IRequiredFieldsSettings } from "../../../views/pages/settings/components/required-fields-settings-dialog/required-fields-settings.interface";
import { IResponse } from "../../_base/crud/interfaces/response-interface";

@Injectable()
export class SettingsEffects {
  loadSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSettings),
      mergeMap(() =>
        this.http
          .get<IResponse<BaseSettingsModel<IRequiredFieldsSettings>>>(`${API.settings}/required-fields-settings`)
          .pipe(
            map((settings) => loadSettingsSuccess({ settings })),
            catchError((error) => of(loadSettingsFailure({ error })))
          )
      )
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}
