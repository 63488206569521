export enum EntityLeasingTypes {
  FINANCIAL = "Financial",
  OPERATIONAL = "Operational",
  AUTO_LOAN = "AutoLoan",
  LOAN = "Loan",
  CREDIT_LINE = "CreditLine",
  STANDSTILL = "StandStill",
}

export const entityLeasingTypesLabels = new Map<string, string>([
  [EntityLeasingTypes.FINANCIAL, "CONTRACTS.LEASING_TYPES.FINANCIAL"],
  [EntityLeasingTypes.OPERATIONAL, "CONTRACTS.LEASING_TYPES.OPERATIONAL"],
  [EntityLeasingTypes.AUTO_LOAN, "CONTRACTS.LEASING_TYPES.AUTO_LOAN"],
  [EntityLeasingTypes.LOAN, "CONTRACTS.LEASING_TYPES.LOAN"],
  [EntityLeasingTypes.CREDIT_LINE, "CONTRACTS.LEASING_TYPES.CREDIT_LINE"],
  [EntityLeasingTypes.STANDSTILL, "CONTRACTS.LEASING_TYPES.STANDSTILL"],
]);
