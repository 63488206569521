<div *ngIf="transactionType" class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    *ngIf="enableHeader"
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ getComponentTitle() }}
        <label class="kt-font-brand">
          -
          {{
            transactionType == "financeVouchers"
              ? ("TRANSACTIONS.VIEW.FINANCE_VOUCHERS_TITLE" | translate)
              : ("TRANSACTIONS.VIEW.SUPPLIER_INVOICES_TITLE" | translate)
          }}
        </label>
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body" *ngIf="product">
      <form [formGroup]="productForm" autocomplete="off" class="kt-form">
        <div class="kt-form__group">
          <div class="transactionType">
            <div class="voucher-row">
              <div class="kt-form__group">
                <div class="row">
                  <div class="col-md-3 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ "TRANSACTIONS.ADD.FIELDS.DATE.PLACEHOLDER" | translate }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs[transactionType + '.date']
                      }"
                    >
                      <input
                        matInput
                        [matDatepicker]="datePicker"
                        placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.DATE.PLACEHOLDER' | translate }}"
                        formControlName="date"
                        type="text"
                      />
                      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                      <mat-datepicker #datePicker></mat-datepicker>
                      <mat-error *ngIf="productForm.controls.date.hasError('required')">
                        <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.DATE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs[transactionType + '.date']">
                      {{ errorMsgs[transactionType + ".date"] }}</mat-error
                    >
                  </div>
                  <div class="col-md-3 kt-margin-bottom-10-mobile" *ngIf="taskCode != null">
                    <label class="mat-input-label">{{
                      "TRANSACTIONS.ADD.FIELDS.VEHICLE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        [value]="assetDetails?.id + '-' + assetDetails?.make?.name + '-' + assetDetails?.model?.model"
                        placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.VEHICLE.PLACEHOLDER' | translate }}"
                        formControlName="vehicle"
                        type="text"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-md-4 kt-margin-bottom-10-mobile" *ngIf="taskCode === 'book_registration_tax'">
                    <label class="mat-input-label">{{
                      "TASKS.VIEW.CREATE_MONTHLY_PAYMENT.ADD.FIELDS.FINAL_REGISTRATION_TAX.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['final_registration_tax']
                      }"
                    >
                      <input
                        matInput
                        type="text"
                        currencyMask
                        placeholder="{{
                          'TASKS.VIEW.CREATE_MONTHLY_PAYMENT.ADD.FIELDS.FINAL_REGISTRATION_TAX.PLACEHOLDER' | translate
                        }}"
                        formControlName="final_registration_tax"
                        autocomplete="off"
                      />
                      <mat-error *ngIf="productForm.controls.final_registration_tax.hasError('required')">
                        <span
                          [innerHTML]="
                            'TASKS.VIEW.CREATE_MONTHLY_PAYMENT.ADD.FIELDS.FINAL_REGISTRATION_TAX.ERROR_MSG' | translate
                          "
                        ></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['final_registration_tax']"
                      >{{ errorMsgs["final_registration_tax"][0] }}
                    </mat-error>
                  </div>
                </div>
                <div
                  [hidden]="
                    !productForm.controls.is_multi_lines?.value ||
                    taskCode == 'book_registration_tax' ||
                    taskCode == 'end_agreement'
                  "
                  class="row"
                >
                  <div class="col-md-12">
                    <div class="transactions my-3" formArrayName="transactions">
                      <div class="row">
                        <label class="mat-input-label col-md-6">{{
                          "TRANSACTIONS.ADD.FIELDS.PRODUCT.PLACEHOLDER" | translate
                        }}</label>
                        <label class="mat-input-label col-md-1">{{
                          "TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.PLACEHOLDER" | translate
                        }}</label>
                        <label class="mat-input-label col-md-2">{{
                          "TRANSACTIONS.ADD.FIELDS.AMOUNT_CENTS.PLACEHOLDER" | translate
                        }}</label>
                      </div>

                      <div
                        class="transaction-row"
                        *ngFor="let transaction of productForm.controls.transactions.controls; let index = index"
                      >
                        <div [formGroupName]="index">
                          <div class="row">
                            <div class="col-md-6 kt-margin-bottom-10-mobile">
                              <mat-form-field
                                class="example-full-width searchable"
                                [ngClass]="{
                                  'mat-form-field-invalid':
                                    errorMsgs[transactionType + '.transactions.' + index + '.product_id']
                                }"
                              >
                                <span
                                  class="kt-svg-icon search-icon"
                                  [inlineSVG]="'./assets/media/icons/magnify.svg'"
                                ></span>
                                <input
                                  type="text"
                                  placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.PRODUCT.PLACEHOLDER' | translate }}"
                                  aria-label="Number"
                                  matInput
                                  formControlName="product"
                                  name="product_{{ index }}"
                                  [matAutocomplete]="transactionProductAutocomplete"
                                />
                                <mat-autocomplete
                                  #transactionProductAutocomplete="matAutocomplete"
                                  [displayWith]="displayFn"
                                  (optionSelected)="transactionsProductSelected($event, index)"
                                >
                                  <mat-option
                                    *ngFor="let product of filteredTransactionsProducts[index] | async"
                                    [value]="product"
                                  >
                                    {{ product.account_number?.entity_id }} -
                                    {{ product.name }}
                                  </mat-option>
                                </mat-autocomplete>
                                <mat-error
                                  *ngIf="
                                    productForm.controls.transactions.controls[index].controls.product.hasError(
                                      'required'
                                    )
                                  "
                                >
                                  <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.PRODUCT.ERROR_MSG' | translate"></span>
                                </mat-error>
                              </mat-form-field>
                              <mat-error *ngIf="errorMsgs[transactionType + '.transactions.' + index + '.product_id']">
                                {{ errorMsgs[transactionType + ".transactions." + index + ".product_id"] }}
                              </mat-error>
                            </div>
                            <div class="col-md-1 kt-margin-bottom-10-mobile">
                              <mat-form-field
                                class="mat-form-field-fluid vat-code-select"
                                [ngClass]="{
                                  'mat-form-field-invalid':
                                    errorMsgs[transactionType + '.transactions.' + index + '.account_vat_code']
                                }"
                              >
                                <mat-select
                                  placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.PLACEHOLDER' | translate }}"
                                  formControlName="account_vat_code"
                                >
                                  <mat-option value=""></mat-option>
                                  <mat-option [value]="vatCode.id" *ngFor="let vatCode of vatCodes">
                                    {{ vatCode.name }}</mat-option
                                  >
                                </mat-select>
                                <mat-error
                                  *ngIf="
                                    productForm.controls.transactions.controls[
                                      index
                                    ].controls.account_vat_code.hasError('required')
                                  "
                                >
                                  <span
                                    [innerHTML]="'TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.ERROR_MSG' | translate"
                                  ></span>
                                </mat-error>
                              </mat-form-field>
                              <mat-error
                                *ngIf="errorMsgs[transactionType + '.transactions.' + index + '.account_vat_code']"
                              >
                                {{ errorMsgs[transactionType + ".transactions." + index + ".account_vat_code"] }}
                              </mat-error>
                            </div>
                            <div class="col-md-2 kt-margin-bottom-10-mobile">
                              <mat-form-field
                                class="mat-form-field-fluid"
                                [ngClass]="{
                                  'mat-form-field-invalid':
                                    errorMsgs[transactionType + '.transactions.' + index + '.amount']
                                }"
                              >
                                <input
                                  matInput
                                  currencyMask
                                  [options]="{
                                    allowNegative: true,
                                    suffix: ''
                                  }"
                                  type="text"
                                  placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.AMOUNT_CENTS.PLACEHOLDER' | translate }}"
                                  formControlName="amount"
                                  autocomplete="off"
                                />
                                <mat-error
                                  *ngIf="
                                    productForm.controls.transactions.controls[index].controls.amount.hasError(
                                      'required'
                                    )
                                  "
                                >
                                  <span
                                    [innerHTML]="'TRANSACTIONS.ADD.FIELDS.AMOUNT_CENTS.ERROR_MSG' | translate"
                                  ></span>
                                </mat-error>
                              </mat-form-field>
                              <mat-error *ngIf="errorMsgs[transactionType + '.transactions.' + index + '.amount']">
                                {{ errorMsgs[transactionType + ".transactions." + index + ".amount"] }}
                              </mat-error>
                            </div>
                            <div class="col-lg-3 align-self-center">
                              <a
                                *ngIf="
                                  index == 0 &&
                                  taskCode !== 'book_registration_tax' &&
                                  taskCode !== 'book_vehicle' &&
                                  taskCode !== 'end_agreement'
                                "
                                href="javascript:;"
                                (click)="addTransactionRow()"
                                class="kt-font-primary d-block"
                              >
                                <span>
                                  <i class="fas fa-plus" style="font-size: 10px"></i>
                                  {{ "TRANSACTIONS.ADD.FIELDS.ATTACHMENTS.ADD_ANOTHER_LINE" | translate }}
                                </span>
                              </a>
                              <a
                                *ngIf="index != 0 && taskCode !== 'book_vehicle'"
                                href="javascript:;"
                                (click)="removeTransactionRow(index)"
                                class="kt-font-danger d-block"
                              >
                                <span>
                                  <i class="fas fa-minus" style="font-size: 10px"></i>
                                  {{ "TRANSACTIONS.ADD.FIELDS.ATTACHMENTS.REMOVE_THIS_LINE" | translate }}
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Reg Tax -->
                <div *ngIf="taskCode == 'book_registration_tax' || taskCode == 'end_agreement'" class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <label class="mat-input-label col-md-6">{{
                        "TRANSACTIONS.ADD.FIELDS.PRODUCT.PLACEHOLDER" | translate
                      }}</label>
                      <label class="mat-input-label col-md-1">{{
                        "TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.PLACEHOLDER" | translate
                      }}</label>
                      <label class="mat-input-label col-md-2">{{
                        "TRANSACTIONS.ADD.FIELDS.AMOUNT_CENTS.PLACEHOLDER" | translate
                      }}</label>
                    </div>
                    <div class="row">
                      <div class="col-md-6 kt-margin-bottom-10-mobile">
                        <mat-form-field
                          class="example-full-width searchable"
                          [ngClass]="{
                            'mat-form-field-invalid': errorMsgs[transactionType + '.product_id']
                          }"
                        >
                          <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                          <input
                            type="text"
                            placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.PRODUCT.PLACEHOLDER' | translate }}"
                            aria-label="Number"
                            matInput
                            formControlName="product"
                            [matAutocomplete]="productAutoComplete"
                          />
                          <mat-autocomplete
                            #productAutoComplete="matAutocomplete"
                            [displayWith]="displayFn"
                            (optionSelected)="productSelected($event)"
                          >
                            <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
                              {{ product.name }}
                            </mat-option>
                          </mat-autocomplete>
                          <mat-error *ngIf="productForm.controls.product.hasError('required')">
                            <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.PRODUCT.ERROR_MSG' | translate"></span>
                          </mat-error>
                        </mat-form-field>
                        <mat-error *ngIf="errorMsgs[transactionType + '.product_id']">
                          {{ errorMsgs[transactionType + ".product_id"][0] }}</mat-error
                        >
                      </div>

                      <div class="col-md-1 kt-margin-bottom-10-mobile">
                        <mat-form-field
                          class="mat-form-field-fluid vat-code-select"
                          [ngClass]="{
                            'mat-form-field-invalid': errorMsgs[transactionType + '.account_vat_code']
                          }"
                        >
                          <mat-select
                            placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.PLACEHOLDER' | translate }}"
                            formControlName="account_vat_code"
                          >
                            <mat-option value=""></mat-option>
                            <mat-option [value]="vatCode.id" *ngFor="let vatCode of vatCodes">
                              {{ vatCode.name }}</mat-option
                            >
                          </mat-select>
                          <mat-error *ngIf="productForm.controls.account_vat_code.hasError('required')">
                            <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.ERROR_MSG' | translate"></span>
                          </mat-error>
                        </mat-form-field>
                        <mat-error *ngIf="errorMsgs[transactionType + '.account_vat_code']">
                          {{ errorMsgs[transactionType + ".account_vat_code"] }}
                        </mat-error>
                      </div>

                      <div class="col-md-3 kt-margin-bottom-10-mobile">
                        <mat-form-field
                          class="mat-form-field-fluid"
                          [ngClass]="{
                            'mat-form-field-invalid': errorMsgs[transactionType + '.amount_cents_reg_tax']
                          }"
                        >
                          <input
                            matInput
                            currencyMask
                            [options]="{ allowNegative: true, suffix: '' }"
                            type="text"
                            placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.AMOUNT_CENTS.PLACEHOLDER' | translate }}"
                            formControlName="amount_cents_reg_tax"
                            autocomplete="off"
                          />
                          <mat-error *ngIf="productForm.controls.amount_cents_reg_tax.hasError('required')">
                            <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.AMOUNT_CENTS.ERROR_MSG' | translate"></span>
                          </mat-error>
                        </mat-form-field>
                        <mat-error *ngIf="errorMsgs[transactionType + '.amount_cents_reg_tax']">
                          {{ errorMsgs[transactionType + ".amount_cents_reg_tax"] }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Reg Tax -->

                <div class="row">
                  <div
                    [hidden]="transactionType == 'supplierInvoices'"
                    class="col-md-6 kt-margin-bottom-10-mobile"
                    [ngStyle]="{
                      order: productForm.controls.is_multi_lines?.value ? -1 : 0
                    }"
                  >
                    <label class="mat-input-label">{{
                      "TRANSACTIONS.ADD.FIELDS.CONTRA_ACCOUNT.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="example-full-width searchable"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs[transactionType + '.contra_account']
                      }"
                    >
                      <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                      <input
                        type="text"
                        placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.CONTRA_ACCOUNT.PLACEHOLDER' | translate }}"
                        aria-label="Number"
                        matInput
                        formControlName="contra_account"
                        [matAutocomplete]="contraAutocomplete"
                      />
                      <mat-autocomplete #contraAutocomplete="matAutocomplete" [displayWith]="accountDisplayFn">
                        <mat-option *ngFor="let account of filteredContraAccounts | async" [value]="account">
                          {{ (account.entity_id || "") + " - " + account.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="productForm.controls.contra_account.hasError('required')">
                        <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.CONTRA_ACCOUNT.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs[transactionType + '.contra_account']">
                      {{ errorMsgs[transactionType + ".contra_account"] }}</mat-error
                    >
                  </div>
                  <div [hidden]="transactionType == 'supplierInvoices'" class="col-md-1 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid vat-code-select"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs[transactionType + '.account_vat_code']
                      }"
                    >
                      <mat-select
                        placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.PLACEHOLDER' | translate }}"
                        formControlName="account_vat_code"
                      >
                        <mat-option value=""></mat-option>
                        <mat-option [value]="vatCode.id" *ngFor="let vatCode of vatCodes">
                          {{ vatCode.name }}</mat-option
                        >
                      </mat-select>
                      <mat-error *ngIf="productForm.controls.account_vat_code.hasError('required')">
                        <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs[transactionType + '.account_vat_code']">
                      {{ errorMsgs[transactionType + ".account_vat_code"] }}</mat-error
                    >
                  </div>
                  <div [hidden]="transactionType == 'supplierInvoices'" class="col-md-3 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "TRANSACTIONS.ADD.FIELDS.AMOUNT_CENTS.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs[transactionType + '.amount_cents']
                      }"
                    >
                      <input
                        matInput
                        currencyMask
                        [options]="{ allowNegative: true, suffix: '' }"
                        type="text"
                        placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.AMOUNT_CENTS.PLACEHOLDER' | translate }}"
                        formControlName="amount_cents"
                        autocomplete="off"
                      />
                      <mat-error *ngIf="productForm.controls.amount_cents.hasError('required')">
                        <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.AMOUNT_CENTS.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs[transactionType + '.amount_cents']">
                      {{ errorMsgs[transactionType + ".amount_cents"] }}</mat-error
                    >
                  </div>

                  <!-- <div [hidden]="transactionType == 'supplierInvoices' || taskCode == 'book_registration_tax'" class="col-md-2 kt-margin-bottom-10-mobile align-self-center">
										<mat-checkbox name="is_multi_lines" formControlName="is_multi_lines">
											{{'TRANSACTIONS.ADD.FIELDS.CREATE_MULTILINE' | translate}}
										</mat-checkbox>
										<mat-error *ngIf="errorMsgs[transactionType+'.is_multi_lines']">{{errorMsgs[transactionType+'.is_multi_lines'][0]}}</mat-error>
									</div> -->
                  <!-- supplier part -->
                </div>
                <div class="row" *ngIf="transactionType == 'supplierInvoices'">
                  <div class="col-md-3 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "TRANSACTIONS.ADD.FIELDS.SUPPLIER.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid searchable"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs[transactionType + '.supplier_id']
                      }"
                    >
                      <span class="kt-svg-icon search-icon" [inlineSVG]="'/assets/media/icons/magnify.svg'"></span>
                      <input
                        type="text"
                        placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.SUPPLIER.PLACEHOLDER' | translate }}"
                        aria-label="Number"
                        matInput
                        formControlName="supplier_id"
                        [matAutocomplete]="supplierAutoComplete"
                      />
                      <mat-autocomplete
                        #supplierAutoComplete="matAutocomplete"
                        [displayWith]="displaySupplierFn"
                        (optionSelected)="setPaymentType()"
                      >
                        <mat-option *ngFor="let supplier of filteredSuppliers | async" [value]="supplier">
                          {{ supplier.name }}
                        </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="productForm.controls.supplier_id.hasError('required')">
                        <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.SUPPLIER.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <a href="javascript:;" class="kt-font-primary d-block" (click)="onAddNewSupplier()">
                      <span>
                        <i class="fas fa-plus" style="font-size: 10px"></i>
                        {{ "SUPPLIERS.VIEW.NEW_BUTTON" | translate }}
                      </span>
                    </a>
                    <mat-error *ngIf="errorMsgs[transactionType + '.supplier_id']">
                      {{ errorMsgs[transactionType + ".supplier_id"] }}</mat-error
                    >
                  </div>
                  <div class="col-md-3 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "TRANSACTIONS.ADD.FIELDS.SUPPLIER_INVOICE_NUMBER.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs[transactionType + '.supplier_invoice_number']
                      }"
                    >
                      <input
                        matInput
                        type="text"
                        placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.SUPPLIER_INVOICE_NUMBER.PLACEHOLDER' | translate }}"
                        formControlName="supplier_invoice_number"
                        autocomplete="off"
                      />
                      <mat-error *ngIf="productForm.controls.supplier_invoice_number.hasError('required')">
                        <span
                          [innerHTML]="'TRANSACTIONS.ADD.FIELDS.SUPPLIER_INVOICE_NUMBER.ERROR_MSG' | translate"
                        ></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs[transactionType + '.supplier_invoice_number']">
                      {{ errorMsgs[transactionType + ".supplier_invoice_number"] }}</mat-error
                    >
                  </div>
                  <div class="col-md-3 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      "TRANSACTIONS.ADD.FIELDS.SUPPLIER_INVOICE_DUEDATE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs[transactionType + '.supplier_invoice_duedate']
                      }"
                    >
                      <input
                        matInput
                        [matDatepicker]="supplierDatePicker"
                        placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.SUPPLIER_INVOICE_DUEDATE.PLACEHOLDER' | translate }}"
                        formControlName="supplier_invoice_duedate"
                        type="text"
                      />
                      <mat-datepicker-toggle matSuffix [for]="supplierDatePicker"> </mat-datepicker-toggle>
                      <mat-datepicker #supplierDatePicker></mat-datepicker>
                      <mat-error *ngIf="productForm.controls.supplier_invoice_duedate.hasError('required')">
                        <span
                          [innerHTML]="'TRANSACTIONS.ADD.FIELDS.SUPPLIER_INVOICE_DUEDATE.ERROR_MSG' | translate"
                        ></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs[transactionType + '.supplier_invoice_duedate']">
                      {{ errorMsgs[transactionType + ".supplier_invoice_duedate"] }}</mat-error
                    >
                  </div>
                  <!-- payment Type ID part -->
                  <div class="col-md-3 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">
                      {{ "SUPPLIERS.ADD.FIELDS.PAYMENT_TYPE.PLACEHOLDER" | translate }}</label
                    >
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['payment_type_id']
                      }"
                    >
                      <mat-select
                        placeholder="Enter payment term"
                        [compareWith]="compareFn"
                        formControlName="payment_type_id"
                      >
                        <mat-option [value]="paymentInfo" *ngFor="let paymentInfo of paymentTypes">
                          {{ paymentInfo.payment_type }}</mat-option
                        >
                      </mat-select>
                      <mat-error *ngIf="productForm.controls.payment_type_id.hasError('required')">
                        <span [innerHTML]="'SUPPLIERS.ADD.FIELDS.PAYMENT_TYPE.ERROR_MSG' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs[transactionType + '.payment_type_id']">
                      {{ errorMsgs[transactionType + ".payment_type_id"][0] }}
                    </mat-error>
                  </div>
                </div>
                <div
                  class="row mt-3"
                  *ngIf="
                    transactionType == transactionTypes.SUPPLIER_INVOICES &&
                    productForm.get('payment_type_id')?.value &&
                    productForm.get('payment_type_id')?.value?.code !== 'none'
                  "
                >
                  <div class="col-md-3 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{ productForm.get("payment_type_id")?.value?.label?.name }}</label>
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        type="text"
                        placeholder="{{ productForm.get('payment_type_id')?.value?.label?.name }}"
                        formControlName="payment_label"
                        autocomplete="off"
                      />
                      <mat-error *ngIf="productForm.controls.payment_label.hasError('required')">
                        <span [innerHTML]="'GENERAL.VALIDATION.REQUIRED_FIELD' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error
                      *ngIf="errorMsgs[transactionType + '.' + productForm.get('payment_type_id')?.value?.label?.code]"
                    >
                      {{ errorMsgs[transactionType + "." + productForm.get("payment_type_id")?.value?.label?.code][0] }}
                    </mat-error>
                  </div>

                  <div class="col-md-3 kt-margin-bottom-10-mobile">
                    <label class="mat-input-label">{{
                      productForm.get("payment_type_id")?.value?.second_label?.name
                    }}</label>
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        type="text"
                        placeholder="{{ productForm.get('payment_type_id')?.value?.second_label?.name }}"
                        formControlName="payment_second_label"
                        autocomplete="off"
                      />
                      <mat-error *ngIf="productForm.controls.payment_second_label.hasError('required')">
                        <span [innerHTML]="'GENERAL.VALIDATION.REQUIRED_FIELD' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error
                      *ngIf="
                        errorMsgs[transactionType + '.' + productForm.get('payment_type_id')?.value?.second_label?.code]
                      "
                    >
                      {{
                        errorMsgs[
                          transactionType + "." + productForm.get("payment_type_id")?.value?.second_label?.code
                        ][0]
                      }}
                    </mat-error>
                  </div>
                </div>
              </div>
              <!-- <div class="kt-form__group">
								<div class="documents" formArrayName="documents">
									<mat-hint class="mx-2" align="start">{{'TRANSACTIONS.ADD.FIELDS.ATTACHMENTS.NAME' | translate}}</mat-hint>
									<div *ngFor="let document of productForm.controls.documents.controls;let i=index">
										<div class="kt-form__group row align-items-center mx-1">
											<div class="col-md-7">
												<div class="row dropzone-row" [ngClass]="{'uploaded':productForm.controls.documents.controls[i].controls.file_name.value}">
													<div class="col-md-10 kt-margin-bottom-10-mobile">
														<div *ngIf="productForm.controls.documents.controls[i].controls.file_name.value">
															<div class="uploaded-file-info">
																<img class="file-icon" src="assets/media/icons/document.png">
																<div class="file-info">
																	<h5 class="title">
																		{{'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.FILE_UPLOADED' | translate}}
																	</h5>
																	<p class="file-name">
																		{{productForm.controls.documents.controls[i].controls.file_name.value}}
																	</p>
																</div>
															</div>
														</div>
														<div [hidden]="productForm.controls.documents.controls[i].controls.file_name.value">
															<div class="dropzone-wrapper dropzone-borderless dropzone-multi" [class.mat-form-field-invalid]="errorMsgs['documents.'+ i +'.file']">
																<dropzone #documentsDropzone [config]="dropZoneConfig" [message]="dropZoneConfig.message"></dropzone>
															</div>
															<mat-error *ngIf="errorMsgs['documents.'+ i +'.file']">{{errorMsgs['documents.'+ i +'.file'][0]}}</mat-error>
														</div>
													</div>
													<div *ngIf="productForm.controls.documents.controls[i].controls.file_name.value" class="col-md-2 remove-document align-self-center">
														<button (click)="deleteDocument(document.value.document_id,i)" class="text-center d-block m-auto" mat-icon-button color="secondary" type="button" matTooltip="{{'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.REMOVE_DOCUMENT' | translate}}">
															<img src="assets/media/icons/close.png" />
														</button>
													</div>
												</div>
												<div class="row mt-1" *ngIf="!productForm.controls.documents.controls[i].controls.file_id.value">
													<div class="col-md-12">
														<a *ngIf="productForm.controls.documents.controls[i].controls.is_first &&
															productForm.controls.documents.controls[i].controls.is_first.value;else not_first"	 href="javascript:;" (click)="addNewDocumentRow()" class="kt-font-primary d-block">
															<span>
																<i class="fas fa-plus" style="font-size: 10px;"></i>
																{{'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.ADD_ANOTHER_DOCUMENT' | translate}}
															</span>
														</a>
														<ng-template #not_first>
															<a href="javascript:;" (click)="removeDocumentRow(i)" class="kt-font-danger d-block">
																<span>
																	<i class="fas fa-minus" style="font-size: 10px;"></i>
																	{{'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.REMOVE_DOCUMENT' | translate}}
																</span>
															</a>
														</ng-template>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> -->
              <div class="kt-form__group">
                <div class="documents">
                  <mat-hint class="mx-2" align="start">
                    {{ "TRANSACTIONS.ADD.FIELDS.ATTACHMENTS.NAME" | translate }}</mat-hint
                  >
                  <div>
                    <div class="kt-form__group row align-items-center mx-1">
                      <div class="col-md-7">
                        <div
                          *ngIf="uploadedDocuments && uploadedDocuments.length"
                          class="dropzone-row uploaded"
                          style="margin: 0 -10px 20px"
                        >
                          <div class="row" *ngFor="let document of uploadedDocuments">
                            <div class="col-md-10 kt-margin-bottom-10-mobile">
                              <div class="uploaded-file-info" style="height: 55px !important">
                                <img class="file-icon" src="assets/media/icons/document.png" />
                                <div class="file-info">
                                  <h5 class="title">
                                    {{
                                      "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.FILE_UPLOADED" | translate
                                    }}
                                  </h5>
                                  <p class="file-name">
                                    {{ document.file_name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2 remove-document align-self-center">
                              <button
                                (click)="deleteDocument(document.document_id)"
                                class="text-center d-block m-auto"
                                mat-icon-button
                                color="secondary"
                                type="button"
                                matTooltip="{{
                                  'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.REMOVE_DOCUMENT' | translate
                                }}"
                              >
                                <img src="assets/media/icons/close.png" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="row dropzone-row">
                          <div class="col-md-10 kt-margin-bottom-10-mobile">
                            <div>
                              <div
                                class="dropzone-wrapper dropzone-borderless dropzone-multi"
                                [class.mat-form-field-invalid]="errorMsgs['documents.' + i + '.file']"
                              >
                                <dropzone
                                  #documentsDropzone
                                  [config]="dropZoneConfig"
                                  [message]="dropZoneConfig.message"
                                ></dropzone>
                              </div>
                              <mat-error *ngIf="errorMsgs['documents.' + i + '.file']">
                                {{ errorMsgs["documents." + i + ".file"][0] }}</mat-error
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5" *ngIf="taskCode === 'book_registration_tax'">
                        <label class="mat-input-label">{{
                          "TRANSACTIONS.ADD.FIELDS.VEHICLE_PLATE_NUMBER.PLACEHOLDER" | translate
                        }}</label>
                        <mat-form-field class="mat-form-field-fluid">
                          <input
                            matInput
                            type="text"
                            placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.VEHICLE_PLATE_NUMBER.PLACEHOLDER' | translate }}"
                            formControlName="vehicle_plate_number"
                            autocomplete="off"
                          />
                          <mat-error *ngIf="productForm.controls.vehicle_plate_number.hasError('required')">
                            <span [innerHTML]="'GENERAL.VALIDATION.REQUIRED_FIELD' | translate"></span>
                          </mat-error>
                        </mat-form-field>
                        <mat-error *ngIf="errorMsgs[transactionType + '.vehicle_plate_number']">
                          {{ errorMsgs[transactionType + ".vehicle_plate_number"][0] }}
                        </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button
          style="border: 1px solid"
          *ngIf="!hideNewButton && taskCode == 'book_registration_tax'"
          class="pull-left"
          mat-button
          color="warn"
          (click)="markTaskAsDone()"
        >
          {{ "TASKS.VIEW.BOOK_REGISTRATION_TAX.NO_REGISTRATION_TAX" | translate }}
        </button>
        <button mat-button (click)="dialogRef.close()">
          {{ "GENERAL.CANCEL" | translate }}</button
        >&nbsp;
        <button class="btn btn-primary" color="primary" mat-raised-button (click)="onSubmit()" [disabled]="viewLoading">
          {{ "TRANSACTIONS.ADD.ACTIONS.CREATE_TRANSACTION_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
