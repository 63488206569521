// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PartialsModule } from "../../partials/partials.module";
import { NgxPermissionsModule } from "ngx-permissions";
// Components
import { TransactionListComponent } from "./transactions-list.component";
import { TransactionBookDialogComponent } from "./dialogs/transaction-book-dialog/transaction-book-dialog.component";
import { TransactionEditDialogComponent } from "./dialogs/transaction-edit-dialog/transaction-edit-dialog.component";
import { TransactionsService } from "./transactions.service";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DocumentService } from "../document.service";
import { TransactionImportDialogComponent } from "./dialogs/transaction-import-dialog/transaction-import-dialog.component";

@NgModule({
  imports: [CommonModule, PartialsModule, NgxPermissionsModule.forChild(), DragDropModule],
  providers: [TransactionsService, DocumentService],
  exports: [
    TransactionListComponent,
    TransactionBookDialogComponent,
    TransactionEditDialogComponent,
    TransactionImportDialogComponent,
    DragDropModule,
  ],
  declarations: [
    TransactionListComponent,
    TransactionBookDialogComponent,
    TransactionEditDialogComponent,
    TransactionImportDialogComponent,
  ],
})
export class TransactionsModule {}
