export class ExtendDateModel {
  public end_date: string;

  clear() {
    this.end_date = "";
    return this;
  }

  public setAttributes(model) {
    this.end_date = model.date;
    return this;
  }
}
