// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PartialsModule } from "../../partials/partials.module";
import { NgxPermissionsModule } from "ngx-permissions";
// Components
import { ContractMileageListComponent } from "./contract-mileage-list.component";
import { ContractMileageService } from "./contract-mileage.service";
import { CreateAdjustmentPaymentsComponent } from "./create-adjustment-payments/create-adjustment-payments.component";
import { AddContractMileageComponent } from "./add-contract-mileage/add-contract-mileage.component";

@NgModule({
  imports: [CommonModule, PartialsModule, NgxPermissionsModule.forChild()],
  providers: [ContractMileageService],
  declarations: [ContractMileageListComponent, AddContractMileageComponent, CreateAdjustmentPaymentsComponent],
  exports: [ContractMileageListComponent, AddContractMileageComponent, CreateAdjustmentPaymentsComponent],
})
export class ContractMileageModule {}
