// Angular
import { Component, ViewEncapsulation, Input } from "@angular/core";

/**
 * Sample actions menu dropdown
 */
@Component({
  selector: "kt-actions-menu",
  templateUrl: "./actions-menu.component.html",
  styleUrls: ["./actions-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ActionsMenuComponent {
  @Input() inTableHeader: boolean = false; // whether the ActionsMenu in table thead or not
}
