export enum EntityTypes {
  SPLIT_LEASING = "SplitLeasing",
  CORPORATE = "Corporate",
  PRIVATE = "Private",
}

export const EntityTypeMap = new Map<string, string>([
  [EntityTypes.PRIVATE, "ENTITY.TYPES.PRIVATE"],
  [EntityTypes.CORPORATE, "ENTITY.TYPES.CORPORATE"],
  [EntityTypes.SPLIT_LEASING, "ENTITY.TYPES.SPLIT_LEASING"],
]);
