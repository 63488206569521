<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.ADD_ANOTHER_DOCUMENT" | translate }}
      </h3>
    </div>
  </div>
  <kt-portlet-body>
    <div class="vehicle-valuation-form" *ngIf="product">
      <form [formGroup]="productForm" class="vehicle-price kt-form kt-form--group-seperator-dashed">
        <kt-alert
          *ngIf="hasFormErrors"
          type="warn"
          [showCloseButton]="true"
          [duration]="10000"
          (close)="onAlertClose($event)"
        >
          {{ "GENERAL.FORM_ERROR_MESSAGE" | translate }}
        </kt-alert>

        <div *ngIf="!viewLoading" class="market-price-documents">
          <div>
            <div class="col-md-12">
              <h5 class="kt-portlet__head-title">
                {{ "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.TITLE" | translate }}
              </h5>
            </div>
            <div class="col-md-9">
              <div class="documents" formArrayName="documents">
                <div
                  class="document-row"
                  *ngFor="let document of productForm.controls.documents.controls; let i = index"
                >
                  <div class="kt-form__group" [formGroupName]="i">
                    <div
                      class="row dropzone-row"
                      [ngClass]="{
                        uploaded: productForm.controls.documents.controls[i].controls.file_name.value
                      }"
                    >
                      <div *ngIf="productForm.controls.documents.controls[i].controls.file_name.value" class="col-md-6">
                        <div class="uploaded-file-info">
                          <img class="file-icon" src="assets/media/icons/document.png" />
                          <div class="file-info">
                            <h5 class="title">
                              {{ "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.FILE_UPLOADED" | translate }}
                            </h5>
                            <p class="file-name">
                              {{ productForm.controls.documents.controls[i].controls.file_name.value }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        [hidden]="productForm.controls.documents.controls[i].controls.file_name.value"
                        class="col-md-6 kt-margin-bottom-10-mobile"
                      >
                        <div
                          class="dropzone-wrapper dropzone-borderless"
                          [class.mat-form-field-invalid]="errorMsgs['documents.' + i + '.file']"
                        >
                          <dropzone
                            #documentsDropzone
                            (addedFile)="setPhotoFile($event, i)"
                            (removedFile)="setPhotoFile(null, i)"
                            [config]="dropZoneConfig"
                            [message]="dropZoneMessage"
                          ></dropzone>
                        </div>
                        <mat-error
                          *ngIf="
                            productForm.controls.documents.controls[i].controls.file.touched &&
                            productForm.controls.documents.controls[i].controls.file.hasError('required')
                          "
                        >
                          <span
                            [innerHTML]="
                              'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.DROPZONE.ERROR_MSG' | translate
                            "
                          ></span>
                        </mat-error>
                      </div>
                      <div class="col-lg-5 kt-margin-bottom-10-mobile align-self-center">
                        <div class="market-price-document-id">
                          <mat-form-field
                            class="mat-form-field-fluid"
                            [ngClass]="{
                              'mat-form-field-invalid':
                                errorMsgs['documents.' + i + '.vehicle_market_price_document_id']
                            }"
                          >
                            <mat-select placeholder="Choose file" formControlName="vehicle_market_price_document_id">
                              <mat-option value="">
                                {{
                                  "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.MARKET_PRICE_DOCUMENT.PLACEHOLDER"
                                    | translate
                                }}
                              </mat-option>
                              <mat-option
                                [value]="marketPriceDocument.id"
                                *ngFor="let marketPriceDocument of marketPriceDocuments"
                              >
                                {{ marketPriceDocument.name }}</mat-option
                              >
                            </mat-select>
                            <mat-error
                              *ngIf="
                                productForm.controls.documents.controls[
                                  i
                                ].controls.vehicle_market_price_document_id.hasError('required')
                              "
                            >
                              <span
                                [innerHTML]="
                                  'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.MARKET_PRICE_DOCUMENT.ERROR_MSG'
                                    | translate
                                "
                              ></span>
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div
                        *ngIf="productForm.controls.documents.controls[i].controls.file_id.value"
                        class="col-md-1 remove-document align-self-center"
                      >
                        <button
                          (click)="deleteDocument(document.value.file_id, i)"
                          class="text-center d-block m-auto"
                          mat-icon-button
                          color="secondary"
                          type="button"
                          matTooltip="{{
                            'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.REMOVE_DOCUMENT' | translate
                          }}"
                        >
                          <img src="assets/media/icons/close.png" />
                        </button>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div *ngIf="!productForm.controls.documents.controls[i].controls.file_id.value" class="col-md-12">
                        <a
                          href="javascript:;"
                          *ngIf="productForm.controls.documents.controls[i].controls.file_name.value"
                          (click)="removeDocumentRow(i)"
                          class="kt-font-danger d-block"
                        >
                          <span>
                            <i class="fas fa-minus" style="font-size: 10px"></i>
                            {{ "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.REMOVE_DOCUMENT" | translate }}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-12 text-center">
      <mat-spinner style="margin: 0 auto; margin-top: 20px" [diameter]="20" *ngIf="viewLoading"></mat-spinner>
    </div>
  </kt-portlet-body>

  <div *ngIf="!viewLoading" class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
    <div class="kt-form__actions kt-form__actions--sm" style="height: 62px !important; padding-top: 13px">
      <button mat-button (click)="dialogRef.close()">
        {{ "GENERAL.CANCEL" | translate }}</button
      >&nbsp;
      <button
        mat-button
        (click)="addVehicle()"
        color="primary"
        cdkFocusInitial
        *ngIf="!isReadonly"
        [disabled]="viewLoading"
      >
        {{ "GENERAL.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>
