// Angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// RxJS
import { Observable } from "rxjs";

import { API } from "../../../core/_base/crud";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { PaymentModel } from "./payment.model";

@Injectable()
export class PaymentsService {
  constructor(private http: HttpClient) {}

  createProduct(product): Observable<any> {
    return this.http.post<any>(API.payments, product);
  }

  getProductById(productId: number): Observable<any> {
    return this.http.get<any>(API.payments + `/${productId}`);
  }

  indexProduct(params: IRequestPagination, routeURL: string): Observable<any> {
    return this.http.get<IResponsePagination<PaymentModel>>(API.payments + "/" + routeURL, {
      params: { ...params },
    });
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.put<any>(`${API.payments}/${productId}`, product);
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.delete<any>(`${API.payments}/${productId}`);
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(`${API.roles}`);
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(`${API.permissions}/categories`);
  }

  getCVRData(q): Observable<any> {
    return this.http.post<any>(`${API.CVRData}`, { search: q });
  }

  addBulkInvoices(payload) {
    return this.http.post<any>(`${API.contractInvoice}/bulk-invoices`, payload);
  }

  createInvoice(payload) {
    return this.http.post<any>(`${API.contractInvoice}/create`, payload);
  }

  getCustomerDetails(customer_id) {
    return this.http.get<any>(`${API.customers}/${customer_id}`);
  }

  getContractsList(q): Observable<any> {
    return this.http.get<any>(`${API.contracts}/getAll`, {
      params: {
        q,
      },
    });
  }

  getContractDetails(contract_id) {
    return this.http.get<any>(`${API.contracts}/${contract_id}`);
  }

  bulkDeletePayments(payments: number[]) {
    return this.http.post<any>(`${API.payments}/mass-delete`, { payments });
  }

  createMonthlyPayment(payload) {
    return this.http.post<any>(`${API.payments}/monthly-payments-manually`, payload);
  }

  reversePayments(payments: number[]) {
    return this.http.post<any>(`${API.payments}/reverse`, { payments });
  }
}
