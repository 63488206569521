export class addComparableVehicleModel {
  id: number;
  product: any;
  product_id: any;
  // advertised_vehicles: any[];
  ad_web_portal: any;
  ad_web_url: string;
  mileage: any;
  advertised_price_cents: number;
  first_registration_date: any;
  vehicle_make: any;
  model: any;
  vehicle_new_price: number;
  extra_equipment_value: any[];
  vehicle_market_price: any[];
  documents: any[];
  market_price_after_adjustments: any;
  total_registration_tax: any;
  advertisement_deduction: any;
  mileage_deduction: any;
  condition_deduction: any;
  extra_equipment_adjustment: any;
  created_at: string;
  updated_at: string;

  clear() {
    this.product = "";
    this.ad_web_portal = null;
    this.ad_web_url = "";
    this.mileage = 1;
    this.advertised_price_cents = 1;
    this.vehicle_make = null;
    this.model = null;
    this.vehicle_new_price = 1;
    this.market_price_after_adjustments = 0;
    this.total_registration_tax = 0;
    this.advertisement_deduction = 0;
    this.mileage_deduction = 0;
    this.condition_deduction = 0;
    this.extra_equipment_adjustment = 0;
    this.extra_equipment_value = [];
    this.created_at = "";
    this.updated_at = null;
  }
}
