// Angular
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
// Partials for CRUD
import { Store, select } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
import {
  ActionNotificationComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
} from "../../../../views/partials/content/crud";
import { PaginatorDefaultOptions } from "../../../_config/paginator.config";
import { currentUser, currentUserRole } from "../../../auth";
import { TranslateService } from "@ngx-translate/core";

export enum MessageType {
  Create,
  Read,
  Update,
  Delete,
}

@Injectable()
export class LayoutUtilsService {
  /**
   * Service constructor
   *
   * @param snackBar: MatSnackBar
   * @param dialog: MatDialog
   */
  constructor(
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  paginatorDefaultOptions = PaginatorDefaultOptions;
  /**
   * Showing (Mat-Snackbar) Notification
   *
   * @param _message
   * @param _type
   * @param _duration
   * @param _showCloseButton
   * @param _showUndoButton
   * @param _undoButtonDuration
   * @param _verticalPosition
   */
  showActionNotification(
    _message: string | null,
    _type: MessageType = MessageType.Create,
    _duration: number = 10000,
    _showCloseButton: boolean = true,
    _showUndoButton: boolean = false,
    _undoButtonDuration: number = 3000,
    _verticalPosition: "top" | "bottom" = "bottom"
  ) {
    const _data = {
      message: _message ?? this.translate.instant("GENERAL.SOMETHING_WENT_WRONG"),
      snackBar: this.snackBar,
      showCloseButton: _showCloseButton,
      showUndoButton: _showUndoButton,
      undoButtonDuration: _undoButtonDuration,
      verticalPosition: _verticalPosition,
      type: _type,
      action: "Undo",
    };
    return this.snackBar.openFromComponent(ActionNotificationComponent, {
      duration: _duration,
      data: _data,
      verticalPosition: _verticalPosition,
    });
  }

  /**
   * Showing Confirmation (Mat-Dialog) before Entity Removing
   *
   * @param title: stirng
   * @param description: stirng
   * @param waitDesciption: string
   */
  deleteElement(
    title: string = "",
    description: string = "",
    waitDesciption: string = "",
    width: string = "440px",
    submitText: string = ""
  ) {
    return this.dialog.open(DeleteEntityDialogComponent, {
      data: { title, description, waitDesciption, submitText },
      width,
    });
  }

  /**
   * Showing Fetching Window(Mat-Dialog)
   *
   * @param _data: any
   */
  fetchElements(_data) {
    return this.dialog.open(FetchEntityDialogComponent, {
      data: _data,
      width: "400px",
    });
  }

  /**
   * Showing Update Status for Entites Window
   *
   * @param title: string
   * @param statuses: string[]
   * @param messages: string[]
   */
  updateStatusForEntities(title, statuses, messages) {
    return this.dialog.open(UpdateStatusDialogComponent, {
      data: { title, statuses, messages },
      width: "480px",
    });
  }

  prepareDropZoneThumbnail(fileUrl, dropzone) {
    let url: string = fileUrl;
    setTimeout(() => {
      if (url) {
        let splitArr: string[] = url.split("/");
        let name = splitArr.at(-1);
        this.initializeDropZoneThumbnail(name, url, dropzone);
      }
    }, 300);
  }

  initializeDropZoneThumbnail(name, url, dropzone) {
    // setTimeout(() => {
    var mockFile = {
      name: name,
      size: 12345,
      accepted: true,
      kind: "image",
      type: "image/*",
    };
    if (dropzone) {
      dropzone.emit("addedfile", mockFile);
      dropzone.emit("thumbnail", mockFile, url);
      dropzone.emit("complete", mockFile);
    }
    // }, 300);
  }

  removeAllDropzoneThumbnails() {
    Array.from(document.getElementsByClassName("dz-wrapper")).forEach((wrapper: any) => {
      if (wrapper && wrapper.dropzone && wrapper.dropzone.files) {
        wrapper.dropzone.removeAllFiles();
        if (wrapper.getElementsByClassName("dz-preview").length > 0) {
          wrapper.getElementsByClassName("dz-preview")[0].style.display = "none";
          wrapper.getElementsByClassName("dz-message")[0].style.display = "block";
        }
      }
    });
  }

  loadUserRoleFromStore() {
    return this.store.pipe(select(currentUserRole));
  }

  loadCurrentUserInfoFromStore() {
    return this.store.pipe(select(currentUser));
  }

  listenToScrollingOnMatDialogOpen() {
    // LEMA-1267
    let timer = null;
    window.addEventListener(
      "wheel",
      function () {
        const matOverlay: any = document.getElementsByClassName("cdk-overlay-backdrop");
        if (timer !== null) {
          if (matOverlay) {
            for (var i = 0; i < matOverlay.length; i++) {
              matOverlay[i].style.display = "none";
            }
          }
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          if (matOverlay) {
            for (var i = 0; i < matOverlay.length; i++) {
              matOverlay[i].style.display = "block";
            }
          }
        }, 100);
      },
      false
    );
  }

  sliceWord(word?: string, length: number = 20) {
    if (!word) {
      return " ";
    }
    return word.length >= length ? word.slice(0, length).concat("...") : word;
  }
}
