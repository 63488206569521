<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ getComponentTitle() }}</h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <kt-portlet-body>
    <div class="vehicle-valuation-form" *ngIf="product">
      <form [formGroup]="productForm" class="vehicle-price kt-form kt-form--group-seperator-dashed">
        <kt-alert
          *ngIf="hasFormErrors"
          type="warn"
          [showCloseButton]="true"
          [duration]="10000"
          (close)="onAlertClose($event)"
        >
          {{ "GENERAL.FORM_ERROR_MESSAGE" | translate }}
        </kt-alert>

        <div>
          <div class="advertised_vehicles" style="margin-top: -41px">
            <div class="vehicle-row">
              <div class="kt-form__group">
                <div class="vehicle-inner-row">
                  <div class="row">
                    <div class="col-md-10 kt-margin-bottom-10-mobile">
                      <label class="mat-input-label">{{
                        "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.WEB_PORTAL_URL.PLACEHOLDER" | translate
                      }}</label>
                      <mat-form-field
                        class="example-full-width searchable"
                        [ngClass]="{ 'mat-form-field-invalid': errorMsgs['ad_web_url'] }"
                      >
                        <!-- <span class="kt-svg-icon search-icon"
                                                    [inlineSVG]="'./assets/media/icons/magnify.svg'"></span> -->
                        <input
                          type="text"
                          placeholder="{{
                            'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.WEB_PORTAL_URL.PLACEHOLDER' | translate
                          }}"
                          aria-label="Number"
                          matInput
                          formControlName="ad_web_url"
                        />
                        <mat-error *ngIf="productForm.controls.ad_web_url.hasError('required')">
                          <span
                            [innerHTML]="'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.WEB_PORTAL_URL.ERROR_MSG' | translate"
                          ></span>
                        </mat-error>
                      </mat-form-field>
                      <mat-error *ngIf="errorMsgs['ad_web_url']">
                        {{ errorMsgs["ad_web_url"] }}
                      </mat-error>
                    </div>
                    <div class="col-md-2 kt-margin-bottom-10-mobile">
                      <button
                        mat-button
                        (click)="loadBilbasenAdInfo()"
                        color="primary"
                        cdkFocusInitial
                        style="margin-top: 26px"
                      >
                        {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.WEB_PORTAL_URL.BUTTON" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!viewLoading" class="advertised_vehicles">
            <div class="vehicle-row">
              <div class="kt-form__group">
                <div class="vehicle-inner-row">
                  <div class="row">
                    <div class="col-md-4 kt-margin-bottom-10-mobile">
                      <label class="mat-input-label">{{
                        "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.VEHICLE_MAKE.PLACEHOLDER" | translate
                      }}</label>
                      <mat-form-field
                        class="example-full-width searchable"
                        [ngClass]="{ 'mat-form-field-invalid': errorMsgs['vehicle_make_id'] }"
                      >
                        <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                        <input
                          type="text"
                          placeholder="{{
                            'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.VEHICLE_MAKE.PLACEHOLDER' | translate
                          }}"
                          aria-label="Number"
                          matInput
                          formControlName="vehicle_make"
                          [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                          #auto="matAutocomplete"
                          [displayWith]="displayFn"
                          (optionSelected)="searchForMakeModels(i, true)"
                        >
                          <mat-option *ngFor="let vehicleMake of filteredMakes | async" [value]="vehicleMake">
                            {{ vehicleMake.name }}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="productForm.controls.vehicle_make.hasError('required')">
                          <span
                            [innerHTML]="'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.VEHICLE_MAKE.ERROR_MSG' | translate"
                          ></span>
                        </mat-error>
                      </mat-form-field>
                      <mat-error *ngIf="errorMsgs['vehicle_make_id']">
                        {{ errorMsgs["vehicle_make_id"] }}
                      </mat-error>
                    </div>
                    <div class="col-md-6 kt-margin-bottom-10-mobile">
                      <label class="mat-input-label">{{
                        "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.VEHICLE_MODEL.PLACEHOLDER" | translate
                      }}</label>
                      <mat-form-field
                        class="example-full-width searchable"
                        [ngClass]="{ 'mat-form-field-invalid': errorMsgs['vehicle_model'] }"
                      >
                        <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
                        <input
                          type="text"
                          placeholder="{{
                            'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.VEHICLE_MODEL.PLACEHOLDER' | translate
                          }}"
                          aria-label="Number"
                          matInput
                          formControlName="vehicle_model"
                          [matAutocomplete]="modelAutocomplete"
                        />
                        <mat-autocomplete #modelAutocomplete="matAutocomplete" [displayWith]="modelDisplayFn">
                          <mat-option *ngFor="let vehicleModel of filteredModels | async" [value]="vehicleModel">
                            {{ vehicleModel.model }}
                          </mat-option>
                        </mat-autocomplete>

                        <mat-error *ngIf="productForm.controls.vehicle_model.hasError('required')">
                          <span
                            [innerHTML]="'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.VEHICLE_MODEL.ERROR_MSG' | translate"
                          ></span>
                        </mat-error>
                      </mat-form-field>
                      <mat-error *ngIf="errorMsgs['vehicle_model']">
                        {{ errorMsgs["vehicle_model"] }}
                      </mat-error>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 kt-margin-bottom-10-mobile">
                      <label class="mat-input-label">{{
                        "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER" | translate
                      }}</label>
                      <mat-form-field
                        class="mat-form-field-fluid"
                        [ngClass]="{ 'mat-form-field-invalid': errorMsgs['first_registration_date'] }"
                      >
                        <input
                          matInput
                          [matDatepicker]="firstRegistrationPicker"
                          placeholder="{{ 'VEHICLE.ADD.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER' | translate }}"
                          formControlName="first_registration_date"
                          type="text"
                        />
                        <mat-datepicker-toggle matSuffix [for]="firstRegistrationPicker"> </mat-datepicker-toggle>
                        <mat-datepicker #firstRegistrationPicker></mat-datepicker>
                        <mat-error *ngIf="productForm.controls.first_registration_date.hasError('required')">
                          <span
                            [innerHTML]="'VEHICLE.ADD.FIELDS.FIRST_REGISTERATION_DATE.ERROR_MSG' | translate"
                          ></span>
                        </mat-error>
                      </mat-form-field>
                      <mat-error *ngIf="errorMsgs['first_registration_date']">
                        {{ errorMsgs["first_registration_date"] }}
                      </mat-error>
                    </div>
                    <div class="col-md-4 kt-margin-bottom-10-mobile">
                      <label class="mat-input-label">{{
                        "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.MILEAGE.PLACEHOLDER" | translate
                      }}</label>
                      <mat-form-field
                        no-scroll
                        class="mat-form-field-fluid"
                        [ngClass]="{ 'mat-form-field-invalid': errorMsgs['.mileage'] }"
                      >
                        <input
                          matInput
                          type="number"
                          placeholder="{{ 'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.MILEAGE.PLACEHOLDER' | translate }}"
                          formControlName="mileage"
                        />
                        <mat-error
                          *ngIf="
                            productForm.controls.mileage.hasError('required') ||
                            productForm.controls.mileage.hasError('min')
                          "
                        >
                          <span
                            [innerHTML]="'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.MILEAGE.ERROR_MSG' | translate"
                          ></span>
                        </mat-error>
                      </mat-form-field>
                      <mat-error *ngIf="errorMsgs['mileage']">
                        {{ errorMsgs["mileage"] }}
                      </mat-error>
                    </div>
                    <div class="col-md-4 kt-margin-bottom-10-mobile">
                      <label class="mat-input-label">{{
                        "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.ADVERTISED_PRICE.PLACEHOLDER" | translate
                      }}</label>
                      <mat-form-field
                        class="mat-form-field-fluid"
                        [ngClass]="{ 'mat-form-field-invalid': errorMsgs['advertised_price'] }"
                      >
                        <input
                          matInput
                          currencyMask
                          type="text"
                          placeholder="{{
                            'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.ADVERTISED_PRICE.PLACEHOLDER' | translate
                          }}"
                          formControlName="advertised_price"
                          autocomplete="off"
                        />
                        <mat-error *ngIf="productForm.controls.advertised_price.hasError('required')">
                          <span
                            [innerHTML]="
                              'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.ADVERTISED_PRICE.ERROR_MSG' | translate
                            "
                          ></span>
                        </mat-error>
                      </mat-form-field>
                      <mat-error *ngIf="errorMsgs['advertised_price']">
                        {{ errorMsgs["advertised_price"] }}
                      </mat-error>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 kt-margin-bottom-10-mobile">
                      <label class="mat-input-label">{{
                        "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.NEW_PRICE.PLACEHOLDER" | translate
                      }}</label>
                      <mat-form-field
                        class="mat-form-field-fluid"
                        [ngClass]="{ 'mat-form-field-invalid': errorMsgs['vehicle_new_price'] }"
                      >
                        <input
                          matInput
                          currencyMask
                          type="text"
                          placeholder="{{ 'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.NEW_PRICE.PLACEHOLDER' | translate }}"
                          formControlName="vehicle_new_price"
                          autocomplete="off"
                        />
                        <mat-error
                          *ngIf="
                            productForm.controls.vehicle_new_price.hasError('required') ||
                            productForm.controls.vehicle_new_price.hasError('min')
                          "
                        >
                          <span
                            [innerHTML]="'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.NEW_PRICE.ERROR_MSG' | translate"
                          ></span>
                        </mat-error>
                      </mat-form-field>
                      <mat-error *ngIf="errorMsgs['vehicle_new_price']">
                        {{ errorMsgs["vehicle_new_price"] }}
                      </mat-error>
                    </div>
                    <div class="col-md-4 kt-margin-bottom-10-mobile">
                      <label class="mat-input-label">{{
                        "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.WEB_PORTAL.PLACEHOLDER" | translate
                      }}</label>
                      <mat-form-field
                        class="mat-form-field-fluid"
                        [ngClass]="{ 'mat-form-field-invalid': errorMsgs['ad_web_portal'] }"
                      >
                        <mat-select
                          placeholder="{{
                            'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.WEB_PORTAL.PLACEHOLDER' | translate
                          }}"
                          formControlName="ad_web_portal"
                        >
                          <mat-option [value]="portal.code" *ngFor="let portal of webPortals">
                            {{ portal.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="productForm.controls.ad_web_portal.hasError('required')">
                          <span
                            [innerHTML]="'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.WEB_PORTAL.ERROR_MSG' | translate"
                          ></span>
                        </mat-error>
                      </mat-form-field>
                      <mat-error *ngIf="errorMsgs['ad_web_portal']">
                        {{ errorMsgs["ad_web_portal"] }}
                      </mat-error>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!viewLoading" class="extra_equipment_values py-3">
            <div class="equipment-value" formArrayName="extra_equipment_value">
              <h4 class="kt-portlet__head-title">
                {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.TITLE" | translate }}
              </h4>
              <div
                class="mt-3"
                *ngFor="let equipment of productForm.controls.extra_equipment_value['controls']; let i = index"
              >
                <div class="row" [formGroupName]="i">
                  <div class="col-lg-4 col-sm-5 col-5 kt-margin-bottom-20-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.NAME.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{ 'mat-form-field-invalid': errorMsgs['extra_equipment_value.' + i + '.name'] }"
                    >
                      <input
                        matInput
                        placeholder="{{
                          'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.NAME.PLACEHOLDER' | translate
                        }}"
                        formControlName="name"
                        type="text"
                      />
                      <mat-error
                        *ngIf="
                          productForm.controls.extra_equipment_value.controls[i].controls.name.hasError('required')
                        "
                      >
                        <span
                          [innerHTML]="
                            'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.NAME.ERROR_MSG' | translate
                          "
                        ></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['extra_equipment_value.' + i + '.name']">
                      {{ errorMsgs["extra_equipment_value." + i + ".name"] }}</mat-error
                    >
                  </div>
                  <div class="col-lg-4 col-sm-5 col-5 kt-margin-bottom-20-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.VALUE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{ 'mat-form-field-invalid': errorMsgs['extra_equipment_value.' + i + '.value'] }"
                    >
                      <input
                        matInput
                        currencyMask
                        placeholder="{{
                          'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.VALUE.PLACEHOLDER' | translate
                        }}"
                        formControlName="value"
                        type="text"
                      />
                      <mat-error
                        *ngIf="
                          productForm.controls.extra_equipment_value.controls[i].controls.value.hasError('required')
                        "
                      >
                        <span
                          [innerHTML]="
                            'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.VALUE.ERROR_MSG' | translate
                          "
                        ></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['extra_equipment_value.' + i + '.value']">
                      {{ errorMsgs["extra_equipment_value." + i + ".value"][0] }}</mat-error
                    >
                  </div>
                  <div class="col-lg-4 col-sm-1 col-2 align-self-center">
                    <a *ngIf="i == 0" href="javascript:;" (click)="addNewEquipment()" class="kt-font-primary d-block">
                      <span>
                        <i class="fas fa-plus" style="font-size: 10px"></i>
                        {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.ADD_ANOTHER_EQUIPMENT" | translate }}
                      </span>
                    </a>
                    <a *ngIf="i !== 0" href="javascript:;" (click)="removeEquipment(i)" class="kt-font-danger d-block">
                      <span>
                        <i class="fas fa-minus" style="font-size: 10px"></i>
                        {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.REMOVE_EQUIPMENT" | translate }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!viewLoading" class="vehicle-result-row">
            <div class="row">
              <div class="col-md-12" *ngIf="calculations">
                <div class="row">
                  <div class="col-md-12">
                    <table class="table table-borderless">
                      <tbody>
                        <tr>
                          <td colspan="2">
                            {{ "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.EXTRA_EQUIPMENT_DEDUCTION" | translate }}
                          </td>
                          <td class="text-right">
                            {{ calculations?.extra_equipment_deduction | number : "1.2-2" }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.YEAR_ADJUSTMENT" | translate }}
                          </td>
                          <td class="text-right">
                            {{ calculations?.year_adjustment | number : "1.2-2" }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.ADJ_ADV_PRICE" | translate }}
                          </td>
                          <td class="text-right">
                            {{ calculations?.adjusted_advertised_price | number : "1.2-2" }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.MODEL_ADJUSTMENT" | translate }}
                          </td>
                          <td class="text-right">
                            {{ calculations?.model_adjustment | number : "1.2-2" }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            {{ "VEHICLE_VALUATION.VEHICLE_PRICE.AVERAGE_TABLE.ADJUSTED_PRICE" | translate }}
                          </td>
                          <td class="text-right">
                            {{ calculations?.adjusted_price | number : "1.2-2" }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!viewLoading" class="market-price-documents">
          <div>
            <div class="col-md-12">
              <h5 class="kt-portlet__head-title mx-2 mt-5">
                {{ "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.TITLE" | translate }}
              </h5>
            </div>
            <div class="col-md-9">
              <div class="documents" formArrayName="documents">
                <div
                  class="document-row"
                  *ngFor="let document of productForm.controls.documents.controls; let i = index"
                >
                  <div class="kt-form__group" [formGroupName]="i">
                    <div
                      class="row dropzone-row"
                      [ngClass]="{ uploaded: productForm.controls.documents.controls[i].controls.file_name.value }"
                    >
                      <div *ngIf="productForm.controls.documents.controls[i].controls.file_name.value" class="col-md-6">
                        <div class="uploaded-file-info">
                          <img class="file-icon" src="assets/media/icons/document.png" />
                          <div class="file-info">
                            <h5 class="title">
                              {{ "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.FILE_UPLOADED" | translate }}
                            </h5>
                            <p class="file-name">
                              {{ productForm.controls.documents.controls[i].controls.file_name.value }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        [hidden]="productForm.controls.documents.controls[i].controls.file_name.value"
                        class="col-md-6 kt-margin-bottom-10-mobile"
                      >
                        <div
                          class="dropzone-wrapper dropzone-borderless"
                          [class.mat-form-field-invalid]="errorMsgs['documents.' + i + '.file']"
                        >
                          <dropzone
                            #documentsDropzone
                            (addedFile)="setPhotoFile($event, i)"
                            (removedFile)="setPhotoFile(null, i)"
                            [config]="dropZoneConfig"
                            [message]="dropZoneMessage"
                          >
                          </dropzone>
                        </div>
                        <mat-error
                          *ngIf="
                            productForm.controls.documents.controls[i].controls.file.touched &&
                            productForm.controls.documents.controls[i].controls.file.hasError('required')
                          "
                        >
                          <span
                            [innerHTML]="
                              'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.DROPZONE.ERROR_MSG' | translate
                            "
                          ></span>
                        </mat-error>
                      </div>
                      <div class="col-lg-5 kt-margin-bottom-10-mobile align-self-center">
                        <div class="market-price-document-id">
                          <mat-form-field
                            class="mat-form-field-fluid"
                            [ngClass]="{
                              'mat-form-field-invalid':
                                errorMsgs['documents.' + i + '.vehicle_market_price_document_id']
                            }"
                          >
                            <mat-select placeholder="Choose file" formControlName="vehicle_market_price_document_id">
                              <mat-option value="">
                                {{
                                  "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.MARKET_PRICE_DOCUMENT.PLACEHOLDER"
                                    | translate
                                }}
                              </mat-option>
                              <mat-option
                                [value]="marketPriceDocument.code"
                                *ngFor="let marketPriceDocument of marketPriceDocuments"
                              >
                                {{ marketPriceDocument.name }}</mat-option
                              >
                            </mat-select>
                            <mat-error
                              *ngIf="
                                productForm.controls.documents.controls[
                                  i
                                ].controls.vehicle_market_price_document_id.hasError('required')
                              "
                            >
                              <span
                                [innerHTML]="
                                  'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.MARKET_PRICE_DOCUMENT.ERROR_MSG'
                                    | translate
                                "
                              ></span>
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div
                        *ngIf="productForm.controls.documents.controls[i].controls.file_id.value"
                        class="col-md-1 remove-document align-self-center"
                      >
                        <button
                          (click)="deleteDocument(document.value.file_id, i)"
                          class="text-center d-block m-auto"
                          mat-icon-button
                          color="secondary"
                          type="button"
                          matTooltip="{{
                            'VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.REMOVE_DOCUMENT' | translate
                          }}"
                        >
                          <img src="assets/media/icons/close.png" />
                        </button>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div *ngIf="!productForm.controls.documents.controls[i].controls.file_id.value" class="col-md-12">
                        <a
                          href="javascript:;"
                          *ngIf="productForm.controls.documents.controls[i].controls.file_name.value"
                          (click)="removeDocumentRow(i)"
                          class="kt-font-danger d-block"
                        >
                          <span>
                            <i class="fas fa-minus" style="font-size: 10px"></i>
                            {{ "VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.REMOVE_DOCUMENT" | translate }}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-12 text-center">
      <mat-spinner style="margin: 0 auto; margin-top: 20px" [diameter]="20" *ngIf="viewLoading"></mat-spinner>
    </div>
  </kt-portlet-body>

  <div *ngIf="!viewLoading" class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
    <div class="kt-form__actions kt-form__actions--sm" style="height: 62px !important; padding-top: 13px">
      <button mat-button (click)="dialogRef.close()">{{ "GENERAL.CANCEL" | translate }}</button>&nbsp;
      <button
        mat-button
        (click)="addVehicle()"
        [disabled]="hasFormErrors"
        color="primary"
        cdkFocusInitial
        *ngIf="!isReadonly"
        [disabled]="viewLoading"
      >
        {{ "GENERAL.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>
