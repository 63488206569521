import { Component, OnInit } from "@angular/core";

@Component({
  selector: "kt-my-page",
  templateUrl: "./my-page.component.html",
  styleUrls: ["./my-page.component.scss"],
})
export class MyPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
