import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "kt-registration-fees",
  templateUrl: "./registration-fees.component.html",
  styleUrls: ["./registration-fees.component.scss"],
})
export class RegistrationFeesComponent implements OnInit {
  calculations: any[];
  contract: any;

  constructor(
    public dialogRef: MatDialogRef<RegistrationFeesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.calculations = this.data.calculations;

    this.contract = this.data.contract;
  }
}
