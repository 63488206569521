<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "CONTRACTS.DETAILS.CONTRACT_DETAILS.EXTEND" | translate }}
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>

  <div class="kt-form">
    <div class="kt-portlet__body" *ngIf="product">
      <form [formGroup]="productForm" autocomplete="off" class="kt-form">
        <div class="kt-form__group">
          <!-- CHANGE DATE  -->
          <!-- <div class="col-md-8 kt-margin-bottom-10-mobile"> -->
          <div class="col-md-12 kt-margin-bottom-10-mobile">
            <label style="margin-left: 51px" class="mat-input-label"
              >{{ "CUSTOMERS.ADD.FIELDS.MONTHS_COUNT.PLACEHOLDER" | translate }}
            </label>
            <mat-form-field
              no-scroll
              class="mat-form-field-fluid"
              style="width: 70% !important; margin: 0 auto; margin-bottom: 23px"
            >
              <input
                matInput
                type="number"
                min="1"
                placeholder="{{ 'CUSTOMERS.ADD.FIELDS.MONTHS_COUNT' | translate }}"
                formControlName="months"
              />
              <mat-error *ngIf="productForm?.controls?.months.hasError('required')">
                <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.MONTHS_COUNT.ERROR_MSG' | translate"> </span>
              </mat-error>
            </mat-form-field>
            <mat-error *ngIf="errorMsgs">
              {{ errorMsgs }}
            </mat-error>
          </div>
          <!-- </div> -->
        </div>
      </form>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="dialogRef.close()">
          {{ "GENERAL.CANCEL" | translate }}</button
        >&nbsp;
        <button
          class="btn btn-primary"
          [disabled]="productForm?.invalid"
          color="primary"
          mat-raised-button
          (click)="onSubmit()"
        >
          {{ "GENERAL.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
