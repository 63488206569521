<kt-portlet class="datatable-view">
  <kt-portlet-header [sticky]="true" [class]="'kt-portlet__head--lg'">
    <ng-container ktPortletTitle>
      <h3 class="kt-portlet__head-title">
        {{ "CONTRACTS.DETAILS.MILEAGE.TITLE" | translate }}
      </h3>
    </ng-container>
    <ng-container ktPortletTools>
      <div class="datatable-search">
        <div class="search-wrapper">
          <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
          <input
            #searchInput
            placeholder="{{ 'GENERAL.SEARCH' | translate }}"
            class="form-control"
            style="height: calc(1.5em + 1rem + 2px)"
          />
          <button
            #searchButton
            mat-raised-button
            style="
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            "
            color="primary"
          >
            Søg
          </button>
        </div>
      </div>

      <button
        *ngxPermissionsOnly="['create_mileage']"
        (click)="addProduct()"
        mat-raised-button
        color="primary"
        class="mr-1"
        matTooltip="{{ 'CONTRACTS.DETAILS.MILEAGE.NEW_BUTTON_TOOLTIP' | translate }}"
      >
        {{ "CONTRACTS.DETAILS.MILEAGE.NEW_BUTTON" | translate }}
      </button>
      <button
        *ngxPermissionsOnly="['view_mileages']"
        (click)="getMileageData()"
        class="btn btn-primary mr-1"
        color="primary"
        mat-raised-button
      >
        {{ "CONTRACTS.DETAILS.MILEAGE.GET_MIEAGE" | translate }}
      </button>
      <button
        *ngxPermissionsOnly="['create_adjustment_payments']"
        (click)="createAdjustPayments()"
        class="btn btn-primary"
        color="primary"
        mat-raised-button
      >
        {{ "CONTRACTS.DETAILS.MILEAGE.CREATE_ADJ_PAYMENTS" | translate }}
      </button>
    </ng-container>
  </kt-portlet-header>
  <!-- end::Header -->

  <kt-portlet-body>
    <!-- end: BOTTOM -->
    <div class="mat-table__wrapper datatable-container">
      <div class="datatable-table-container">
        <table
          #table
          mat-table
          [dataSource]="data"
          class="example-table lmat-elevation-z8"
          matSort
          matSortActive="date_from"
          matSortDisableClear
          matSortDirection="asc"
          multiTemplateDataRows
        >
          <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef class="expand-entity-header"></th>
            <td
              mat-cell
              *matCellDef="let row"
              class="expand-entity-info"
              [class.entity-expanded-row]="expandedElement === row"
            >
              <button
                class="expand-info"
                mat-icon-button
                color="primary"
                (click)="expandedElement = expandedElement === row ? null : row"
              >
                <i class="far fa-plus-square"></i>
              </button>
              <button
                mat-icon-button
                class="collapse-info"
                color="warn"
                (click)="expandedElement = expandedElement === row ? null : row"
              >
                <i class="far fa-minus-square"></i>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="date_from">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "CONTRACTS.DETAILS.MILEAGE.FROM_DATE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.date_from | dmyDate }}</td>
          </ng-container>

          <ng-container matColumnDef="date_to">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "CONTRACTS.DETAILS.MILEAGE.TO_DATE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.date_to | dmyDate }}</td>
          </ng-container>

          <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "CONTRACTS.DETAILS.MILEAGE.CUSTOMER" | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{ row.customer?.name }}</td>
          </ng-container>

          <ng-container matColumnDef="mileage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="responsive">
              {{ "CONTRACTS.DETAILS.MILEAGE.MILEAGE" | translate }}
            </th>
            <td mat-cell *matCellDef="let row" class="responsive">{{ row.mileage | number : "2." }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef width="10%"></th>
            <td mat-cell *matCellDef="let row" width="10%">
              <div *ngIf="row.is_manual_entry">
                <kt-actions-menu *ngxPermissionsOnly="['update_mileage', 'delete_mileage']">
                  <li *ngxPermissionsOnly="['update_mileage']" (click)="editProduct(row)" class="kt-nav__item">
                    <a href="javascript:;" class="kt-nav__link">
                      <span [inlineSVG]="'./assets/media/icons/edit.svg'"></span>
                      <span class="kt-nav__link-text">
                        {{ "GENERAL.EDIT" | translate }}
                      </span>
                    </a>
                  </li>
                  <li *ngxPermissionsOnly="['delete_mileage']" (click)="deleteMileage(row.id)" class="kt-nav__item">
                    <a href="javascript:;" class="kt-nav__link">
                      <span [inlineSVG]="'./assets/media/icons/trash.svg'"></span>
                      <span class="kt-nav__link-text">
                        {{ "GENERAL.DELETE" | translate }}
                      </span>
                    </a>
                  </li>
                </kt-actions-menu>
              </div>
            </td>
          </ng-container>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div
                class="entity-details-info-column"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
              >
                <div class="entity-details-info-column-data">
                  <div class="entity-details-info-column-data-key">
                    {{ "VEHICLE_MILEAGE.VIEW.TABLE_COLUMNS.VALUE" | translate }}
                  </div>
                  <div class="entity-details-info-column-data-value">
                    {{ element.mileage | number : "1.2-2" }}
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="entity-default-row"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="entity-details-info-row"></tr>
        </table>
        <div *ngIf="data.length == 0" class="d-flex justify-content-center">
          <p class="m-3">{{ "GENERAL.NO_RESULTS_FOUND" | translate }}</p>
        </div>
        <div class="mat-table__message" *ngIf="isRateLimitReached">
          {{ "GENERAL.SOMETHING_WENT_WRONG" | translate }}
        </div>
        <div class="mat-table__message" *ngIf="isLoadingResults">{{ "GENERAL.PLEASE_WAIT" | translate }}</div>
      </div>

      <!-- start: BOTTOM -->
      <div class="mat-table__bottom">
        <mat-spinner [diameter]="20" *ngIf="isLoadingResults"></mat-spinner>
        <mat-paginator appStylePaginator [length]="resultsLength" showFirstLastButtons></mat-paginator>
      </div>
      <!-- end: BOTTOM -->
    </div>
  </kt-portlet-body>
  <!-- end::Body -->
</kt-portlet>
