// Angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PartialsModule } from "../../partials/partials.module";
import { NgxPermissionsModule } from "ngx-permissions";
// Components
import { AmortizationListComponent } from "./amortizations-list.component";
import { AmortizationsService } from "./amortizations.service";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [AmortizationListComponent],
  imports: [CommonModule, PartialsModule, NgxPermissionsModule.forChild(), DragDropModule],
  providers: [AmortizationsService],
})
export class AmortizationsModule {}
