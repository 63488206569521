export class PermissionModel {
  id: number;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;

  clear() {
    this.title = "";
    this.description = "";
    this.created_at = "";
    this.updated_at = null;
  }
}
