// Angular
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS
import { Subscription } from "rxjs";
// Layout
// CRUD
import { LayoutUtilsService, MessageType, TypesUtilsService } from "../../../core/_base/crud";
import { SharedService } from "../shared.service";
import { InvoiceModel } from "./invoice.model";
import { TranslateService } from "@ngx-translate/core";
import { PaymentConstants } from "../payments/payment.constants";
import { PaymentTermsTypes } from "../enums/payments/paymentTermsTypes";

@Component({
  selector: "kt-invoice-create-dialog",
  templateUrl: "./invoice-create-dialog.component.html",
})
export class InvoiceCreateDialogComponent implements OnInit, OnDestroy {
  // Public properties
  product: InvoiceModel;
  productForm: FormGroup;
  hasFormErrors: boolean = false;
  paymentTerms: any[];
  paymentTermsEnum = PaymentTermsTypes;
  errorMsgs: any = {}; //Back-end errors
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;

  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private productsService: SharedService,
    private cdr: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    public dialogRef: MatDialogRef<InvoiceCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.permissionsService.permissions$.subscribe((res) => {
      if (this.data.payload) {
        let product = {
          ...this.data.payload,
          invoice_date: this.data.payload.invoice_date
            ? this.typesUtilsService.getDateFromString(this.data.payload.invoice_date)
            : "",
          payments_up_to_date: this.data.payload.payments_up_to_date
            ? this.typesUtilsService.getDateFromString(this.data.payload.payments_up_to_date)
            : "",
          payment_due_date: this.data.payload.payment_due_date
            ? this.typesUtilsService.getDateFromString(this.data.payload.payment_due_date)
            : "",
          payment_term: {
            id: this.data.payload.payment_term_id,
            entity_object: {
              paymentTermsType: this.data.payload.payment_due_date ? PaymentTermsTypes.DUE_DATE : null,
            },
          },
        };
        this.loadProduct(product);
      } else {
        const newProduct = new InvoiceModel();
        newProduct.clear();
        this.loadProduct(newProduct);
      }
    });

    const paymentTermsSubscription = this.productsService.getLookups("payment_terms").subscribe((res) => {
      this.paymentTerms = res.data;
    });
    this.componentSubscriptions.push(paymentTermsSubscription);

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  initProduct() {
    this.createForm();
  }

  createForm() {
    console.log(this.data);
    if (this.data.contract) {
      const contract = this.data.contract;
      const vehicle_make = contract.asset_details?.make?.name ? contract.asset_details?.make?.name : "";
      const vehicle_model = contract.asset_details?.model?.model ? contract.asset_details?.model?.model : "";
      const vehicle_trim = contract.asset_details?.trim ? contract.asset_details?.trim : "";
      const vin_number = contract.asset_details?.vin_number ? contract.asset_details?.vin_number : "";

      this.product.notes_text_1 =
        "Køretøj:  " +
        vehicle_make +
        " " +
        vehicle_model +
        " " +
        vehicle_trim +
        ", Stelnummer: " +
        vin_number +
        ", Kontraktnr.: " +
        contract.number;
    }

    if (this.data.paymentType == PaymentConstants.VEHICLE_PAYMENT_TYPE) {
      this.product.notes_text_1 = `Køretøj: ${this.data.asset?.make?.name || ""} ${
        this.data.asset?.model?.model || ""
      } ${this.data.asset?.details?.trim || ""}, Stelnummer: ${this.data.asset?.model?.model || ""} ${
        this.data.asset?.vin_number || ""
      }`;
    }

    let formObject: Object = {
      contract_id: [this.data.contract_id],
      invoice_date: [this.product.invoice_date, Validators.required],
      payments_up_to_date: [this.product.payments_up_to_date, Validators.required],
      notes_headline: [this.product.notes_headline],
      notes_text_1: [this.product.notes_text_1],
      payment_term: [this.product.payment_term, Validators.required],
      payment_due_date: [this.product.payment_due_date],
    };

    if (this.data.paymentType == PaymentConstants.VEHICLE_PAYMENT_TYPE) {
      formObject["vehicle_id"] = [this.data?.id];
    }

    this.productForm = this.productFB.group(formObject);
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.viewLoading = true;
    const controls = this.productForm.controls;
    /** check form */
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      this.hasFormErrors = true;
      this.viewLoading = false;
      return;
    }

    let payload: any = {};

    if (this.data.paymentType == PaymentConstants.VEHICLE_PAYMENT_TYPE)
      payload.vehicle_id = +controls["vehicle_id"].value;
    else payload.contract_id = +controls["contract_id"].value;

    if (controls["invoice_date"] && controls["invoice_date"].value) {
      payload.invoice_date = this.typesUtilsService.formatDate(controls["invoice_date"].value, "dd-MM-yyyy");
    }
    if (controls["payments_up_to_date"] && controls["payments_up_to_date"].value) {
      payload.payments_up_to_date = this.typesUtilsService.formatDate(
        controls["payments_up_to_date"].value,
        "dd-MM-yyyy"
      );
    }
    if (
      controls["payment_due_date"].value &&
      controls["payment_term"].value?.entity_object?.paymentTermsType == PaymentTermsTypes.DUE_DATE
    ) {
      payload.payment_due_date = this.typesUtilsService.formatDate(controls["payment_due_date"].value, "dd-MM-yyyy");
    }
    if (controls["notes_headline"].value) {
      payload.notes_headline = controls["notes_headline"].value;
    }
    if (controls["notes_text_1"].value) {
      payload.notes_text_1 = controls["notes_text_1"].value;
    }
    payload.payment_term_id = controls["payment_term"].value ? controls["payment_term"].value.id : "";

    // if (this.id > 0) {
    // 	this.updateProduct(payload);
    // 	return;
    // }

    this.addProduct(payload);
  }

  addProduct(payload) {
    let createProductSubscription: Subscription;

    if (this.data.paymentType == PaymentConstants.VEHICLE_PAYMENT_TYPE) {
      createProductSubscription = this.productsService.prepareVehicleInvoice(payload).subscribe(
        (res) => {
          this.viewLoading = false;
          this.dialogRef.close({
            invoice: res.data,
            payload,
          });
        },
        (err) => {
          this.viewLoading = false;
          if (err.error) {
            if (err.error.data) {
              this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
            }
            this.errorMsgs = err.error.errors || {};
            this.cdr.markForCheck();
            this.hasFormErrors = true;
          }
        }
      );
    } else {
      createProductSubscription = this.productsService.prepareContractInvoice(payload).subscribe(
        (res) => {
          this.viewLoading = false;
          // if(res.data){
          // 	this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Create, 10000, true, false);
          // }
          this.dialogRef.close({
            invoice: res.data,
            payload,
          });
        },
        (err) => {
          this.viewLoading = false;
          if (err.error) {
            if (err.error.data) {
              this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
            }
            this.errorMsgs = err.error.errors || {};
            this.cdr.markForCheck();
            this.hasFormErrors = true;
          }
        }
      );
    }
    this.componentSubscriptions.push(createProductSubscription);
  }

  getComponentTitle() {
    let result = this.translate.instant("INVOICES.ADD.PREPARE_TITLE");
    if (!this.product || !this.product.id) {
      return result;
    }

    result = `${this.translate.instant("INVOICES.ADD.EDIT_TITLE")} - ${this.product.notes_headline}`;
    return result;
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
