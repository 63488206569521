export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: "Dashboards",
          root: true,
          alignment: "left",
          page: "/dashboard",
          translate: "MENU.DASHBOARD",
        },
        {
          title: "Applications",
          root: true,
          alignment: "left",
          toggle: "click",
          translate: "MENU.APPLICATIONS",
          submenu: [
            {
              title: "User Management",
              bullet: "dot",
              icon: "flaticon-user",
              translate: "MENU.USER_MANAGEMENT.TITLE",
              submenu: [
                {
                  title: "Users",
                  page: "users",
                  permission: ["view_users"],
                  translate: "MENU.USER_MANAGEMENT.USERS",
                },
                {
                  title: "Roles",
                  page: "/user-management/roles",
                  permission: ["view_roles"],
                  translate: "MENU.USER_MANAGEMENT.ROLES",
                },
              ],
            },
          ],
        },
        {
          title: "Custom",
          root: true,
          alignment: "left",
          toggle: "click",
          submenu: [
            {
              title: "Error Pages",
              bullet: "dot",
              icon: "flaticon2-list-2",
              submenu: [
                {
                  title: "Error 1",
                  page: "/error/error-v1",
                },
                {
                  title: "Error 2",
                  page: "/error/error-v2",
                },
                {
                  title: "Error 6",
                  page: "/error/error-v6",
                },
              ],
            },
            {
              title: "Wizard",
              bullet: "dot",
              icon: "flaticon2-mail-1",
              submenu: [
                {
                  title: "Wizard 1",
                  page: "/wizard/wizard-1",
                },
                {
                  title: "Wizard 2",
                  page: "/wizard/wizard-2",
                },
              ],
            },
          ],
        },
      ],
    },
    aside: {
      self: {},
      items: [
        // {
        // 	title: 'Overview',
        // 	root: true,
        // 	//icon: 'fas fa-th-large',
        // 	svg: 'overview',
        // 	page: '/dashboard',
        // 	translate: 'MENU.OVERVIEW',
        // 	bullet: 'dot',
        // },
        // {
        // 	title: 'Actions',
        // 	root: true,
        // 	//icon: 'fas fa-layer-group',
        // 	svg:'actions',
        // 	page: '/actions',
        // 	translate: 'MENU.ACTIONS',
        // 	bullet: 'dot',
        // },
        {
          title: "Tasks",
          root: true,
          icon: "far fa-check-square",
          // svg: 'tasks',
          page: "/tasks",
          translate: "MENU.TASKS",
          bullet: "dot",
        },
        {
          title: "Lists",
          translate: "MENU.LISTS",
          root: true,
          bullet: "dot",
          svg: "lists",
          // permission: ['view_customers', 'view_vehicles', 'view_contracts'],
          submenu: [
            {
              title: "Customers",
              //icon: 'fas fa-users',
              page: "/customers",
              translate: "MENU.CUSTOMERS",
              //bullet: 'dot',
              permission: ["view_customers"],
            },
            {
              title: "Vehicles",
              page: "/vehicles",
              translate: "MENU.VEHICLES_MANAGEMENT.VEHICLES",
              permission: ["view_vehicles"],
            },
            {
              title: "Contracts",
              page: "/contracts",
              translate: "MENU.CONTRACTS",
              permission: ["view_contracts", "contract_dealership_view", "contract_dealership_update"],
            },
            {
              title: "Offers",
              page: "/offers",
              translate: "MENU.OFFERS",
              //permission:['view_offers'],
            },
          ],
        },
        {
          title: "Accounting",
          root: true,
          // svg: 'settings',
          icon: "fas fa-money-check-alt",
          translate: "MENU.ACCOUNTING",
          bullet: "dot",
          permission: ["view_payments", "view_transactions"],
          submenu: [
            {
              title: "All Payments",
              page: "/contracts/payments",
              translate: "MENU.ALL_PAYMENTS",
              permission: ["view_payments"],
            },
            {
              title: "All Transactions",
              page: "/contracts/transactions",
              translate: "MENU.ALL_TRANSACTIONS",
              permission: ["view_transactions"],
            },
          ],
        },
        {
          title: "Settings",
          root: true,
          svg: "settings",
          translate: "MENU.SETTINGS.TITLE",
          bullet: "dot",
          permission: [
            "view_settings",
            "view_templates",
            "view_users",
            "view_roles",
            "view_permissions",
            "view_dealerships",
            "view_makes",
            "view_products",
            "view_suppliers",
            "view_funding_rates",
          ],
          submenu: [
            {
              title: "Administration",
              page: "/settings/administration",
              translate: "MENU.SETTINGS.ADMINSTRATION",
              //bullet: 'dot',
              permission: [
                "view_users",
                "view_templates",
                "view_roles",
                "view_permissions",
                "view_dealerships",
                "view_makes",
                "view_products",
                "view_suppliers",
                "view_funding_rates",
              ],
            },
            {
              title: "Variables",
              page: "/settings/variables",
              translate: "MENU.SETTINGS.VARIABLES",
              //bullet: 'dot',
              permission: ["view_settings"],
            },
          ],
        },
        {
          title: "Patch note",
          root: true,
          icon: "fas fa-scroll",
          page: "/patchNote",
          translate: "MENU.PATCH_NOTE",
          bullet: "dot",
        },
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
