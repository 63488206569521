import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
// RxJS
import { Observable } from "rxjs";
// CRUD
import { SharedService } from "../shared.service";
import { TranslateService } from "@ngx-translate/core";
import { ExtendDateModel } from "./extend-date.model";
import { LayoutUtilsService, TypesUtilsService, MessageType } from "../../../core/_base/crud";
@Component({
  selector: "kt-extend-date",
  templateUrl: "./extend-date.component.html",
  styleUrls: ["./extend-date.component.scss"],
})
export class ExtendDateComponent implements OnInit {
  public product: any;
  public productForm: FormGroup;
  public hasFormErrors: boolean;
  public entity: any;
  public entityType: any;
  constructor(
    private productFB: FormBuilder,
    private productsService: SharedService,
    public dialogRef: MatDialogRef<ExtendDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit(): void {
    this.entity = this.data.entity;
    this.entityType = this.data.entityType;
    this.loadProduct();
    this.createForm();
  }

  public loadProduct() {
    this.product = new ExtendDateModel().clear();
  }

  public createForm() {
    this.productForm = this.productFB.group({
      months: [1, [Validators.required, Validators.min(1)]],
    });
  }
  public onSubmit() {
    this.hasFormErrors = false;
    this.changeEntityDate();
  }

  public changeEntityDate() {
    this.productsService.extendDate(this.entityType, this.entity.id, this.productForm.value).subscribe(
      (res) => {
        this.dialogRef.close({ res });
      },
      (err) => {
        this.layoutUtilsService.showActionNotification(
          err?.error?.errors?.months
            ? err?.error?.errors?.months[0]
            : err?.error?.data?.message || err?.error?.errors?.new_customer[0],
          MessageType.Update
        );
      }
    );
  }

  getComponentTitle() {
    return this.translate.instant("CONTRACTS.DETAILS.CONTRACT_DETAILS.EXNTEND");
  }
}
