// Angular
import { Component, Input, OnInit } from "@angular/core";

export interface ShortInfoData {
  title: string;
  percentage: string;
  isIncreased: boolean;
  value: string;
  valueClass?: string;
}

@Component({
  selector: "short-info-widget",
  templateUrl: "./short-info.component.html",
  styleUrls: ["./short-info.component.scss"],
})
export class ShortInfoComponent implements OnInit {
  // Public properties
  @Input() data: ShortInfoData;

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    if (!this.data) {
      this.data = {
        title: "ACTIVE CONTRACT",
        percentage: "75%",
        isIncreased: true,
        value: "103",
        valueClass: "kt-font-brand",
      };
    }
  }
}
