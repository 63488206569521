<div
  ngbDropdown
  [placement]="'bottom-right'"
  [ngClass]="{ 'actions-menu-in-thead': inTableHeader }"
  class="actions-menu dropdown dropdown-inline"
>
  <button ngbDropdownToggle type="button" class="btn btn-clean btn-icon btn-icon-md mx-3">
    <span [inlineSVG]="'./assets/media/icons/dots.svg'"></span>
  </button>
  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-top-rounded"
    [ngClass]="{ 'dropdown-menu-in-thead': inTableHeader }"
  >
    <ul class="kt-nav">
      <ng-content></ng-content>
    </ul>
  </div>
</div>
