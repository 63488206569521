<div class="kt-portlet__body" *ngIf="product">
  <form [formGroup]="productForm" autocomplete="off" class="kt-form">
    <div class="kt-form__group">
      <div class="row">
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "PAYMENTS.ADD.FIELDS.PRODUCT.PLACEHOLDER" | translate }}</label>
          <mat-form-field
            class="example-full-width searchable"
            [ngClass]="{
              'mat-form-field-invalid': errorMsgs['product_id']
            }"
          >
            <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
            <input
              type="text"
              placeholder="{{ 'PAYMENTS.ADD.FIELDS.PRODUCT.PLACEHOLDER' | translate }}"
              aria-label="Number"
              matInput
              formControlName="product"
              [matAutocomplete]="productAutoComplete"
            />
            <mat-autocomplete #productAutoComplete="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
                {{ product.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="productForm.controls.product.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.PRODUCT.ERROR_MSG' | translate"></span>
            </mat-error>
            <!-- <mat-hint align="start">
                  <span [innerHTML]="'PAYMENTS.ADD.FIELDS.PRODUCT.HINT_PLACEHOLDER' | translate"></span>
                </mat-hint> -->
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['product_id']">{{ errorMsgs["product_id"][0] }}</mat-error>
        </div>
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "PAYMENTS.ADD.FIELDS.DATE.PLACEHOLDER" | translate }}</label>
          <mat-form-field
            class="mat-form-field-fluid"
            [ngClass]="{
              'mat-form-field-invalid': errorMsgs['date']
            }"
          >
            <input
              matInput
              [matDatepicker]="datePicker"
              placeholder="{{ 'PAYMENTS.ADD.FIELDS.DATE.PLACEHOLDER' | translate }}"
              formControlName="date"
              type="text"
            />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="productForm.controls.date.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.DATE.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['date']">{{ errorMsgs["date"][0] }}</mat-error>
        </div>
      </div>
      <div *ngIf="productForm.controls.product?.value?.has_date_interval" class="row">
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "PAYMENTS.ADD.FIELDS.FROM.PLACEHOLDER" | translate }}</label>
          <mat-form-field
            class="mat-form-field-fluid"
            [ngClass]="{
              'mat-form-field-invalid': errorMsgs['from_date']
            }"
          >
            <input
              matInput
              [matDatepicker]="fromPicker"
              [max]="productForm.controls.to_date.value"
              placeholder="{{ 'PAYMENTS.ADD.FIELDS.FROM.PLACEHOLDER' | translate }}"
              formControlName="from_date"
              type="text"
            />
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
            <mat-error *ngIf="productForm.controls.from_date.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.FROM.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['from_date']">{{ errorMsgs["from_date"][0] }}</mat-error>
        </div>
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "PAYMENTS.ADD.FIELDS.TO.PLACEHOLDER" | translate }}</label>
          <mat-form-field
            class="mat-form-field-fluid"
            [ngClass]="{
              'mat-form-field-invalid': errorMsgs['to_date']
            }"
          >
            <input
              matInput
              [matDatepicker]="toPicker"
              [min]="productForm.controls.from_date.value"
              placeholder="{{ 'PAYMENTS.ADD.FIELDS.TO.PLACEHOLDER' | translate }}"
              formControlName="to_date"
              type="text"
            />
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
            <mat-error *ngIf="productForm.controls.to_date.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.TO.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['to_date']">{{ errorMsgs["to_date"][0] }}</mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{
            "PAYMENTS.ADD.FIELDS.PRICE_EXCEL_VAT_CENTS.PLACEHOLDER" | translate
          }}</label>
          <mat-form-field
            class="mat-form-field-fluid"
            [ngClass]="{
              'mat-form-field-invalid': errorMsgs['price_excl_vat_cents']
            }"
          >
            <input
              matInput
              currencyMask
              type="text"
              placeholder="{{ 'PAYMENTS.ADD.FIELDS.PRICE_EXCEL_VAT_CENTS.PLACEHOLDER' | translate }}"
              formControlName="price_excl_vat_cents"
              autocomplete="off"
            />
            <mat-error *ngIf="productForm.controls.price_excl_vat_cents.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.PRICE_EXCEL_VAT_CENTS.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['price_excl_vat_cents']"> {{ errorMsgs["price_excl_vat_cents"][0] }}</mat-error>
        </div>
        <div
          class="col-md-6 kt-margin-bottom-10-mobile"
          *ngIf="
            (productForm.controls.product?.value?.code == 'leasing_payment' ||
              productForm.controls.product?.value?.code == 'down_payment') &&
            (contract_type == entityTypes.SPLIT_LEASING || contract_type == entityTypes.CORPORATE) &&
            contract_period >= 6
          "
        >
          <label class="mat-input-label">{{
            "PAYMENTS.ADD.FIELDS.VAT_DEDUCTION_CORPORATE_CENTS.PLACEHOLDER" | translate
          }}</label>
          <mat-form-field
            class="mat-form-field-fluid"
            [ngClass]="{
              'mat-form-field-invalid': errorMsgs['vat_deduction_corporate_cents']
            }"
          >
            <input
              matInput
              currencyMask
              type="text"
              placeholder="{{ 'PAYMENTS.ADD.FIELDS.VAT_DEDUCTION_CORPORATE_CENTS.PLACEHOLDER' | translate }}"
              formControlName="vat_deduction_corporate_cents"
              autocomplete="off"
            />
            <mat-error *ngIf="productForm.controls.vat_deduction_corporate_cents.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.VAT_DEDUCTION_CORPORATE_CENTS.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['vat_deduction_corporate_cents']">
            {{ errorMsgs["vat_deduction_corporate_cents"][0] }}</mat-error
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "CONTRACTS.ADD.FIELDS.CUSTOMER.CUSTOMER" | translate }}</label>
          <mat-form-field class="example-full-width searchable">
            <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
            <input
              type="text"
              aria-label="Number"
              matInput
              formControlName="privateCustomer"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCustomerFn">
              <mat-option *ngFor="let prvCustomer of privateCustomers | async" [value]="prvCustomer">
                {{ prvCustomer.id }} - {{ prvCustomer.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="productForm.controls.privateCustomer.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.CUSTOMER.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['customer_id']">{{ errorMsgs["customer_id"][0] }}</mat-error>
          <a href="javascript:;" class="kt-font-primary d-block" (click)="onAddNewCustomer()">
            <span>
              <i class="fas fa-plus" style="font-size: 10px"></i>
              {{ "CUSTOMERS.VIEW.NEW_BUTTON_TOOLTIP" | translate }}
            </span>
          </a>
        </div>
        <div *ngIf="contract_type == entityTypes.SPLIT_LEASING" class="col-md-6 kt-margin-bottom-10-mobile">
          <label class="mat-input-label">{{ "CONTRACTS.ADD.FIELDS.CUSTOMER.COMPANY_CUSTOMER" | translate }}</label>
          <mat-form-field class="example-full-width searchable">
            <span class="kt-svg-icon search-icon" [inlineSVG]="'./assets/media/icons/magnify.svg'"></span>
            <input
              type="text"
              aria-label="Number"
              matInput
              formControlName="corporateCustomer"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCustomerFn">
              <mat-option *ngFor="let crpCustomer of corporateCustomers | async" [value]="crpCustomer">
                {{ crpCustomer.id }} - {{ crpCustomer.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="productForm.controls.corporateCustomer.hasError('required')">
              <span [innerHTML]="'PAYMENTS.ADD.FIELDS.CUSTOMER.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="errorMsgs['customer_id']">{{ errorMsgs["customer_id"][0] }}</mat-error>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
  <div class="kt-form__actions kt-form__actions--sm">
    <button mat-button (click)="dialogRef.close()">
      {{ "GENERAL.CANCEL" | translate }}</button
    >&nbsp;
    <button mat-button (click)="onSubmit()" color="primary" cdkFocusInitial [disabled]="viewLoading">
      {{ "GENERAL.SAVE" | translate }}
    </button>
  </div>
</div>
