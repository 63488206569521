export enum InputTypesEnum {
  STRING = "string",
  NUMBER = "number",
  YEAR = "year",
  DATE = "date",
  EMAIL = "email",
  DROPDOWN = "dropdown",
}

export const paramLabelsMapper = new Map<string, string>([
  ["year", "SETTINGS.JOBS.FIELDS.YEAR"],
  ["accounting_year", "SETTINGS.JOBS.FIELDS.YEAR"],
]);
