<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ getComponentTitle() }}</h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body" *ngIf="product">
      <form [formGroup]="productForm" autocomplete="off" class="kt-form">
        <div class="kt-form__group">
          <div class="row">
            <div class="col-md-6 kt-margin-bottom-10-mobile">
              <label class="mat-input-label">{{ "INVOICES.ADD.FIELDS.DATE.PLACEHOLDER" | translate }}</label>
              <mat-form-field class="mat-form-field-fluid" [ngClass]="{ 'mat-form-field-invalid': errorMsgs['date'] }">
                <input
                  matInput
                  [matDatepicker]="datePicker"
                  placeholder="{{ 'INVOICES.ADD.FIELDS.DATE.PLACEHOLDER' | translate }}"
                  formControlName="date"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
                <mat-error *ngIf="productForm.controls.date.hasError('required')">
                  <span [innerHTML]="'INVOICES.ADD.FIELDS.DATE.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['date']">{{ errorMsgs["date"][0] }}</mat-error>
            </div>
            <div class="col-md-6 kt-margin-bottom-10-mobile">
              <label class="mat-input-label">{{ "INVOICES.ADD.FIELDS.NOTES_HEADLINE.PLACEHOLDER" | translate }}</label>
              <mat-form-field
                class="mat-form-field-fluid"
                [ngClass]="{
                  'mat-form-field-invalid': errorMsgs['notes_headline']
                }"
              >
                <input
                  matInput
                  type="text"
                  placeholder="{{ 'INVOICES.ADD.FIELDS.NOTES_HEADLINE.PLACEHOLDER' | translate }}"
                  formControlName="notes_headline"
                  autocomplete="off"
                />
                <mat-error *ngIf="productForm.controls.notes_headline.hasError('required')">
                  <span [innerHTML]="'INVOICES.ADD.FIELDS.NOTES_HEADLINE.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['notes_headline']">{{ errorMsgs["notes_headline"][0] }}</mat-error>
            </div>
          </div>

          <!-- <div class="row margin-top">
						<div class="col-md-6 kt-margin-bottom-10-mobile">
							  <mat-checkbox class="mat-form-field-fluid" formControlName="checked" [checked]="checked" (change)="checkBulkByMonth()" >{{'INVOICES.ADD.FIELDS.BULK_BY_MONTH.PLACEHOLDER' | translate}}</mat-checkbox>
						</div>
					</div> -->

          <div class="row margin-top" *ngIf="bulk_by_month">
            <div class="col-md-6 kt-margin-bottom-10-mobile">
              <!-- <mat-form-field class="mat-form-field-fluid" [ngClass]="{'mat-form-field-invalid' : errorMsgs['month']}">
								<input matInput [matDatepicker]="duedatePicker" placeholder="{{'INVOICES.ADD.FIELDS.PAYMENT_DUEDATE.PLACEHOLDER' | translate}}"  formControlName="month" type="text">
								<mat-datepicker-toggle matSuffix [for]="duedatePicker"></mat-datepicker-toggle>
								<mat-datepicker startView="year" #duedatePicker (monthSelected)="monthSelectedHandler($event)"></mat-datepicker>
								<mat-error *ngIf="productForm.controls.month.hasError('required')">
									<span [innerHTML]="'INVOICES.ADD.FIELDS.PAYMENT_DUEDATE.ERROR_MSG' | translate"></span>
								</mat-error>
							</mat-form-field> -->
              <label class="mat-input-label">{{ "INVOICES.ADD.FIELDS.MONTH.PLACEHOLDER" | translate }}</label>
              <mat-form-field class="mat-form-field-fluid" [ngClass]="{ 'mat-form-field-invalid': errorMsgs['month'] }">
                <mat-select
                  placeholder="{{ 'INVOICES.ADD.FIELDS.MONTH.PLACEHOLDER' | translate }}"
                  formControlName="month"
                >
                  <mat-option [value]="month" *ngFor="let month of months">{{ month }}</mat-option>
                </mat-select>
                <mat-error *ngIf="productForm.controls.month.hasError('required')">
                  <span [innerHTML]="'INVOICES.ADD.FIELDS.MONTH.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['month']">{{ errorMsgs["month"][0] }}</mat-error>
            </div>
          </div>

          <div class="row margin-top" *ngIf="!bulk_by_month">
            <div class="col-md-6 kt-margin-bottom-10-mobile">
              <label class="mat-input-label">{{ "INVOICES.ADD.FIELDS.PAYMENTS_UP_TO.PLACEHOLDER" | translate }}</label>
              <mat-form-field
                class="mat-form-field-fluid"
                [ngClass]="{
                  'mat-form-field-invalid': errorMsgs['payments_date']
                }"
              >
                <input
                  matInput
                  [matDatepicker]="datePicker"
                  placeholder="{{ 'INVOICES.ADD.FIELDS.PAYMENTS_UP_TO.PLACEHOLDER' | translate }}"
                  formControlName="payments_date"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
                <mat-error *ngIf="productForm.controls.payments_date.hasError('required')">
                  <span [innerHTML]="'INVOICES.ADD.FIELDS.PAYMENTS_UP_TO.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['payments_date']">{{ errorMsgs["payments_date"][0] }}</mat-error>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 kt-margin-bottom-10-mobile">
              <label class="mat-input-label">{{ "INVOICES.ADD.FIELDS.PAYMENT_TERM.PLACEHOLDER" | translate }}</label>
              <mat-form-field
                class="mat-form-field-fluid"
                [ngClass]="{
                  'mat-form-field-invalid': errorMsgs['payment_term_id']
                }"
              >
                <mat-select
                  (selectionChange)="onBookChange($event)"
                  placeholder="{{ 'INVOICES.ADD.FIELDS.PAYMENT_TERM.PLACEHOLDER' | translate }}"
                  formControlName="payment_term_id"
                >
                  <mat-option [value]="paymentTerm" *ngFor="let paymentTerm of paymentTerms">{{
                    paymentTerm.name
                  }}</mat-option>
                </mat-select>
                <mat-error *ngIf="productForm.controls.payment_term_id.hasError('required')">
                  <span [innerHTML]="'INVOICES.ADD.FIELDS.PAYMENT_TERM.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['payment_term_id']">{{ errorMsgs["payment_term_id"][0] }}</mat-error>
            </div>

            <div
              *ngIf="
                productForm.controls.payment_term_id.value?.entity_object?.paymentTermsType ==
                paymentTermsEnums.DUE_DATE
              "
              class="col-md-6 kt-margin-bottom-10-mobile"
            >
              <label class="mat-input-label">{{ "INVOICES.ADD.FIELDS.PAYMENT_DUEDATE.PLACEHOLDER" | translate }}</label>
              <mat-form-field
                class="mat-form-field-fluid"
                [ngClass]="{
                  'mat-form-field-invalid': errorMsgs['payment_due_date']
                }"
              >
                <input
                  matInput
                  [matDatepicker]="duedatePicker"
                  [min]="productForm.controls.date.value"
                  placeholder="{{ 'INVOICES.ADD.FIELDS.PAYMENT_DUEDATE.PLACEHOLDER' | translate }}"
                  formControlName="payment_due_date"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="duedatePicker"></mat-datepicker-toggle>
                <mat-datepicker #duedatePicker></mat-datepicker>
                <mat-error *ngIf="productForm.controls.payment_due_date.hasError('required')">
                  <span [innerHTML]="'INVOICES.ADD.FIELDS.PAYMENT_DUEDATE.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['payment_due_date']">{{ errorMsgs["payment_due_date"][0] }}</mat-error>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="dialogRef.close()">
          {{ "GENERAL.CANCEL" | translate }}</button
        >&nbsp;
        <button mat-button (click)="onSubmit()" color="primary" cdkFocusInitial [disabled]="viewLoading">
          {{ "GENERAL.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
