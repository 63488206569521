// Anglar
import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
// Layout Directives
// Services
import {
  ContentAnimateDirective,
  FirstLetterPipe,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  ScrollTopDirective,
  SparklineChartDirective,
  StickyDirective,
  TabClickEventDirective,
  TimeElapsedPipe,
  ToggleDirective,
  TabDirective,
  DMYDatePipe,
} from "./_base/layout/index";
import { ContractsService } from "../views/pages/contracts";
import { NoScrollDirective } from "./_base/layout/directives/no-scroll.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    TabDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    FirstLetterPipe,
    NoScrollDirective,
  ],
  exports: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    TabDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    FirstLetterPipe,
    NoScrollDirective,
  ],
  providers: [DatePipe, DMYDatePipe, ContractsService, NoScrollDirective],
})
export class CoreModule {}
