export enum LookupCategories {
  CUSTOMER_TYPES = "customer_types",
  OFFER_TYPE = "offer_types",
  CONTRACT_STATUSES = "contract_statuses",
  VAT_ZONES = "vat_zones",
  PAYMENT_TERMS = "payment_terms",
  VEHICLE_TYPES = "vehicle_types",
  FUEL_TYPES = "fuel_types",
  VEHICLE_CONDITIONS = "vehicle_conditions",
  ASSET_TYPES = "asset_types",
  SETTINGS = "settings",
  VAT_CODES = "vat_codes",
  DEALERSHIP_CALCULATOR_FIELDS = "dealership_calculator_fields",
  ACCOUNTS = "accounts",
  PRODUCT_GROUPS = "product_groups",
  LEASING_TYPES = "leasing_types",
  CONTRACT_TYPES = "contract_types",
  KEY_FIGURE_TYPES = "key_figure_types",
  AD_WEB_PORTALS = "ad_web_portal",
  CONTRACT_STEPS = "contract_steps",
  TRANSACTION_TYPES = "transaction_types",
  INVOICE_LAYOUTS = "invoice_layouts",
  JOURNALS = "journals",
  TASK_CATEGORIES = "task_categories",
  TASK_SUB_CATEGORIES = "task_sub_categories",
  VEHICLE_STATUSES = "vehicle_statuses",
  VEHICLE_MARKET_PRICE_DOCUMENTS = "vehicle_market_price_documents",
  INSURANCE_COMPANIES = "insurance_companies",
  INVOICE_STATUSES = "invoice_statuses",
  TEMPLATE_TYPES = "template_types",
  SIGNING_STATUSES = "signing_statuses",
  SETTING_TYPES = "setting_types",
  PAYMENT_TERM_TYPES = "payment_term_types",
  SUPPLIER_GROUPS = "supplier_groups",
  OFFER_STATUSES = "offer_statuses",
  OFFER_STEPS = "offer_steps",
  COMPANY_STATUS = "company_status",
  CUSTOMER_DOCUMENTS = "customer_documents",
  VALUATION_TYPES = "valuation_types",
  VALUATION_SOURCE = "valuation_source",
  ENTRY_TYPES = "entry_types",
  ECONOMIC_ACCOUNT_YEARS = "economic_account_years",
  NOTES = "notes",
  FUEL_MEASUREMENT = "fuel_measurement",
  REGISTRATION_TAX_SETTING = "registration_tax_setting",
  LICENSE_PLATE_TYPE = "license_plate_type",
  MILEAGE_TRACKERS = "mileage_trackers",
  DOCUMENT_TYPES = "document_types",
}
