<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ getComponentTitle() }}</h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <div class="invoice-box">
        <table
          *ngFor="let invoice of privateInvoice; let i = index"
          cellpadding="0"
          cellspacing="0"
          [ngClass]="{
            'border-bottom': i !== privateInvoice?.length - 1
          }"
        >
          <tr class="top information">
            <td colspan="2">
              <table>
                <tr>
                  <td *ngIf="invoice.customer">
                    {{ invoice.customer.name }}
                    <span class="kt-font-primary kt-font-bolder"
                      >[{{ customerTypes.get(invoice?.customer?.customer_type) | translate }}]</span
                    >
                    <br />
                    <span class="d-block">
                      {{ invoice.customer.address }}<br />
                      {{ invoice.customer.postal_code }}{{ invoice.customer.city }}<br />
                    </span>
                  </td>

                  <td>
                    {{ "INVOICES.VIEW.TABLE_COLUMNS.INVOICE_NUMBER" | translate }}
                    #: {{ invoice.id }}<br />
                    {{ "INVOICES.VIEW.TABLE_COLUMNS.CREATED" | translate }}: {{ invoice.date | dmyDate }}<br />
                    <span
                      *ngIf="
                        invoice.payment_term?.entity_object?.paymentTermsType == paymentTermTypes.DUE_DATE;
                        else notDate
                      "
                    >
                      {{ "INVOICES.VIEW.TABLE_COLUMNS.DUEDATE" | translate }}:
                      {{ invoice.payment_due_date | dmyDate }}
                    </span>
                    <ng-template #notDate>
                      {{ invoice.payment_term?.name }}
                    </ng-template>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr class="heading">
            <td>{{ "GENERAL.ITEM" | translate }}</td>

            <td>{{ "GENERAL.PRICE" | translate }}</td>
          </tr>

          <tr class="item" *ngFor="let payment of invoice?.payments; let last = last" [ngClass]="{ last: last }">
            <td>
              {{ payment?.product_line_description }}
            </td>

            <td>
              {{ payment?.price_excl_vat_cents | number : "1.2-2" }}
            </td>
          </tr>

          <tr class="total">
            <td></td>

            <td>
              {{ "CALCULATION_RESULT.TOTAL_BEFORE_VAT" | translate }}:
              {{ invoice.total_before_vat | number : "1.2-2" }}
            </td>
          </tr>

          <tr class="total">
            <td></td>

            <td>
              {{ "CALCULATION_RESULT.VAT" | translate }}:
              {{ invoice.vat | number : "1.2-2" }}
            </td>
          </tr>

          <tr class="total">
            <td></td>

            <td>
              {{ "CALCULATION_RESULT.TOTAL_AFTER_VAT" | translate }}:
              {{ invoice.total_after_vat | number : "1.2-2" }}
            </td>
          </tr>
        </table>

        <div *ngIf="privateInvoice?.length && corporateInvoice?.length" class="divider my-4"></div>

        <table
          *ngFor="let invoice of corporateInvoice; let i = index"
          cellpadding="0"
          cellspacing="0"
          class="border-bottom"
          [ngClass]="{
            'border-bottom': i !== privateInvoice?.length - 1
          }"
        >
          <tr class="top information">
            <td colspan="2">
              <table>
                <tr>
                  <td *ngIf="invoice.customer">
                    {{ invoice.customer.name + " " }}
                    <span class="kt-font-primary kt-font-bolder"
                      >[{{ customerTypes.get(invoice?.customer?.customer_type) | translate }}]</span
                    ><br />
                    {{ invoice.customer.address }}<br />
                    {{ invoice.customer.postal_code }}
                    {{ invoice.customer.city }}<br />
                  </td>

                  <td>
                    {{ "INVOICES.VIEW.TABLE_COLUMNS.INVOICE_NUMBER" | translate }}
                    #: {{ invoice.id }}<br />
                    {{ "INVOICES.VIEW.TABLE_COLUMNS.CREATED" | translate }}: {{ invoice.date | dmyDate }}<br />
                    <span
                      *ngIf="
                        invoice.payment_term?.entity_object?.paymentTermsType == paymentTermTypes.DUE_DATE;
                        else notDate
                      "
                    >
                      {{ "INVOICES.VIEW.TABLE_COLUMNS.DUEDATE" | translate }}:
                      {{ invoice.payment_due_date | dmyDate }}
                    </span>
                    <ng-template #notDate>
                      {{ invoice.payment_term?.name }}
                    </ng-template>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr class="heading">
            <td>{{ "GENERAL.ITEM" | translate }}</td>

            <td>{{ "GENERAL.PRICE" | translate }}</td>
          </tr>
          <tr class="item" *ngFor="let payment of invoice?.payments; let last = last" [ngClass]="{ last: last }">
            <td>
              {{ payment?.product_line_description }}
            </td>

            <td>
              {{ payment?.price_excl_vat_cents | number : "1.2-2" }}
            </td>
          </tr>

          <tr class="total">
            <td></td>

            <td>
              {{ "CALCULATION_RESULT.TOTAL_BEFORE_VAT" | translate }}:
              {{ invoice.total_before_vat | number : "1.2-2" }}
            </td>
          </tr>

          <tr class="total">
            <td></td>

            <td>
              {{ "CALCULATION_RESULT.VAT" | translate }}:
              {{ invoice.vat | number : "1.2-2" }}
            </td>
          </tr>

          <tr class="total">
            <td></td>

            <td>
              {{ "CALCULATION_RESULT.TOTAL_AFTER_VAT" | translate }}:
              {{ invoice.total_after_vat | number : "1.2-2" }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="dialogRef.close()">
          {{ "GENERAL.CANCEL" | translate }}</button
        >&nbsp;
        <button mat-button (click)="onSubmit()" color="primary" cdkFocusInitial [disabled]="viewLoading">
          {{ "INVOICES.ADD.SEND_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
