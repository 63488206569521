import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import moment from "moment";
import { LayoutUtilsService } from "../../../core/_base/crud";
import { currentUser } from "../../../core/auth";
import { AppState } from "../../../core/reducers";
import { OffersService } from "../../pages/offers";
import { customerTypesLabels } from "../enums/customer/customerTypes";
import { CustomerStepComponent } from "./customer-step/customer-step.component";
import { TranslateService } from "@ngx-translate/core";

interface RequestContractFromOfferPayload {
  customers: Array<any>;
  vehicle: any;
}

@Component({
  selector: "kt-contract-from-offer",
  templateUrl: "./contract-from-offer.component.html",
  styleUrls: ["./contract-from-offer.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class ContractFromOfferComponent implements OnInit {
  offerId: any;
  firstCustomer: any;
  secondCustomer: any = null;
  vehicle: any;
  title: string = "Contract from Offer";
  customerTypesLabel = customerTypesLabels;
  UserHasPermission = false;

  step2: CustomerStepComponent;
  isVehicleFormInvalid = false;
  isMainCustomerFormInvalid = false;
  isSecondCustomerFormInvalid = false;
  isSubmitted = false;

  constructor(
    private dialogRef: MatDialogRef<ContractFromOfferComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    private offerService: OffersService,
    private layoutUtilsService: LayoutUtilsService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.store.select(currentUser).subscribe((res: { permissions_list: [] }) => {
      this.UserHasPermission = !!(
        res.permissions_list.find((x: { name: string }) => x.name === "view_users") &&
        res.permissions_list.find((x: { name: string }) => x.name === "assign_customer_account_manager")
      );
    });
  }

  ngOnInit(): void {
    const { offerId, message, customersDetails, vehicleDetails } = this.data;
    this.offerId = offerId;
    this.firstCustomer = customersDetails[0].customer;
    this.secondCustomer = customersDetails[1]?.customer;
    this.vehicle = vehicleDetails;
    this.title = message;
  }

  onSubmit(formData: any) {
    if (this.isSecondCustomerFormInvalid || this.isVehicleFormInvalid || this.isMainCustomerFormInvalid) {
      const message: string = this.translate.instant("GENERAL.FILL_MISSING_FIELDS");
      this.layoutUtilsService.showActionNotification(message);
      return;
    }
    this.isSubmitted = true;
    this.offerService.requestContractFromOffer(this.offerId, this.preparePayload(formData)).subscribe({
      next: (res) => {
        this.layoutUtilsService.showActionNotification(res.message);
        this.dialogRef.close(true);
        const { contract_number } = res.data;
        this.router.navigate([`/contracts/details/${contract_number}`]);
      },
      error: (err) => {
        this.isSubmitted = false;
        this.layoutUtilsService.showActionNotification(err.error.message);
      },
    });
  }

  preparePayload(_formData: { firstCustomer: any; vehicle: any; mileagePhoto: any; secondCustomer: any }) {
    const payload: RequestContractFromOfferPayload = {
      customers: [this.prepareCustomerPayload(_formData.firstCustomer, this.firstCustomer)],
      vehicle: this.prepareVehiclePayload(_formData.vehicle, _formData.mileagePhoto),
    };

    if (this.secondCustomer) {
      payload.customers.push(this.prepareCustomerPayload(_formData.secondCustomer, this.secondCustomer));
    }
    return this.convertPayLoadToFormData(payload);
  }

  convertPayLoadToFormData(payload: any): FormData {
    const formData = new FormData();

    Object.entries(payload.vehicle).forEach(([key, value]: [string, any]) => {
      if (value === null || value === "") return;
      value = value === true ? "1" : value === false ? "0" : value;
      formData.append(`vehicle[${key}]`, value);
    });

    payload.customers.forEach((customer: {}, index: number) => {
      Object.entries(customer).forEach(([key, value]: [string, any]) => {
        if (value === null || value === "") return;
        value = value === true ? "1" : value === false ? "0" : value;
        formData.append(`customers[${index}][${key}]`, value);
      });
    });

    return formData;
  }

  prepareCustomerPayload(customerFormValue: any, customer: any) {
    const payload = { ...customerFormValue };
    payload.account_manager_id = this.UserHasPermission ? customerFormValue.account_manager_id?.id : null;
    payload.aml_risk_class_id = customerFormValue.risk_class_id?.id ?? null;
    payload.risk_class_id = customerFormValue.risk_class_id?.id ?? null;
    payload.birthdate = customerFormValue.birthdate ? payload.birthdate.format("DD-MM-YYYY") : null;
    payload.name = customer.name;
    payload.customer_type = customer.customer_type;
    payload.id = customer.id;
    return payload;
  }

  prepareVehiclePayload(vehicleFormValue: any, mileagePhoto: any) {
    const payload = { ...vehicleFormValue };
    if (mileagePhoto) {
      payload.mileagePhoto = mileagePhoto;
    }
    payload.year = payload.year ? payload.year.year() : payload.year;
    payload.vehicle_type_id = this.vehicle.vehicle_type_id;
    payload.make_id = this.vehicle.make.id;
    payload.model_id = this.vehicle.model.id;
    payload.first_registration_date = moment(this.vehicle.first_registration_date).format("DD-MM-YYYY");
    payload.vehicle_valuation = false;
    payload.id = this.vehicle.id;
    if (payload.mileage_car === this.vehicle.latest_mileage) delete payload.mileage_car;
    return payload;
  }

  vehicleFormHasErrors(value: boolean) {
    this.isVehicleFormInvalid = value;
  }

  mainCustomerHasErrors(value: boolean) {
    this.isMainCustomerFormInvalid = value;
  }

  secondCustomerHasErrors(value: boolean) {
    this.isSecondCustomerFormInvalid = value;
  }

  setFirstCustomerValue(value: any) {
    this.firstCustomer = value;
  }

  setSecondCustomerValue(value: any) {
    this.secondCustomer = value;
  }
}
