<!--begin: Quick actions -->
<div ngbDropdown placement="bottom-right" autoClose="true" class="kt-header__topbar-item">
  <div ngbDropdownToggle class="kt-header__topbar-wrapper">
    <span
      class="kt-header__topbar-icon quick-actions-navigation-icon"
      [ngClass]="{ 'kt-header__topbar-icon--warning': iconType === 'warning' }"
    >
      <!-- <i *ngIf="!useSVG" [ngClass]="icon"></i>
			<span *ngIf="useSVG" class="kt-svg-icon" [inlineSVG]="icon" [ngClass]="{ 'kt-svg-icon--warning': iconType === 'warning' }"></span> -->
      <button mat-mini-fab color="primary">
        <i class="fas fa-plus"></i>
      </button>
    </span>
  </div>
  <div
    ngbDropdownMenu
    class="dropdown-menu dropdown-menu-fit quick-actions-navigation-dropdown dropdown-menu-right dropdown-menu-anim dropdown-menu-top-rounded dropdown-menu-xs"
  >
    <form>
      <ul class="kt-nav">
        <li class="kt-nav__item">
          <a [routerLink]="['/customers/add']" class="kt-nav__link" ngbDropdownItem>
            <!-- <i class="kt-nav__link-icon fas fa-user-friends"></i> -->
            <img src="assets/media/icons/customers.png" class="kt-nav__link-icon" />
            <span class="kt-nav__link-text">{{ "NAVBAR.QUICK_NAVIGATION_MENU.CUSTOMER" | translate }}</span>
          </a>
        </li>
        <li class="kt-nav__item">
          <a href="javascript:;" class="kt-nav__link" ngbDropdownItem>
            <!-- <i class="kt-nav__link-icon fas fa-briefcase"></i> -->
            <img src="assets/media/icons/asset.png" class="kt-nav__link-icon" />
            <span class="kt-nav__link-text">{{ "NAVBAR.QUICK_NAVIGATION_MENU.ASSET" | translate }}</span>
          </a>
        </li>
        <li *ngxPermissionsOnly="['create_contract']" class="kt-nav__item">
          <a [routerLink]="['/calculator/add/contract']" class="kt-nav__link" ngbDropdownItem>
            <!-- <i class="kt-nav__link-icon far fa-file-alt"></i> -->
            <img src="assets/media/icons/contract.png" class="kt-nav__link-icon" />
            <span class="kt-nav__link-text">{{ "NAVBAR.QUICK_NAVIGATION_MENU.CONTRACT" | translate }}</span>
          </a>
        </li>
        <li class="kt-nav__item">
          <a [routerLink]="['/calculator/add/offer']" class="kt-nav__link" ngbDropdownItem>
            <!-- <i class="kt-nav__link-icon fas fa-percent"></i> -->
            <img src="assets/media/icons/offer.png" class="kt-nav__link-icon" />
            <span class="kt-nav__link-text">{{ "NAVBAR.QUICK_NAVIGATION_MENU.OFFER" | translate }}</span>
          </a>
        </li>
        <!-- <li class="kt-nav__separator"></li>
				<li class="kt-nav__foot">
					<a class="btn btn-label-brand btn-bold btn-sm" href="javascript:;">Upgrade plan</a>
					<a class="btn btn-clean btn-bold btn-sm kt-hidden" href="javascript:;" data-placement="left" ngbTooltip="Click to learn more...">Learn more</a>
				</li> -->
      </ul>
      <!--begin: Grid Nav -->
      <!-- <div class="kt-grid-nav kt-grid-nav--skin-{{ gridNavSkin }}">
				<div class="kt-grid-nav__row">
					<a href="javascript:;" class="kt-grid-nav__item">
						<span class="kt-grid-nav__icon" [inlineSVG]="'./assets/media/icons/svg/Shopping/Euro.svg'" (onSVGInserted)="onSVGInserted($event)"></span>
						<span class="kt-grid-nav__title">Accounting</span>
						<span class="kt-grid-nav__desc">eCommerce</span>
					</a>
					<a href="javascript:;" class="kt-grid-nav__item">
						<span class="kt-grid-nav__icon" [inlineSVG]="'./assets/media/icons/svg/Communication/Mail-attachment.svg'" (onSVGInserted)="onSVGInserted($event)"></span>
						<span class="kt-grid-nav__title">Administration</span>
						<span class="kt-grid-nav__desc">Console</span>
					</a>
				</div>
				<div class="kt-grid-nav__row">
					<a href="javascript:;" class="kt-grid-nav__item">
						<span class="kt-grid-nav__icon" [inlineSVG]="'./assets/media/icons/svg/Shopping/Box2.svg'" (onSVGInserted)="onSVGInserted($event)"></span>
						<span class="kt-grid-nav__title">Projects</span>
						<span class="kt-grid-nav__desc">Pending Tasks</span>
					</a>
					<a href="javascript:;" class="kt-grid-nav__item">
						<span class="kt-grid-nav__icon" [inlineSVG]="'./assets/media/icons/svg/Communication/Group.svg'" (onSVGInserted)="onSVGInserted($event)"></span>
						<span class="kt-grid-nav__title">Customers</span>
						<span class="kt-grid-nav__desc">Latest cases</span>
					</a>
				</div> 				
			</div> -->
    </form>
  </div>
</div>
<!--end: Quick actions -->
