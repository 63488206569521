import { createAction, props } from "@ngrx/store";
import { BaseSettingsModel } from "../../../views/pages/settings/base-settings.model";
import { IRequiredFieldsSettings } from "../../../views/pages/settings/components/required-fields-settings-dialog/required-fields-settings.interface";
import { IResponse } from "../../_base/crud/interfaces/response-interface";

export const loadSettings = createAction("[Settings] Load Settings");
export const loadSettingsSuccess = createAction(
  "[Settings] Load Settings Success",
  props<{ settings: IResponse<BaseSettingsModel<IRequiredFieldsSettings>> }>()
);
export const loadSettingsFailure = createAction("[Settings] Load Settings Failure", props<{ error: any }>());

export const updateFormSettings = createAction(
  "[Settings] Update Settings",
  props<{ updatedValue: BaseSettingsModel<IRequiredFieldsSettings> }>()
);
