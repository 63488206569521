export const MAT_DATA_TABLES_CONTRACTS = "MatDataTables_Contracts";
export const MAT_DATA_TABLES_ADMIN_SETTING = "MatDataTables_AdminSetting";
export const MAT_DATA_TABLES_DEPARTMENTS = "MatDataTables_Departments";
export const MAT_DATA_TABLES_RISK_CLASSIFICATION = "MatDataTables_RiskClassification";
export const MAT_DATA_TABLES_CUSTOMERS = "MatDataTables_Customers";
export const MAT_DATA_TABLES_DEALERSHIPS = "MatDataTables_Dealerships";
export const MAT_DATA_TABLES_FUNDING_RATES = "MatDataTables_FundingRates";
export const MAT_DATA_TABLES_REGISTRATION_TAX = "MatDataTables_RegistrationTax";
export const MAT_DATA_TABLES_REGISTRATION_TAX_INTEREST_RATE = "MatDataTables_RegistrationTaxInterestRate";
export const MAT_DATA_TABLES_OFFERS = "MatDataTables_Offers";
export const MAT_DATA_TABLES_PERMISSIONS = "MatDataTables_Permissions";
export const MAT_DATA_TABLES_PRODUCTS = "MatDataTables_Products";
export const MAT_DATA_TABLES_ROLES = "MatDataTables_Roles";
export const MAT_DATA_TABLES_SUPPLIERS = "MatDataTables_Suppliers";
export const MAT_DATA_TABLES_TASKS = "MatDataTables_Tasks";
export const MAT_DATA_TABLES_TEMPLATES = "MatDataTables_Templates";
export const MAT_DATA_TABLES_USERS = "MatDataTables_Users";
export const MAT_DATA_TABLES_VEHICLES_MARKET = "MatDataTables_VehiclesMarket";
export const MAT_DATA_TABLES_VEHICLES_MILEAGE = "MatDataTables_VehiclesMileage";
export const MAT_DATA_TABLES_VEHICLES = "MatDataTables_Vehicles";
export const MAT_DATA_TABLES_VEHICLES_MAKES = "MatDataTables_VehiclesMakes";
export const MAT_DATA_TABLES_VEHICLES_MODELS = "MatDataTables_VehiclesModels";
export const MAT_DATA_TABLES_AMORTIZATIONS = "MatDataTables_Amortizations";
export const MAT_DATA_TABLES_CONTRACTS_MILEAGE = "MatDataTables_ContractsMileage";
export const MAT_DATA_TABLES_INVOICES = "MatDataTables_Invoices";
export const MAT_DATA_TABLES_PAYMENTS = "MatDataTables_Payments";
export const MAT_DATA_TABLES_TRANSACTIONS = "MatDataTables_Transactions";
export const MAT_DATA_TABLES_EVALUATION_SOURCE = "MatDataTables_EvaluationSource";
export const MAT_DATA_TABLES_EVALUATION_TYPE = "MatDataTables_EvaluationType";
export const MAT_DATA_TABLES_INSURANCE_COMPANY = "MatDataTables_InsuranceCompany";
export const MAT_DATA_TABLES_VEHICLE_TYPE = "MatDataTables_VehicleType";
export const MAT_DATA_TABLES_VEHICLE_CONDITION = "MatDataTables_VehicleCondition";
export const MAT_DATA_TABLES_NOTE_CATEGORIES = "MatDataTables_NoteCategories";
export const MAT_DATA_TABLES_VEHICLE_REG_TAX = "MatDataTables_VehicleRegTax";
export const APP_VERSION = "appVersion";
export const LAYOUT_CONFIG = "layoutConfig";
export const LANGUAGE = "language";
