import { LastActionModel } from "../../tasks/last-action-model";

export class VehicleModel {
  id: number;
  asset_id: number;
  make_id: any;
  make;
  vehicle_make: any;
  model: any;
  trim: string;
  first_registration_date: any;
  year: any;
  vin_number: any;
  vehicle_type: any;
  vehicle_type_id: number;
  fuel_type: number;
  vehicle_condition_id: number;
  vehicle_condition: any;
  new_price_cents: number;
  fuel_consumption: number;
  electric_consumption: number;
  electric_range: number;
  co2_per_km: number;
  curb_weight: number;
  status: any;
  fuel_norm: string;
  battery_capacity: number;
  //extra_equipment_value:any[];
  num_airbags: number;
  num_integrated_child_seats: number;
  num_seat_belt_alarms: number;
  is_special_usage: any;
  is_ncap: any;
  is_abs: any;
  mileage_car: any;
  market_car: any;
  payments: any;
  invoices: any;
  vehicle_valuation: any;
  transaction_expense?: any[];
  created_at: string;
  updated_at: string;
  last_action?: LastActionModel;
  booked_value?: number;
  location?: string;

  clear() {
    this.make_id = "";
    this.model = "";
    this.trim = "";
    this.year = "";
    this.created_at = "";
    this.updated_at = null;
    this.vin_number = "";
    //this.extra_equipment_value = [];
    this.is_special_usage = false;
    this.is_ncap = false;
    this.is_abs = false;
    // this.mileage_car = [];
    // this.market_car = [];
  }
}

export enum VehicleConstants {
  MOVE_VEHICLE = "MOVE_VEHICLE",
}

export interface IVehicleRegistrationTaxDocument {
  id: number;
  vehicle_id: number;
  contract?: { id: number; number: number } | null;
  offer?: { id: number; number: number } | null;
  created_at: string;
  url: string;
  isDownloading: boolean;
}
