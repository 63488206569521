import { Directive, AfterViewInit, OnDestroy, Optional } from "@angular/core";
import { NgControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Subscription } from "rxjs";

@Directive({
  selector: "[tab-directive]",
})
export class TabDirective implements AfterViewInit, OnDestroy {
  triggerTab: Subscription;
  constructor(@Optional() private autoTrigger: MatAutocompleteTrigger, private control: NgControl) {}
  ngAfterViewInit() {
    this.triggerTab = this.autoTrigger.panelClosingActions.subscribe((x) => {
      if (this.autoTrigger.activeOption) {
        const value = this.autoTrigger.activeOption.value;
        if (this.control)
          this.control.control.setValue(value, {
            emit: true,
          });
        this.autoTrigger.autocomplete.optionSelected.emit(value);
        this.autoTrigger.writeValue(value);
      }
    });
  }
  ngOnDestroy() {
    this.triggerTab.unsubscribe();
  }
}
