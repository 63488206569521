export class TransactionModel {
  id: number;
  is_multi_lines: any;
  transaction_type_code: any;
  date: any;
  product: any;
  account_vat_code: any;
  amount_cents: any;
  contra_account: any;
  supplier_id: any;
  supplier: any;
  payment_details: any;
  payment_info: any;
  supplier_invoice_number: any;
  supplier_invoice_duedate: any;
  transactions?: any[];
  documents: any[];
  created_at: string;
  updated_at: string;
  set?: any;
  contract_id?: number;
  vehicle_id?: number;
  is_booked?: boolean;

  clear() {
    this.date = "";
    this.is_multi_lines = false;
    this.transaction_type_code = null;
    this.product = null;
    this.amount_cents = 0;
    this.contra_account = null;
    this.created_at = "";
    this.updated_at = null;
  }
}
