export enum ContractOfferStatus {
  DRAFT = "Draft",
  PENDING = "Pending",
  COMPLETED = "Completed",
  APPROVED = "Approved",
  CANCELED = "Canceled",
  DECLINED = "Declined",
  ACTIVE = "Active",
  INACTIVE = "inactive",
  CLOSED = "Closed",
}

export const contractOfferStatusLabels = new Map<string, string>([
  [ContractOfferStatus.DRAFT, "CONTRACTS.STATUS.DRAFT"],
  [ContractOfferStatus.PENDING, "CONTRACTS.STATUS.PENDING"],
  [ContractOfferStatus.COMPLETED, "CONTRACTS.STATUS.COMPLETED"],
  [ContractOfferStatus.APPROVED, "CONTRACTS.STATUS.APPROVED"],
  [ContractOfferStatus.CANCELED, "CONTRACTS.STATUS.CANCELED"],
  [ContractOfferStatus.DECLINED, "CONTRACTS.STATUS.DECLINED"],
  [ContractOfferStatus.ACTIVE, "CONTRACTS.STATUS.ACTIVE"],
  [ContractOfferStatus.CLOSED, "CONTRACTS.STATUS.CLOSED"],
  [null, "CONTRACTS.STATUS.DRAFT"],
]);
