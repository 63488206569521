import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import moment, { Moment, now } from "moment";
import { BehaviorSubject, noop, Observable, of, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { currentUser } from "../../../../core/auth";
import { AppState } from "../../../../core/reducers";
import { CustomerTypes, customerTypesLabels } from "../../enums/customer/customerTypes";
import { LookupCategories } from "../../enums/lookups/lookupCategories";
import { SharedService } from "../../shared.service";
import { LayoutUtilsService } from "../../../../core/_base/crud";
import { EventEmitter } from "@angular/core";
import { IRequiredFieldsSettings } from "../../../pages/settings/components/required-fields-settings-dialog/required-fields-settings.interface";
import { IResponse } from "../../../../../app/core/_base/crud/interfaces/response-interface";
import { BaseSettingsModel } from "../../../../../app/views/pages/settings/base-settings.model";
import { selectSettings } from "../../../../../app/core/auth/_selectors/setting.selectors";
import { useCustomerFieldsSettings } from "../../../../../app/core/_base/crud/utils/form-utils";
import { EntityType } from "../../enums/entity/entityType";
import { CustomerRequestContractFromOfferFormDefaultSettings } from "../../../../../app/views/pages/settings/components/required-fields-settings-dialog/required-fields-enum";

@Component({
  selector: "kt-customer-step",
  templateUrl: "./customer-step.component.html",
  styleUrls: ["./customer-step.component.scss"],
})
export class CustomerStepComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() customerInfo!: any;
  @Input() isIdentifcationNumberRequired!: boolean;
  @Output() customerErrorsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Output() customerFormValueChanged = new EventEmitter();
  accountManagersFilter: Observable<any[]>;
  riskLevelsFilter: Observable<any[]>;
  currencyFilter: Observable<any[]>;
  companyNameFilter: Observable<any[]>;
  paymentTermsFilter: Observable<any[]>;
  UserHasPermission = false;
  lastZipCode: string | null = null;
  emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  customerTypesLabel = customerTypesLabels;
  formSettings: BaseSettingsModel<IRequiredFieldsSettings>;
  isSettingsLoading = false;
  settings$: Observable<IResponse<BaseSettingsModel<IRequiredFieldsSettings>>>;
  private componentSubscriptions: Subscription[] = [];

  customerType!: number;

  customerForm = this._formBuilder.group({
    name: [{ value: "", disabled: true }],
    customer_type: [{ value: 1, disabled: true }, Validators.required],
    company_contact_name: [""],
    id_number: [""],
    birthdate: ["", [this.birthDateValidator()]],
    address: ["", [Validators.required, Validators.maxLength(100)]],
    postal_code: ["", [Validators.required, Validators.maxLength(100)]],
    city: ["", [Validators.required, Validators.maxLength(100)]],
    phone: ["", Validators.maxLength(100)],
    email: ["", [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
    account_manager_id: [""],
    payment_term_id: ["", Validators.required],
    risk_class_id: [""],
    payment_eligible: [""],
    bank_code: ["", [Validators.minLength(4), Validators.maxLength(4)]],
    bank_account_number: ["", Validators.maxLength(11)],
    currency: ["", Validators.required],
    country: ["", Validators.required],
  });

  // tslint:disable-next-line:variable-name
  constructor(
    private _formBuilder: FormBuilder,
    private sharedService: SharedService,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService
  ) {
    this.store.select(currentUser).subscribe((res: { permissions_list: [] }) => {
      this.UserHasPermission = !!(
        res.permissions_list.find((x: { name: string }) => x.name === "view_users") &&
        res.permissions_list.find((x: { name: string }) => x.name === "assign_customer_account_manager")
      );
    });
  }

  ngOnInit(): void {
    this.customerType = this.customerInfo.customer_type;
    !this.isCorporate()
      ? this.customerForm.controls.id_number.addValidators([Validators.minLength(10), Validators.maxLength(10)])
      : this.customerForm.controls.id_number.addValidators([Validators.required]);

    this.isCorporate()
      ? this.customerForm.controls.company_contact_name.addValidators([Validators.required])
      : this.customerForm.controls.company_contact_name.addValidators([Validators.nullValidator]);

    this.UserHasPermission
      ? this.customerForm.controls.account_manager_id.addValidators([Validators.required])
      : this.customerForm.controls.account_manager_id.addValidators([Validators.nullValidator]);

    this.isIdentifcationNumberRequired
      ? this.customerForm.controls.id_number.addValidators([Validators.required])
      : this.customerForm.controls.id_number.addValidators([Validators.nullValidator]);
    this.initForm(this.customerInfo);
    this.initFilters();
    const settings$: Observable<IResponse<BaseSettingsModel<IRequiredFieldsSettings>>> =
      this.store.select(selectSettings);
    const settingSubscription = settings$.subscribe((settings) => {
      if (settings) {
        useCustomerFieldsSettings(
          settings,
          this.customerForm,
          EntityType.CONTRACT,
          CustomerRequestContractFromOfferFormDefaultSettings
        );
      }
    });
    this.componentSubscriptions.push(settingSubscription);
  }

  ngAfterViewInit(): void {
    this.customerErrorsSubject.next(this.customerForm.invalid);
    this.getCustomerFormError();
  }

  private initForm(customerInfo: any): void {
    this.customerForm.patchValue(customerInfo);
    if (customerInfo.birthdate)
      this.customerForm.controls.birthdate.setValue(moment(customerInfo.birthdate, "DD-MM-YYYY"));
    if (this.isCorporate()) {
      this.customerForm.controls.name.setValue({
        name: customerInfo.name,
      });
    }
    if (!customerInfo.manager || !customerInfo.risk_class) {
      this.sharedService.getCustomerById(customerInfo.id).subscribe((res) => {
        this.customerForm.controls.account_manager_id.setValue(res.data.manager);
        this.customerForm.controls.risk_class_id.setValue(res.data.risk_class);
      });
    } else {
      this.customerForm.controls.account_manager_id.setValue(customerInfo.manager);
      this.customerForm.controls.risk_class_id.setValue(customerInfo.risk_class);
    }
    this.customerForm.markAllAsTouched();
  }

  private initFilters(): void {
    this.accountManagersFilter = this.customerForm.controls.account_manager_id.valueChanges.pipe(
      switchMap((value) => this.sharedService.getUsers(value)),
      map((res) => res.data)
    );

    this.riskLevelsFilter = this.customerForm.controls.risk_class_id.valueChanges.pipe(
      switchMap((value) => this.sharedService.getRiskClassifications(value)),
      map((res) => res.data)
    );

    this.currencyFilter = this.customerForm.controls.currency.valueChanges.pipe(
      switchMap((value) => this.sharedService.listCurrencies(value)),
      map((res) => res.data)
    );

    this.companyNameFilter = this.customerForm.controls.company_contact_name.valueChanges.pipe(
      switchMap((value) => this.sharedService.getCVRData(value)),
      map((res) => res.data)
    );

    this.paymentTermsFilter = this.sharedService
      .getLookups(LookupCategories.PAYMENT_TERMS)
      .pipe(map((res) => res.data));
  }

  isCorporate(): boolean {
    return this.customerType === CustomerTypes.CORPORATE;
  }

  getCustomerFormControl(control: string) {
    return this.customerForm.controls[control] as FormControl;
  }

  displayFn(item: any): string {
    return item && item.name ? item.name : "";
  }

  birthDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedDate = moment(control.value, "DD-MM-YYYY").isValid() ? moment(control.value, "DD-MM-YYYY") : null;

      if (selectedDate) {
        const currentDate: moment.Moment = moment();
        if (currentDate.isBefore(selectedDate)) {
          return { dateNotAllowed: true };
        }
      }

      return null;
    };
  }

  getCustomerFormError() {
    this.customerForm.valueChanges.subscribe((value) => {
      this.customerErrorsSubject.next(this.customerForm.invalid);
    });
  }

  loadCityName() {
    if (
      this.customerForm.controls.postal_code?.dirty &&
      (this.customerForm.controls.postal_code?.value !== this.lastZipCode || this.lastZipCode === null)
    ) {
      let postalCityName: string = "";
      const zipCode = this.customerForm.controls.postal_code?.value;
      this.sharedService.getPostalCodeCity(zipCode).subscribe((res: { id: number; zip_code: string; name: string }) => {
        this.lastZipCode = res.zip_code;
        if (!this.customerForm.controls.postal_code?.value) {
          this.customerForm.get("postal_code").setErrors({ required: true });
          this.customerForm.get("city").setValue("");
        } else {
          this.customerForm.get("postal_code").setErrors({ required: false });
          this.customerForm.get("postal_code").updateValueAndValidity();
          postalCityName = res.name;
          this.customerForm.get("city").setValue(postalCityName);
        }
      });
    } else {
      return;
    }
  }

  public isControlRequired(formName: string) {
    return this.getCustomerFormControl(formName).hasValidator(Validators.required);
  }

  updateBirthdateFromCPR() {
    const idNumber = this.customerForm.controls.id_number.value;
    if (!idNumber || (!this.isCorporate() && idNumber.length !== 10)) return;

    this.sharedService
      .getBirthdayFromCPR(idNumber)
      .pipe(
        catchError((error) => {
          this.layoutUtilsService.showActionNotification(error.error.errors.message, 2, 5000, true, false);
          return of(null);
        })
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.customerForm.controls.birthdate.setValue(moment(res, "DD-MM-YYYY"));
          }
        },
      });
  }

  updateCustomerForm() {
    const idNumber = this.customerForm.controls.id_number.value;
    if (!idNumber) return;

    this.sharedService
      .getCustomerByCPR(idNumber)
      .pipe(
        catchError((error) => {
          return of(null);
        })
      )
      .subscribe({
        next: (res) => {
          if (!res) return;
          this.initForm(res);
          this.customerFormValueChanged.emit(res);
        },
      });
  }

  updateFormFromCPR() {
    this.updateBirthdateFromCPR();
    this.updateCustomerForm();
  }

  ngOnDestroy(): void {
    this.componentSubscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
