export class SuppliersModel {
  id: number;
  supplier: any;
  name: string;
  supplier_cvr: string;
  supplier_group_id: number;
  payment_terms_id: number;
  paymentTerm: any;
  paymentInfo: any;
  payment_info_id: any;
  account_no: any;
  credit_id: any;

  clear() {
    this.name = "";
    this.supplier_cvr = "";
    this.supplier_group_id = null;
    this.payment_terms_id = null;
    this.paymentInfo = null;
    this.payment_info_id = null;
    this.account_no = null;
    this.credit_id = null;
  }
}
