// Angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// RxJS
import { Observable } from "rxjs";

import { API } from "../../../core/_base/crud";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { InvoiceModel } from "./invoice.model";

@Injectable()
export class InvoicesService {
  constructor(private http: HttpClient) {}

  createProduct(product): Observable<any> {
    return this.http.post<any>(API.contractInvoice, product);
  }

  getProductById(productId: number): Observable<any> {
    return this.http.get<any>(API.contractInvoice + `/${productId}`);
  }

  indexProduct(requestUrl: string, params: IRequestPagination): Observable<any> {
    return this.http.get<IResponsePagination<InvoiceModel>>(requestUrl, {
      params: { ...params },
    });
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.put<any>(`${API.contractInvoice}/${productId}`, product);
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.delete<any>(`${API.contractInvoice}/${productId}`);
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(`${API.roles}`);
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(`${API.permissions}/categories`);
  }

  getCVRData(q): Observable<any> {
    return this.http.post<any>(`${API.CVRData}`, { search: q });
  }

  downloadContractInvoice(invoice_id) {
    return this.http.get(`${API.contractInvoice}/${invoice_id}/download`, {
      responseType: "blob",
    });
  }

  getLookups(category_code): Observable<any> {
    return this.http.get<any>(`${API.lookups}`, {
      params: {
        category_code,
      },
    });
  }
}
