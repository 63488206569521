export enum FuelTypes {
  GASOLINE = "Gasoline",
  DIESEL = "Diesel",
  ELECTRIC = "Electric",
  HYBRID = "Hybrid",
}

export const FuelTypesMap = new Map<string, string>([
  [FuelTypes.GASOLINE, "VEHICLE.FUEL_TYPES.GASOLINE"],
  [FuelTypes.DIESEL, "VEHICLE.FUEL_TYPES.DIESEL"],
  [FuelTypes.ELECTRIC, "VEHICLE.FUEL_TYPES.ELECTRIC"],
  [FuelTypes.HYBRID, "VEHICLE.FUEL_TYPES.HYBRID"],
]);
