<mat-dialog-content>
  <div>
    <h6 class="my-4 text-center text-danger">{{ "GENERAL.FORM_REQUIRED_FIELDS" | translate }}</h6>
  </div>
  <div>
    <form [formGroup]="customerForm" class="customer">
      <!-- company name -->
      <div class="row" *ngIf="isCorporate()">
        <div class="col-lg-6">
          <label>{{ "CUSTOMERS.ADD.FIELDS.COMPANY_NAME.PLACEHOLDER" | translate }} </label>
          <mat-form-field class="w-100" appearance="fill">
            <mat-icon aria-hidden="false" class="pt-1">search</mat-icon>
            <input
              class="pl-3"
              type="text"
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.COMPANY_NAME.PLACEHOLDER' | translate }}"
              aria-label="Number"
              matInput
              [matAutocomplete]="companyAuto"
              [formControl]="getCustomerFormControl('name')"
            />
            <mat-autocomplete autoActiveFirstOption #companyAuto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let companyName of companyNameFilter | async" [value]="companyName">
                {{ companyName.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <!-- customer and customer type -->
      <div class="row">
        <div class="col-lg-6" *ngIf="!isCorporate(); else showPrivateCustomerNameField">
          <label>{{ "CUSTOMERS.ADD.FIELDS.NAME.PLACEHOLDER" | translate }} </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              matInput
              type="text"
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.NAME.PLACEHOLDER' | translate }}"
              [formControl]="getCustomerFormControl('name')"
            />
          </mat-form-field>
        </div>
        <ng-template #showPrivateCustomerNameField>
          <div class="col-lg-6">
            <label
              >{{ "CUSTOMERS.ADD.FIELDS.NAME.COMPANY_CONTACT_NAME" | translate }}
              <span *ngIf="isControlRequired('company_contact_name')"> * </span>
            </label>
            <mat-form-field appearance="fill" class="w-100">
              <input
                type="text"
                matInput
                placeholder="{{ 'CUSTOMERS.ADD.FIELDS.NAME.COMPANY_CONTACT_NAME' | translate }}"
                [formControl]="getCustomerFormControl('company_contact_name')"
              />
              <mat-error *ngIf="getCustomerFormControl('company_contact_name').errors?.required">
                <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.NAME.ERROR_MSG_COMPANY_CONTACT_NAME' | translate"></span>
              </mat-error>
            </mat-form-field>
          </div>
        </ng-template>
        <div class="col-lg-6 d-flex align-items-center">
          <mat-radio-group aria-label="Select an option" [value]="1" disabled>
            <mat-radio-button [value]="1"
              ><span class="text-primary m-0"> {{ customerTypesLabel.get(customerType) | translate }} </span>
              {{ "CUSTOMERS.ADD.FIELDS.CUSTOMER_TYPE.PLACEHOLDER" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- birthday and  CVR/CPR-->
      <div class="row">
        <div class="col-lg-6">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.ID_NUMBER.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('id_number')"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              (blur)="updateFormFromCPR()"
              type="text"
              matInput
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.ID_NUMBER.PLACEHOLDER' | translate }}"
              [formControl]="getCustomerFormControl('id_number')"
            />
            <mat-error *ngIf="getCustomerFormControl('id_number').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.ID_NUMBER.ERROR_MSG' | translate"></span>
            </mat-error>
            <mat-error
              *ngIf="
                getCustomerFormControl('id_number').errors?.maxlength ||
                getCustomerFormControl('id_number').errors?.minlength
              "
            >
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.ID_NUMBER.MAX_LENGTH' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-4">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.BIRTHDAY.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('birthdate')"> * </span>
          </label>
          <mat-form-field appearance="fill">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="getCustomerFormControl('birthdate')" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="getCustomerFormControl('birthdate').errors?.dateNotAllowed">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.BIRTHDAY.ERROR_MSG' | translate"></span>
            </mat-error>
            <mat-error *ngIf="getCustomerFormControl('birthdate').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.BIRTHDAY.ERROR_MSG_REQUIRED' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- address -->
      <div class="row">
        <div class="col-lg-12">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.ADDRESS.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('address')"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              type="text"
              matInput
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.ADDRESS.PLACEHOLDER' | translate }}"
              [formControl]="getCustomerFormControl('address')"
            />
            <mat-error *ngIf="getCustomerFormControl('address').errors?.maxlength">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.ADDRESS.MAX_LENGTH' | translate"></span>
            </mat-error>
            <mat-error *ngIf="getCustomerFormControl('address').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.ADDRESS.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- postal code / by -->
      <div class="row mt-4">
        <div class="col-lg-6">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.POSTAL_CODE.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('postal_code')"> * </span>
          </label>
          <mat-form-field no-scroll appearance="fill" class="w-100">
            <input
              matInput
              type="number"
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.POSTAL_CODE.PLACEHOLDER' | translate }}"
              [formControl]="getCustomerFormControl('postal_code')"
              (focusout)="loadCityName()"
            />
            <mat-error *ngIf="getCustomerFormControl('postal_code').errors?.maxlength">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.POSTAL_CODE.MAX_LENGTH' | translate"></span>
            </mat-error>
            <mat-error *ngIf="getCustomerFormControl('postal_code').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.POSTAL_CODE.REQUIRED' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.CITY.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('city')"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              type="text"
              matInput
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.CITY.PLACEHOLDER' | translate }}"
              [formControl]="getCustomerFormControl('city')"
            />
            <mat-error *ngIf="getCustomerFormControl('city').errors?.maxlength">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.CITY.MAX_LENGTH' | translate"></span>
            </mat-error>
            <mat-error *ngIf="getCustomerFormControl('city').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.CITY.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- phone and email -->
      <div class="row">
        <div class="col-lg-6">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.PHONE.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('phone')"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              inputmode="tel"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              matInput
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.PHONE.PLACEHOLDER' | translate }}"
              [formControl]="getCustomerFormControl('phone')"
            />
            <mat-error *ngIf="getCustomerFormControl('phone').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.PHONE.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.EMAIL.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('email')"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              type="email"
              matInput
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.EMAIL.PLACEHOLDER' | translate }}"
              [formControl]="getCustomerFormControl('email')"
            />
          </mat-form-field>
          <ng-container
            *ngIf="getCustomerFormControl('email').errors | onlyOneError : ['required', 'email', 'pattern'] as error"
          >
            <mat-error *ngIf="error.required">
              <span class="mat-invalid-input" [innerHTML]="'CUSTOMERS.ADD.FIELDS.EMAIL.ERROR_MSG' | translate"></span>
            </mat-error>
            <mat-error *ngIf="error.email">
              <span
                class="mat-invalid-input"
                [innerHTML]="'CUSTOMERS.ADD.FIELDS.EMAIL.INVALID_EMAIL' | translate"
              ></span>
            </mat-error>
            <mat-error *ngIf="error.pattern">
              <span class="mat-invalid-input" [innerHTML]="'CUSTOMERS.ADD.FIELDS.EMAIL.INVALID' | translate"></span>
            </mat-error>
          </ng-container>
        </div>
      </div>
      <!-- account manager and payment terms -->
      <div class="row">
        <div class="col-lg-6" *ngIf="UserHasPermission">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.ACCOUNT_MANAGER.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('account_manager_id')"> * </span>
          </label>
          <mat-form-field class="w-100" appearance="fill">
            <mat-icon aria-hidden="false" class="pt-1">search</mat-icon>
            <input
              class="pl-3"
              type="text"
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.ACCOUNT_MANAGER.PLACEHOLDER' | translate }}"
              aria-label="Number"
              matInput
              [matAutocomplete]="managersAuto"
              [formControl]="getCustomerFormControl('account_manager_id')"
            />
            <mat-autocomplete autoActiveFirstOption #managersAuto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let accountManager of accountManagersFilter | async" [value]="accountManager">
                {{ accountManager.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="getCustomerFormControl('account_manager_id').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.ACCOUNT_MANAGER.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.PAYMENT_TERM.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('payment_term_id')"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-select [formControl]="getCustomerFormControl('payment_term_id')">
              <mat-option [value]="paymentTerm.id" *ngFor="let paymentTerm of paymentTermsFilter | async"
                >{{ paymentTerm.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getCustomerFormControl('payment_term_id').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.PAYMENT_TERM.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- risk_level and registered_checkbox -->
      <div class="row">
        <div class="col-lg-6" *ngIf="UserHasPermission">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.RISK_CLASS.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('risk_class_id')"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-icon aria-hidden="false" class="pt-1">search</mat-icon>
            <input
              class="pl-3"
              type="text"
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.RISK_CLASS.PLACEHOLDER' | translate }}"
              aria-label="risk"
              matInput
              [matAutocomplete]="riskAuto"
              [formControl]="getCustomerFormControl('risk_class_id')"
            />
            <mat-autocomplete autoActiveFirstOption #riskAuto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let riskClass of riskLevelsFilter | async" [value]="riskClass">
                {{ riskClass.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="getCustomerFormControl('risk_class_id').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.RISK_CLASS.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6 d-flex align-items-center">
          <div>
            <mat-checkbox [formControl]="getCustomerFormControl('payment_eligible')"
              >Kunde er tilmeldt betalingsløsning
            </mat-checkbox>
          </div>
        </div>
      </div>
      <!-- bank_reg_num and back_account_num -->
      <div class="row">
        <div class="col-lg-6">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.BANK_CODE.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('bank_code')"> * </span>
          </label>
          <mat-form-field no-scroll appearance="fill" class="w-100">
            <input
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              matInput
              type="number"
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.BANK_CODE.PLACEHOLDER' | translate }}"
              [formControl]="getCustomerFormControl('bank_code')"
            />
            <mat-error
              *ngIf="
                getCustomerFormControl('bank_code').errors?.maxlength ||
                getCustomerFormControl('bank_code').errors?.minlength
              "
            >
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.BANK_CODE.MAX_LENGTH' | translate"></span>
            </mat-error>

            <mat-error *ngIf="getCustomerFormControl('bank_code').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.BANK_CODE.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.BANK_ACCOUNT_NUMBER.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('bank_account_number')"> * </span>
          </label>
          <mat-form-field no-scroll appearance="fill" class="w-100">
            <input
              matInput
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.BANK_ACCOUNT_NUMBER.PLACEHOLDER' | translate }}"
              [formControl]="getCustomerFormControl('bank_account_number')"
            />
            <mat-error *ngIf="getCustomerFormControl('bank_account_number').errors?.maxlength">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.BANK_ACCOUNT_NUMBER.MAX_LENGTH' | translate"></span>
            </mat-error>
            <mat-error *ngIf="getCustomerFormControl('bank_account_number').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.BANK_ACCOUNT_NUMBER.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- currency and land -->
      <div class="row">
        <div class="col-lg-6">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.CURRENCY.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('currency')"> * </span>
          </label>
          <mat-form-field class="w-100" appearance="fill">
            <mat-icon aria-hidden="false" class="pt-1">search</mat-icon>
            <input
              class="pl-3"
              type="text"
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.CURRENCY.PLACEHOLDER' | translate }}"
              aria-label="Number"
              matInput
              [matAutocomplete]="currencyAuto"
              [formControl]="getCustomerFormControl('currency')"
            />
            <mat-autocomplete autoActiveFirstOption #currencyAuto="matAutocomplete">
              <mat-option *ngFor="let currency of currencyFilter | async" [value]="currency.code">
                {{ currency.code }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="getCustomerFormControl('currency').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.CURRENCY.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "CUSTOMERS.ADD.FIELDS.COUNTRY.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('country')"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              inputmode="text"
              matInput
              placeholder="{{ 'CUSTOMERS.ADD.FIELDS.COUNTRY.PLACEHOLDER' | translate }}"
              [formControl]="getCustomerFormControl('country')"
            />
            <mat-error *ngIf="getCustomerFormControl('country').errors?.required">
              <span [innerHTML]="'CUSTOMERS.ADD.FIELDS.COUNTRY.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div align="end" mat-dialog-actions>
        <button mat-button matStepperNext align="end" color="primary">Next</button>
      </div>
    </form>
  </div>
</mat-dialog-content>
