<div class="FContainer pb-0">
  <div class="FContainer">
    <h3 class="FContainer__title">{{ title }}</h3>
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="secondCustomer; else renderOneClientStepper">
    <mat-stepper #stepper class="FContainer__stepper" labelPosition="bottom">
      <mat-step [stepControl]="step1.customerForm" [errorMessage]="'GENERAL.FILL_MISSING_FIELDS' | translate">
        <ng-template matStepLabel
          >{{ customerTypesLabel.get(firstCustomer.customer_type) | translate }} Kundedetaljer
        </ng-template>
        <kt-customer-step
          [isIdentifcationNumberRequired]="true"
          [customerInfo]="firstCustomer"
          #step1
          (customerErrorsSubject)="mainCustomerHasErrors($event)"
          (customerFormValueChanged)="setFirstCustomerValue($event)"
        >
        </kt-customer-step>
      </mat-step>
      <!-- second customer -->
      <mat-step [stepControl]="step2.customerForm" [errorMessage]="'GENERAL.FILL_MISSING_FIELDS' | translate">
        <ng-template matStepLabel
          >{{ customerTypesLabel.get(secondCustomer.customer_type) | translate }}
          Kundedetaljer
        </ng-template>
        <kt-customer-step
          [customerInfo]="secondCustomer"
          #step2
          [isIdentifcationNumberRequired]="true"
          (customerErrorsSubject)="secondCustomerHasErrors($event)"
          (customerFormValueChanged)="setSecondCustomerValue($event)"
        >
        </kt-customer-step>
      </mat-step>

      <!-- vehicle form-->
      <mat-step [stepControl]="step3.vehicleForm" [errorMessage]="'GENERAL.FILL_MISSING_FIELDS' | translate">
        <ng-template matStepLabel>Køretøjsdetaljer</ng-template>
        <kt-vehicle-step
          [vehicleInfo]="vehicle"
          #step3
          (vehicleErrorsSubject)="vehicleFormHasErrors($event)"
        ></kt-vehicle-step>
        <div mat-dialog-actions align="end">
          <button mat-button matStepperPrevious>Back</button>

          <button
            *ngIf="secondCustomer"
            [ngClass]="isSubmitted ? '' : 'bg-primary text-white'"
            (click)="
              onSubmit({
                firstCustomer: step1.customerForm.value,
                secondCustomer: step2.customerForm.value,
                vehicle: step3.vehicleForm.value,
                mileagePhoto: step3.uploadMileagePhoto
              })
            "
            [disabled]="isSubmitted"
            mat-button
            color="primary"
          >
            Submit
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </ng-container>
  <ng-template #renderOneClientStepper>
    <mat-stepper #stepper class="FContainer__stepper" labelPosition="bottom">
      <mat-step [stepControl]="step1.customerForm" [errorMessage]="'GENERAL.FILL_MISSING_FIELDS' | translate">
        <ng-template matStepLabel
          >{{ customerTypesLabel.get(firstCustomer.customer_type) | translate }} Kundedetaljer
        </ng-template>
        <kt-customer-step
          [isIdentifcationNumberRequired]="true"
          [customerInfo]="firstCustomer"
          #step1
          (customerErrorsSubject)="mainCustomerHasErrors($event)"
          (customerFormValueChanged)="setFirstCustomerValue($event)"
        >
        </kt-customer-step>
      </mat-step>
      <!--    vehicle form-->
      <mat-step [stepControl]="step3.vehicleForm" [errorMessage]="'GENERAL.FILL_MISSING_FIELDS' | translate">
        <ng-template matStepLabel>Køretøjsdetaljer</ng-template>
        <kt-vehicle-step
          [vehicleInfo]="vehicle"
          #step3
          (vehicleErrorsSubject)="vehicleFormHasErrors($event)"
        ></kt-vehicle-step>
        <div mat-dialog-actions align="end">
          <button mat-button matStepperPrevious>Back</button>

          <button
            *ngIf="!secondCustomer"
            [ngClass]="isSubmitted ? '' : 'bg-primary text-white'"
            (click)="
              onSubmit({
                firstCustomer: step1.customerForm.value,
                vehicle: step3.vehicleForm.value,
                mileagePhoto: step3.uploadMileagePhoto
              })
            "
            mat-button
            [disabled]="isSubmitted"
            color="primary"
          >
            Submit
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </ng-template>
</div>
