<div ngbDropdown [placement]="'bottom-right'" class="dropdown dropdown-inline">
  <button ngbDropdownToggle type="button" class="btn btn-clean btn-sm btn-icon btn-icon-md">
    <i class="flaticon-more-v2"></i>
  </button>
  <div ngbDropdownMenu class="dropdown-menu">
    <ul class="kt-nav">
      <li class="kt-nav__item">
        <a href="javascript:;" class="kt-nav__link">
          <i class="kt-nav__link-icon flaticon2-line-chart"></i>
          <span class="kt-nav__link-text">Reports</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a href="javascript:;" class="kt-nav__link">
          <i class="kt-nav__link-icon flaticon2-send"></i>
          <span class="kt-nav__link-text">Messages</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a href="javascript:;" class="kt-nav__link">
          <i class="kt-nav__link-icon flaticon2-pie-chart-1"></i>
          <span class="kt-nav__link-text">Charts</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a href="javascript:;" class="kt-nav__link">
          <i class="kt-nav__link-icon flaticon2-avatar"></i>
          <span class="kt-nav__link-text">Members</span>
        </a>
      </li>
      <li class="kt-nav__item">
        <a href="javascript:;" class="kt-nav__link">
          <i class="kt-nav__link-icon flaticon2-settings"></i>
          <span class="kt-nav__link-text">Settings</span>
        </a>
      </li>
    </ul>
  </div>
</div>
