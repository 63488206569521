// Angular
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
// RxJS
import { Subscription } from "rxjs";
// Layout
import { LayoutConfigService } from "../../../../core/_base/layout";
// CRUD
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService, MessageType, TypesUtilsService } from "../../../../core/_base/crud";
import { ContractMileageModel, ContractMileageService } from "../index";

import * as _moment from "moment";
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from "moment";
import { EntityTypes } from "../../../shared/enums/entity/entityTypes";

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "kt-add-contract-mileage",
  templateUrl: "./add-contract-mileage.component.html",
  styleUrls: ["./add-contract-mileage.component.scss"],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class AddContractMileageComponent implements OnInit {
  // Public properties
  id: any;
  product: ContractMileageModel;
  constract_id: any;
  customers: any;
  contract_type: any;
  productForm: FormGroup;
  hasFormErrors: boolean = false;
  errorMsgs: any = {}; //Back-end errors
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;
  pickerDate: any;
  date = new FormControl(moment(), Validators.required);
  EntityTypes = EntityTypes;
  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private layoutConfigService: LayoutConfigService,
    private productsService: ContractMileageService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AddContractMileageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.constract_id = this.data.contract.id;
    this.customers = this.data.contract.customers;
    this.id = this.data.contract.mileage_id;

    console.log(this.data.contract.type);
    if (this.data.contract.type) {
      this.contract_type = this.data.contract.type;
    }

    if (this.id) {
      this.loadProductFromService(this.data.contract.mileage_id);
    } else {
      const newProduct = new ContractMileageModel();
      newProduct.clear();
      this.loadProduct(newProduct);
    }

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    // const ctrlValue = this.date.value;
    // ctrlValue.month(normalizedMonth.month());
    normalizedMonth.set({ date: 1 });
    this.date.setValue(normalizedMonth);
    datepicker.close();
  }

  loadProductFromService(productId) {
    const getMileageDetailsSubscription = this.productsService.getProductById(productId).subscribe((res) => {
      if (res.data) {
        res.data["date"] = moment(this.typesUtilsService.getDateFromString(res.data.date_from));
        this.date.setValue(res.data["date"]);
        this.loadProduct(res.data);
      }
    });
    this.componentSubscriptions.push(getMileageDetailsSubscription);
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  initProduct() {
    this.createForm();
  }

  minDate = new Date(2000, 0, 1);
  maxDate = new Date(2050, 0, 1);

  createForm() {
    this.productForm = this.productFB.group({
      // date: [this.product.date, Validators.required],
      date_from: [this.product.date_from ? moment(this.product.date_from, "DD/MM/YYYY") : ""],
      date_to: [this.product.date_to ? moment(this.product.date_to, "DD/MM/YYYY") : ""],
      customer: [this.product.customer, Validators.required],
      mileage: [this.product.mileage, Validators.required],
    });
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? +obj1.id === +obj2.id : +obj1 === +obj2;
  }

  onSubmit() {
    console.log(this.productForm.value);
    this.hasFormErrors = false;
    this.viewLoading = true;
    const controls = this.productForm.controls;
    /** check form */
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      this.hasFormErrors = true;
      this.viewLoading = false;
      return;
    }

    let payload: any = {};

    payload.date = this.typesUtilsService.formatDate(this.date.value, "MM-yyyy");
    payload.mileage = controls["mileage"].value;
    payload.contract_id = this.constract_id;
    payload.customer_id = controls["customer"].value.id;
    if (this.contract_type == EntityTypes.SPLIT_LEASING) {
      payload.date_to = this.typesUtilsService.formatDate(controls["date_to"].value, "dd-MM-yyyy");
      payload.date_from = this.typesUtilsService.formatDate(controls["date_from"].value, "dd-MM-yyyy");
    }

    if (this.id) this.editProduct(payload);
    else this.addProduct(payload);
  }

  addProduct(payload) {
    const createProductSubscription = this.productsService.addContractMileage(payload).subscribe(
      (res) => {
        this.viewLoading = false;
        if (res.data) {
          this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
        }
        this.dialogRef.close(true);
      },
      (err) => {
        this.viewLoading = false;
        if (err.error) {
          this.errorMsgs = err.error.errors;
          this.cdr.markForCheck();
          this.hasFormErrors = true;
        }
      }
    );
    this.componentSubscriptions.push(createProductSubscription);
  }

  editProduct(payload) {
    const createProductSubscription = this.productsService.editContractMileage(payload, this.id).subscribe(
      (res) => {
        this.viewLoading = false;
        if (res.data) {
          this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
        }
        this.dialogRef.close(true);
      },
      (err) => {
        this.viewLoading = false;
        if (err.error) {
          this.errorMsgs = err.error.errors;
          this.cdr.markForCheck();
          this.hasFormErrors = true;
        }
      }
    );
    this.componentSubscriptions.push(createProductSubscription);
  }

  getComponentTitle() {
    let result = this.translate.instant("CONTRACTS.DETAILS.MILEAGE.ADD_MIEAGE");
    if (!this.product || !this.product.id) {
      return result;
    }

    result = `${this.translate.instant("CONTRACTS.DETAILS.MILEAGE.EDIT_MILEAGE")} - ${this.product.mileage}`;
    return result;
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
