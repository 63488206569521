import { CustomersModel } from "./customers.model";
import { Inject } from "@angular/core";
// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS
import { Subscription } from "rxjs";
// Layout
import { SubheaderService } from "../../../core/_base/layout";
import { TranslateService } from "@ngx-translate/core";
import { MatIconRegistry } from "@angular/material/icon";
import { Location } from "@angular/common";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "kt-customer-edit",
  templateUrl: "./customer-edit.component.html",
  //changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [],
})
export class CustomerEditComponent implements OnInit, OnDestroy {
  // Public properties
  id: any;
  isReadonly: boolean = false;
  errorMsgs: any = {}; //Back-end errors
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private permissionsService: NgxPermissionsService,
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    public dialogRef: MatDialogRef<CustomerEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private location: Location
  ) {}

  ngOnInit() {
    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.permissionsService.permissions$.subscribe((res) => {
        this.isReadonly = this.id && !res.update_customer ? true : false;
      });
    });
    this.componentSubscriptions.push(routeSubscription);

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  ngAfterViewInit() {
    const TITLE = this.translate.instant("CUSTOMERS.VIEW.TITLE");
    const ADD_TITLE = this.translate.instant("CUSTOMERS.ADD.ADD_TITLE");
    const EDIT_TITLE = this.translate.instant("CUSTOMERS.ADD.EDIT_TITLE");
    if (!this.id) {
      this.subheaderService.setBreadcrumbs([
        { title: TITLE, page: `/customers` },
        { title: ADD_TITLE, page: `/customers/add` },
      ]);
      return;
    }
    this.subheaderService.setBreadcrumbs([
      { title: TITLE, page: `/customers` },
      { title: EDIT_TITLE, page: `/customers/edit/${this.id}` },
    ]);
  }

  goBackToListPage(customer) {
    if (this.dialogData.isCancelEnabled) {
      this.closeSelfDialog(customer);
    } else {
      this.location.back();
    }
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  getComponentTitle() {
    let result = this.translate.instant("CUSTOMERS.ADD.ADD_TITLE");
    if (!this.id) {
      return result;
    }

    result = `${this.translate.instant("CUSTOMERS.ADD.EDIT_TITLE")}`;
    return result;
  }

  /**
   * close create customer dialog
   * used in contract details
   * and pass the created customer
   * if existed
   * @param customer
   */
  closeSelfDialog(customer?: CustomersModel) {
    this.dialogRef.close(customer);
  }
}
