// Angular
import { animate, state, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
// CRUD
import { HttpClient } from "@angular/common/http";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { fromEvent, merge, of as observableOf, Subscription } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, filter, map, tap } from "rxjs/operators";
import { ContractMileageModel, ContractMileageService } from ".";
import { API, LayoutUtilsService, MessageType } from "../../../core/_base/crud";
// import { VehicleMileageEditComponent } from './vehicle-mileage-edit-dialog.component';
import { MatButton } from "@angular/material/button";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { currentUser } from "../../../core/auth";
import { AppState } from "../../../core/reducers";
import { AddContractMileageComponent } from "./add-contract-mileage/add-contract-mileage.component";
import { CreateAdjustmentPaymentsComponent } from "./create-adjustment-payments/create-adjustment-payments.component";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { GlobalUtilsService } from "../../../core/_base/crud/utils/global-utils.service";
import { MAT_DATA_TABLES_CONTRACTS_MILEAGE } from "../../../core/constants/local-storage-constants";

@Component({
  selector: "kt-contract-mileage-list",
  templateUrl: "./contract-mileage-list.component.html",
  styleUrls: ["./contract-mileage-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*", marginBottom: "10px" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
    ]),
  ],
})
export class ContractMileageListComponent implements OnInit, OnDestroy {
  contractId: any;
  customers: any = [];
  private subscriptions: Subscription[] = [];
  displayedColumns: string[] = ["expand", "date_from", "date_to", "customer", "mileage", "actions"];
  expandedElement: ContractMileageModel | null;
  data: ContractMileageModel[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
  @ViewChild("searchButton", { static: true }) searchButton: MatButton;

  filtersState; // The currently saved filters in localStorage
  userId: any; //Logged in user id
  contractType: any;

  constructor(
    private _httpClient: HttpClient,
    private productsService: ContractMileageService,
    private layoutUtilsService: LayoutUtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ContractMileageListComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    if (this.dialogData.contract_id) {
      // If component is opend as modal
      this.contractId = this.dialogData.contract_id;
      this.customers = this.dialogData.customers;
      this.contractType = this.dialogData.contract_type;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.paginator.pageSize = this.layoutUtilsService.paginatorDefaultOptions.pageSize;
      this.paginator.pageSizeOptions = this.layoutUtilsService.paginatorDefaultOptions.pageSizeOptions;
      this.sort.direction = "desc";

      // If the user changes the sort order, reset back to the first page.
      const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      this.subscriptions.push(sortSubscription);

      const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadProductsList()))
        .subscribe();
      this.subscriptions.push(paginatorSubscriptions);

      // Filtration, bind to searchInput
      // Filtration, bind to searchInput
      const searchButtonClickEvent = fromEvent(this.searchButton._elementRef.nativeElement, "click").pipe(
        distinctUntilChanged()
      );
      const searchBarEnterKeyEvent = fromEvent(this.searchInput.nativeElement, "keyup").pipe(
        filter((event: KeyboardEvent) => event.code === "Enter" || event.code === "NumpadEnter"),
        distinctUntilChanged()
      );
      const searchSubscription = merge(searchBarEnterKeyEvent, searchButtonClickEvent)
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(() => {
            this.paginator.pageIndex = 0;
            this.loadProductsList();
          })
        )
        .subscribe();
      this.subscriptions.push(searchSubscription);

      this.store.pipe(select(currentUser)).subscribe(
        (user) => {
          if (user) {
            this.userId = user.id;

            if (localStorage.getItem(`${MAT_DATA_TABLES_CONTRACTS_MILEAGE}_${this.userId}`)) {
              this.filtersState = GlobalUtilsService.getLocalStorageFilter(
                `${MAT_DATA_TABLES_CONTRACTS_MILEAGE}_${this.userId}`
              );
              this.searchInput.nativeElement.value = this.filtersState.search;
              this.paginator.pageSize = this.filtersState.per_page;
              this.paginator.pageIndex = this.filtersState.page - 1;
              this.sort.active = this.filtersState.order_column;
              this.sort.direction = this.filtersState.order_dir;
            }

            observableOf(undefined).subscribe(() => {
              this.loadProductsList();
            });
          }
        },
        (err) => {
          observableOf(undefined).subscribe(() => {
            this.loadProductsList();
          });
        }
      );
    });
  }

  loadProductsList() {
    this.isLoadingResults = true;

    this.productsService
      .indexProduct(this.filterConfiguration())
      .pipe(
        map((data) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.recordsFiltered;
          this.changeDetectorRef.markForCheck();
          return data.data;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Return empty data if error occurs
          this.isRateLimitReached = true;
          this.changeDetectorRef.markForCheck();
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.data = data));
  }

  filterConfiguration(): IRequestPagination {
    let filter: IRequestPagination = {
      order_column: this.sort.active,
      order_dir: this.sort.direction,
      page: this.paginator.pageIndex + 1,
      per_page: this.paginator.pageSize,
      search: this.searchInput.nativeElement.value,
    };
    filter["contract_id"] = this.contractId;
    return filter;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
    this.saveDatatableState();
  }

  @HostListener("window:unload", ["$event"])
  saveDatatableState() {
    localStorage.setItem(
      `${MAT_DATA_TABLES_CONTRACTS_MILEAGE}_${this.userId}`,
      JSON.stringify(this.filterConfiguration())
    );
  }

  /**
   * Add role
   */
  addProduct() {
    const newValue = new ContractMileageModel();
    newValue.clear(); // Set all defaults fields
    this.editProduct(newValue);
  }

  /**
   * Edit role
   *
   * @param role: Role
   */
  editProduct(product) {
    const dialogRef = this.dialog.open(AddContractMileageComponent, {
      data: {
        contract: { id: this.contractId, type: this.contractType, customers: this.customers, mileage_id: product.id },
      },
      width: "440px",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.loadProductsList();
    });
  }

  getMileageData() {
    this.productsService.getMileageData(this.contractId).subscribe(
      (res) => {
        if (res.data) {
          this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Delete);
        }
        this.changeDetectorRef.detectChanges();
        this.loadProductsList();
      },
      (err) => {
        if (err.error.data) {
          this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
        }
      }
    );
  }

  createAdjustPayments() {
    const dialogRef = this.dialog.open(CreateAdjustmentPaymentsComponent, {
      data: {
        contract: { id: this.contractId },
      },
      width: "800px",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.loadProductsList();
    });
  }

  deleteMileage(productId) {
    const _title: string = this.translate.instant("CONTRACTS.DETAILS.MILEAGE.DELETE_MODAL.TITLE");
    const _description: string = this.translate.instant("CONTRACTS.DETAILS.MILEAGE.DELETE_MODAL.DESCRIPTION");
    const _waitDesciption: string = this.translate.instant("CONTRACTS.DETAILS.MILEAGE.DELETE_MODAL.WAIT_DESCRIPTION");

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.beforeClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.productsService.deleteMileage(productId).subscribe(
        (res) => {
          if (res.data) {
            this.layoutUtilsService.showActionNotification(res.data.message, MessageType.Delete);
          }
          this.changeDetectorRef.detectChanges();
          this.loadProductsList();
        },
        (err) => {
          if (err.error.data) {
            this.layoutUtilsService.showActionNotification(err.error.data.message, MessageType.Delete);
          }
        }
      );
    });
  }
}
