// Angular
import { Component, Input, HostListener, Output, EventEmitter } from "@angular/core";

export interface ISearchResult {
  icon: string;
  img: string;
  title: string;
  text: string;
  url: string;
}

@Component({
  selector: "kt-search-result",
  templateUrl: "./search-result.component.html",
  styleUrls: ["./search-result.component.scss"],
})
export class SearchResultComponent {
  // Public properties
  @Input() data: ISearchResult[];
  @Input() noRecordText: string;

  length: 0;
  domElements;

  @Output() redirectToSearchResult: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngAfterViewInit() {
    // '.kt-quick-search__result' has direct children as '.kt-quick-search__result__child'
    // We will loop through them and set the focus to the anchor inside which is '.kt-quick-search__item-title'
    this.domElements = document.querySelectorAll(".kt-quick-search__result > *"); // .kt-quick-search__result__child
    this.length = this.domElements.length;
  }

  redirectToResult() {
    this.redirectToSearchResult.emit("redirect");
  }

  @HostListener("document:keydown", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    const activeEle: any = document.activeElement; // <a>
    const isSearchInputActive =
      activeEle && activeEle.className && activeEle.className.includes("kt-quick-search__input");
    if (isSearchInputActive) {
      const activeItem = Array.prototype.find.call(this.domElements, (el) => el.classList.contains("active"));
      if (event.keyCode === 40) {
        // Down key
        if (activeItem) {
          activeItem.classList.remove("active");
          if (activeItem.nextElementSibling) {
            activeItem.nextElementSibling.classList.add("active");
            activeItem.nextElementSibling.scrollIntoView();
          } else this.activateElementAtIndex(0);
        } else this.activateElementAtIndex(0);
      } else if (event.keyCode === 38) {
        // Up key
        if (activeItem) {
          activeItem.classList.remove("active");
          if (activeItem.previousElementSibling) {
            activeItem.previousElementSibling.classList.add("active");
            activeItem.previousElementSibling.scrollIntoView();
          } else this.activateElementAtIndex(this.domElements.length - 1);
        } else this.activateElementAtIndex(this.domElements.length - 1);
      } else if (event.keyCode === 13) {
        // Enter key
        if (activeItem) {
          if (activeItem.querySelectorAll("a") && activeItem.querySelectorAll("a").item(0)) {
            activeItem.querySelectorAll("a").item(0).click();
            if (<any>document.activeElement) {
              (<any>document.activeElement).click(); // To close the dropdown
            }
          }
        }
      }
    }
  }

  activateElementAtIndex(index) {
    this.domElements.item(index).classList.add("active");
    this.domElements.item(index).scrollIntoView();
  }
}
