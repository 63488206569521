import { Component, OnInit, Input, SimpleChange } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import { EntityTypes } from "../enums/entity/entityTypes";
import { EntityType } from "../enums/entity/entityType";

@Component({
  selector: "kt-calculation-results",
  templateUrl: "./calculation-results.component.html",
  styleUrls: ["./calculation-results.component.scss"],
})
export class CalculationResultsComponent implements OnInit {
  view_profit = false;
  percentageKeyFigures: string[] = ["self_financing_percent", "Return_of_investment", "total_depreciation"];
  entityTypes = EntityTypes;
  @Input() calculations: any;
  @Input() summary: any;
  @Input() entityType: any;
  @Input() entityTypeCode: string; // contract type code
  @Input() withAccordion = true; // whether we need an accordion to hide the details
  @Input() applyStyle = false;
  @Input() investment: number = 0;
  @Input() investmentTitle: string = "Investment";
  @Input() showKeyFigures: boolean = true;
  firstPaymentExpanded = false;
  monthlyPaymentExpanded = false;
  profitExpanded = false;
  keyFiguresExpanded = false;
  corpKeyFiguresExpanded = false;

  constructor(private permissionsService: NgxPermissionsService) {}

  ngOnInit() {
    this.permissionsService.permissions$.subscribe((res) => {
      if (this.entityType === EntityType.CONTRACT) {
        this.view_profit = !!res.view_contract_profit;
      } else {
        this.view_profit = !!res.view_offer_profit;
      }

      if (this.withAccordion) {
        this.firstPaymentExpanded = true;
        this.monthlyPaymentExpanded = true;
        this.profitExpanded = true;
        this.keyFiguresExpanded = true;
        this.corpKeyFiguresExpanded = true;
      }
    });
  }
}
