<div class="kt-portlet">
  <!-- Header -->
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ "PAYMENTS.ADD.ADD_TITLE" | translate }}
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <!-- Content -->
  <div class="kt-form">
    <!-- switcher -->
    <div class="switcher" style="padding: 0 25px">
      <mat-radio-group aria-label="Active dialog" [(ngModel)]="activePaymentMode">
        <mat-radio-button value="SINGLE">{{ "PAYMENTS.ADD.SINGLE_PAYMENT" | translate }}</mat-radio-button>
        <mat-radio-button value="MONTHLY">{{ "PAYMENTS.ADD.MONTHLY_PAYMENT" | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Single Payment -->
    <ng-container *ngIf="activePaymentMode === 'SINGLE'">
      <kt-create-single-payment></kt-create-single-payment>
    </ng-container>

    <!-- Monthly Payment -->
    <ng-container *ngIf="activePaymentMode === 'MONTHLY'">
      <kt-create-monthly-payment></kt-create-monthly-payment>
    </ng-container>
  </div>
</div>
