<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ getComponentTitle() }}</h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body">
      <form [formGroup]="productForm" autocomplete="off" class="kt-form">
        <div class="form-group kt-form__group">
          <div
            class="extra_equipment_values"
            formArrayName="extra_equipment_value"
            *ngFor="let equipment of productForm.controls.extra_equipment_value['controls']; let i = index"
          >
            <div class="equipment-value" [formGroupName]="i">
              <div class="mb-1">
                <div class="row mt-3">
                  <div class="col-lg-6 col-sm-5 col-5 kt-margin-bottom-20-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.NAME.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['extra_equipment_value.' + i + '.name']
                      }"
                    >
                      <input
                        matInput
                        placeholder="{{
                          'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.NAME.PLACEHOLDER' | translate
                        }}"
                        formControlName="name"
                        type="text"
                      />
                      <mat-error
                        *ngIf="
                          productForm.controls.extra_equipment_value.controls[i].controls.name.hasError('required')
                        "
                      >
                        <span
                          [innerHTML]="
                            'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.NAME.ERROR_MSG' | translate
                          "
                        ></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['extra_equipment_value.' + i + '.name']">{{
                      errorMsgs["extra_equipment_value." + i + ".name"]
                    }}</mat-error>
                  </div>
                  <div class="col-lg-6 col-sm-5 col-5 kt-margin-bottom-20-mobile">
                    <label class="mat-input-label">{{
                      "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.VALUE.PLACEHOLDER" | translate
                    }}</label>
                    <mat-form-field
                      class="mat-form-field-fluid"
                      [ngClass]="{
                        'mat-form-field-invalid': errorMsgs['extra_equipment_value.' + i + '.value']
                      }"
                    >
                      <input
                        matInput
                        currencyMask
                        placeholder="{{
                          'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.VALUE.PLACEHOLDER' | translate
                        }}"
                        formControlName="value"
                        type="text"
                      />
                      <mat-error
                        *ngIf="
                          productForm.controls.extra_equipment_value.controls[i].controls.value.hasError('required')
                        "
                      >
                        <span
                          [innerHTML]="
                            'VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.VALUE.ERROR_MSG' | translate
                          "
                        ></span>
                      </mat-error>
                    </mat-form-field>
                    <mat-error *ngIf="errorMsgs['extra_equipment_value.' + i + '.value']">{{
                      errorMsgs["extra_equipment_value." + i + ".value"][0]
                    }}</mat-error>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-sm-1 col-2 align-self-center">
                    <a *ngIf="i == 0" href="javascript:;" (click)="addNewEquipment()" class="kt-font-primary d-block">
                      <span>
                        <i class="fas fa-plus" style="font-size: 10px"></i>
                        {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.ADD_ANOTHER_EQUIPMENT" | translate }}
                      </span>
                    </a>
                    <a *ngIf="i !== 0" href="javascript:;" (click)="removeEquipment(i)" class="kt-font-danger d-block">
                      <span>
                        <i class="fas fa-minus" style="font-size: 10px"></i>
                        {{ "VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.REMOVE_EQUIPMENT" | translate }}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="dialogRef.close()">
          {{ "GENERAL.CANCEL" | translate }}</button
        >&nbsp;
        <button
          mat-button
          (click)="onSubmit()"
          color="primary"
          cdkFocusInitial
          *ngIf="!isReadonly"
          [disabled]="viewLoading"
        >
          {{ "GENERAL.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
