export class CustomersModel {
  id: number;
  name: string;
  company_name: any;
  address: string;
  postal_code: string;
  city: string;
  phone: string;
  id_number: string;
  company_contact_name: string;
  account_manger: any;
  manager: any;
  customer_type_id: any;
  customer_type: any;
  payment_term_id: any;
  bank_code: any;
  bank_account_number: any;
  risk_class_id: number;
  risk_class: any;
  email: string;
  country: string = "Danmark";
  currency: string;
  created_at: string;
  updated_at: string;
  birthdate?: string;
  customer_id?: number;
  payment_eligible: number;
  ean_number?: string;

  clear() {
    this.name = "";
    this.email = "";
    this.address = "";
    this.postal_code = "";
    this.created_at = "";
    this.ean_number = "";
    this.updated_at = null;
  }
}
