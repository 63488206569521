export class ContractMileageModel {
  id: number;
  contract_id: any;
  customer_id: any;
  mileage: any;
  customer: any;
  date: any;
  date_from: any;
  date_to: any;
  split_adj_date: any;

  clear() {
    this.mileage = "";
    this.date = null;
    this.date_from = null;
    this.date_to = null;
    this.split_adj_date = null;
  }
}
