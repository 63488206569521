// Angular
import { ChangeDetectorRef, Component, Inject, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
// RxJS
import { Observable, Subscription, of } from "rxjs";
// Layout
// CRUD
import { TranslateService } from "@ngx-translate/core";
import { LayoutUtilsService, TypesUtilsService } from "app/core/_base/crud";
import { DropzoneComponent } from "ngx-dropzone-wrapper";
import { debounceTime, map, startWith, switchMap } from "rxjs/operators";
import { MarketPriceProviders } from "../enums/vehicle/marketPriceProviders";
import { SharedService } from "../shared.service";
import { addComparableVehicleModel } from "./add-comparable-vehicle.model";

@Component({
  selector: "kt-add-comparable-vehicle",
  templateUrl: "./add-comparable-vehicle.component.html",
  styleUrls: ["./add-comparable-vehicle.component.scss"],
})
export class AddComparableVehicleComponent implements OnInit {
  @ViewChildren("documentsDropzone") documentsDropzone: QueryList<DropzoneComponent>;
  contract;
  vehicleData: any = {};
  dropZoneConfig: any = {
    acceptedFiles: ".jpeg,.png,.jpg,.bmp,.gif",
    maxFilesize: 20,
    maxFiles: 1,
  };
  // Public properties
  product: addComparableVehicleModel;
  vehicleId: any;
  productForm: FormGroup;
  webPortals: any[];
  marketPriceDocuments: any[];
  filteredMakes: Observable<any>;
  filteredModels: Observable<any>;
  vehicleToEditIndex: number = -1;
  hasFormErrors: boolean = false;
  calculations: any;
  @Input() isReadonly: boolean = false;
  dropZoneMessage: any;
  errorMsgs: any = {}; //Back-end errors
  documentsTooltipMsg = `
		- Upload PDF's for each advertised car. All content needs to be viewable as well as a timestamp for the ad.
		- The vehicle's price as new including all extra equipment.
		- Copy of original registration certificate
		- Copy of vehicle purchase invoice
		- Servicebook to document mileage
		- Other relevant documentation
	`;
  // Private password
  private componentSubscriptions: Subscription[] = [];
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;

  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private typesUtilsService: TypesUtilsService,
    private productsService: SharedService,
    public dialogRef: MatDialogRef<AddComparableVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    this.dropZoneMessage = `
			<div class="dropzone-placeholder">
				<img class="file-icon" src="assets/media/icons/document-grey.png">
				<span class="dropzone-placeholder-text">
					${this.translate.instant("VEHICLE_VALUATION.VEHICLE_PRICE.MARKET_PRICE_DOCUMENTS.DROPZONE.PLACEHOLDER")}
				</span>
			</div>
		`;
  }

  ngOnInit(): void {
    this.contract = this.data.contract;
    let vehicles = this.data.vehicles;
    this.vehicleToEditIndex = this.data.vehicleToEditIndex;
    if (this.vehicleToEditIndex >= 0) {
      this.product = vehicles[this.vehicleToEditIndex];
      this.loadProduct(this.product);
    } else {
      const newProduct = new addComparableVehicleModel();
      newProduct.clear();
      this.loadProduct(newProduct || {});
    }

    this.vehicleId = this.contract && this.contract.asset_details && this.contract.asset_details.id;

    const webPortalsSubscription = this.productsService.getLookups("ad_web_portal").subscribe((res) => {
      this.webPortals = res.data;
      this.setDefaultWebPortal();
    });
    this.componentSubscriptions.push(webPortalsSubscription);

    const marketPriceDocumentsSubscription = this.productsService
      .getLookups("vehicle_market_price_documents")
      .subscribe((res) => {
        this.marketPriceDocuments = res.data;
      });
    this.componentSubscriptions.push(marketPriceDocumentsSubscription);

    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  initProduct() {
    this.createForm();
  }

  setDefaultWebPortal() {
    if (
      this.product &&
      !this.product.ad_web_portal &&
      this.productForm &&
      this.productForm.get("ad_web_portal") &&
      !this.productForm.get("ad_web_portal").value
    ) {
      if (this.webPortals && this.webPortals.length > 0) {
        let defaultWebPortal = this.webPortals.find((t) => t.code == MarketPriceProviders.BILBASEN_DK);
        this.productForm.get("ad_web_portal").setValue(defaultWebPortal.id);
      }
    }
  }

  createForm() {
    let extraEquipmentArray: FormGroup[] = [];
    if (this.product.extra_equipment_value && this.product.extra_equipment_value.length > 0) {
      this.product.extra_equipment_value.forEach((equipment) => {
        extraEquipmentArray.push(
          this.productFB.group({
            name: [equipment.name],
            value: [equipment.value],
          })
        );
      });
    } else {
      extraEquipmentArray.push(
        this.productFB.group({
          name: [""],
          value: [""],
        })
      );
    }

    let documentsArray: FormGroup[] = [];
    if (this.product.documents && this.product.documents.length > 0) {
      this.product.documents.forEach((document) => {
        documentsArray.push(
          this.productFB.group({
            file: [document.file],
            file_id: [document.file_id],
            file_name: [document.file ? document.file.name : document?.file_name ? document?.file_name : ""],
            vehicle_market_price_document_id: [document.vehicle_market_price_document_id, Validators.required],
            is_first: [false],
            url: [document.url || ""],
            vehicleToEditIndex: this.vehicleToEditIndex,
          })
        );
      });
    } else {
      // To have at least one default dropzone
      documentsArray.push(
        this.productFB.group({
          file: [null],
          file_id: [null],
          file_name: [""],
          vehicle_market_price_document_id: ["", Validators.required],
          is_first: [true],
          vehicleToEditIndex: this.vehicleToEditIndex,
        })
      );
    }

    this.productForm = this.productFB.group({
      ad_web_portal: [{ value: this.product.ad_web_portal, disabled: this.isReadonly }, Validators.required],
      ad_web_url: [{ value: this.product.ad_web_url, disabled: this.isReadonly }],
      mileage: [{ value: this.product.mileage, disabled: this.isReadonly }, [Validators.required, Validators.min(1)]],
      advertised_price: [
        { value: this.product.advertised_price_cents, disabled: this.isReadonly },
        Validators.required,
      ],
      first_registration_date: [
        {
          value: this.typesUtilsService.getDateFromString(this.product.first_registration_date),
          disabled: this.isReadonly,
        },
        Validators.required,
      ],
      vehicle_make: [{ value: this.product.vehicle_make, disabled: this.isReadonly }, Validators.required],
      vehicle_model: [{ value: this.product.model, disabled: this.isReadonly }, Validators.required],
      vehicle_new_price: [
        { value: this.product.vehicle_new_price, disabled: this.isReadonly },
        [Validators.required, Validators.min(1)],
      ],
      extra_equipment_value: this.productFB.array(extraEquipmentArray),
      documents: this.productFB.array(documentsArray),
    });

    this.searchForVehicleMakes(0);
    this.setDefaultWebPortal();
    this.productForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.calculateAdjustmentTax();
    });

    if (this.product.vehicle_market_price && this.product.vehicle_market_price.length > 0) {
      this.product.vehicle_market_price.forEach((vehicle, index) => {
        this.searchForMakeModels(false); // To get model for every make row
      });
      // To call calculateRegistrationTax()
      this.productForm.updateValueAndValidity({
        onlySelf: false,
        emitEvent: true,
      });
    }
  }

  loadBilbasenAdInfo() {
    this.viewLoading = true;
    let payload = {
      ad_web_url: this.productForm.get("ad_web_url").value,
    };
    const bilbasenAdInfoSubscription = this.productsService.getBilbasenAdInfo(payload).subscribe(
      (res) => {
        console.log("loadBilbasenAdInfo", res.data);
        this.viewLoading = false;
        const info = res.data;
        if (info.mileage) {
          this.productForm.controls["mileage"].setValue(parseInt(info.mileage));
        }
        if (info.ad_price) {
          this.productForm.controls["advertised_price"].setValue(info.ad_price);
        }
        if (info.make) {
          this.productForm.controls["vehicle_make"].setValue(info.makeObj);
        }
        if (info.modelObj) {
          this.productForm.controls["vehicle_model"].setValue(info.modelObj);
          console.log("vehicle_model", this.productForm.get("vehicle_model").value);
        }
        if (info.new_price) {
          this.productForm.controls["vehicle_new_price"].setValue(info.new_price);
        }
        if (info.first_registration) {
          this.productForm.controls["first_registration_date"].setValue(info.first_registration);
        }
        if (info.doc) {
          const document = info.doc;
          const marketPriceDocument = this.marketPriceDocuments.filter((marketPriceDocument) => {
            return marketPriceDocument.code == "advertised_car";
          })[0];

          this.productForm.controls["documents"] = this.productFB.array([]);
          (<FormArray>this.productForm.get("documents")).push(
            this.productFB.group({
              file: [document.file],
              url: [document.url],
              file_id: [null],
              file_name: [document.file_name ? document.file_name : ""],
              vehicle_market_price_document_id: [marketPriceDocument.id, Validators.required],
              is_first: [true],
              crawled: document?.crawled,
              vehicleToEditIndex: this.vehicleToEditIndex,
            })
          );

          console.log(this.productForm.get("documents"));
        }

        this.searchForMakeModels();
        this.calculateAdjustmentTax();
      },
      (err) => {
        this.viewLoading = false;
      }
    );
    this.componentSubscriptions.push(bilbasenAdInfoSubscription);
  }

  calculateAdjustmentTax() {
    let payload = {};
    this.calculations = null;
    if (this.productForm.get("extra_equipment_value").value) {
      let extra_equipment_value: any[] = this.productForm
        .get("extra_equipment_value")
        .value.filter((eq) => eq.name && eq.value && eq.name != "" && eq.value != "");

      if (extra_equipment_value && extra_equipment_value.length > 0) {
        payload["extra_equipment_value"] = extra_equipment_value;
      }
    }

    if (this.productForm.get("ad_web_portal").value) {
      payload["ad_web_portal"] = this.productForm.get("ad_web_portal").value;
    }
    if (this.productForm.get("mileage").value && this.productForm.get("mileage").value >= 1) {
      payload["mileage"] = this.productForm.get("mileage").value;
    }
    // if (this.productForm.get("advertised_price").value && this.productForm.get("advertised_price").value >= 1) {
    payload["advertised_price"] = this.productForm.get("advertised_price").value;
    // }
    if (this.productForm.get("first_registration_date").value) {
      payload["first_registration_date"] = this.typesUtilsService.formatDate(
        this.productForm.get("first_registration_date").value,
        "dd-MM-yyyy"
      );
    }

    if (this.productForm.get("vehicle_make").value) {
      payload["make_id"] = this.productForm.get("vehicle_make").value.id;
    }
    if (this.productForm.get("vehicle_model").value) {
      console.log("vehicle_model get", this.productForm.get("vehicle_model").value);
      payload["model_id"] = this.productForm.get("vehicle_model").value.id;
    }
    if (this.productForm.get("vehicle_new_price").value && this.productForm.get("vehicle_new_price").value >= 1) {
      payload["vehicle_new_price"] = this.productForm.get("vehicle_new_price").value;
    }
    console.log("this.productForm", this.productForm);
    if (
      this.productForm.value.ad_web_portal &&
      this.productForm.value.mileage &&
      this.productForm.value.first_registration_date &&
      this.productForm.value.vehicle_make &&
      this.productForm.value.vehicle_model && //
      this.productForm.value.vehicle_new_price
    ) {
      const registrationTaxCalculatorSubscription = this.productsService
        .priceAdjustmentCalculator(payload, this.vehicleId)
        .subscribe(
          (res) => {
            console.log("priceAdjustmentCalculator calculations", res);
            this.calculations = res.data;
            this.hasFormErrors = false;
            this.cdr.markForCheck();
          },
          (err) => {
            this.viewLoading = false;
            if (err.error) {
              this.errorMsgs = err.error.errors;
              this.cdr.markForCheck();
              this.hasFormErrors = true;
            }
          }
        );
      this.componentSubscriptions.push(registrationTaxCalculatorSubscription);
    }
  }

  searchForVehicleMakes(rowIndex) {
    this.filteredMakes = (<FormGroup>this.productForm).controls.vehicle_make.valueChanges.pipe(
      startWith(""),
      debounceTime(200),
      switchMap((value) => {
        if (typeof value == "string") {
          if (value == "" && !(<FormGroup>this.productForm).controls.vehicle_make.value) {
            (<FormGroup>this.productForm).controls.vehicle_model.reset();
            (<FormGroup>this.productForm).controls.vehicle_model.disable();
          } else (<FormGroup>this.productForm).controls.vehicle_model.enable();
          return this.productsService.getVehicleMakeList(value, this.contract.asset_details.vehicle_type.id);
        } else return of([]);
      }),
      map((res) => res.data)
    );
    this.cdr.detectChanges();
  }

  searchForMakeModels(withReset?) {
    console.log("searchForMakeModels 0");

    if (this.productForm) {
      if (withReset) {
        (<FormGroup>this.productForm).controls.vehicle_model.reset();
      }
      (<FormGroup>this.productForm).controls.vehicle_model.enable();
      const vehicle_make_id = (<FormGroup>this.productForm).controls.vehicle_make.value
        ? (<FormGroup>this.productForm).controls.vehicle_make.value.id
        : null;
      this.filteredModels = (<FormGroup>this.productForm).controls.vehicle_model.valueChanges.pipe(
        startWith(""),
        debounceTime(200),
        switchMap((value) => {
          console.log("searchForMakeModels", value);
          if (typeof value == "string") {
            return this.productsService.getMakeModelsList(
              value,
              vehicle_make_id,
              this.contract.asset_details.vehicle_type.id
            );
          } else return of([]);
        }),
        map((res) => res.data)
      );
      this.cdr.detectChanges();
    }
  }

  setPhotoFile(file, rowIndex) {
    const documentsArray = <FormArray>this.productForm.get("documents");
    if (documentsArray) {
      if (documentsArray.controls) {
        const photoRow = <FormGroup>documentsArray.controls[rowIndex];
        if (photoRow) {
          if (photoRow.controls) {
            const photoControl = <FormControl>photoRow.controls["file"];
            if (photoControl) {
              photoControl.setValue(file);
            }
          }
        }
      }
    }
  }

  addNewDocumentRow() {
    (<FormArray>this.productForm.get("documents")).push(
      this.productFB.group({
        vehicle_market_price_document_id: [""],
        file_id: [null],
        file_name: [""],
        file: [null],
        is_first: [false],
        vehicleToEditIndex: this.vehicleToEditIndex,
      })
    );
  }

  removeDocumentRow(index) {
    (<FormArray>this.productForm.get("documents")).removeAt(index);
    this.addNewDocumentRow();
  }

  addNewEquipment() {
    (<FormArray>this.productForm.get("extra_equipment_value")).push(
      this.productFB.group({
        name: [""],
        value: [""],
      })
    );
  }

  removeEquipment(index) {
    (<FormArray>this.productForm.get("extra_equipment_value")).removeAt(index);
  }

  displayFn(product: any): string {
    return product && product.name ? product.name : "";
  }

  modelDisplayFn(vehicle_model: any): string {
    return vehicle_model && vehicle_model.model ? vehicle_model.model : "";
  }

  addVehicle() {
    this.hasFormErrors = false;
    const controls = this.productForm.controls;

    /** check form */
    console.log("addVehicle", this.productForm.invalid);
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAllAsTouched());

      this.hasFormErrors = true;
      // return;
    }

    // let fd: FormData = this.prepareProduct();
    this.prepareProduct();
    if (controls["documents"].value[0].vehicleToEditIndex == -1) {
      if (!this.data.vehicles || (this.data.vehicles && this.data.vehicles.length == 0))
        controls["documents"].value[0].vehicleToEditIndex = 0;
      else controls["documents"].value[0].vehicleToEditIndex = this.data.vehicles.length;
    }
    console.log("this.vehicleToEditIndex", this.vehicleToEditIndex);
    console.log("this.vehicleToEditIndex", this.vehicleData);
    console.log("this.vehicleToEditIndex documents", controls["documents"].value);

    if (this.vehicleData.model)
      this.dialogRef.close({
        success: true,
        documents: controls["documents"].value.file ? controls["documents"].value : controls["documents"].value,
        vehicleToEditIndex: this.vehicleToEditIndex,
        vehicle_data: this.vehicleData,
      });
  }

  storeRegistrationTax(payload) {
    const createProductSubscription = this.productsService.storeRegistrationTax(payload, this.vehicleId).subscribe(
      (res) => {
        this.viewLoading = false;
        // this.vehicleValuated.emit(this.calculations.total_registration_tax);
        this.errorMsgs = {};
        this.cdr.markForCheck();
      },
      (err) => {
        this.viewLoading = false;
        if (err.error) {
          this.errorMsgs = err.error.errors;
          this.cdr.markForCheck();
          this.hasFormErrors = true;
        }
      }
    );
    this.componentSubscriptions.push(createProductSubscription);
  }

  /**
   * Returns object for saving
   */
  prepareProduct() {
    console.log("calculations", this.calculations);
    if (this.calculations) {
      this.productForm.patchValue(this.calculations);
    }

    const controls = { ...this.productForm.controls };
    this.vehicleData["ad_web_portal"] = controls["ad_web_portal"].value;
    this.vehicleData["mileage"] = controls["mileage"].value;
    this.vehicleData["advertised_price_cents"] = controls["advertised_price"].value;
    this.vehicleData["first_registration_date"] = this.typesUtilsService.formatDate(
      controls["first_registration_date"].value,
      "dd-MM-yyyy"
    );
    this.vehicleData["vehicle_make"] = controls["vehicle_make"].value;
    this.vehicleData["model"] = controls["vehicle_model"].value;
    // if (this.calculations && this.calculations.adjusted_mileage) {
    this.vehicleData["adjusted_mileage_cents"] = this.calculations?.adjusted_mileage;
    // }

    if (this.calculations?.extra_equipment_deduction) {
      this.vehicleData["extra_equipment_adjustment_cents"] = this.calculations?.extra_equipment_deduction;
    }
    this.vehicleData["adjusted_price_cents"] = this.calculations?.adjusted_price;
    this.vehicleData["year_adjustment_cents"] = this.calculations?.year_adjustment;
    this.vehicleData["model_adjustment_cents"] = this.calculations?.model_adjustment;
    this.vehicleData["adjusted_advertised_price_cents"] = this.calculations?.adjusted_advertised_price;
    this.vehicleData["vehicle_new_price"] = controls["vehicle_new_price"].value;

    if (this.productForm.get("extra_equipment_value").value) {
      let extra_equipment_value: any[] = this.productForm
        .get("extra_equipment_value")
        .value.filter((eq) => eq.name && eq.value && eq.name != "" && eq.value != "");

      if (extra_equipment_value && extra_equipment_value.length > 0) {
        this.vehicleData["extra_equipment_value"] = extra_equipment_value;
      }
    }

    // if (controls['extra_equipment_value'].value) {
    //   console.log(controls['extra_equipment_value'].value);
    //   this.vehicleData["extra_equipment_value"] = controls['extra_equipment_value'].value;
    // }
    // else {
    //   this.vehicleData["extra_equipment_value"] = null;
    // }

    if (controls["documents"].value) {
      this.vehicleData["documents"] = controls["documents"].value;
    }
  }

  getComponentTitle() {
    let result = this.translate.instant("VEHICLE_VALUATION.VEHICLE_PRICE.SUB_TITLE");

    return result;
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
