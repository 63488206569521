export class InvoiceModel {
  id: number;
  contract_id: any;
  date: any;
  amount_excl_vat_cents: number;
  notes_headline: string;
  notes_text_1: string;
  payment_term_id: any;
  payment_term: any;
  invoice_date: any;
  payments_up_to_date: any;
  payment_due_date: any;
  created_at: string;
  updated_at: string;

  clear() {
    this.date = "";
    this.notes_headline = "";
    this.notes_text_1 = "";
    this.created_at = "";
    this.updated_at = null;
  }
}
