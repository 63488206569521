<!-- begin:: Footer -->
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop" id="kt_footer">
  <div class="kt-container" [ngClass]="{ 'kt-container--fluid': fluid }">
    <div class="kt-footer__copyright">
      {{ today | date : "yyyy" }}&nbsp;&copy;&nbsp;<a href="https://lema.dk" target="_blank" class="kt-link"
        >Lema ApS</a
      >
    </div>
    <div class="kt-footer__menu">
      <a href="" target="_blank" class="kt-footer__menu-link kt-link">About</a>
      <a href="" target="_blank" class="kt-footer__menu-link kt-link">Team</a>
      <a href="" target="_blank" class="kt-footer__menu-link kt-link">Contact</a>
    </div>
  </div>
</div>
<!-- end:: Footer -->
