<div class="kt-form">
  <div class="kt-portlet__head-progress">
    <mat-progress-bar mode="indeterminate" *ngIf="noteLoading || fieldsLoading"></mat-progress-bar>
  </div>
  <mat-tab-group class="mat-tab-body-content">
    <mat-tab *ngIf="hasNotes" [label]="getNoteComponentTitle()">
      <div class="kt-portlet kt-form">
        <div class="kt-portlet__body" *ngIf="note">
          <form [formGroup]="noteForm" autocomplete="off" class="kt-form">
            <div class="kt-form__group">
              <!-- NOTE CATEGORY -->
              <div class="row" [hidden]="is_task">
                <div class="col-md-8 kt-margin-bottom-10-mobile">
                  <label for="category_id" class="mat-input-label">
                    {{ "NOTES.ADD.FIELDS.CATEGORY.LABEL" | translate }}
                  </label>
                  <mat-form-field class="mat-form-field-fluid">
                    <mat-select
                      placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.PLACEHOLDER' | translate }}"
                      formControlName="category_id"
                    >
                      <mat-option [value]="category.id" *ngFor="let category of categories">
                        {{ category.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="noteForm.controls.category_id.hasError('required')">
                      <span [innerHTML]="'NOTES.ADD.FIELDS.CATEGORY.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs">
                    {{ errorMsgs }}
                  </mat-error>
                </div>
              </div>

              <!-- NOTE CUSTOMER -->
              <div class="row" [hidden]="is_customer || is_task">
                <div class="col-md-8 kt-margin-bottom-10-mobile">
                  <label for="customers" class="mat-input-label">
                    {{ "NOTES.ADD.FIELDS.CUSTOMER.LABEL" | translate }}
                  </label>
                  <mat-form-field class="mat-form-field-fluid">
                    <mat-select
                      placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.ACCOUNT_VAT_CODE.PLACEHOLDER' | translate }}"
                      formControlName="customer_id"
                    >
                      <mat-option [value]="0">ingen kunder</mat-option>
                      <mat-option [value]="customer.id" *ngFor="let customer of customers">
                        {{ customer.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="noteForm.controls.customer_id.hasError('required')">
                      <span [innerHTML]="'NOTES.ADD.FIELDS.CUSTOMER.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs">
                    {{ errorMsgs }}
                  </mat-error>
                </div>
              </div>

              <!-- NOTE TEXT  -->
              <div class="row">
                <div class="col-md-8 kt-margin-bottom-10-mobile">
                  <label class="mat-input-label">
                    {{ "NOTES.ADD.ADD_BUTTON" | translate }}
                  </label>
                  <mat-form-field class="mat-form-field-fluid">
                    <textarea
                      matInput
                      class="text-text-area"
                      placeholder="{{ 'NOTES.ADD.FIELDS.TEXT.PLACEHOLDER' | translate }}"
                      formControlName="text"
                      type="text"
                    ></textarea>
                    <mat-error *ngIf="noteForm.controls.text.hasError('required')">
                      <span [innerHTML]="'NOTES.ADD.FIELDS.TEXT.ERROR_MSG' | translate"></span>
                    </mat-error>
                  </mat-form-field>
                  <mat-error *ngIf="errorMsgs"> {{ errorMsgs }}</mat-error>
                  <div *ngIf="is_task" class="text-left pt-3">
                    <button
                      class="btn btn-danger"
                      color="accent"
                      mat-raised-button
                      (click)="deleteNote()"
                      [disabled]="noteLoading || !note.id"
                    >
                      <i class="fas fa-trash-alt"></i>
                      {{ "NOTES.ADD.DELETE_BUTTON" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="is_task && customFieldsSettings?.is_active" [label]="'CONTRACTS.CUSTOM_FIELDS.TITLE' | translate">
      <div class="kt-portlet kt-form">
        <div class="kt-portlet__body">
          <form [formGroup]="customFieldsForm">
            <div formArrayName="fields">
              <div *ngFor="let field of customFieldsFormArray.controls; let i = index" [formGroupName]="i">
                <div class="row">
                  <div class="col-md-4 kt-margin-bottom-10-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <mat-select
                        (selectionChange)="onFieldSelect($event, i)"
                        [formControl]="field.controls.template_key"
                      >
                        <ng-container *ngFor="let settingField of remainingUnusedSettings">
                          <mat-option [value]="settingField.template_key">
                            {{ settingField.name }}
                          </mat-option>
                        </ng-container>
                        <mat-option
                          *ngIf="field.controls.template_key.value"
                          [value]="field.controls.template_key.value"
                        >
                          {{ field.controls.name.value }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="field.controls.template_key.value" class="col-md-4 kt-margin-bottom-10-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                      <input
                        matInput
                        [formControl]="field.controls.value"
                        [type]="field.controls.type.value === inputTypes.NUMBER ? 'number' : 'text'"
                      />
                      <mat-error *ngIf="field.controls.value.hasError('required')">
                        <span [innerHTML]="'CONTRACTS.CUSTOM_FIELDS.FIELD_REQUIRED' | translate"></span>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4 kt-margin-bottom-10-mobile">
                    <button mat-icon-button (click)="removeCustomField(i)" type="button" color="warn">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              [disabled]="emptyCustomFields.length >= remainingUnusedSettings.length"
              mat-raised-button
              color="primary"
              (click)="addCustomField()"
            >
              <i class="fas fa-plus"></i>
              {{ "CONTRACTS.CUSTOM_FIELDS.ADD" | translate }}
            </button>
          </form>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
    <div class="kt-form__actions kt-form__actions--sm">
      <button mat-button (click)="dialogRef.close()">
        {{ "GENERAL.CANCEL" | translate }}
      </button>
      <button class="btn btn-primary" color="primary" mat-raised-button (click)="onSubmit()" [disabled]="noteLoading">
        {{ "GENERAL.SAVE" | translate }}
      </button>
    </div>
  </div>
</div>
