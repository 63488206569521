import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
// Angular
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Inject,
  Optional,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS
import { Observable, BehaviorSubject, Subscription, of } from "rxjs";
import { map, startWith, switchMap, catchError } from "rxjs/operators";
// Layout
import { LayoutConfigService, SubheaderService } from "../../../core/_base/layout";
// CRUD
import { LayoutUtilsService, MessageType } from "../../../core/_base/crud";
import { SuppliersModel } from "./suppliers.model";
import { SuppliersService } from "./suppliers.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "kt-supplier-edit",
  templateUrl: "./supplier-edit.component.html",
})
export class SupplierEditComponent implements OnInit, OnDestroy {
  // Public properties
  id: any;
  loadingSubject = new BehaviorSubject<boolean>(true);
  loading$: Observable<boolean>;
  supplierGroups: any[];
  paymentTerms: any[];
  paymenstInfo: any[];
  product: SuppliersModel;
  productForm: FormGroup;
  hasFormErrors: boolean = false;
  errorMsgs: any = {}; //Back-end errors
  filteredSuppliers: Observable<any[]>;
  filteredSupplierCVR: Observable<any[]>;
  // Private password
  private componentSubscriptions: Subscription[] = [];
  isReadonly: boolean = false;
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;

  constructor(
    private productFB: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private layoutConfigService: LayoutConfigService,
    private productsService: SuppliersService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private permissionsService: NgxPermissionsService,
    public dialogRef: MatDialogRef<SupplierEditComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private translate: TranslateService
  ) {}
  ngOnInit() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);

    const routeSubscription = this.activatedRoute.params.subscribe((params) => {
      this.id = params["id"];
      this.permissionsService.permissions$.subscribe((res) => {
        if (this.id && this.id > 0) {
          this.loadProductFromService(this.id);
        } else {
          const newProduct = new SuppliersModel();
          newProduct.clear();
          this.loadProduct(newProduct);
        }
      });
    });
    const supplierGroupsSubscription = this.productsService.getLookups("supplier_groups").subscribe((res) => {
      this.supplierGroups = res.data;
      this.cdr.markForCheck();
    });
    this.componentSubscriptions.push(supplierGroupsSubscription);
    const paymentTermsSubscription = this.productsService.getLookups("payment_terms").subscribe((res) => {
      this.paymentTerms = res.data;
    });
    this.componentSubscriptions.push(paymentTermsSubscription);

    const paymentInfoSubscription = this.productsService.getPaymentInfo().subscribe((res) => {
      this.paymenstInfo = res.data;
      this.cdr.markForCheck();
      // if(this.productForm && this.productForm.get('payment_info_id')){
      //   this.productForm.get('payment_info_id').patchValue(this.product.paymentInfo);

      // }
    });
    this.componentSubscriptions.push(paymentInfoSubscription);
  }

  ngOnDestroy() {
    this.componentSubscriptions.forEach((el) => el.unsubscribe());
  }

  loadProduct(_product) {
    this.product = _product;
    this.initProduct();
    this.cdr.markForCheck();
  }

  loadProductFromService(productId) {
    const getProductByIdSubscription = this.productsService.getProductById(productId).subscribe((res) => {
      this.loadProduct(res.data);
    });
    this.componentSubscriptions.push(getProductByIdSubscription);
  }

  initProduct() {
    this.createForm();
    this.loadingSubject.next(false);

    const TITLE = this.translate.instant("SUPPLIERS.VIEW.TITLE");
    const ADD_TITLE = this.translate.instant("SUPPLIERS.ADD.ADD_TITLE");
    const EDIT_TITLE = this.translate.instant("SUPPLIERS.ADD.EDIT_TITLE");
    if (!this.product.id) {
      this.subheaderService.setBreadcrumbs([
        { title: TITLE, page: `/suppliers` },
        { title: ADD_TITLE, page: `/suppliers/add` },
      ]);
      return;
    }
    this.subheaderService.setBreadcrumbs([
      { title: TITLE, page: `/suppliers` },
      { title: EDIT_TITLE, page: `/suppliers/edit/${this.id}` },
    ]);
  }

  createForm() {
    console.log("product: ", this.product);

    let supplier = { name: this.product.name };
    this.productForm = this.productFB.group({
      supplier: [{ value: supplier, disabled: this.isReadonly }, Validators.required],
      supplier_cvr: [
        {
          value: this.product.supplier_cvr,
          disabled: true,
        },
      ],
      supplier_group_id: [this.product.supplier_group_id, Validators.required],
      payment_term_id: [this.product.paymentTerm, Validators.required],
      payment_info_id: [this.product.paymentInfo],
      credit_id: [this.product.credit_id],
      account_no: [this.product.account_no],
    });
    console.log("Form: ", this.product.paymentInfo);

    this.productForm.get("payment_info_id").setValue(this.product.paymentInfo);
    console.log("Form @ : ", this.productForm.value);
    this.cdr.markForCheck();

    this.filteredSuppliers = this.productForm.get("supplier").valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (typeof value == "string" && value !== "") {
          return this.productsService.getCVRData(value);
        } else return of([]);
      }),
      map((res) => res.data),
      catchError((err) => {
        return of([]);
      })
    );

    this.filteredSupplierCVR = this.productForm.get("supplier_cvr").valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        if (typeof value == "string" && value !== "") {
          return this.productsService.getCVRData(value);
        } else return of([]);
      }),
      map((res) => res.data),
      catchError((err) => {
        return of([]);
      })
    );
    this.cdr.detectChanges();
    console.log(this.productForm);
  }

  displayFn(supplier: any): string {
    return supplier && supplier.name ? supplier.name : "";
  }

  compareFn(obj1: any, obj2: any): boolean {
    return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
  }

  supplierSelected($event) {
    if ($event.option.value) {
      this.productForm.get("supplier_cvr").setValue($event.option.value.company_cvr_number);
    }
    delete this.errorMsgs["supplier_cvr"];
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.viewLoading = true;
    const controls = this.productForm.controls;
    /** check form */
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAsTouched());
      this.hasFormErrors = true;
      this.viewLoading = false;
      return;
    }

    let payload: any = {};
    payload.name = controls["supplier"].value ? controls["supplier"].value.name : controls["supplier"].value;
    payload.supplier_cvr = controls["supplier_cvr"].value;
    payload.supplier_group_id = controls["supplier_group_id"].value;
    payload.payment_term_id = controls["payment_term_id"].value.id;
    payload.payment_info_id = controls["payment_info_id"].value?.id || "";
    payload.account_no = controls["account_no"].value;
    if (controls["payment_info_id"].value?.show_credit_id) {
      payload.credit_id = controls["credit_id"].value;
    } else {
      payload.credit_id = null;
    }

    if (this.id > 0) {
      this.updateProduct(payload);
      return;
    }

    this.addProduct(payload);
  }

  addProduct(payload) {
    this.viewLoading = true;
    this.errorMsgs = {};
    const createProductSubscription = this.productsService.createProduct(payload).subscribe(
      (res) => {
        this.viewLoading = false;
        if (res.data) {
          this.layoutUtilsService.showActionNotification(res.message, MessageType.Create, 10000, true, false);
        }
        if (this.dialogData.isCancelEnabled) {
          this.dialogRef.close(res.data.data);
        } else {
          this.goBackToListPage();
        }
      },
      (err) => {
        this.viewLoading = false;
        if (err.error) {
          this.errorMsgs = err.error.errors;
          this.cdr.markForCheck();
          this.hasFormErrors = true;
        }
      }
    );
    this.componentSubscriptions.push(createProductSubscription);
  }

  updateProduct(payload) {
    this.viewLoading = true;
    this.errorMsgs = {};
    const updateProductSubscription = this.productsService.updateProduct(payload, this.id).subscribe(
      (res) => {
        this.viewLoading = false;
        if (res.data) {
          this.layoutUtilsService.showActionNotification(res.message, MessageType.Update, 10000, true, false);
        }
        this.goBackToListPage();
      },
      (err) => {
        this.viewLoading = false;
        if (err.error) {
          this.errorMsgs = err.error.errors;
          this.cdr.markForCheck();
          this.hasFormErrors = true;
        }
      }
    );
    this.componentSubscriptions.push(updateProductSubscription);
  }

  goBackToListPage() {
    if (this.id) {
      this.router.navigate(["../../"], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(["../"], { relativeTo: this.activatedRoute });
    }
  }

  getComponentTitle() {
    let result = this.translate.instant("SUPPLIERS.ADD.ADD_TITLE");
    if (!this.product || !this.product.id) {
      return result;
    }

    result = `${this.translate.instant("SUPPLIERS.ADD.EDIT_TITLE")} - ${this.product.name}`;
    return result;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
