// Angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// RxJS
import { Observable } from "rxjs";

import { API } from "../../../core/_base/crud";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { ContractMileageModel } from "./contract-mileage.model";

@Injectable()
export class ContractMileageService {
  constructor(private http: HttpClient) {}

  addContractMileage(product): Observable<any> {
    return this.http.post<any>(`${API.contractMileage}`, product);
  }

  editContractMileage(product, productId): Observable<any> {
    return this.http.put<any>(`${API.contractMileage}/${productId}`, product);
  }

  deleteMileage(productId) {
    return this.http.delete<any>(`${API.contractMileage}/${productId}`);
  }

  getProductById(productId: number): Observable<any> {
    return this.http.get<any>(API.contractMileage + `/${productId}`);
  }

  indexProduct(params: IRequestPagination): Observable<any> {
    return this.http.get<IResponsePagination<ContractMileageModel>>(API.contractMileage, {
      params: { ...params },
    });
  }

  getMileageData(contract_id: any) {
    return this.http.get<any>(API.contracts + `/${contract_id}/get-mileage`);
  }

  calculateMileageSplit(contract_id, payload) {
    return this.http.get<any>(`${API.contracts}/${contract_id}/calculate-mileage-split`, {
      params: payload,
    });
  }

  createAdjustmentPayments(contract_id, payload): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contract_id}/create-mileage-adjustment-payments`, payload);
  }
}
