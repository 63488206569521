import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { EntityTypes } from "../../shared/enums/entity/entityTypes";
import { FormArray, FormGroup } from "@angular/forms";

@Component({
  selector: "kt-customers-form-group",
  templateUrl: "./customers-form-group.component.html",
  styleUrls: ["./customers-form-group.component.scss"],
})
export class CustomersFormGroupComponent implements OnInit {
  @Input() productForm: FormGroup;
  @Input() contractInfo: string;
  @Input() includeBankInfo: boolean = true;
  @Input() includeIdNumber: boolean = false;
  @Input() customerBankInfoRequiredFields: boolean;
  @Input() isDisabled: boolean;
  @Input() baseCustomerCount: number = 0;
  @Input() addMultipleCustomer: boolean = true;
  @Output() removeCustomer = new EventEmitter();

  @ContentChild("customerInfo") customContent: TemplateRef<any>;

  entityTypesEnum = EntityTypes;

  constructor() {}

  ngOnInit(): void {
    if (this.isDisabled) this.productForm.disable();
  }

  get customerInfo(): FormArray {
    return this.productForm.get("customerInfo") as FormArray;
  }

  removeCustomerInfo(i: number) {
    this.removeCustomer.emit(i);
  }

  get customerInfoLabel() {
    if (this.contractInfo === EntityTypes.PRIVATE) {
      return "TASKS.VIEW.SEND_CONTRACT.FIELDS.CUSTOMER_NAME_LABEL";
    } else if (this.contractInfo === EntityTypes.CORPORATE) {
      return "TASKS.VIEW.SEND_CONTRACT.FIELDS.COMPANY_NAME_LABEL";
    } else if (this.contractInfo === EntityTypes.SPLIT_LEASING) {
      return [
        "TASKS.VIEW.SEND_CONTRACT.FIELDS.CUSTOMER_NAME_LABEL",
        "TASKS.VIEW.SEND_CONTRACT.FIELDS.COMPANY_NAME_LABEL",
      ];
    }
  }
}
