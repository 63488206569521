// Angular
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { NgxPermissionsService } from "ngx-permissions";
// RxJS
import { Subscription } from "rxjs";
// Layout
// CRUD
import { EquipmentModel } from "./extra-equipment.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "kt-extra-equipment-dialog",
  templateUrl: "./extra-equipment-dialog.component.html",
})
export class ExtraEquipmentDialogComponent implements OnInit, OnDestroy {
  // Public properties
  id: any;
  productForm: FormGroup;
  hasFormErrors: boolean = false;
  errorMsgs: any = {}; //Back-end errors
  // Private password
  private componentSubscriptions: Subscription[] = [];
  isReadonly: boolean = false;
  // sticky portlet header margin
  private headerMargin: number;
  viewLoading = false;

  constructor(
    private productFB: FormBuilder,
    private cdr: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    public dialogRef: MatDialogRef<ExtraEquipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
    // sticky portlet header
    window.onload = () => {
      const style = getComputedStyle(document.getElementById("kt_header"));
      this.headerMargin = parseInt(style.height, 0);
    };
  }

  ngOnDestroy() {
    if (this.componentSubscriptions) {
      this.componentSubscriptions.forEach((el) => el.unsubscribe());
    }
  }

  createForm() {
    let extraEquipmentArray: FormGroup[] = [];
    if (this.data.extra_equipment_value && this.data.extra_equipment_value.length > 0) {
      this.data.extra_equipment_value.forEach((equipment) => {
        extraEquipmentArray.push(
          this.productFB.group({
            name: [equipment.name, Validators.required],
            value: [equipment.value, Validators.required],
          })
        );
      });
    } else {
      extraEquipmentArray.push(
        this.productFB.group({
          name: ["", Validators.required],
          value: ["", Validators.required],
        })
      );
    }
    this.productForm = this.productFB.group({
      extra_equipment_value: this.productFB.array(extraEquipmentArray),
    });
    this.cdr.detectChanges();
  }

  addNewEquipment() {
    (<FormArray>this.productForm.get("extra_equipment_value")).push(
      this.productFB.group({
        name: ["", Validators.required],
        value: ["", Validators.required],
      })
    );
  }

  removeEquipment(index) {
    (<FormArray>this.productForm.get("extra_equipment_value")).removeAt(index);
  }

  onSubmit() {
    this.hasFormErrors = false;
    this.viewLoading = true;
    const controls = this.productForm.controls;
    /** check form */
    if (this.productForm.invalid) {
      Object.keys(controls).forEach((controlName) => controls[controlName].markAllAsTouched());
      this.hasFormErrors = true;
      this.viewLoading = false;
      return;
    }

    this.addProduct(controls["extra_equipment_value"].value);
  }

  addProduct(extra_equipment_value) {
    this.dialogRef.close({
      success: true,
      extra_equipment_value,
    });
  }

  getComponentTitle() {
    let result = this.translate.instant("VEHICLE_VALUATION.VEHICLE_PRICE.FIELDS.EXTRA_EQUIPMENT.TITLE");

    return result;
  }

  /**
   * Close alert
   *
   * @param $event
   */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }
}
