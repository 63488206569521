<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ getComponentTitle() }}</h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body" *ngIf="product">
      <form [formGroup]="productForm" autocomplete="off" class="kt-form">
        <div class="kt-form__group">
          <div class="row">
            <div class="col-md-12 kt-margin-bottom-10-mobile">
              <label class="mat-input-label">{{ "TRANSACTIONS.ADD.FIELDS.DATE.PLACEHOLDER" | translate }}</label>
              <mat-form-field class="mat-form-field-fluid" [ngClass]="{ 'mat-form-field-invalid': errorMsgs['date'] }">
                <input
                  matInput
                  [matDatepicker]="datePicker"
                  placeholder="{{ 'TRANSACTIONS.ADD.FIELDS.DATE.PLACEHOLDER' | translate }}"
                  formControlName="date"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker></mat-datepicker>
                <mat-error *ngIf="productForm.controls.date.hasError('required')">
                  <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.DATE.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['date']">{{ errorMsgs["date"][0] }}</mat-error>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="dialogRef.close()">
          {{ "GENERAL.CANCEL" | translate }}</button
        >&nbsp;
        <button mat-button (click)="onSubmit()" color="primary" cdkFocusInitial [disabled]="viewLoading">
          {{ "GENERAL.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
