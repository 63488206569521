<mat-dialog-content>
  <div>
    <h6 class="my-4 text-center text-danger">{{ "GENERAL.FORM_REQUIRED_FIELDS" | translate }}</h6>
  </div>
  <div>
    <form [formGroup]="vehicleForm" class="vehicle">
      <!-- Vehicle Type & Vehicle Make -->
      <div class="row">
        <div class="col-lg-6">
          <label>{{ "VEHICLE.ADD.FIELDS.VEHICLE_TYPE.PLACEHOLDER" | translate }} </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-select disabled value="default">
              <mat-option value="default">
                {{ vehicleInfo.vehicle_type.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-lg-6">
          <label>{{ "VEHICLE.ADD.FIELDS.MAKE.PLACEHOLDER" | translate }} </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-select disabled value="default">
              <mat-option value="default">
                {{ vehicleInfo.make.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Model -->
      <div class="row">
        <div class="col-lg-12">
          <label>{{ "VEHICLE.ADD.FIELDS.MODEL.PLACEHOLDER" | translate }} </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-select disabled value="default">
              <mat-option value="default">
                {{ vehicleInfo.model.model }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Varient -->
      <div class="row">
        <div class="col-lg-12">
          <label
            >{{ "VEHICLE.ADD.FIELDS.TRIM.PLACEHOLDER" | translate }} <span *ngIf="isControlRequired('trim')"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              inputmode="text"
              matInput
              placeholder="{{ 'VEHICLE.ADD.FIELDS.TRIM.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl(vehicleFieldEnum.TRIM)"
            />
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.TRIM).errors?.maxlength">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.TRIM.ERROR_MSG' | translate"></span>
            </mat-error>
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.TRIM).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.TRIM.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- First_Reg_Date & Vehicle_Year -->
      <div class="row">
        <div class="col-lg-6">
          <label>{{ "VEHICLE.ADD.FIELDS.FIRST_REGISTERATION_DATE.PLACEHOLDER" | translate }} </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Choose a date</mat-label>
            <input
              matInput
              [matDatepicker]="registerationDate"
              [formControl]="getVehicleFormControl(vehicleFieldEnum.FIRST_REGISTERATION_DATE)"
            />
            <mat-datepicker-toggle matSuffix [for]="registerationDate"></mat-datepicker-toggle>
            <mat-datepicker #registerationDate></mat-datepicker>
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.FIRST_REGISTERATION_DATE).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.FIRST_REGISTERATION_DATE.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.YEAR.PLACEHOLDER" | translate }} <span *ngIf="isControlRequired('year')"> * </span>
          </label>
          <mat-form-field appearance="fill" ktYearPicker>
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="year" [formControl]="getVehicleFormControl(vehicleFieldEnum.YEAR)" />
            <mat-datepicker-toggle matSuffix [for]="year"></mat-datepicker-toggle>
            <mat-datepicker #year startView="multi-year" (yearSelected)="setYear($event, year)"></mat-datepicker>
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.YEAR).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.YEAR.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- vin_Number & Used_vate -->
      <div class="row mt-4">
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.VIN_NUMBER.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.VIN_NUMBER)"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              inputmode="text"
              type="text"
              matInput
              placeholder="{{ 'VEHICLE.ADD.FIELDS.VIN_NUMBER.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl(vehicleFieldEnum.VIN_NUMBER)"
            />
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.VIN_NUMBER).errors?.pattern">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.VIN_NUMBER.VALID_LENGTH' | translate"></span>
            </mat-error>
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.VIN_NUMBER).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.VIN_NUMBER.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6 d-flex align-items-center">
          <div>
            <mat-checkbox [formControl]="getVehicleFormControl('is_used_vat')"
              >{{ "VEHICLE.ADD.FIELDS.IS_USED_VAT.PLACEHOLDER" | translate }}
            </mat-checkbox>
          </div>
        </div>
      </div>

      <!-- Fuel_Type & Fuel_Consumption -->
      <div class="row">
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.FUEL_TYPE.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.FUEL_TYPE)"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-select [formControl]="getVehicleFormControl(vehicleFieldEnum.FUEL_TYPE)">
              <mat-option [value]="fuelType.code" *ngFor="let fuelType of fuelTypesFilter | async"
                >{{ fuelType.code }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.FUEL_TYPE).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.FUEL_TYPE.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="col-lg-6"
          *ngIf="getVehicleFormControl(vehicleFieldEnum.FUEL_TYPE).value !== fuelTypesEnum.ELECTRIC"
        >
          <label
            >{{ "VEHICLE.ADD.FIELDS.FUEL_CONSUMPTION.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('fuel_consumption')"> * </span>
          </label>
          <mat-form-field no-scroll appearance="fill" class="w-100">
            <input
              matInput
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              placeholder="{{ 'VEHICLE.ADD.FIELDS.FUEL_CONSUMPTION.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl('fuel_consumption')"
            />
            <mat-error *ngIf="getVehicleFormControl('fuel_consumption').errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.FUEL_CONSUMPTION.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- //Electric_Consumption & Electron_Range -->
      <div class="row" *ngIf="getVehicleFormControl(vehicleFieldEnum.FUEL_TYPE).value === fuelTypesEnum.ELECTRIC">
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.ELECTRIC_CONSUMPTION.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('electric_consumption')"> * </span>
          </label>
          <mat-form-field no-scroll appearance="fill" class="w-100">
            <input
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              matInput
              placeholder="{{ 'VEHICLE.ADD.FIELDS.ELECTRIC_CONSUMPTION.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl('electric_consumption')"
            />
            <mat-error *ngIf="getVehicleFormControl('electric_consumption').errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.ELECTRIC_CONSUMPTION.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.ELECTRIC_RANGE.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('electric_range')"> * </span>
          </label>
          <mat-form-field no-scroll appearance="fill" class="w-100">
            <input
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              matInput
              placeholder="{{ 'VEHICLE.ADD.FIELDS.ELECTRIC_RANGE.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl('electric_range')"
            />
            <mat-error *ngIf="getVehicleFormControl('electric_range').errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.ELECTRIC_RANGE.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- // New_Price & Condition  -->
      <div class="row" *ngIf="getVehicleFormControl('vehicle_valuation').value">
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.NEW_PRICE_CENTS.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired('new_price_cents')"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              currencyMask
              inputmode="text"
              matInput
              placeholder="{{ 'VEHICLE.ADD.FIELDS.NEW_PRICE_CENTS.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl('new_price_cents')"
            />
            <mat-error *ngIf="getVehicleFormControl('new_price_cents').errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.NEW_PRICE_CENTS.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.CONDITION.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.VEHICLE_CONDITION)"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-select [formControl]="getVehicleFormControl(vehicleFieldEnum.VEHICLE_CONDITION)">
              <mat-option [value]="vehicleCondition.id" *ngFor="let vehicleCondition of vehicleConditionsFilter | async"
                >{{ vehicleCondition.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.VEHICLE_CONDITION).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.CONDITION.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Market_value & Mileage  -->
      <div class="row">
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.MARKET.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.MARKET_CAR)"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <input
              currencyMask
              inputmode="text"
              matInput
              placeholder="{{ 'VEHICLE.ADD.FIELDS.MARKET.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl(vehicleFieldEnum.MARKET_CAR)"
            />
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.MARKET_CAR).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.MARKET.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.MILEAGE.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.MILEAGE_CAR)"> * </span>
          </label>
          <mat-form-field no-scroll appearance="fill" class="w-100">
            <input
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              matInput
              placeholder="{{ 'VEHICLE.ADD.FIELDS.MILEAGE.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl(vehicleFieldEnum.MILEAGE_CAR)"
            />
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.MILEAGE_CAR).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.MILEAGE.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- upload_picture -->
      <div class="row my-4 align-items-center">
        <div class="col-md-6">
          <mat-checkbox
            [formControl]="getVehicleFormControl(vehicleFieldEnum.HAS_MILEAGE_PHOTO)"
            name="hasMileagePhoto"
          >
            {{ "VEHICLE.ADD.FIELDS.MILEAGE.HAS_PHOTO_LABEL" | translate }}
          </mat-checkbox>
          <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.HAS_MILEAGE_PHOTO).errors?.required">
            <span [innerHTML]="'VEHICLE.ADD.FIELDS.MILEAGE.ERROR_MSG' | translate"></span>
          </mat-error>
        </div>
        <div *ngIf="getVehicleFormControl(vehicleFieldEnum.HAS_MILEAGE_PHOTO).value" class="col-md-6">
          <div class="dropzone-wrapper">
            <dropzone
              #mileagePhoto
              [config]="dropZoneConfig"
              message="Drop your vehicle photo here.."
              (addedFile)="onFileAdded($event)"
              (removedFile)="onFileRemoved($event)"
            >
            </dropzone>
            <mat-error *ngIf="!isMileagePhotoValid">
              <span
                style="font-size: 75%"
                [innerHTML]="'VEHICLE.ADD.FIELDS.MILEAGE.PHOTO_ERROR_MSG' | translate"
              ></span>
            </mat-error>
          </div>
        </div>
      </div>

      <!-- //Measure_Stand & CO2_Emmision -->
      <div class="row" *ngIf="getVehicleFormControl('vehicle_valuation').value">
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.FULE_NORM.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.FUEL_NORM)"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-select [formControl]="getVehicleFormControl(vehicleFieldEnum.FUEL_NORM)">
              <mat-option [value]="fuelNorm.code" *ngFor="let fuelNorm of fuelNormsFilter | async"
                >{{ fuelNorm.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.FUEL_NORM).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.FULE_NORM.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.CO2_PER_KM.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.CO2_PER_KM)"> * </span>
          </label>
          <mat-form-field no-scroll appearance="fill" class="w-100">
            <input
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              matInput
              placeholder="{{ 'VEHICLE.ADD.FIELDS.CO2_PER_KM.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl(vehicleFieldEnum.CO2_PER_KM)"
            />
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.CO2_PER_KM).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.CO2_PER_KM.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- //Own_Wieght & Battery_Capacity -->
      <div class="row" *ngIf="getVehicleFormControl('vehicle_valuation').value">
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.CURB_WEIGHT.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.CURB_WEIGHT)"> * </span>
          </label>
          <mat-form-field no-scroll appearance="fill" class="w-100">
            <input
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              matInput
              placeholder="{{ 'VEHICLE.ADD.FIELDS.CURB_WEIGHT.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl(vehicleFieldEnum.CURB_WEIGHT)"
            />
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.CURB_WEIGHT).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.CURB_WEIGHT.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.BATTERY_CAPACITY.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.BATTERY_CAPACITY)"> * </span>
          </label>
          <mat-form-field no-scroll appearance="fill" class="w-100">
            <input
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              matInput
              type="number"
              placeholder="{{ 'VEHICLE.ADD.FIELDS.BATTERY_CAPACITY.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl(vehicleFieldEnum.BATTERY_CAPACITY)"
            />
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.BATTERY_CAPACITY).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.BATTERY_CAPACITY.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Horse_Power & Next_Inspection_Date  -->
      <div class="row">
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.HORSE_POWER.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.HORSE_POWER)"> * </span>
          </label>
          <mat-form-field no-scroll class="w-100" appearance="fill">
            <input
              inputmode="number"
              oninput="this.value = this.value.replace(/\D+/g, '')"
              matInput
              type="number"
              placeholder="{{ 'VEHICLE.ADD.FIELDS.HORSE_POWER.PLACEHOLDER' | translate }}"
              [formControl]="getVehicleFormControl(vehicleFieldEnum.HORSE_POWER)"
            />
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.HORSE_POWER).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.HORSE_POWER.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6">
          <label
            >{{ "VEHICLE.ADD.FIELDS.NEXT_INSPECTION.PLACEHOLDER" | translate }}
            <span *ngIf="isControlRequired(vehicleFieldEnum.NEXT_INSPECTION)"> * </span>
          </label>
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Choose a date</mat-label>
            <input
              matInput
              [matDatepicker]="inspectionDate"
              [formControl]="getVehicleFormControl(vehicleFieldEnum.NEXT_INSPECTION)"
            />
            <mat-datepicker-toggle matSuffix [for]="inspectionDate"></mat-datepicker-toggle>
            <mat-datepicker #inspectionDate></mat-datepicker>
            <mat-error *ngIf="getVehicleFormControl(vehicleFieldEnum.NEXT_INSPECTION).errors?.required">
              <span [innerHTML]="'VEHICLE.ADD.FIELDS.NEXT_INSPECTION.ERROR_MSG' | translate"></span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
