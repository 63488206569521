// Angular
import { Inject, Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { RollbarService } from "../../_base/crud/utils/rollbar.service";
// RxJS
import { tap } from "rxjs/operators";
// NGRX
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
// Auth actions
import * as Rollbar from "rollbar";
import { environment } from "../../../../environments/environment";
import { APP_VERSION } from "../../constants/local-storage-constants";
import { AppState } from "../../reducers";
import { AuthActionTypes, Logout, Register, UserLoaded } from "../_actions/auth.actions";
import { AuthService } from "../_services/index";

@Injectable()
export class AuthEffects {
  // @Effect({dispatch: false})
  // login$ = this.actions$.pipe(
  //     ofType<Login>(AuthActionTypes.Login),
  //     tap(action => {
  //         localStorage.setItem(environment.authTokenKey, action.payload.authToken);
  //         this.store.dispatch(new UserRequested());
  //     }),
  // );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {
      this.auth.logout().subscribe(
        (res) => {
          this.router
            .navigate(["/auth/login"], { queryParams: { returnUrl: this.returnUrl } })
            .then(() => {
              localStorage.clear();
              const appVersion = environment.appVersion;
              localStorage.setItem(APP_VERSION, appVersion);
            })
            .catch((err) => {
              this.errorClearStorage();
            });
        },
        (err) => {
          this.errorClearStorage();
        }
      );
    })
  );

  errorClearStorage = () => {
    this.router.navigate(["/auth/login"], { queryParams: { returnUrl: this.returnUrl } });
    localStorage.clear();
    localStorage.removeItem(environment.authTokenKey);
  };

  @Effect({ dispatch: false })
  register$ = this.actions$.pipe(
    ofType<Register>(AuthActionTypes.Register),
    tap((action) => {
      localStorage.setItem(environment.authTokenKey, action.payload.authToken);
    })
  );

  // @Effect({dispatch: false})
  // loadUser$ = this.actions$
  // .pipe(
  //     ofType<UserRequested>(AuthActionTypes.UserRequested),
  //     withLatestFrom(this.store.pipe(select(isUserLoaded))),
  //     filter(([action, _isUserLoaded]) => !_isUserLoaded),
  //     mergeMap(([action, _isUserLoaded]) => this.auth.getUserByToken()),
  //     tap((_user:any) => {
  //         if (_user) {
  //             this.store.dispatch(new UserLoaded({ user: _user.data }));
  //         } else {
  //             this.store.dispatch(new Logout());
  //         }
  //     })
  //   );

  @Effect({ dispatch: false })
  userLoaded$ = this.actions$.pipe(
    ofType<UserLoaded>(AuthActionTypes.UserLoaded),
    tap((action) => {
      this.rollbar.configure({
        payload: {
          person: {
            id: action.payload.user.id,
            username: action.payload.user.name,
            email: action.payload.user.email,
          },
        },
      });
    })
  );

  // @Effect()
  // init$: Observable<Action> = defer(() => {
  //     const userToken = localStorage.getItem(environment.authTokenKey);
  //     let observableResult = of({type: 'NO_ACTION'});
  //     if (userToken) {
  //         observableResult = of(new Login({  authToken: userToken }));
  //     }
  //     return observableResult;
  // });

  private returnUrl: string;

  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
      }
    });
  }
}
