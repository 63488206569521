<div
  class="kt-portlet__head kt-portlet__head__custom"
  mat-dialog-title
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  style="padding-top: 20px"
>
  <div class="kt-portlet__head-progress">
    <!-- here can place a progress bar-->
    <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
  </div>

  <div class="kt-portlet__head-label">
    <h3 class="kt-portlet__head-title">
      <label
        class="kt-font-brand"
        style="margin: 0; padding-left: 32px; font-size: 1.2rem; font-weight: 500; color: #48465b !important"
      >
        {{ ('TRANSACTIONS.VIEW.FINANCE_TITLE' | translate) }}
      </label>
    </h3>
    <span class="kt-portlet__head-icon kt-hide">
      <i class="la la-gear"></i>
    </span>
  </div>
</div>
<hr />
<div class="kt-form">
  <form [formGroup]="endTaskForm" (submit)="endTask()">
    <div class="kt-portlet__body container">
      <div class="kt-form__group">
        <div class="row justify-content-center">
          <div class="col-md-6 kt-margin-bottom-10-mobile">
            <label class="mat-input-label"
              >{{'TASKS.VIEW.CREATE_MONTHLY_PAYMENT.ADD.FIELDS.FINAL_REGISTRATION_TAX.PLACEHOLDER' | translate}}</label
            >
            <mat-form-field
              class="mat-form-field-fluid"
              [ngClass]="{'mat-form-field-invalid' : errorMsgs['final_registration_tax']}"
            >
              <input
                matInput
                type="text"
                currencyMask
                placeholder="{{'TASKS.VIEW.CREATE_MONTHLY_PAYMENT.ADD.FIELDS.FINAL_REGISTRATION_TAX.PLACEHOLDER' | translate}}"
                formControlName="final_registration_tax"
                autocomplete="off"
              />
              <mat-error *ngIf="endTaskForm.controls.final_registration_tax.hasError('required')">
                <span
                  [innerHTML]="'TASKS.VIEW.CREATE_MONTHLY_PAYMENT.ADD.FIELDS.FINAL_REGISTRATION_TAX.ERROR_MSG' | translate"
                ></span>
              </mat-error>
            </mat-form-field>
            <mat-error *ngIf="errorMsgs['final_registration_tax']"
              >{{errorMsgs['final_registration_tax'][0]}}
            </mat-error>
          </div>
        </div>
        <div class="row mt-3 justify-content-center">
          <div class="col text-center">
            <span style="font-size: 13px; font-weight: 400; color: rgba(164, 19, 19, 0.87)"
              >{{'TASKS.VIEW.BOOK_REGISTRATION_TAX.END_TASK'|translate}}</span
            >
          </div>
        </div>
      </div>
      <hr />
      <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
        <div class="kt-form__actions kt-form__actions--sm" style="padding: 1px 13px 13px 10px">
          <button style="background: none" mat-button (click)="dialogRef.close()">
            {{'GENERAL.CANCEL' | translate}}</button
          >&nbsp;
          <button
            class="btn btn-primary"
            color="primary"
            mat-raised-button
            type="submit"
            style="background: none; /* color: red; */ color: #589ff4; font-size: 14px"
            [disabled]="viewLoading"
          >
            {{'GENERAL.SAVE'|translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
