import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MultiDropdownService } from "../multi-dropdown.service";

@Component({
  selector: "kt-multi-dropdown-menu",
  templateUrl: "./multi-dropdown-menu.component.html",
  styleUrls: ["./multi-dropdown-menu.component.scss"],
})
export class MultiDropdownMenuComponent implements OnInit {
  @Input() itemList: any[];
  @Input() filters: any[];
  @Output() filtersChange = new EventEmitter<any>();
  @Input() isMultiple: boolean = true;
  constructor(private multiDropdownService: MultiDropdownService) {}

  ngOnInit(): void {}

  onFilterChange(): void {
    this.filtersChange.next(this.filters);
    this.multiDropdownService.dataFilterChanged$.next();
  }
}
