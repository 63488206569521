export class PaymentModel {
  id: number;
  contract_id: any;
  date: any;
  from_date: any;
  to_date: any;
  product_id: any;
  product: any;
  is_booked: boolean;
  price_excl_vat_cents: any;
  contract_customers?: [];
  vat_deduction_corporate_cents: any;
  created_at: string;
  updated_at: string;

  clear() {
    this.date = "";
    this.from_date = "";
    this.to_date = "";
    this.price_excl_vat_cents = 0;
    this.vat_deduction_corporate_cents = 0;
    this.created_at = "";
    this.updated_at = null;
    this.is_booked = false;
  }
}
