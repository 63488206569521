<div class="kt-portlet">
  <div
    class="kt-portlet__head kt-portlet__head__custom"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <div class="kt-portlet__head-progress">
      <!-- here can place a progress bar-->
      <mat-progress-bar mode="indeterminate" *ngIf="viewLoading"></mat-progress-bar>
    </div>
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">
        {{ getComponentTitle() }}
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>
  </div>
  <div class="kt-form">
    <div class="kt-portlet__body" *ngIf="product">
      <form [formGroup]="productForm" autocomplete="off" class="kt-form">
        <div class="kt-form__group">
          <div class="row">
            <div class="col-md-6 kt-margin-bottom-10-mobile">
              <label class="mat-input-label">{{
                "CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.MILEAGE.PLACEHOLDER" | translate
              }}</label>
              <mat-form-field
                class="mat-form-field-fluid"
                [ngClass]="{ 'mat-form-field-invalid': errorMsgs['mileage'] }"
              >
                <input
                  matInput
                  type="text"
                  placeholder="{{ 'CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.MILEAGE.PLACEHOLDER' | translate }}"
                  formControlName="mileage"
                  autocomplete="off"
                />
                <mat-error *ngIf="productForm.controls.mileage.hasError('required')">
                  <span [innerHTML]="'CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.MILEAGE.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['mileage']">{{ errorMsgs["mileage"][0] }}</mat-error>
            </div>

            <div class="col-md-6 kt-margin-bottom-10-mobile">
              <label class="mat-input-label">{{
                "CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.CUSTOMER.PLACEHOLDER" | translate
              }}</label>
              <mat-form-field
                class="mat-form-field-fluid"
                [ngClass]="{ 'mat-form-field-invalid': errorMsgs['customer'] }"
              >
                <mat-select
                  placeholder="{{ 'CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.CUSTOMER.PLACEHOLDER' | translate }}"
                  formControlName="customer"
                  [compareWith]="compareFn"
                >
                  <mat-option [value]="customer" *ngFor="let customer of customers">
                    {{ customer.customer.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="productForm.controls.customer.hasError('required')">
                  <span [innerHTML]="'CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.CUSTOMER.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['customer']"> {{ errorMsgs["customer"] }}</mat-error>
            </div>
          </div>

          <div class="row" *ngIf="contract_type != 'split_leasing'">
            <div class="col-md-6 kt-margin-bottom-10-mobile">
              <label class="mat-input-label">{{
                "CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.DATE.PLACEHOLDER" | translate
              }}</label>
              <mat-form-field class="mat-form-field-fluid" [ngClass]="{ 'mat-form-field-invalid': errorMsgs['date'] }">
                <input
                  matInput
                  [matDatepicker]="datePicker"
                  placeholder="{{ 'CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.DATE.PLACEHOLDER' | translate }}"
                  [formControl]="date"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker
                  #datePicker
                  startView="year"
                  (yearSelected)="chosenYearHandler($event)"
                  (monthSelected)="chosenMonthHandler($event, datePicker)"
                  panelClass="example-month-picker"
                >
                </mat-datepicker>
                <!-- <mat-error *ngIf="productForm.controls.date.hasError('required')">
                                    <span
                                        [innerHTML]="'CONTRACTS.DETAILS.MILEAGE.ADD.FIELDS.DATE.ERROR_MSG' | translate"></span>
                                </mat-error> -->
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['date']">{{ errorMsgs["date"][0] }}</mat-error>
            </div>
          </div>

          <div class="row" *ngIf="contract_type == EntityTypes.SPLIT_LEASING">
            <div class="col-md-6 kt-margin-bottom-10-mobile">
              <label class="mat-input-label">{{ "CONTRACTS.DETAILS.MILEAGE.FROM_DATE" | translate }}</label>
              <mat-form-field
                class="mat-form-field-fluid"
                [ngClass]="{ 'mat-form-field-invalid': errorMsgs['date_from'] }"
              >
                <input
                  matInput
                  [matDatepicker]="mileageFromDatePicker"
                  placeholder="{{ 'CONTRACTS.DETAILS.MILEAGE.FROM_DATE' | translate }}"
                  formControlName="date_from"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="mileageFromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #mileageFromDatePicker></mat-datepicker>
                <mat-error *ngIf="productForm.controls.date_from.hasError('required')">
                  <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.DATE.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['date_from']">{{ errorMsgs["date_from"][0] }}</mat-error>
            </div>

            <div class="col-md-6 kt-margin-bottom-10-mobile">
              <label class="mat-input-label">{{ "CONTRACTS.DETAILS.MILEAGE.TO_DATE" | translate }}</label>
              <mat-form-field
                class="mat-form-field-fluid"
                [ngClass]="{ 'mat-form-field-invalid': errorMsgs['date_to'] }"
              >
                <input
                  matInput
                  [matDatepicker]="mileageToDatePicker"
                  placeholder="{{ 'CONTRACTS.DETAILS.MILEAGE.TO_DATE' | translate }}"
                  formControlName="date_to"
                  type="text"
                />
                <mat-datepicker-toggle matSuffix [for]="mileageToDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #mileageToDatePicker></mat-datepicker>
                <mat-error *ngIf="productForm.controls.date_to.hasError('required')">
                  <span [innerHTML]="'TRANSACTIONS.ADD.FIELDS.DATE.ERROR_MSG' | translate"></span>
                </mat-error>
              </mat-form-field>
              <mat-error *ngIf="errorMsgs['date_to']">{{ errorMsgs["date_to"][0] }}</mat-error>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button mat-button (click)="dialogRef.close()">
          {{ "GENERAL.CANCEL" | translate }}</button
        >&nbsp;
        <button mat-button (click)="onSubmit()" color="primary" cdkFocusInitial [disabled]="viewLoading">
          {{ "GENERAL.SAVE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
