import { Component, Input, OnInit } from "@angular/core";
import { InvoiceStatus } from "../../../shared/enums/invoice/invoiceStatus";

@Component({
  selector: "invoice-status-button",
  templateUrl: "./invoice-status-button.component.html",
  styleUrls: ["./invoice-status-button.component.scss"],
})
export class InvoiceStatusButtonComponent implements OnInit {
  @Input() status: InvoiceStatus;
  invoiceStatus = InvoiceStatus;
  constructor() {}

  ngOnInit(): void {}
}
