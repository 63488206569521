import { BaseModel } from "../../_base/crud/models/_base.model";
import { Address } from "./address.model";
import { SocialNetworks } from "./social-networks.model";

export class User extends BaseModel {
  id: number;
  username: string;
  password: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  roles: object[];
  role?: object;
  permissions: object[];
  pic: string;
  fullname: string;
  name: string;
  occupation: string;
  companyName: string;
  phone: string;
  address: Address;
  socialNetworks: SocialNetworks;

  clear(): void {
    this.id = undefined;
    this.username = "";
    this.password = "";
    this.email = "";
    this.roles = [];
    this.permissions = [];
    this.fullname = "";
    this.accessToken = "access-token-" + Math.random();
    this.refreshToken = "access-token-" + Math.random();
    this.pic = "./assets/media/users/default.jpg";
    this.occupation = "";
    this.companyName = "";
    this.phone = "";
    this.address = new Address();
    this.address.clear();
    this.socialNetworks = new SocialNetworks();
    this.socialNetworks.clear();
  }
}
